import { Space } from 'antd';

import { FormattedMessageWithMarkup } from '@/components';
import { OperationRefreshMerchantWallet } from '@/features/merchant-wallets/components';
import { useMerchantWallet } from '@/features/merchant-wallets/hooks';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withMerchantWalletDataLoading = <
  Original extends { data: MerchantWallet } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { bt: BlockchainTypeAPIModel; address?: string } = Omit<Original, 'data'> & {
    bt: BlockchainTypeAPIModel;
    address?: string;
  },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithMerchantWalletDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useMerchantWallet(props.bt, props.address);
    return withCardDataLoading<MerchantWallet, Original>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title: (
        <FormattedMessageWithMarkup id={I18nPageAudit.WALLET_VIEW_COMPONENTS_WALLET_CARD} values={{ bt: props.bt }} />
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshMerchantWallet
              bt={props.bt}
              address={props.address}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withMerchantWalletDataLoading;
