import React, { useEffect, useRef } from 'react';

import { useReport, useReportActions } from '@/features/reports/hooks';
import { useIsMounted, useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

import type { ReportDownloaderProps } from './types';

const ReportDownloaderRaw: React.FC<ReportDownloaderProps> = ({ 'data-test': dataTest, reportId }) => {
  const generatedFileId = useRef<string>();
  const { data } = useReport(reportId);
  const { generateURL } = useReportActions(reportId);
  const [reportURL, setReportURL] = useStateMountSafe<string>();
  const isMounted = useIsMounted();
  useEffect(() => {
    withSuppressPromise(async () => {
      if (isMounted()) {
        if (data.data?.fileId && generatedFileId.current !== data.data.fileId) {
          generatedFileId.current = data.data.fileId;
          const url = await generateURL.act();
          setReportURL(url);
        }
      }
    })();
  }, [data.data?.fileId, generateURL, isMounted, reportURL, setReportURL]);
  /**
   This iframe is a workaround:
   1. we have no way to authorize get request so we use otp
   2. but there is no safe way to download file programmatically (the browser should do that itself)
   */
  return reportURL ? <iframe data-test={dataTest} src={reportURL} style={{ display: 'none' }} title="report" /> : null;
};

const ReportDownloader = React.memo(ReportDownloaderRaw);

export default ReportDownloader;
