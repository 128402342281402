import type {
  SubscriptionChargeAPIModel,
  SubscriptionChargePredicatesAPIModel,
  SubscriptionChargeSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { SubscriptionChargeApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import {
  amountFromAPI,
  type DataSlice,
  pageToAPI,
  type SliceDataRequestWithTotal,
  sliceFromAPI,
} from '@/infrastructure/model/api';

import type { SubscriptionCharge } from './types';

const subscriptionChargeApi = new SubscriptionChargeApi(coreConfigurationFactory('auth'));

const parseSubscriptionCharge = ({
  amount,
  ...subscriptionCharge
}: SubscriptionChargeAPIModel): SubscriptionCharge => ({
  ...subscriptionCharge,
  amount: amountFromAPI(amount),
});

export const querySubscriptionCharges = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<SubscriptionChargePredicatesAPIModel, SubscriptionChargeSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<SubscriptionCharge, SubscriptionChargeSortByAPIModel>> => {
  const { list, ...slice } = sliceFromAPI(
    await subscriptionChargeApi.searchSubscriptionCharges(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
  );
  return { ...slice, list: list.map(parseSubscriptionCharge) };
};

export const querySubscriptionCharge = async (id: string, initOverrides?: RequestInit) =>
  parseSubscriptionCharge(await subscriptionChargeApi.getSubscriptionCharge(id, initOverrides));
