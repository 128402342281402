import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import { PayoutsIcon } from '@/features/payouts/components';
import { I18nMenu } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';
import type { CustomizableRoute } from '@/layouts/types';
import { PayoutsPageRoutes, payoutsRoutes, payoutsLink } from '@/pages/payouts/routes';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (
  selected: boolean,
  formatMessage: IntlFormattersArrow['formatMessage'],
  featureFlags: FeatureFlags | undefined,
): CustomizableRoute[] =>
  featureFlags?.enablePayouts
    ? [
        {
          name: formatMessage({ id: I18nMenu.PAYOUTS }),
          key: appRoute(AppPageRoutes.PAYOUTS),
          icon: <PayoutsIcon />,
          path: payoutsLink(),
          hideChildrenInMenu: !selected,

          children: enumValues(PayoutsPageRoutes)
            .filter((route) => route !== PayoutsPageRoutes.ALL)
            .map((route) => payoutsRoutes(route))
            .map((path) => ({ path })),
        },
      ]
    : [];

export default function useMenuData(): CustomizableRoute[] {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.PAYOUTS));
  return useMemo(() => menuCreator(selected, formatMessage, featureFlags), [featureFlags, formatMessage, selected]);
}
