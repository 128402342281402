import { Form } from 'antd';
import get from 'lodash-es/get';
import React, { useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';

import { I18nComponents } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';
import { nameof } from '@/infrastructure/utils/ts';

import FormattedMessage from '../FormattedMessage';

import { DateTimePickerWrapper } from './components';

import type { DateTimeRange, DateTimeRangeItemProps } from './types';
import type { Dayjs } from 'dayjs';
import type { Rule, NamePath, Store } from 'rc-field-form/es/interface';

const DateTimeRangeItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  mode = 'dateTime',
  readonly,
  required,
  messages,
  InputToProps,
  InputFromProps,
  relativeUpdateKey,
}: DateTimeRangeItemProps<Values>) => {
  const { formatMessage, formatDate } = useIntl();
  const { formatDateTime } = useLocaleSettings();
  const formatValue = useCallback(
    (v?: Dayjs) => (mode === 'date' ? formatDate : formatDateTime)(v?.toDate()),
    [formatDate, formatDateTime, mode],
  );
  const validateRange: Rule = useCallback(
    ({ getFieldValue }: { getFieldValue: (name: NamePath) => Date | undefined }) => ({
      validator: async () => {
        const from = getFieldValue([name, nameof<DateTimeRange>('from')]);
        const to = getFieldValue([name, nameof<DateTimeRange>('to')]);
        if (to && from && to.getTime() <= from.getTime()) {
          throw new Error();
        }
        return Promise.resolve();
      },
      message: <FormattedMessage id={I18nComponents.DATE_TIME_RANGE_FILTER_FROM_GTE_TO_ERROR} />,
    }),
    [name],
  );
  return (
    <>
      <Form.Item
        label={messages?.from?.label || <FormattedMessage id={I18nComponents.DATE_TIME_RANGE_FILTER_FROM_LABEL} />}
        name={[...(Array.isArray(name) ? name : [name]), nameof<DateTimeRange>('from')]}
        rules={[validateRange]}
        required={required}
        dependencies={[[...(Array.isArray(name) ? name : [name]), nameof<DateTimeRange>('to')]]}
      >
        <DateTimePickerWrapper
          timeMode={mode}
          format={formatValue}
          readonly={readonly}
          data-test={dataTest && `${dataTest}-from`}
          placeholder={
            !readonly
              ? (messages?.from?.placeholder
                ?? formatMessage({ id: I18nComponents.DATE_TIME_RANGE_FILTER_FROM_PLACEHOLDER }))
              : ''
          }
          style={{ width: '100%' }}
          {...InputFromProps}
        />
      </Form.Item>
      <Form.Item
        label={messages?.to?.label || <FormattedMessage id={I18nComponents.DATE_TIME_RANGE_FILTER_TO_LABEL} />}
        name={[...(Array.isArray(name) ? name : [name]), nameof<DateTimeRange>('to')]}
        rules={[validateRange]}
        dependencies={[[...(Array.isArray(name) ? name : [name]), nameof<DateTimeRange>('from')]]}
      >
        <DateTimePickerWrapper
          timeMode={mode}
          format={formatValue}
          readonly={readonly}
          data-test={dataTest && `${dataTest}-to`}
          placeholder={
            !readonly
              ? messages?.to?.placeholder || formatMessage({ id: I18nComponents.DATE_TIME_RANGE_FILTER_TO_PLACEHOLDER })
              : ''
          }
          style={{ width: '100%' }}
          {...InputToProps}
        />
      </Form.Item>

      {relativeUpdateKey && (
        <Form.Item
          hidden
          noStyle
          shouldUpdate={(p, n, { source }) => !isEqual(get(p, name), get(n, name)) && source === 'internal'}
        >
          {({ setFieldsValue, getFieldValue, isFieldTouched }) => {
            if (isFieldTouched(name) && getFieldValue(relativeUpdateKey)) {
              setTimeout(() => setFieldsValue({ [relativeUpdateKey]: undefined }), 0);
            }
            return null;
          }}
        </Form.Item>
      )}
    </>
  );
};

const DateTimeRangeItemMemo = React.memo(DateTimeRangeItem) as typeof DateTimeRangeItem;

export default DateTimeRangeItemMemo;
