import React from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useRechargeAddress } from '@/features/recharges/hooks';
import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { rechargeAddressViewLink } from '@/pages/recharges/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { RechargeEntityLinkProps } from './types';

const RechargeEntityLink: React.FC<RechargeEntityLinkProps> = ({ 'data-test': dataTest, value }) => {
  const data = useRechargeAddress(value.id);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={rechargeAddressViewLink(value.id)} replace />;
  }

  return (
    <EntityNotFoundAlert data-test={dataTest} value={CollectableKindAPIModel.Recharge} onRetry={data.forceRefresh} />
  );
};

const RechargeEntityLinkMemo = React.memo(RechargeEntityLink);

export default RechargeEntityLinkMemo;
