import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { usePayout } from '@/features/payouts/hooks';
import usePayoutWeb3Actions from '@/features/payouts/hooks/usePayoutWeb3Actions';
import type { PayoutSummary } from '@/features/payouts/types';
import { OperationRefreshBalances } from '@/features/statistics/components';
import { useUser } from '@/features/user/hooks';
import type { WalletOperationProps } from '@/features/web3/components';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, withLogError, withOnSuccess } from '@/infrastructure/utils/functions';
import { isRejected, withRejectedByUser } from '@/infrastructure/utils/ui';
import PayoutViewLink from '@/pages/payouts/payout-view/components/PayoutViewLink';

import type { OperationStartPayoutWithConnectorProps } from './types';

const OperationStartPayoutWithConnector: React.FC<OperationStartPayoutWithConnectorProps> = ({
  'data-test': dataTest,
  payoutId,
  onSuccess,
  withInProgress = emptyWith,
}) => {
  const {
    data: { data: payout },
  } = usePayout(payoutId);
  const { start: startAction } = usePayoutWeb3Actions(payoutId);
  const {
    data: { data: user },
  } = useUser();
  const blockchainState = useBlockchainSystemInfo(payout?.blockchain);
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) =>
        state.apiType === BlockchainAPITypeAPIModel.WEB3 ? state : null,
      ),
    [blockchainState.data],
  );
  const { connector } = useWeb3Connector();
  const { withNotification } = useNotification();
  const options = useMemo(
    () => ({
      error: (error?: unknown) =>
        !isRejected(error)
          ? {
              message: (
                <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_FAILED_MESSAGE} />
              ),
            }
          : undefined,
      success: (payout: PayoutSummary) => ({
        message: (
          <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_SUCCESS_MESSAGE} />
        ),
        description: (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_SUCCESS_DESCRIPTION}
            values={{
              link: (title: React.ReactNode) => <PayoutViewLink value={payout.id} title={title} mode="text" />,
            }}
          />
        ),
      }),
    }),
    [],
  );
  const doStart = useMemo(
    () =>
      withLogError(
        withInProgress(withNotification(withOnSuccess(withRejectedByUser(startAction.act), onSuccess), options)),
      ),
    [withNotification, withInProgress, startAction.act, onSuccess, options],
  );
  const disabledMessage = useMemo(() => {
    switch (startAction.unavailabilityReason) {
      case 'no-data':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'invalid-status':
        return (
          <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_STATUS} />
        );
      case 'invalid-account':
        return (
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_ACCOUNT}
            values={{ account: user?.address }}
          />
        );
      case 'invalid-chain-id':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_CHAIN_ID}
            values={{ bt: payout?.blockchain }}
          />
        );
      case 'unsupported-chain':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_UNSUPPORTED}
            values={{ bt: payout?.blockchain }}
          />
        );
      case 'insufficient-balance':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INSUFFICIENT_BALANCE}
            values={{ ln: (title: React.ReactNode) => <OperationRefreshBalances title={title} mode="link" /> }}
          />
        );
      case undefined:
        return undefined;
    }
  }, [payout?.blockchain, startAction.unavailabilityReason, user?.address]);

  const requiredChain = useMemo(
    () => (blockchain.data?.chainId ? { id: blockchain.data.chainId, network: blockchain.data.bt } : undefined),
    [blockchain.data?.bt, blockchain.data?.chainId],
  );

  const action: WalletOperationProps['mainAction'] = useMemo(
    () => ({
      withClient: true,
      onAction: doStart,
      title: (
        <FormattedMessage
          id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_TITLE}
          values={{ connector: connector.name }}
        />
      ),
    }),
    [connector.name, doStart],
  );

  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connector.id}
      requiredChain={requiredChain}
      requiredAccount={user?.address}
      disabled={!startAction.available}
      disabledMessage={disabledMessage}
      inProgress={startAction.inAction}
      mainAction={action}
    />
  );
};

const OperationStartPayoutWithConnectorContexted = withWeb3Connector(OperationStartPayoutWithConnector);

const OperationStartPayoutWithConnectorMemo = React.memo(
  OperationStartPayoutWithConnectorContexted,
) as typeof OperationStartPayoutWithConnectorContexted;

export default OperationStartPayoutWithConnectorMemo;
