import { useCallback, useMemo } from 'react';

import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchQRDelegateContracts } from '@/features/company-settings/actions';
import { makeSelectQRDelegateContracts } from '@/features/company-settings/selectors';
import type { QRDelegateContract } from '@/features/company-settings/types';
import { useListBlockchains } from '@/features/dictionary/blockchain/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<QRDelegateContract[]>, Parameters<typeof fetchQRDelegateContracts>[0]> =>
  fetchQRDelegateContracts({ force });
const selectFetching = makeSelectPending(fetchQRDelegateContracts);
const selectData = makeSelectQRDelegateContracts();

export default function useQRDelegateContracts(network?: BlockchainNetworkTypeAPIModel) {
  const delegatesState = useAppLoadableData(fetch, selectData, selectFetching);

  const blockchainsState = useListBlockchains(network);
  const networkBlockchain = useMemo(
    () =>
      (
        (network ? blockchainsState.data.data?.filter(({ net }) => network === net) : blockchainsState.data.data) ?? []
      ).map(({ blockchain }) => blockchain),
    [blockchainsState.data.data, network],
  );
  const forceRefresh = useCallback(
    async () =>
      mapStoredState(await delegatesState.forceRefresh(), (delegates) =>
        network ? delegates.filter(({ blockchain }) => networkBlockchain.includes(blockchain)) : delegates,
      ),
    [network, networkBlockchain, delegatesState],
  );

  const data = useMemo(
    () =>
      mapStoredState(delegatesState.data, (delegates) =>
        network ? delegates.filter(({ blockchain }) => networkBlockchain.includes(blockchain)) : delegates,
      ),
    [network, networkBlockchain, delegatesState.data],
  );

  return { forceRefresh, data, loading: delegatesState.loading };
}
