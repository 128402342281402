/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CompanyBrandingProfileAPIModel,
  CompanyBrandingProfileContentAPIModel,
  CompanyBrandingResponseAPIModel,
  CompanyToSContentAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CompanyBrandingProfileAPIModelFromJSON,
    CompanyBrandingProfileAPIModelToJSON,
    CompanyBrandingProfileContentAPIModelFromJSON,
    CompanyBrandingProfileContentAPIModelToJSON,
    CompanyBrandingResponseAPIModelFromJSON,
    CompanyBrandingResponseAPIModelToJSON,
    CompanyToSContentAPIModelFromJSON,
    CompanyToSContentAPIModelToJSON,
} from '../models/index';

export interface ActivateBrandingProfileRequest {
    profileId: string;
}

export interface CreateBrandingProfileRequest {
    companyBrandingProfileContentAPIModel: CompanyBrandingProfileContentAPIModel;
}

export interface DeactivateBrandingProfileRequest {
    profileId: string;
}

export interface DeleteBrandingProfileRequest {
    profileId: string;
}

export interface GetBrandingProfileRequest {
    profileId: string;
}

export interface UpdateBrandingProfileRequest {
    profileId: string;
    companyBrandingProfileContentAPIModel: CompanyBrandingProfileContentAPIModel;
}

export interface UpdateTermsOfServiceRequest {
    companyToSContentAPIModel: CompanyToSContentAPIModel;
}

/**
 * CompanyBrandingApi - interface
 * 
 * @export
 * @interface CompanyBrandingApiInterface
 */
export interface CompanyBrandingApiInterface {
    /**
     * Activates branding profile
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    activateBrandingProfileRaw(requestParameters: ActivateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>>;

    /**
     * Activates branding profile
     */
    activateBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel>;

    /**
     * Creates a new profile
     * @param {CompanyBrandingProfileContentAPIModel} companyBrandingProfileContentAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    createBrandingProfileRaw(requestParameters: CreateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingProfileAPIModel>>;

    /**
     * Creates a new profile
     */
    createBrandingProfile(companyBrandingProfileContentAPIModel: CompanyBrandingProfileContentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingProfileAPIModel>;

    /**
     * Deactivates branding profile
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    deactivateBrandingProfileRaw(requestParameters: DeactivateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>>;

    /**
     * Deactivates branding profile
     */
    deactivateBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel>;

    /**
     * Deletes branding profile
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    deleteBrandingProfileRaw(requestParameters: DeleteBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>>;

    /**
     * Deletes branding profile
     */
    deleteBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel>;

    /**
     * Deletes branding profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    deleteTermsOfServiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Deletes branding profile
     */
    deleteTermsOfService(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Returns the profile by id
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    getBrandingProfileRaw(requestParameters: GetBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingProfileAPIModel>>;

    /**
     * Returns the profile by id
     */
    getBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingProfileAPIModel>;

    /**
     * Returns profiles data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    getBrandingProfilesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>>;

    /**
     * Returns profiles data
     */
    getBrandingProfiles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel>;

    /**
     * Returns company Terms of Service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    getTermsOfServiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyToSContentAPIModel>>;

    /**
     * Returns company Terms of Service
     */
    getTermsOfService(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyToSContentAPIModel>;

    /**
     * Updates branding profile
     * @param {string} profileId 
     * @param {CompanyBrandingProfileContentAPIModel} companyBrandingProfileContentAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    updateBrandingProfileRaw(requestParameters: UpdateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>>;

    /**
     * Updates branding profile
     */
    updateBrandingProfile(profileId: string, companyBrandingProfileContentAPIModel: CompanyBrandingProfileContentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel>;

    /**
     * Updates company Terms of Service
     * @param {CompanyToSContentAPIModel} companyToSContentAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyBrandingApiInterface
     */
    updateTermsOfServiceRaw(requestParameters: UpdateTermsOfServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates company Terms of Service
     */
    updateTermsOfService(companyToSContentAPIModel: CompanyToSContentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class CompanyBrandingApi extends runtime.BaseAPI implements CompanyBrandingApiInterface {

    /**
     * Activates branding profile
     */
    async activateBrandingProfileRaw(requestParameters: ActivateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>> {
        if (requestParameters['profileId'] == null) {
            throw new runtime.RequiredError(
                'profileId',
                'Required parameter "profileId" was null or undefined when calling activateBrandingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles/{profileId}/activate`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters['profileId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Activates branding profile
     */
    async activateBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel> {
        const response = await this.activateBrandingProfileRaw({ profileId: profileId }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new profile
     */
    async createBrandingProfileRaw(requestParameters: CreateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingProfileAPIModel>> {
        if (requestParameters['companyBrandingProfileContentAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companyBrandingProfileContentAPIModel',
                'Required parameter "companyBrandingProfileContentAPIModel" was null or undefined when calling createBrandingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBrandingProfileContentAPIModelToJSON(requestParameters['companyBrandingProfileContentAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingProfileAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates a new profile
     */
    async createBrandingProfile(companyBrandingProfileContentAPIModel: CompanyBrandingProfileContentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingProfileAPIModel> {
        const response = await this.createBrandingProfileRaw({ companyBrandingProfileContentAPIModel: companyBrandingProfileContentAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Deactivates branding profile
     */
    async deactivateBrandingProfileRaw(requestParameters: DeactivateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>> {
        if (requestParameters['profileId'] == null) {
            throw new runtime.RequiredError(
                'profileId',
                'Required parameter "profileId" was null or undefined when calling deactivateBrandingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles/{profileId}/deactivate`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters['profileId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Deactivates branding profile
     */
    async deactivateBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel> {
        const response = await this.deactivateBrandingProfileRaw({ profileId: profileId }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes branding profile
     */
    async deleteBrandingProfileRaw(requestParameters: DeleteBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>> {
        if (requestParameters['profileId'] == null) {
            throw new runtime.RequiredError(
                'profileId',
                'Required parameter "profileId" was null or undefined when calling deleteBrandingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters['profileId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Deletes branding profile
     */
    async deleteBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel> {
        const response = await this.deleteBrandingProfileRaw({ profileId: profileId }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes branding profile
     */
    async deleteTermsOfServiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/tos`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes branding profile
     */
    async deleteTermsOfService(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTermsOfServiceRaw(initOverrides);
    }

    /**
     * Returns the profile by id
     */
    async getBrandingProfileRaw(requestParameters: GetBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingProfileAPIModel>> {
        if (requestParameters['profileId'] == null) {
            throw new runtime.RequiredError(
                'profileId',
                'Required parameter "profileId" was null or undefined when calling getBrandingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters['profileId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingProfileAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns the profile by id
     */
    async getBrandingProfile(profileId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingProfileAPIModel> {
        const response = await this.getBrandingProfileRaw({ profileId: profileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns profiles data
     */
    async getBrandingProfilesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns profiles data
     */
    async getBrandingProfiles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel> {
        const response = await this.getBrandingProfilesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns company Terms of Service
     */
    async getTermsOfServiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyToSContentAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/tos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyToSContentAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns company Terms of Service
     */
    async getTermsOfService(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyToSContentAPIModel> {
        const response = await this.getTermsOfServiceRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates branding profile
     */
    async updateBrandingProfileRaw(requestParameters: UpdateBrandingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyBrandingResponseAPIModel>> {
        if (requestParameters['profileId'] == null) {
            throw new runtime.RequiredError(
                'profileId',
                'Required parameter "profileId" was null or undefined when calling updateBrandingProfile().'
            );
        }

        if (requestParameters['companyBrandingProfileContentAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companyBrandingProfileContentAPIModel',
                'Required parameter "companyBrandingProfileContentAPIModel" was null or undefined when calling updateBrandingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/profiles/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters['profileId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyBrandingProfileContentAPIModelToJSON(requestParameters['companyBrandingProfileContentAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBrandingResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates branding profile
     */
    async updateBrandingProfile(profileId: string, companyBrandingProfileContentAPIModel: CompanyBrandingProfileContentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyBrandingResponseAPIModel> {
        const response = await this.updateBrandingProfileRaw({ profileId: profileId, companyBrandingProfileContentAPIModel: companyBrandingProfileContentAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates company Terms of Service
     */
    async updateTermsOfServiceRaw(requestParameters: UpdateTermsOfServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyToSContentAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companyToSContentAPIModel',
                'Required parameter "companyToSContentAPIModel" was null or undefined when calling updateTermsOfService().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/branding/tos`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToSContentAPIModelToJSON(requestParameters['companyToSContentAPIModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates company Terms of Service
     */
    async updateTermsOfService(companyToSContentAPIModel: CompanyToSContentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTermsOfServiceRaw({ companyToSContentAPIModel: companyToSContentAPIModel }, initOverrides);
    }

}
