import { css } from '@emotion/css';
import { Tooltip, theme } from 'antd';
import React, { useMemo } from 'react';

import type { WarningTooltipProps } from './types';

const { useToken } = theme;

const WarningTooltip: React.FC<WarningTooltipProps> = ({ title, type = 'warning', open, children }) => {
  const token = useToken();
  return (
    <Tooltip
      title={title}
      color={type === 'warning' ? token.token.orange2 : token.token.red1}
      classNames={useMemo(
        () => ({
          root: css`
            --antd-arrow-background-color: ${type === 'warning' ? token.token.orange4 : token.token.red2} !important;
            border: 1px solid ${type === 'warning' ? token.token.orange4 : token.token.red2};
            border-radius: 5px;
          `,
        }),
        [token.token.orange4, token.token.red2, type],
      )}
      styles={useMemo(
        () => ({ body: { color: type === 'warning' ? token.token.orange8 : token.token.red7 } }),
        [token.token.orange8, token.token.red7, type],
      )}
      open={open}
    >
      {children}
    </Tooltip>
  );
};

const WarningTooltipMemo = React.memo(WarningTooltip);

export default WarningTooltipMemo;
