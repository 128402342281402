import React from 'react';

import type { QRDelegateContractsListProps } from '@/features/company-settings/components';
import { OperationDeployQRDelegateContract } from '@/features/company-settings/components';

const QRDelegateContractActualListOperations: QRDelegateContractsListProps['Operations'] = ({
  'data-test': dataTest,
  value,
}) => <OperationDeployQRDelegateContract data-test={dataTest} value={value.id} />;

const QRDelegateContractActualListOperationsMemo = React.memo(QRDelegateContractActualListOperations);

export default QRDelegateContractActualListOperationsMemo;
