import { PageContainer } from '@/components';
import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withPageParams } from '@/hocs';
import { optionalParamsParser } from '@/hocs/withPageParams/utils';
import type { TestableProps } from '@/infrastructure/utils/react';
import { enumByKey } from '@/infrastructure/utils/ts';

import withPageQuery from '../../../hocs/withPageQuery';

import { MerchantWalletBreadcrumb, MerchantWalletCard, MerchantWalletTransactionsCard } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface MerchantWalletPageParams {
  bt: BlockchainTypeAPIModel;
}

interface MerchantWalletPageQuery {
  address: string | undefined;
}

const paramParser = (
  params: Partial<Record<keyof MerchantWalletPageParams, string | undefined>>,
): MerchantWalletPageParams | undefined => {
  try {
    const bt = enumByKey(BlockchainTypeAPIModel, params.bt);
    return bt ? { bt } : undefined;
  } catch {
    return undefined;
  }
};

type MerchantWalletPageProps = PropsWithChildren<
  TestableProps & { pageParams: MerchantWalletPageParams } & {
    pageQuery: MerchantWalletPageQuery;
  }
>;

const MerchantWalletPage: React.FC<MerchantWalletPageProps> = ({
  'data-test': dataTest = 'mw',
  pageParams: { bt },
  pageQuery: { address },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <MerchantWalletBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled bt={bt} address={address} />
    }
  >
    <MerchantWalletCard data-test={dataTest && `${dataTest}-wallet`} bt={bt} address={address} />
    <MerchantWalletTransactionsCard data-test={dataTest && `${dataTest}-transactions`} bt={bt} address={address} />
  </PageContainer>
);

const MerchantWalletPageWithParam = withPageParams<keyof MerchantWalletPageParams, MerchantWalletPageParams>(
  paramParser,
)(
  withPageQuery<
    keyof MerchantWalletPageQuery,
    MerchantWalletPageQuery,
    MerchantWalletPageQuery,
    MerchantWalletPageProps
  >(optionalParamsParser(['address']))(MerchantWalletPage),
);

export default MerchantWalletPageWithParam;
