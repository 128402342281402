import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { deployQRDelegateContract } from '@/features/company-settings/actions';
import { useQRDelegateContract } from '@/features/company-settings/hooks';
import type { QRDelegateContract } from '@/features/company-settings/types';
import { useActionPending } from '@/features/global/hooks';
import { useMerchantWallet } from '@/features/merchant-wallets/hooks';
import type { BlockchainTypeAPIModel} from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletStatusAPIModel , ForwarderDelegateStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';

export type QRDelegateDeployActionUnavailability =
  | 'no-data'
  | 'loading'
  | 'already-deployed'
  | 'deploying'
  | 'no-merchant-wallet';

export interface UseQRDelegateContractActions {
  deploy: HookAction<[], QRDelegateContract, QRDelegateDeployActionUnavailability>;
}

export default function useQRDelegateContractActions(
  btOrId: BlockchainTypeAPIModel | string | undefined,
): UseQRDelegateContractActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data, loading } = useQRDelegateContract(btOrId);
  const { data: walletState, loading: walletLoading } = useMerchantWallet(
    data.data?.blockchain,
    data.data?.merchantWallet,
  );

  const unavailabilityReason: UseQRDelegateContractActions['deploy']['unavailabilityReason'] = useMemo(() => {
    if ((!data.data && data.isDirty && loading) || (!walletState.data && walletState.isDirty && !walletLoading))
      return 'loading';
    if (!data.data) return 'no-data';
    if (walletState.data?.status !== MerchantWalletStatusAPIModel.Deployed) return 'no-merchant-wallet';
    if (data.data.status === ForwarderDelegateStatusAPIModel.Deployed) return 'already-deployed';
    if (data.data.status === ForwarderDelegateStatusAPIModel.Pending) return 'deploying';
    return undefined;
  }, [data.data, data.isDirty, loading, walletLoading, walletState.data, walletState.isDirty]);

  const deployQRDelegateContractPending = useActionPending(deployQRDelegateContract);

  const deployQRDelegateContractAction = useCallback(
    () => withExtractDataDispatch(deployQRDelegateContract)(someOrFail(data.data?.id)),
    [data.data?.id, withExtractDataDispatch],
  );
  const deploy = useMemo(
    () => ({
      act: deployQRDelegateContractAction,
      available: !unavailabilityReason,
      inAction: deployQRDelegateContractPending,
      unavailabilityReason,
    }),
    [deployQRDelegateContractAction, deployQRDelegateContractPending, unavailabilityReason],
  );

  return {
    deploy,
  };
}
