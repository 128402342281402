/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteWithdrawalScheduleResponseAPIModel
 */
export interface DeleteWithdrawalScheduleResponseAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteWithdrawalScheduleResponseAPIModel
     */
    isSucceeded: boolean;
}

/**
 * Check if a given object implements the DeleteWithdrawalScheduleResponseAPIModel interface.
 */
export function instanceOfDeleteWithdrawalScheduleResponseAPIModel(value: object): boolean {
    if (!('isSucceeded' in value)) return false;
    return true;
}

export function DeleteWithdrawalScheduleResponseAPIModelFromJSON(json: any): DeleteWithdrawalScheduleResponseAPIModel {
    return DeleteWithdrawalScheduleResponseAPIModelFromJSONTyped(json, false);
}

export function DeleteWithdrawalScheduleResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteWithdrawalScheduleResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'isSucceeded': json['isSucceeded'],
    };
}

export function DeleteWithdrawalScheduleResponseAPIModelToJSON(value?: DeleteWithdrawalScheduleResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isSucceeded': value['isSucceeded'],
    };
}

