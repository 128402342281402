import type { CollectableEntityTypedId } from '@/features/collectable/types';
import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withPageParams } from '@/hocs';
import { enumByKey } from '@/infrastructure/utils/ts';

export type CollectableBalanceEntityParams = CollectableEntityTypedId;

const paramParser = (
  params: Partial<Record<keyof CollectableBalanceEntityParams, string | undefined>>,
): CollectableBalanceEntityParams | undefined => {
  try {
    const kind = enumByKey(CollectableKindAPIModel, params.kind);
    return kind && params.id ? { id: params.id, kind } : undefined;
  } catch {
    return undefined;
  }
};

const withCollectableEntityPageParams = withPageParams<
  keyof CollectableBalanceEntityParams,
  CollectableBalanceEntityParams
>(paramParser);

export default withCollectableEntityPageParams;
