import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantWalletActions } from '@/features/merchant-wallets/hooks';

import type { OperationRefreshMerchantWalletStateProps } from './types';

const OperationRefreshMerchantWalletState: React.FC<OperationRefreshMerchantWalletStateProps> = ({
  'data-test': dataTest,
  bt,
  address,
  title,
  mode = 'inline',
}) => {
  const { refresh } = useMerchantWalletActions(bt, address);
  return (
    <OperationRefresh
      refresh={refresh.act}
      data-test={dataTest}
      mode={mode}
      messages={{ title }}
      disabled={!refresh.available}
      inProgress={refresh.inAction}
    />
  );
};

const OperationRefreshMerchantWalletStateMemo = React.memo(OperationRefreshMerchantWalletState);

export default OperationRefreshMerchantWalletStateMemo;
