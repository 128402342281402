import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { mapStoredState } from '@/infrastructure/model';

import { NAMESPACE } from './types';

export const makeSelectCompanySettings = () => (state: AppRootState) => state[NAMESPACE].settings;
export const makeSelectWebhooks = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].settings,
    (state) => mapStoredState(state, ({ webhooks }) => webhooks),
  );

export const makeSelectStatisticsAsset = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].settings,
    (state) => mapStoredState(state, ({ statisticsAsset }) => statisticsAsset),
  );

export const makeSelectQRDelegateContracts = () => (state: AppRootState) => state[NAMESPACE].delegates;

export const makeSelectAPIKeys = () => (state: AppRootState) => state[NAMESPACE].keys;
