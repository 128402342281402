import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayments } from '@/features/payments/actions';
import { makeSelectPaymentListData, makeSelectPaymentListParameters } from '@/features/payments/selectors';
import type { Payment } from '@/features/payments/types';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

import type { UsePaymentList } from './types';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<Payment>, Parameters<typeof fetchPayments>[0]> => fetchPayments({ force });

const dataSelector = makeSelectPaymentListData();
const dataFetchingSelector = makeSelectPending(fetchPayments);
const parametersSelector = makeSelectPaymentListParameters();

export default function usePaymentList(): UsePaymentList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
