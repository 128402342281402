import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshCollectableBalance } from '@/features/collectable/components';
import { useCollectableBalance } from '@/features/collectable/hooks';
import type { CollectableBalance, CollectableEntityTypedId } from '@/features/collectable/types';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCollectableBalanceDataLoading = <
  Original extends { data: CollectableBalance } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & {
    id: CollectableEntityTypedId;
  } = Omit<Original, 'data'> & { id: CollectableEntityTypedId },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithCollectableBalanceDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useCollectableBalance(props.id);
    return withCardDataLoading<CollectableBalance, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageBalances.COLLECTABLE_VIEW_COMPONENTS_VIEW_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshCollectableBalance id={props.id} data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withCollectableBalanceDataLoading;
