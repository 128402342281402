import { PlusCircleOutlined } from '@ant-design/icons';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { payoutAddLink } from '@/pages/payouts/routes';

import type { PayoutAddLinkProps } from './types';

const PayoutAddLink: React.FC<PayoutAddLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
  copyOf,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(payoutAddLink(copyOf)), [copyOf, openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={(icon ?? copyOf) ? <CopyOutlined /> : <PlusCircleOutlined />}
      title={
        (title ?? copyOf) ? (
          <FormattedMessage id={I18nPagePayouts.PAYOUT_ADD_COMPONENTS_LINK_COPY_TITLE} />
        ) : (
          <FormattedMessage id={I18nPagePayouts.PAYOUT_ADD_COMPONENTS_LINK_TITLE} />
        )
      }
    />
  );
};

const PayoutAddLinkMemo = React.memo(withCompanyPermissionsGuard('settleManagement')(PayoutAddLink));

export default PayoutAddLinkMemo;
