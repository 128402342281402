import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { usePayoutBatchForSettlement } from '@/features/payouts/hooks';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import { payoutBatchViewLink } from '@/pages/payouts/routes';

import type { PayoutBatchBySettlementViewLinkProps } from './types';

const PayoutBatchBySettlementViewLink: React.FC<PayoutBatchBySettlementViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => {
  const { data: batch } = usePayoutBatchForSettlement(value);
  return (
    <Link
      data-test={dataTest}
      to={batch.data ? payoutBatchViewLink(batch.data.payoutId, batch.data.num) : ''}
      mode={mode}
      disabled={!batch.data}
      title={title ?? <FormattedMessage id={I18nPagePayouts.PAYOUT_BATCH_VIEW_COMPONENTS_LINK_TITLE} />}
      icon={icon}
    />
  );
};

const PayoutBatchBySettlementViewLinkMemo = React.memo(PayoutBatchBySettlementViewLink);

export default PayoutBatchBySettlementViewLinkMemo;
