import { Form } from 'antd';
import React, { useMemo } from 'react';

import { useStateMountSafe } from '@/hooks';

import { QRSupportItemInput } from './components';

import type { QRSupportErrorData, QRSupportErrorItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Store } from 'rc-field-form/es/interface';

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const QRAuxAssetsSupportErrorItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  assets: baseAssets,
  amount,
}: QRSupportErrorItemProps<Values>) => {
  const assets = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (baseAssets ? (Array.isArray(baseAssets) ? baseAssets : [baseAssets]) : []),
    [baseAssets],
  );
  const [issue, setIssue] = useStateMountSafe(false);
  const rules = useMemo<FormItemProps<Values>['rules']>(
    () => [
      {
        validator: async (_, value?: QRSupportErrorData) => {
          setIssue(!!value?.errors);
          if (value?.errors) {
            return Promise.reject(new Error(''));
          }
        },
      },
      {
        validator: async (_, value?: QRSupportErrorData) => {
          if (value?.errors) {
            return;
          }
          setIssue(!!value?.warnings);
          if (value?.warnings) {
            return Promise.reject(new Error(''));
          }
        },
        warningOnly: true,
      },
    ],
    [setIssue],
  );

  return (
    <Form.Item<Values> name={name} rules={rules} noStyle={!issue} {...layout}>
      {amount ? (
        <QRSupportItemInput data-test={dataTest && `${dataTest}-input`} amount={amount} assets={assets} />
      ) : null}
    </Form.Item>
  );
};

const QRAuxAssetsSupportErrorItemMemo = React.memo(QRAuxAssetsSupportErrorItem) as typeof QRAuxAssetsSupportErrorItem;

export default QRAuxAssetsSupportErrorItemMemo;
