import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createPayment, exportPayments } from '@/features/payments/actions';

import usePaymentList from '../usePaymentList';

import type { UsePaymentsActions } from './types';

export default function usePaymentsActions(): UsePaymentsActions {
  const { loading } = usePaymentList();
  const { withExtractDataDispatch } = useAppDispatch();
  const isCreating = useActionPending(createPayment);
  const create: UsePaymentsActions['create'] = {
    act: useMemo(() => withExtractDataDispatch(createPayment), [withExtractDataDispatch]),
    inAction: isCreating,
    available: !loading,
  };

  const isExporting = useActionPending(exportPayments);
  const exportPaymentsAction: UsePaymentsActions['exportPayments'] = {
    act: useMemo(() => withExtractDataDispatch(exportPayments), [withExtractDataDispatch]),
    inAction: isExporting,
    available: !loading,
  };

  return { create, exportPayments: exportPaymentsAction };
}
