import isNil from 'lodash-es/isNil';
import { useCallback, useMemo } from 'react';

import { usePayoutBatch, usePayoutBatches } from '@/features/payouts/hooks';
import { noopAsync } from '@/infrastructure/utils/functions';

import usePayoutForSettlement from '../usePayoutForSettlement';

import type { UsePayoutBatchForSettlement } from './types';

export default function usePayoutBatchForSettlement(settlementId: string | undefined): UsePayoutBatchForSettlement {
  const payoutState = usePayoutForSettlement(settlementId);
  const batchesState = usePayoutBatches(payoutState.data.data?.id);
  const batchNum = useMemo(
    () => batchesState.data.data?.find((batch) => batch.settlementId === settlementId)?.num,
    [batchesState.data.data, settlementId],
  );
  const batchState = usePayoutBatch(payoutState.data.data?.id, batchNum);

  const forceRefresh = useCallback(async () => {
    const [, , newState] = await Promise.all([
      async () => (payoutState.data.data ? noopAsync() : payoutState.forceRefresh()),
      async () => (!isNil(batchNum) ? noopAsync() : batchState.forceRefresh()),
      batchState.forceRefresh(),
    ]);
    return newState;
  }, [batchNum, batchState, payoutState]);
  const loading = batchesState.loading || batchState.loading || payoutState.loading;
  return useMemo(() => ({ data: batchState.data, loading, forceRefresh }), [batchState.data, forceRefresh, loading]);
}
