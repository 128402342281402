import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessageWithMarkup, Operation } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import UpdateActiveAssetsLink from '@/pages/settings/company/assets-edit/components/UpdateActiveAssetsLink';

import type { OperationAddFiatPeggedAssetsProps } from './types';

const OperationAddFiatPeggedAssets: React.FC<OperationAddFiatPeggedAssetsProps> = ({
  'data-test': dataTest,
  mainAsset,
  assets: allAssets,
  selectedAssets,
  onSelect,
}) => {
  const selectableAssets = useMemo(
    () =>
      allAssets
        ?.filter(({ peggedToFiat }) => !!peggedToFiat && peggedToFiat === mainAsset.peggedToFiat)
        .filter(({ code }) => !selectedAssets?.includes(code))
        .filter(({ issue }) => !issue)
        .map(({ code }) => code),
    [allAssets, mainAsset.peggedToFiat, selectedAssets],
  );
  const doSelect = useCallback(() => {
    if (selectableAssets) {
      onSelect(selectableAssets);
    }
  }, [onSelect, selectableAssets]);
  const disabledMessage = useMemo(() => {
    if (!selectableAssets?.length)
      return (
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ADD_FIAT_PEGGED_DISABLED}
          values={{
            ln: (text: React.ReactNode) => (
              <UpdateActiveAssetsLink data-test={dataTest && `${dataTest}-settings`} mode="link" title={text} />
            ),
            pegged: mainAsset.peggedToFiat,
          }}
        />
      );
    return undefined;
  }, [selectableAssets?.length, dataTest, mainAsset.peggedToFiat]);

  return (
    <Operation
      mode="link"
      data-test={dataTest}
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
      onClick={doSelect}
      icon={<PlusCircleOutlined />}
      title={
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ADD_FIAT_PEGGED_TITLE}
          values={{ pegged: mainAsset.peggedToFiat }}
        />
      }
      tooltip={
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ADD_FIAT_PEGGED_TOOLTIP}
          values={{ pegged: mainAsset.peggedToFiat }}
        />
      }
    />
  );
};

const OperationAddFiatPeggedAssetsMemo = React.memo(
  OperationAddFiatPeggedAssets,
) as typeof OperationAddFiatPeggedAssets;

export default OperationAddFiatPeggedAssetsMemo;
