import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantWallet } from '@/features/merchant-wallets/hooks';

import type { OperationRefreshMerchantWalletProps } from './types';

const OperationRefreshMerchantWallet: React.FC<OperationRefreshMerchantWalletProps> = ({
  'data-test': dataTest,
  bt,
  address,
  title,
  mode = 'inline',
}) => {
  const { forceRefresh } = useMerchantWallet(bt, address);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={{ title }} />;
};

const OperationRefreshMerchantWalletMemo = React.memo(OperationRefreshMerchantWallet);

export default OperationRefreshMerchantWalletMemo;
