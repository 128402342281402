import type { Payment } from '@/features/payments/types';
import { parseReport } from '@/features/reports/api';
import type {
  NewPaymentAPIModel,
  PaymentAPIModel,
  PaymentPredicatesAPIModel,
  PaymentSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PaymentApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import { type SliceDataRequestWithTotal, pageToAPI, sliceFromAPI, amountFromAPI } from '@/infrastructure/model/api';

const paymentApi = new PaymentApi(coreConfigurationFactory('auth'));

export const parsePayment = ({ amount, auxAmounts, ...payment }: PaymentAPIModel): Payment => {
  return {
    ...payment,
    amount: amountFromAPI(amount),
    auxAmounts: (auxAmounts ?? []).map(({ amount, ...rest }) => ({ ...rest, amount: amountFromAPI(amount) })),
  };
};

export const queryPayment = async (paymentId: string, initOverrides?: RequestInit) =>
  parsePayment((await paymentApi.getPayment(paymentId, initOverrides)).payment);

export const queryPayments = async (
  { page, filter, orderBy, withTotal }: SliceDataRequestWithTotal<PaymentPredicatesAPIModel, PaymentSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await paymentApi.searchPayments(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parsePayment,
  );

export const requestCreatePayment = async (payment: NewPaymentAPIModel, initOverrides?: RequestInit) =>
  parsePayment((await paymentApi.createPayment(payment, initOverrides)).payment);

export const requestExportPayments = async (filter: PaymentPredicatesAPIModel, initOverrides?: RequestInit) =>
  parseReport(await paymentApi.exportPayments(filter, initOverrides));
