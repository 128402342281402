/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BigDecimalRangeAPIModel } from './BigDecimalRangeAPIModel';
import {
    BigDecimalRangeAPIModelFromJSON,
    BigDecimalRangeAPIModelFromJSONTyped,
    BigDecimalRangeAPIModelToJSON,
} from './BigDecimalRangeAPIModel';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';

/**
 * 
 * @export
 * @interface RechargeTransactionPredicatesAPIModel
 */
export interface RechargeTransactionPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    fromAddressLike?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    rechargeAddressIn?: Array<string>;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {BigDecimalRangeAPIModel}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    amountRange?: BigDecimalRangeAPIModel;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    createdAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    broadcastAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof RechargeTransactionPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the RechargeTransactionPredicatesAPIModel interface.
 */
export function instanceOfRechargeTransactionPredicatesAPIModel(value: object): boolean {
    return true;
}

export function RechargeTransactionPredicatesAPIModelFromJSON(json: any): RechargeTransactionPredicatesAPIModel {
    return RechargeTransactionPredicatesAPIModelFromJSONTyped(json, false);
}

export function RechargeTransactionPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechargeTransactionPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'fromAddressLike': json['fromAddressLike'] == null ? undefined : json['fromAddressLike'],
        'rechargeAddressIn': json['rechargeAddressIn'] == null ? undefined : json['rechargeAddressIn'],
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'amountRange': json['amountRange'] == null ? undefined : BigDecimalRangeAPIModelFromJSON(json['amountRange']),
        'createdAtRange': json['createdAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdAtRange']),
        'broadcastAtRange': json['broadcastAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['broadcastAtRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function RechargeTransactionPredicatesAPIModelToJSON(value?: RechargeTransactionPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'fromAddressLike': value['fromAddressLike'],
        'rechargeAddressIn': value['rechargeAddressIn'],
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'amountRange': BigDecimalRangeAPIModelToJSON(value['amountRange']),
        'createdAtRange': DateTimeRangeAPIModelToJSON(value['createdAtRange']),
        'broadcastAtRange': DateTimeRangeAPIModelToJSON(value['broadcastAtRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

