import React from 'react';

import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { useMerchantWallet } from '@/features/merchant-wallets/hooks';
import { MerchantWalletIssue } from '@/features/merchant-wallets/types';

import {
  MerchantWalletDeployingIssueBanner,
  MerchantWalletInvalidIssueBanner,
  MerchantWalletNotDeployedIssueBanner,
} from './components';

import type { MerchantWalletIssueBannerProps } from './types';

const MerchantWalletIssueBanner: React.FC<MerchantWalletIssueBannerProps> = (props) => {
  const wallet = useMerchantWallet(props.bt, props.address).data.data;
  const issue = wallet ? wallet.issue : MerchantWalletIssue.NOT_DEPLOYED;
  if (!issue) {
    return null;
  }
  switch (issue) {
    case MerchantWalletIssue.FAILED:
    case MerchantWalletIssue.NOT_DEPLOYED:
      return <MerchantWalletNotDeployedIssueBanner {...props} />;
    case MerchantWalletIssue.DEPLOYING:
      return <MerchantWalletDeployingIssueBanner {...props} />;
    case MerchantWalletIssue.INVALID:
      return <MerchantWalletInvalidIssueBanner {...props} />;
  }
};

const MerchantWalletIssueBannerHOCed = withBlockchainType<MerchantWalletIssueBannerProps>(MerchantWalletIssueBanner);

const MerchantWalletIssueBannerMemo = React.memo(
  MerchantWalletIssueBannerHOCed,
) as typeof MerchantWalletIssueBannerHOCed;

export default MerchantWalletIssueBannerMemo;
