import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { donationAssetIdToKey } from '@/features/donations/utils';
import { mapStoredState } from '@/infrastructure/model';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { defaultDonationTransactionForDonationListState, NAMESPACE } from './types';

import type { DonationTransactionListState, DonationAssetId } from './types';

export const {
  makeSelectDonation,
  makeSelectDonationListData,
  makeSelectDonationListParameters: baseMakeSelectDonationListParameters,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state) => state[NAMESPACE].entities,
  'Donation' as const,
  undefined,
);

export const makeSelectDonationAsset = ({ donationId, asset }: DonationAssetId) =>
  createSelector(makeSelectDonation(donationId), (donation) =>
    mapStoredState(donation, ({ addresses }) => addresses.find((address) => address.asset === asset)),
  );

export const makeSelectDonationAssetDeployTransaction = (id: DonationAssetId) =>
  makeSelectCollectEntityProcessTransaction(donationAssetIdToKey(id));

export const makeSelectDonationsListParameters: typeof baseMakeSelectDonationListParameters = () =>
  createSelector(
    baseMakeSelectDonationListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, networkEq): ReturnType<ReturnType<typeof baseMakeSelectDonationListParameters>> => ({
      ...parameters,
      filter: { ...filter, networkEq },
    }),
  );

export const {
  makeSelectDonationTransaction,
  makeSelectDonationTransactionListData,
  makeSelectDonationTransactionListParameters: baseMakeSelectDonationTransactionsListParameters,
} = createNormalizedListSelectors(
  {
    listNoColumn: (state: AppRootState) => state[NAMESPACE].transactions.list,
    columnState: (state: AppRootState) => state[NAMESPACE].transactions.columnState,
  },
  (state) => state[NAMESPACE].transactions.entities,
  'DonationTransaction' as const,
  undefined,
);

export const makeSelectDonationTransactionListParameters: typeof baseMakeSelectDonationTransactionsListParameters =
  () =>
    createSelector(
      baseMakeSelectDonationTransactionsListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof baseMakeSelectDonationTransactionsListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );

export const {
  makeSelectDonationTransactionForDonationListData,
  makeSelectDonationTransactionForDonationListParameters: baseMakeSelectDonationTransactionForDonationListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, donationId: string | undefined): DonationTransactionListState =>
    state[NAMESPACE].transactions.byDonation[donationId!] ?? defaultDonationTransactionForDonationListState,
  (state) => state[NAMESPACE].transactions.columnState,
  (state) => state[NAMESPACE].transactions.entities,
  'DonationTransactionForDonation' as const,
);

export const makeSelectDonationTransactionForDonationListParameters: typeof baseMakeSelectDonationTransactionForDonationListParameters =
  (donationId) =>
    createSelector(
      baseMakeSelectDonationTransactionForDonationListParameters(donationId),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof baseMakeSelectDonationTransactionForDonationListParameters>> => ({
        ...parameters,
        filter: { ...filter, donationIdIn: [donationId!], networkEq },
      }),
    );

export const { makeSelectDonationIdByAddress } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].idByAddress,
  'DonationIdByAddress' as const,
  undefined,
);
