/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportScheduleAPIModel,
  MerchantReportSchedulesFilterAPIModel,
  NewReportScheduleAPIModel,
  ReportScheduleAPIModel,
  ReportScheduleDeactivateParamsAPIModel,
  ReportScheduleUpdateAPIModel,
  SliceMerchantReportScheduleReportScheduleSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportScheduleAPIModelFromJSON,
    MerchantReportScheduleAPIModelToJSON,
    MerchantReportSchedulesFilterAPIModelFromJSON,
    MerchantReportSchedulesFilterAPIModelToJSON,
    NewReportScheduleAPIModelFromJSON,
    NewReportScheduleAPIModelToJSON,
    ReportScheduleAPIModelFromJSON,
    ReportScheduleAPIModelToJSON,
    ReportScheduleDeactivateParamsAPIModelFromJSON,
    ReportScheduleDeactivateParamsAPIModelToJSON,
    ReportScheduleUpdateAPIModelFromJSON,
    ReportScheduleUpdateAPIModelToJSON,
    SliceMerchantReportScheduleReportScheduleSortByAPIModelFromJSON,
    SliceMerchantReportScheduleReportScheduleSortByAPIModelToJSON,
} from '../models/index';

export interface ActivateReportScheduleRequest {
    reportScheduleId: string;
}

export interface CreateReportScheduleRequest {
    newReportScheduleAPIModel: NewReportScheduleAPIModel;
}

export interface DeactivateReportScheduleRequest {
    reportScheduleId: string;
    reportScheduleDeactivateParamsAPIModel: ReportScheduleDeactivateParamsAPIModel;
}

export interface DeleteReportScheduleRequest {
    reportScheduleId: string;
}

export interface GetReportScheduleRequest {
    reportScheduleId: string;
}

export interface SearchReportSchedulesRequest {
    merchantReportSchedulesFilterAPIModel: MerchantReportSchedulesFilterAPIModel;
    withTotal?: boolean;
}

export interface UpdateReportScheduleRequest {
    reportScheduleId: string;
    reportScheduleUpdateAPIModel: ReportScheduleUpdateAPIModel;
}

/**
 * ReportScheduleApi - interface
 * 
 * @export
 * @interface ReportScheduleApiInterface
 */
export interface ReportScheduleApiInterface {
    /**
     * Activates report scheduler
     * @param {string} reportScheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    activateReportScheduleRaw(requestParameters: ActivateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>>;

    /**
     * Activates report scheduler
     */
    activateReportSchedule(reportScheduleId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel>;

    /**
     * Crates report schedule
     * @param {NewReportScheduleAPIModel} newReportScheduleAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    createReportScheduleRaw(requestParameters: CreateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>>;

    /**
     * Crates report schedule
     */
    createReportSchedule(newReportScheduleAPIModel: NewReportScheduleAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel>;

    /**
     * Deactivates report scheduler
     * @param {string} reportScheduleId 
     * @param {ReportScheduleDeactivateParamsAPIModel} reportScheduleDeactivateParamsAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    deactivateReportScheduleRaw(requestParameters: DeactivateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>>;

    /**
     * Deactivates report scheduler
     */
    deactivateReportSchedule(reportScheduleId: string, reportScheduleDeactivateParamsAPIModel: ReportScheduleDeactivateParamsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel>;

    /**
     * Deletes report schedule by id
     * @param {string} reportScheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    deleteReportScheduleRaw(requestParameters: DeleteReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Deletes report schedule by id
     */
    deleteReportSchedule(reportScheduleId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Returns report schedule by id
     * @param {string} reportScheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    getReportScheduleRaw(requestParameters: GetReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportScheduleAPIModel>>;

    /**
     * Returns report schedule by id
     */
    getReportSchedule(reportScheduleId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportScheduleAPIModel>;

    /**
     * Returns report schedules by filter
     * @param {MerchantReportSchedulesFilterAPIModel} merchantReportSchedulesFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    searchReportSchedulesRaw(requestParameters: SearchReportSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantReportScheduleReportScheduleSortByAPIModel>>;

    /**
     * Returns report schedules by filter
     */
    searchReportSchedules(merchantReportSchedulesFilterAPIModel: MerchantReportSchedulesFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantReportScheduleReportScheduleSortByAPIModel>;

    /**
     * Updates report schedule by id
     * @param {string} reportScheduleId 
     * @param {ReportScheduleUpdateAPIModel} reportScheduleUpdateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportScheduleApiInterface
     */
    updateReportScheduleRaw(requestParameters: UpdateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>>;

    /**
     * Updates report schedule by id
     */
    updateReportSchedule(reportScheduleId: string, reportScheduleUpdateAPIModel: ReportScheduleUpdateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel>;

}

/**
 * 
 */
export class ReportScheduleApi extends runtime.BaseAPI implements ReportScheduleApiInterface {

    /**
     * Activates report scheduler
     */
    async activateReportScheduleRaw(requestParameters: ActivateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>> {
        if (requestParameters['reportScheduleId'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleId',
                'Required parameter "reportScheduleId" was null or undefined when calling activateReportSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules/{reportScheduleId}/activate`.replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(requestParameters['reportScheduleId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Activates report scheduler
     */
    async activateReportSchedule(reportScheduleId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel> {
        const response = await this.activateReportScheduleRaw({ reportScheduleId: reportScheduleId }, initOverrides);
        return await response.value();
    }

    /**
     * Crates report schedule
     */
    async createReportScheduleRaw(requestParameters: CreateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>> {
        if (requestParameters['newReportScheduleAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newReportScheduleAPIModel',
                'Required parameter "newReportScheduleAPIModel" was null or undefined when calling createReportSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewReportScheduleAPIModelToJSON(requestParameters['newReportScheduleAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Crates report schedule
     */
    async createReportSchedule(newReportScheduleAPIModel: NewReportScheduleAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel> {
        const response = await this.createReportScheduleRaw({ newReportScheduleAPIModel: newReportScheduleAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Deactivates report scheduler
     */
    async deactivateReportScheduleRaw(requestParameters: DeactivateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>> {
        if (requestParameters['reportScheduleId'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleId',
                'Required parameter "reportScheduleId" was null or undefined when calling deactivateReportSchedule().'
            );
        }

        if (requestParameters['reportScheduleDeactivateParamsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleDeactivateParamsAPIModel',
                'Required parameter "reportScheduleDeactivateParamsAPIModel" was null or undefined when calling deactivateReportSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules/{reportScheduleId}/deactivate`.replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(requestParameters['reportScheduleId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportScheduleDeactivateParamsAPIModelToJSON(requestParameters['reportScheduleDeactivateParamsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Deactivates report scheduler
     */
    async deactivateReportSchedule(reportScheduleId: string, reportScheduleDeactivateParamsAPIModel: ReportScheduleDeactivateParamsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel> {
        const response = await this.deactivateReportScheduleRaw({ reportScheduleId: reportScheduleId, reportScheduleDeactivateParamsAPIModel: reportScheduleDeactivateParamsAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes report schedule by id
     */
    async deleteReportScheduleRaw(requestParameters: DeleteReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['reportScheduleId'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleId',
                'Required parameter "reportScheduleId" was null or undefined when calling deleteReportSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules/{reportScheduleId}`.replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(requestParameters['reportScheduleId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes report schedule by id
     */
    async deleteReportSchedule(reportScheduleId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportScheduleRaw({ reportScheduleId: reportScheduleId }, initOverrides);
    }

    /**
     * Returns report schedule by id
     */
    async getReportScheduleRaw(requestParameters: GetReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportScheduleAPIModel>> {
        if (requestParameters['reportScheduleId'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleId',
                'Required parameter "reportScheduleId" was null or undefined when calling getReportSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules/{reportScheduleId}`.replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(requestParameters['reportScheduleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns report schedule by id
     */
    async getReportSchedule(reportScheduleId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportScheduleAPIModel> {
        const response = await this.getReportScheduleRaw({ reportScheduleId: reportScheduleId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns report schedules by filter
     */
    async searchReportSchedulesRaw(requestParameters: SearchReportSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantReportScheduleReportScheduleSortByAPIModel>> {
        if (requestParameters['merchantReportSchedulesFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantReportSchedulesFilterAPIModel',
                'Required parameter "merchantReportSchedulesFilterAPIModel" was null or undefined when calling searchReportSchedules().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantReportSchedulesFilterAPIModelToJSON(requestParameters['merchantReportSchedulesFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceMerchantReportScheduleReportScheduleSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns report schedules by filter
     */
    async searchReportSchedules(merchantReportSchedulesFilterAPIModel: MerchantReportSchedulesFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantReportScheduleReportScheduleSortByAPIModel> {
        const response = await this.searchReportSchedulesRaw({ merchantReportSchedulesFilterAPIModel: merchantReportSchedulesFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Updates report schedule by id
     */
    async updateReportScheduleRaw(requestParameters: UpdateReportScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportScheduleAPIModel>> {
        if (requestParameters['reportScheduleId'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleId',
                'Required parameter "reportScheduleId" was null or undefined when calling updateReportSchedule().'
            );
        }

        if (requestParameters['reportScheduleUpdateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'reportScheduleUpdateAPIModel',
                'Required parameter "reportScheduleUpdateAPIModel" was null or undefined when calling updateReportSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report-schedules/{reportScheduleId}`.replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(requestParameters['reportScheduleId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportScheduleUpdateAPIModelToJSON(requestParameters['reportScheduleUpdateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates report schedule by id
     */
    async updateReportSchedule(reportScheduleId: string, reportScheduleUpdateAPIModel: ReportScheduleUpdateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportScheduleAPIModel> {
        const response = await this.updateReportScheduleRaw({ reportScheduleId: reportScheduleId, reportScheduleUpdateAPIModel: reportScheduleUpdateAPIModel }, initOverrides);
        return await response.value();
    }

}
