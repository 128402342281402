/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentStatusAPIModel {
    Created = 'Created',
    InProgress = 'InProgress',
    Succeeded = 'Succeeded',
    Expired = 'Expired',
    Failed = 'Failed'
}


export function PaymentStatusAPIModelFromJSON(json: any): PaymentStatusAPIModel {
    return PaymentStatusAPIModelFromJSONTyped(json, false);
}

export function PaymentStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatusAPIModel {
    return json as PaymentStatusAPIModel;
}

export function PaymentStatusAPIModelToJSON(value?: PaymentStatusAPIModel | null): any {
    return value as any;
}

