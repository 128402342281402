import { Space } from 'antd';
import React, { type FC } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { CollectableBalanceTable } from '@/features/collectable/components';
import { useCollectLockedBalanceListView } from '@/features/collectable/hooks';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import CollectableBalanceEntityLink from '@/pages/balances/collectable-entity/components/CollectableBalanceEntityLink';
import CollectableBalanceViewLink from '@/pages/balances/collectable-view/components/CollectableBalanceViewLink';
import CollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';

import type { LockedBalancesCardProps } from './types';

const LockedBalancesCard: FC<LockedBalancesCardProps> = (props) => {
  const parameters = useCollectLockedBalanceListView();

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageBalances.LOCKED_COMPONENTS_BALANCES_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
    >
      <CollectableBalanceTable
        {...parameters}
        {...props}
        CollectableBalanceLink={CollectableBalanceViewLink}
        CollectTaskLink={CollectTaskLink}
        CollectableEntityLink={CollectableBalanceEntityLink}
      />
    </PageCard>
  );
};
const LockedBalancesCardMemo = React.memo(LockedBalancesCard);

export default LockedBalancesCardMemo;
