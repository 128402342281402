import React from 'react';

import { QRDelegateContractView } from '@/features/company-settings/components';

import { withQRDelegateContractDataLoading } from './hocs';

const QRDelegateContractCard = withQRDelegateContractDataLoading(QRDelegateContractView);

const QRDelegateContractCardMemo: typeof QRDelegateContractCard = React.memo(QRDelegateContractCard);

export default QRDelegateContractCardMemo;
