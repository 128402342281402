import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'react-fast-compare';

import { loadingDataLoaded, mapLoadingState } from '@/infrastructure/model';
import { defaultPage } from '@/infrastructure/model/api';
import type {
  ListDataUpdateAction,
  ListParametersUpdateAction,
  NestedListDataMarkDirtyAction,
  NestedListDataUpdateAction,
  NestedListParametersUpdateAction,
} from '@/infrastructure/model/list/actions';
import type {
  ListColumnState,
  ListParametersState,
  ListSortBy,
  ListState,
  ListStateNoColumns,
  UpdateListParametersPayload,
} from '@/infrastructure/model/list/types';
import { createSingleReducers, multipleStoreReducer } from '@/infrastructure/model/single/reducers';
import type { SingleState } from '@/infrastructure/model/single/types';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { Draft } from 'immer';

export const reduceListParamStateUpdate = <Global, Filter, SortBy extends string>(
  state: Draft<Global>,
  listParametersState: ListParametersState<Filter, SortBy>,
  payload: UpdateListParametersPayload<Filter, SortBy>,
  defaultSortBy: ListSortBy<SortBy>,
  updater: (
    currentState: Draft<Global>,
    newListParameterState: ListParametersState<Filter, SortBy>,
    isDataDirty: boolean,
    isTotalDirty: boolean,
  ) => Draft<Global>,
) => {
  const hasPage = payload.page !== undefined;
  const hasFilter = payload.filter !== undefined;
  const hasColumnState = payload.columnState !== undefined;
  const hasSortBy = payload.sortBy !== undefined;

  const isPageDirty = hasPage && !isEqual(payload.page, listParametersState.page);
  const isFilterDirty = hasFilter && !isEqual(payload.filter, listParametersState.filter);
  const isColumnStateDirty = hasColumnState && !isEqual(payload.columnState, listParametersState.columnState);
  const isSortByDirty = hasSortBy && !isEqual(payload.sortBy, listParametersState.sortBy);

  const isDirty = isPageDirty || isFilterDirty || isColumnStateDirty || isSortByDirty;
  const isDataDirty = isPageDirty || isFilterDirty || isSortByDirty;
  const page = hasPage ? (payload.page ?? defaultPage) : listParametersState.page;
  return isDirty
    ? updater(
        state,
        {
          page: isFilterDirty ? { ...page, page: defaultPage.page } : page,
          filter: hasFilter ? payload.filter! : listParametersState.filter,
          columnState: hasColumnState ? (payload.columnState ?? {}) : listParametersState.columnState,
          // eslint-disable-next-line no-nested-ternary
          sortBy: hasSortBy
            ? payload.sortBy && !isEmpty(payload.sortBy)
              ? payload.sortBy
              : defaultSortBy
            : listParametersState.sortBy,
        },
        isDataDirty,
        isFilterDirty,
      )
    : state;
};

export const reduceListParamSplitColumnStateUpdate = <
  Global,
  Filter,
  SortBy extends string,
  ListParameters extends Omit<ListParametersState<Filter, SortBy>, 'columnState'> = Omit<
    ListParametersState<Filter, SortBy>,
    'columnState'
  >,
>(
  state: Draft<Global>,
  listParametersState: ListParameters,
  columnState: ListColumnState,
  payload: UpdateListParametersPayload<Filter, SortBy>,
  defaultSortBy: ListSortBy<SortBy>,
  parametersUpdater: (
    currentState: Draft<Global>,
    newListParameterState: ListParameters,
    isDataDirty: boolean,
    isTotalDirty: boolean,
  ) => Draft<Global>,
  columnsUpdater: (currentState: Draft<Global>, newColumnState: ListColumnState) => Draft<Global>,
) => {
  const hasPage = payload.page !== undefined;
  const hasFilter = payload.filter !== undefined;
  const hasColumnState = payload.columnState !== undefined;
  const hasSortBy = payload.sortBy !== undefined;

  const isPageDirty = hasPage && !isEqual(payload.page, listParametersState.page);
  const isFilterDirty = hasFilter && !isEqual(payload.filter, listParametersState.filter);
  const isColumnStateDirty = hasColumnState && !isEqual(payload.columnState, columnState);
  const isSortByDirty = hasSortBy && !isEqual(payload.sortBy, listParametersState.sortBy);

  const isParametersDirty = isPageDirty || isFilterDirty || isSortByDirty;
  const page = hasPage ? (payload.page ?? defaultPage) : listParametersState.page;
  const parametersState = isParametersDirty
    ? parametersUpdater(
        state,
        {
          ...listParametersState,
          page: isFilterDirty ? { ...page, page: defaultPage.page } : page,
          filter: hasFilter ? (payload.filter ?? []) : listParametersState.filter,
          // eslint-disable-next-line no-nested-ternary
          sortBy: hasSortBy
            ? payload.sortBy && !isEmpty(payload.sortBy)
              ? payload.sortBy
              : defaultSortBy
            : listParametersState.sortBy,
        },
        isParametersDirty,
        isFilterDirty,
      )
    : state;
  return isColumnStateDirty && payload.columnState
    ? columnsUpdater(parametersState, payload.columnState)
    : parametersState;
};

export const listMarkDirtyReducer =
  <
    Global extends EmptyObject,
    Value,
    Filter,
    SortBy extends string,
    List extends Omit<ListState<Value, Filter, SortBy>, 'columnState'> = Omit<
      ListState<Value, Filter, SortBy>,
      'columnState'
    >,
  >(
    get: (global: Draft<Global>) => List,
    set: (global: Draft<Global>, newListState: List) => Draft<Global>,
  ) =>
  (state: Draft<Global>) => {
    const listState = get(state);
    return !listState.data.isDirty
      ? set(state, {
          ...get(state),
          data: { ...listState.data, isDirty: true },
        })
      : state;
  };

export const listMarkTotalDirtyReducer =
  <
    Global extends EmptyObject,
    Value,
    Filter,
    SortBy extends string,
    List extends Omit<ListState<Value, Filter, SortBy>, 'columnState'> = Omit<
      ListState<Value, Filter, SortBy>,
      'columnState'
    >,
  >(
    get: (global: Global) => List,
    set: (global: Global, newListState: List) => Global,
  ) =>
  (state: Global) => {
    const listState = get(state);
    return !listState.data.isTotalDirty || !listState.data.isDirty
      ? set(state, { ...get(state), data: { ...listState.data, isDirty: true, isTotalDirty: true } })
      : state;
  };

export const listStoreDataReducer =
  <
    Global extends EmptyObject,
    Value,
    Filter,
    SortBy extends string,
    List extends Omit<ListState<Value, Filter, SortBy>, 'columnState'> = Omit<
      ListState<Value, Filter, SortBy>,
      'columnState'
    >,
  >(
    get: (global: Draft<Global>) => List,
    set: (global: Draft<Global>, newListState: List) => Draft<Global>,
  ) =>
  (state: Draft<Global>, { payload }: ListDataUpdateAction<Value>) =>
    set(state, { ...get(state), data: { isDirty: false, isTotalDirty: false, ...payload } });

export const listStoreParametersReducer =
  <Global extends EmptyObject, Value, Filter, SortBy extends string>(
    get: (global: Draft<Global>) => ListState<Value, Filter, SortBy>,
    set: (global: Draft<Global>, newListState: ListState<Value, Filter, SortBy>) => Draft<Global>,
    defaultSortBy: ListSortBy<SortBy>,
  ) =>
  (state: Draft<Global>, { payload }: ListParametersUpdateAction<Filter, SortBy>) =>
    reduceListParamStateUpdate(
      state,
      get(state),
      payload,
      defaultSortBy,
      (currentState, newParametersState, isDataDirty, isTotalDirty) => {
        const oldData = get(currentState).data;
        return set(currentState, {
          data: { ...oldData, isDirty: isDataDirty, isTotalDirty: oldData.isTotalDirty || isTotalDirty },
          ...newParametersState,
        });
      },
    );

export const createNestedListParametersReducers = <
  Type extends string,
  Global extends EmptyObject,
  Filter,
  SortBy extends string,
  ParentId = string,
  ListParameters extends Omit<ListParametersState<Filter, SortBy>, 'columnState'> = Omit<
    ListParametersState<Filter, SortBy>,
    'columnState'
  >,
>(
  type: Type,
  getList: (global: Draft<Global>, parentId: ParentId | undefined) => ListParameters | undefined,
  setList: (global: Draft<Global>, parentId: ParentId, newListState: ListParameters) => Draft<Global>,
  getColumnState: (global: Draft<Global>) => ListColumnState,
  setColumnState: (global: Draft<Global>, newColumnState: ListColumnState) => Draft<Global>,
  defaultState: ListParameters,
) => {
  const storeParametersName = `store${type}ListParametersReducer` as const;

  const storeParameters = (
    state: Draft<Global>,
    { payload: { parentId, parameters } }: NestedListParametersUpdateAction<ParentId, Filter, SortBy>,
  ) =>
    reduceListParamSplitColumnStateUpdate(
      state,
      getList(state, parentId) ?? defaultState,
      getColumnState(state),
      parameters,
      defaultState.sortBy,
      (currentState, newParametersState) => {
        return setList(currentState, parentId, { ...getList(currentState, parentId), ...newParametersState });
      },
      (currentState, newColumnState) => setColumnState(currentState, newColumnState),
    );

  // FIXME: redefine the type without the cast
  return { [storeParametersName]: storeParameters } as Record<typeof storeParametersName, typeof storeParameters>;
};

export const createListReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Filter,
  SortBy extends string,
>(
  type: Type,
  get: (global: Draft<Global>) => ListState<Value, Filter, SortBy>,
  set: (global: Draft<Global>, newListState: ListState<Value, Filter, SortBy>) => Draft<Global>,
  defaultState: ListParametersState<Filter, SortBy>,
) => {
  const markDirtyName = `mark${type}ListDirtyReducer` as const;
  const markTotalDirtyName = `mark${type}ListTotalDirtyReducer` as const;
  const storeDataName = `store${type}ListDataReducer` as const;
  const storeParametersName = `store${type}ListParametersReducer` as const;

  const markDirty = listMarkDirtyReducer(get, set);
  const markTotalDirty = listMarkTotalDirtyReducer(get, set);
  const storeData = listStoreDataReducer(get, set);
  const storeParameters = listStoreParametersReducer(get, set, defaultState.sortBy);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [markTotalDirtyName]: markTotalDirty,
    [storeDataName]: storeData,
    [storeParametersName]: storeParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markTotalDirtyName, typeof markTotalDirty> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeParametersName, typeof storeParameters>;
};

export const createNormalizedListReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Filter,
  SortBy extends string,
  Id = string,
  Key extends string = string,
>(
  type: Type,
  getList: (global: Draft<Global>) => ListState<Key, Filter, SortBy>,
  setList: (global: Draft<Global>, newListState: ListState<Key, Filter, SortBy>) => Draft<Global>,
  defaultState: ListParametersState<Filter, SortBy>,
  getSingle: (global: Draft<Global>) => SingleState<Value, Key>,
  setSingle: (state: Draft<Global>, newSingleState: SingleState<Value, Key>) => Draft<Global>,
  extractId: (value: Value) => Id,
  mapper?: (id: Id) => Key,
) => {
  const listReducers = createListReducers(type, getList, setList, defaultState);
  const singleReducers = createSingleReducers(type, getSingle, setSingle, mapper);
  const storeDataName = `store${type}ListDataReducer` as const;
  const listReducer = listStoreDataReducer(getList, setList);
  const toStored = (id: Id): Key => (typeof id === 'string' ? (id as unknown as Key) : mapper!(id));
  const storeData = (state: Draft<Global>, { payload }: ListDataUpdateAction<Value>) => {
    const listState = listReducer(state, {
      payload: mapLoadingState(payload, (data) => ({
        ...data,
        data: data.data.map(extractId).map(toStored),
      })),
    });
    return payload.data
      ? multipleStoreReducer(getSingle, setSingle)(listState, {
          payload: payload.data.data.map((value) => ({
            id: toStored(extractId(value)),
            data: loadingDataLoaded(value),
          })),
        })
      : listState;
  };

  // FIXME: redefine the type without the cast
  return {
    ...listReducers,
    ...singleReducers,
    [storeDataName]: storeData,
  } as Omit<typeof listReducers, typeof storeDataName> &
    typeof singleReducers &
    Record<typeof storeDataName, typeof storeData>;
};

export const createNestedNormalizedListReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Filter,
  SortBy extends string,
  ParentId = string,
  List extends ListStateNoColumns<string, Filter, SortBy> = ListStateNoColumns<string, Filter, SortBy>,
>(
  type: Type,
  getList: (global: Draft<Global>, parentId: ParentId | undefined) => List | undefined,
  setList: (global: Draft<Global>, parentId: ParentId, newListState: List) => Draft<Global>,
  getColumnState: (global: Draft<Global>) => ListColumnState,
  setColumnState: (global: Draft<Global>, newColumnState: ListColumnState) => Draft<Global>,
  defaultState: List,
  toKey: (value: Value) => string,
) => {
  const storeDataName = `store${type}ListDataReducer` as const;
  const markDirtyName = `mark${type}ListDirtyReducer` as const;
  const markTotalDirtyName = `mark${type}ListTotalDirtyReducer` as const;
  const storeParametersName = `store${type}ListParametersReducer` as const;

  const markDirty = (state: Draft<Global>, { payload: parentId }: NestedListDataMarkDirtyAction<ParentId>) =>
    listMarkDirtyReducer(
      (st: Draft<Global>) => getList(st, parentId) ?? defaultState,
      (global: Draft<Global>, newListDataState: List) => setList(global, parentId, newListDataState),
    )(state);
  const markTotalDirty = (state: Draft<Global>, { payload: parentId }: NestedListDataMarkDirtyAction<ParentId>) =>
    listMarkTotalDirtyReducer(
      (st: Draft<Global>) => getList(st, parentId) ?? defaultState,
      (global: Draft<Global>, newListDataState: List) => setList(global, parentId, newListDataState),
    )(state);
  const storeData = (
    state: Draft<Global>,
    { payload: { parentId, data } }: NestedListDataUpdateAction<ParentId, Value>,
  ) =>
    setList(state, parentId, {
      ...(getList(state, parentId) ?? defaultState),
      data: {
        isDirty: false,
        isTotalDirty: false,
        ...mapLoadingState(data, ({ data: values, total }) => ({ data: values.map(toKey), total })),
      },
    });
  const storeParameters = (
    state: Draft<Global>,
    { payload: { parentId, parameters } }: NestedListParametersUpdateAction<ParentId, Filter, SortBy>,
  ) =>
    reduceListParamSplitColumnStateUpdate(
      state,
      getList(state, parentId) ?? defaultState,
      getColumnState(state),
      parameters,
      defaultState.sortBy,
      (currentState, newParametersState, isDataDirty, isTotalDirty) => {
        const oldData = (getList(state, parentId) ?? defaultState).data;
        return setList(currentState, parentId, {
          ...getList(currentState, parentId),
          ...newParametersState,
          data: { ...oldData, isDirty: isDataDirty, isTotalDirty: oldData.isTotalDirty || isTotalDirty },
        });
      },
      (currentState, newColumnState) => setColumnState(currentState, newColumnState),
    );

  // FIXME: redefine the type without the cast
  return {
    [storeDataName]: storeData,
    [markDirtyName]: markDirty,
    [markTotalDirtyName]: markTotalDirty,
    [storeParametersName]: storeParameters,
  } as Record<typeof storeDataName, typeof storeData> &
    Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markTotalDirtyName, typeof markTotalDirty> &
    Record<typeof storeParametersName, typeof storeParameters>;
};

export const createSplitNormalizedListReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Filter,
  SortBy extends string,
  List extends ListStateNoColumns<string, Filter, SortBy> = ListStateNoColumns<string, Filter, SortBy>,
>(
  type: Type,
  getList: (global: Draft<Global>) => List | undefined,
  setList: (global: Draft<Global>, newListState: List) => Draft<Global>,
  getColumnState: (global: Draft<Global>) => ListColumnState,
  setColumnState: (global: Draft<Global>, newColumnState: ListColumnState) => Draft<Global>,
  defaultState: List,
  toKey: (value: Value) => string,
) => {
  const storeDataName = `store${type}ListDataReducer` as const;
  const markDirtyName = `mark${type}ListDirtyReducer` as const;
  const markTotalDirtyName = `mark${type}ListTotalDirtyReducer` as const;
  const storeParametersName = `store${type}ListParametersReducer` as const;

  const markDirty = (state: Draft<Global>) =>
    listMarkDirtyReducer(
      (st: Draft<Global>) => getList(st) ?? defaultState,
      (global: Draft<Global>, newListDataState: List) => setList(global, newListDataState),
    )(state);
  const markTotalDirty = (state: Draft<Global>) =>
    listMarkTotalDirtyReducer(
      (st: Draft<Global>) => getList(st) ?? defaultState,
      (global: Draft<Global>, newListDataState: List) => setList(global, newListDataState),
    )(state);
  const storeData = (state: Draft<Global>, { payload: data }: ListDataUpdateAction<Value>) =>
    setList(state, {
      ...(getList(state) ?? defaultState),
      data: {
        isDirty: false,
        isTotalDirty: false,
        ...mapLoadingState(data, ({ data: values, total }) => ({ data: values.map(toKey), total })),
      },
    });
  const storeParameters = (state: Draft<Global>, { payload: parameters }: ListParametersUpdateAction<Filter, SortBy>) =>
    reduceListParamSplitColumnStateUpdate(
      state,
      getList(state) ?? defaultState,
      getColumnState(state),
      parameters,
      defaultState.sortBy,
      (currentState, newParametersState, isDataDirty, isTotalDirty) => {
        const oldData = (getList(state) ?? defaultState).data;
        return setList(currentState, {
          ...getList(currentState),
          ...newParametersState,
          data: { ...oldData, isDirty: isDataDirty, isTotalDirty: oldData.isTotalDirty || isTotalDirty },
        });
      },
      (currentState, newColumnState) => setColumnState(currentState, newColumnState),
    );

  // FIXME: redefine the type without the cast
  return {
    [storeDataName]: storeData,
    [markDirtyName]: markDirty,
    [markTotalDirtyName]: markTotalDirty,
    [storeParametersName]: storeParameters,
  } as Record<typeof storeDataName, typeof storeData> &
    Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markTotalDirtyName, typeof markTotalDirty> &
    Record<typeof storeParametersName, typeof storeParameters>;
};
