import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { AssetAmount, AssetLabel } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import CollectTaskStatusBanner from '../CollectTaskStatusBanner';
import CollectTaskStatusView from '../CollectTaskStatusView';

import type { CollectTaskViewProps } from './types';

const CollectTaskView: React.FC<CollectTaskViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<CollectTaskStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    <Descriptions.Item>
      <CollectTaskStatusBanner data-test={dataTest && `${dataTest}-intent`} task={data} mode="alert" />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_ASSET} />}>
      <AssetLabel data-test={dataTest && `${dataTest}-asset`} value={data.asset} mode="medium" />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_PROCESS_AT} />}>
      <ReadonlyDateTime value={data.processAt} data-test={dataTest && `${dataTest}-processAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_COLLECTED_AMOUNT} />}>
      {data.collectedAmount && (
        <AssetAmount
          value={data.collectedAmount.value}
          assetId={data.collectedAmount.asset}
          data-test={dataTest && `${dataTest}-amount`}
          withCurrency
          withBlockchainMark
        />
      )}
    </Descriptions.Item>
  </Descriptions>
);

const CollectTaskViewMemo = React.memo(CollectTaskView);

export default CollectTaskViewMemo;
