import type { ReportParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/reports';

import GasHistoryFilterForm from '../GasHistoryFilterForm';

import type React from 'react';

const GasHistoryReportParametersView: React.FC<ReportParametersViewProps> = ({ value, 'data-test': dataTest }) => (
  <GasHistoryFilterForm data-test={dataTest} value={value} readonly />
);
export default GasHistoryReportParametersView;
