/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CollectableKindAPIModel } from './CollectableKindAPIModel';
import {
    CollectableKindAPIModelFromJSON,
    CollectableKindAPIModelFromJSONTyped,
    CollectableKindAPIModelToJSON,
} from './CollectableKindAPIModel';

/**
 * 
 * @export
 * @interface CollectTaskAddressAPIModel
 */
export interface CollectTaskAddressAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAddressAPIModel
     */
    id: string;
    /**
     * 
     * @type {CollectableKindAPIModel}
     * @memberof CollectTaskAddressAPIModel
     */
    kind: CollectableKindAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAddressAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAddressAPIModel
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskAddressAPIModel
     */
    collectedAmount?: string;
}

/**
 * Check if a given object implements the CollectTaskAddressAPIModel interface.
 */
export function instanceOfCollectTaskAddressAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('kind' in value)) return false;
    if (!('address' in value)) return false;
    if (!('asset' in value)) return false;
    return true;
}

export function CollectTaskAddressAPIModelFromJSON(json: any): CollectTaskAddressAPIModel {
    return CollectTaskAddressAPIModelFromJSONTyped(json, false);
}

export function CollectTaskAddressAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectTaskAddressAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'kind': CollectableKindAPIModelFromJSON(json['kind']),
        'address': json['address'],
        'asset': json['asset'],
        'collectedAmount': json['collectedAmount'] == null ? undefined : json['collectedAmount'],
    };
}

export function CollectTaskAddressAPIModelToJSON(value?: CollectTaskAddressAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'kind': CollectableKindAPIModelToJSON(value['kind']),
        'address': value['address'],
        'asset': value['asset'],
        'collectedAmount': value['collectedAmount'],
    };
}

