import { useMemo } from 'react';

import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletDeployTransactions } from '@/features/merchant-wallets/actions';
import { makeSelectMerchantWalletDeployTransactions } from '@/features/merchant-wallets/selectors';
import type { MerchantWalletDeployTransaction, MerchantWalletId } from '@/features/merchant-wallets/types';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory = (
  id: MerchantWalletId,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<MerchantWalletDeployTransaction[]>,
  Parameters<typeof fetchMerchantWalletDeployTransactions>[0]
> => fetchMerchantWalletDeployTransactions({ ...id, force });

export default function useMerchantWalletDeployTransactions(
  bt: BlockchainTypeAPIModel | undefined,
  address: string | undefined,
) {
  const id = useMemo(() => (bt && address ? { bt, address } : undefined), [bt, address]);
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchMerchantWalletDeployTransactions, id && merchantWalletIdToStoreKey(id)),
    [id],
  );
  return useAppSingleData(
    fetchFactory,
    makeSelectMerchantWalletDeployTransactions,
    noSingleDataIdFn(),
    id,
    dataFetchingSelector,
  );
}
