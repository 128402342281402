import { Alert, Tag } from 'antd';
import React from 'react';

import { WarningTooltip } from '@/components';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import { withGasWallet } from '@/features/gas-wallets/hocs';

import type { GasWalletAllOutOfServiceCheckBannerProps } from './types';

const type = 'all-service';

const GasWalletAllOutOfServiceCheckBannerRaw: React.FC<GasWalletAllOutOfServiceCheckBannerProps> = ({
  'data-test': dataTest,
  wallet,
  mode,
}) => {
  if (!wallet.isAllOutOfService) {
    return null;
  }
  switch (mode) {
    case 'alert':
      return (
        <Alert
          message={<GasWalletInvalidStateMessage mode="short" wallet={wallet} type={type} />}
          description={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}
          data-test={dataTest}
          type="error"
          showIcon
        />
      );
    case 'icon':
      return (
        <WarningTooltip type="error" title={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}>
          <Tag color="error">
            <GasWalletInvalidStateMessage mode="tag" wallet={wallet} type={type} />
          </Tag>
        </WarningTooltip>
      );
    case 'message':
      return <GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />;
  }
};

const GasWalletAllOutOfServiceCheckBanner = React.memo(
  withGasWallet<GasWalletAllOutOfServiceCheckBannerProps>(GasWalletAllOutOfServiceCheckBannerRaw),
);

export default GasWalletAllOutOfServiceCheckBanner;
