import { Form, Input } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isAddress } from 'viem';

import { FormattedMessage } from '@/components';
import { BlockchainAPITypeAPIModel, BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { isAddressValid } from '@/infrastructure/utils/tron/address';

import type { AddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AddressItem = <Values extends Store = Store>({
  'data-test': dataTest,
  required,
  name,
  blockchainApis,
  net,
  messages,
  rules = [],
  InputProps = {},
  ItemProps = {},
}: AddressItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const messagesOrDefault = useMemo(
    () => ({
      label: messages?.label ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_LABEL} />,
      required: messages?.required ?? (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_ERROR_REQUIRED} />
      ),
      placeholder: messages?.placeholder || i18n({ id: I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_PLACEHOLDER }),
    }),
    [i18n, messages?.label, messages?.placeholder, messages?.required],
  );
  const validateAddress = useCallback(
    async (_: unknown, addressField: string) => {
      if (required && !addressField) {
        return;
      }

      const isAnyAddressValid = !!(
        await Promise.all(
          blockchainApis.map(async (api) => {
            switch (api) {
              case BlockchainAPITypeAPIModel.BTC: {
                try {
                  const bitcoin = await import('bitcoinjs-lib');
                  bitcoin.address.toOutputScript(
                    addressField,
                    net === BlockchainNetworkTypeAPIModel.MainNet ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
                  );
                  return true;
                } catch {
                  return false;
                }
              }
              case BlockchainAPITypeAPIModel.Tron:
                return isAddressValid(addressField);
              case BlockchainAPITypeAPIModel.WEB3:
                return isAddress(addressField);
              default:
                return true;
            }
          }),
        )
      ).filter((v) => v).length;

      if (!isAnyAddressValid) {
        const message = i18n({ id: I18nFeatureDictionary.COMPONENTS_ADDRESS_ITEM_ERROR_ADDRESS_INVALID });
        throw new Error(message);
      }
    },
    [blockchainApis, i18n, net, required],
  );

  return (
    <Form.Item<Values>
      required={required}
      label={messagesOrDefault.label}
      name={name}
      rules={useMemo(
        () => [
          { validator: validateAddress },
          ...rules,
          ...(required ? [{ required: true, message: messagesOrDefault.required }] : []),
        ],
        [messagesOrDefault.required, required, rules, validateAddress],
      )}
      {...ItemProps}
    >
      <Input
        data-test={dataTest}
        placeholder={messagesOrDefault.placeholder}
        className="ym-hide-content"
        {...InputProps}
      />
    </Form.Item>
  );
};

const AddressItemMemo = React.memo(AddressItem) as typeof AddressItem;

export default AddressItemMemo;
