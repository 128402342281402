import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import CollectableKindView from '@/features/collectable/components/CollectableKindView';
import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { CollectableKindFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CollectableKindFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: CollectableKindFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={
      <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_BALANCE_FILTER_FORM_COMPONENTS_KIND_LABEL} />
    }
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_COLLECT_BALANCE_FILTER_FORM_COMPONENTS_KIND_PLACEHOLDER}
        />
      }
      options={useMemo(
        () =>
          enumValues(CollectableKindAPIModel).map((code) => ({
            value: code,
            label: <CollectableKindView value={code} mode="full" data-test={dataTest && `${dataTest}-kind`} />,
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const CollectableKindFilterItemMemo = React.memo(CollectableKindFilterItem) as typeof CollectableKindFilterItem;

export default CollectableKindFilterItemMemo;
