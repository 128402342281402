import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import AuditBreadcrumb from '@/pages/audit/components/AuditBreadcrumb';

import MerchantWalletRunningBalancesLink from '../MerchantWalletRunningBalancesLink';

import type { MerchantWalletRunningBalancesBreadcrumbProps } from './types';

const MerchantWalletRunningBalancesBreadcrumb: React.FC<MerchantWalletRunningBalancesBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <AuditBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.BALANCES_COMPONENTS_BREADCRUMB} />
        ) : (
          <MerchantWalletRunningBalancesLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            title={<FormattedMessage id={I18nPageAudit.BALANCES_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantWalletRunningBalancesBreadcrumbMemo = React.memo(MerchantWalletRunningBalancesBreadcrumb);

export default MerchantWalletRunningBalancesBreadcrumbMemo;
