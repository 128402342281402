import type {
  PaymentTransactionAPIModel,
  PaymentTransactionSearchIdAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PaymentTransactionApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import { amountFromAPI } from '@/infrastructure/model/api';
import { someOrFail } from '@/infrastructure/utils/functions';

import { PaymentTransactionKind } from './types';

import type { PaymentTransaction } from './types';

const paymentTransactionApi = new PaymentTransactionApi(coreConfigurationFactory('auth'));

export const parsePaymentTransaction = ({
  amount,
  isForwarder,
  ...paymentTransaction
}: PaymentTransactionAPIModel): PaymentTransaction => ({
  ...paymentTransaction,
  amount: amountFromAPI(amount),
  kind: isForwarder ? PaymentTransactionKind.FORWARDER : PaymentTransactionKind.DIRECT,
});

export const queryMultiplePaymentTransactions = async (
  searchIds: PaymentTransactionSearchIdAPIModel[],
  initOverrides?: RequestInit,
) =>
  (await paymentTransactionApi.getPaymentTransactions({ predicates: { idIn: searchIds } }, initOverrides)).list?.map(
    parsePaymentTransaction,
  ) ?? [];

export const queryPaymentTransaction = async (
  searchId: PaymentTransactionSearchIdAPIModel,
  initOverrides?: RequestInit,
) =>
  someOrFail(
    (await queryMultiplePaymentTransactions([searchId], initOverrides))[0],
    () => new Error('PaymentTransactionNotFound'),
  );

export const queryPaymentTransactionsForPayment = async (paymentId: string, initOverrides?: RequestInit) =>
  (await paymentTransactionApi.getPaymentTransactions({ predicates: { paymentId } }, initOverrides)).list?.map(
    parsePaymentTransaction,
  ) ?? [];
