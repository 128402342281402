import { useMemo } from 'react';

import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';

import useMerchantWallets from '../useMerchantWallets';

const useActiveMerchantWallet = (bt: BlockchainTypeAPIModel | undefined) => {
  const { loading, forceRefresh, data: walletsState } = useMerchantWallets();
  const data = useMemo(
    () =>
      mapStoredState(walletsState, (wallets) =>
        wallets.find(({ isFactoryActive, blockchain }) => isFactoryActive && blockchain === bt),
      ),
    [bt, walletsState],
  );
  return useMemo(() => ({ loading, forceRefresh, data }), [data, forceRefresh, loading]);
};

export default useActiveMerchantWallet;
