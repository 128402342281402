import { CloudDownloadOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useMerchantGasWalletActions } from '@/features/gas-wallets/hooks';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import type { DefaultErrorOpts, NotificationOptions } from '@/hooks';
import { useDefaultNotification, useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import { GasWalletRefundDialog } from './components';

import type { OperationRefundGasWalletProps } from './types';

const success: NotificationOptions['success'] = () => (
  <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_MESSAGE_SUCCESS} />
);
const error: DefaultErrorOpts = {
  message: () => <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_MESSAGE_ERROR} />,
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const OperationRefundGasWallet: React.FC<OperationRefundGasWalletProps> = ({
  'data-test': dataTest,
  bt,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { refund: refundGasWalletAction } = useMerchantGasWalletActions(bt);
  const formControl = useFormVisible(false);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const doRefund = useMemo(
    () =>
      withSuccess(
        withDefaultError(formControl.withFormHide(withOnSuccess(refundGasWalletAction.act, onSuccess)), error),
        success,
      ),
    [withSuccess, withDefaultError, formControl, refundGasWalletAction.act, onSuccess],
  );

  const showForm = formControl.show;

  const disabledMessage = useMemo(() => {
    if (refundGasWalletAction.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_UNAVAILABILITY_NO_DATA} />;
    }
    if (refundGasWalletAction.unavailabilityReason === 'empty-balance') {
      return (
        <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_UNAVAILABILITY_EMPTY_BALANCE} />
      );
    }
    return undefined;
  }, [refundGasWalletAction.unavailabilityReason]);

  return (
    <>
      {formControl.visible && (
        <GasWalletRefundDialog
          layout={layout}
          bt={bt}
          tailLayout={defaultDialogFormTailLayout}
          data-test={dataTest && `${dataTest}-form`}
          onCancel={formControl.hide}
          onSubmit={doRefund}
        />
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_TITLE} />}
        icon={<CloudDownloadOutlined />}
        disabled={disabled || !refundGasWalletAction.available}
        disabledMessage={disabledMessage}
        onClick={showForm}
        inProgress={inProgress || formControl.visible || refundGasWalletAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationRefundGasWalletMemo = React.memo(OperationRefundGasWallet);

export default OperationRefundGasWalletMemo;
