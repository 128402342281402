import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import CollectableBalanceView from '@/features/collectable/components/CollectableBalanceView';
import CollectableKindView from '@/features/collectable/components/CollectableKindView';
import CollectableTransactionIcon from '@/features/collectable/components/CollectableTransactionIcon';
import type { CollectableBalance } from '@/features/collectable/types';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import { CollectableAddressBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import type { CollectableBalanceFilter, CollectableBalanceTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<CollectableBalance>('calculatedAt')]: CollectableAddressBalanceSortByAPIModel.CalculatedAt,
};
const extractKey = ({ id }: CollectableBalance) => id;
const sortColumnToModel = (s: string) => sortMapping[s];

const CollectableBalanceTable = <FilterType extends CollectableBalanceFilter = CollectableBalanceFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  CollectableEntityLink,
  CollectableBalanceLink,
  CollectTaskLink,
}: CollectableBalanceTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<CollectableBalance>[] = useMemo(
    () => [
      {
        key: nameof<CollectableBalance>('id'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_ID} />,
        dataIndex: nameof<CollectableBalance>('id'),
        render: (_, { id }) => <ReadonlyComponent value={id} data-test={dataTest && `${dataTest}-${id}-id`} copyable />,
      },
      {
        key: nameof<CollectableBalance>('kind'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_KIND} />,
        dataIndex: nameof<CollectableBalance>('kind'),
        render: (_, { id, kind }) => (
          <CollectableKindView value={kind} data-test={dataTest && `${dataTest}-${id}-kind`} mode="icon" />
        ),
        width: 60,
      },
      {
        key: nameof<CollectableBalance>('balance'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_BALANCE} />,
        dataIndex: nameof<CollectableBalance>('balance'),
        render: (_, { id, balance }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={balance.asset}
                value={balance.value}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-${id}-balance`}
              />
            }
            copyable={false}
          />
        ),
        width: 120,
      },
      {
        key: nameof<CollectableBalance>('calculatedAt'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_CALCULATED_AT} />,
        dataIndex: nameof<CollectableBalance>('calculatedAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.CalculatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, calculatedAt }) => (
          <ReadonlyDateTime value={calculatedAt} data-test={dataTest && `${dataTest}-${id}-calculatedAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<CollectableBalance>('address'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_ADDRESS} />,
        dataIndex: nameof<CollectableBalance>('address'),
        render: (_, { id, address, blockchain }) => (
          <AddressLink address={address} bt={blockchain} data-test={dataTest && `${dataTest}-${id}-address`} />
        ),
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, { id, kind, collectTaskId }) => (
          <StopPropagationContainer>
            {CollectableBalanceLink && (
              <CollectableBalanceLink value={{ id, kind }} data-test={dataTest && `${dataTest}-${id}-link`} />
            )}
            {CollectableEntityLink && (
              <CollectableEntityLink value={{ id, kind }} data-test={dataTest && `${dataTest}-${id}-entityLink`} />
            )}
            {CollectTaskLink && collectTaskId ? (
              <CollectTaskLink
                value={collectTaskId}
                data-test={dataTest && `${dataTest}-${id}-taskLink`}
                icon={<CollectableTransactionIcon />}
              />
            ) : null}
          </StopPropagationContainer>
        ),
      },
    ],
    [CollectTaskLink, CollectableBalanceLink, CollectableEntityLink, dataTest, sortBy.CalculatedAt],
  );

  const preview = useCallback(
    (value: CollectableBalance) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            CollectableBalanceLink && (
              <CollectableBalanceLink
                value={{ id: value.id, kind: value.kind }}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <CollectableBalanceView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
          CollectableEntityLink={CollectableEntityLink}
          CollectTaskLink={CollectTaskLink}
        />
      ),
    }),
    [CollectTaskLink, CollectableBalanceLink, CollectableEntityLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'calculatedAtRange', 'calculatedAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey="calculatedAtRange"
              relativeKey="calculatedAtRangeRelative"
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      FilterForm,
      ExportOperation,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<CollectableBalance, FilterType, CollectableAddressBalanceSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const CollectableBalanceTableMemo = React.memo(CollectableBalanceTable);

export default CollectableBalanceTableMemo;
