import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import CollectableBalancesBreadcrumb from '@/pages/balances/collectable/components/CollectableBalancesBreadcrumb';

import CollectableBalanceEntityLink from '../CollectableBalanceEntityLink';

import type { CollectableBalanceEntityBreadcrumbProps } from './types';

const CollectableBalanceEntityBreadcrumb: React.FC<CollectableBalanceEntityBreadcrumbProps> = ({
  items = [],
  id,
  disabled,
  'data-test': dataTest,
}) => (
  <CollectableBalancesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_BREADCRUMB}
            values={{ kind: id.kind, id: id.id }}
          />
        ) : (
          <CollectableBalanceEntityLink
            value={id}
            mode="text"
            title={
              <FormattedMessage
                id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_BREADCRUMB}
                values={{ kind: id.kind, id: id.id }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const CollectableBalanceEntityBreadcrumbMemo = React.memo(CollectableBalanceEntityBreadcrumb);

export default CollectableBalanceEntityBreadcrumbMemo;
