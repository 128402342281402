/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { GasWalletDerivedAPIModel } from './GasWalletDerivedAPIModel';
import {
    GasWalletDerivedAPIModelFromJSON,
    GasWalletDerivedAPIModelFromJSONTyped,
    GasWalletDerivedAPIModelToJSON,
} from './GasWalletDerivedAPIModel';

/**
 * 
 * @export
 * @interface GasWalletFullDetailsAPIModel
 */
export interface GasWalletFullDetailsAPIModel {
    /**
     * 
     * @type {string}
     * @memberof GasWalletFullDetailsAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof GasWalletFullDetailsAPIModel
     */
    lowWatermark?: string;
    /**
     * 
     * @type {Array<GasWalletDerivedAPIModel>}
     * @memberof GasWalletFullDetailsAPIModel
     */
    derived?: Array<GasWalletDerivedAPIModel>;
    /**
     * 
     * @type {boolean}
     * @memberof GasWalletFullDetailsAPIModel
     */
    isRefunding: boolean;
    /**
     * 
     * @type {string}
     * @memberof GasWalletFullDetailsAPIModel
     */
    refundHash?: string;
}

/**
 * Check if a given object implements the GasWalletFullDetailsAPIModel interface.
 */
export function instanceOfGasWalletFullDetailsAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('isRefunding' in value)) return false;
    return true;
}

export function GasWalletFullDetailsAPIModelFromJSON(json: any): GasWalletFullDetailsAPIModel {
    return GasWalletFullDetailsAPIModelFromJSONTyped(json, false);
}

export function GasWalletFullDetailsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GasWalletFullDetailsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'lowWatermark': json['lowWatermark'] == null ? undefined : json['lowWatermark'],
        'derived': json['derived'] == null ? undefined : ((json['derived'] as Array<any>).map(GasWalletDerivedAPIModelFromJSON)),
        'isRefunding': json['isRefunding'],
        'refundHash': json['refundHash'] == null ? undefined : json['refundHash'],
    };
}

export function GasWalletFullDetailsAPIModelToJSON(value?: GasWalletFullDetailsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'lowWatermark': value['lowWatermark'],
        'derived': value['derived'] == null ? undefined : ((value['derived'] as Array<any>).map(GasWalletDerivedAPIModelToJSON)),
        'isRefunding': value['isRefunding'],
        'refundHash': value['refundHash'],
    };
}

