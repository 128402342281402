import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useAssetsForDonation } from '@/features/donations/hooks';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import SettingsLink from '@/pages/settings/components/SettingsLink';

import type { OperationAddDonationAssetProps } from './types';

const OperationAddDonationAsset: React.FC<OperationAddDonationAssetProps> = ({
  'data-test': dataTest,
  disabled,
  selectedAssets,
  onAdd,
}) => {
  const { data: companyAssets } = useAssetsForDonation();
  const availableAsset = useMemo(
    () =>
      companyAssets.data
        ?.filter(({ issue }) => !issue)
        .map(({ code }) => code)
        .find((v) => !selectedAssets.includes(v)),
    [companyAssets.data, selectedAssets],
  );
  const doAdd = useCallback(() => {
    if (availableAsset) {
      onAdd({ asset: availableAsset, defaultAmounts: [], isActive: true });
    }
  }, [availableAsset, onAdd]);
  return (
    <Operation
      mode="button"
      data-test={dataTest}
      disabled={disabled || !availableAsset}
      disabledMessage={
        <FormattedMessage
          id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ADD_DISABLED}
          values={{
            link: (text: React.ReactNode) => (
              <SettingsLink data-test={dataTest && `${dataTest}-settings`} mode="text" title={text} />
            ),
          }}
        />
      }
      onClick={doAdd}
      icon={<PlusCircleOutlined />}
      title={<FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ADD_TITLE} />}
    />
  );
};

const OperationAddDonationAssetMemo = React.memo(OperationAddDonationAsset);

export default OperationAddDonationAssetMemo;
