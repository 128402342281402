/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantReportSchedulePredicatesAPIModel } from './MerchantReportSchedulePredicatesAPIModel';
import {
    MerchantReportSchedulePredicatesAPIModelFromJSON,
    MerchantReportSchedulePredicatesAPIModelFromJSONTyped,
    MerchantReportSchedulePredicatesAPIModelToJSON,
} from './MerchantReportSchedulePredicatesAPIModel';
import type { PageReportScheduleSortByAPIModel } from './PageReportScheduleSortByAPIModel';
import {
    PageReportScheduleSortByAPIModelFromJSON,
    PageReportScheduleSortByAPIModelFromJSONTyped,
    PageReportScheduleSortByAPIModelToJSON,
} from './PageReportScheduleSortByAPIModel';

/**
 * 
 * @export
 * @interface MerchantReportSchedulesFilterAPIModel
 */
export interface MerchantReportSchedulesFilterAPIModel {
    /**
     * 
     * @type {MerchantReportSchedulePredicatesAPIModel}
     * @memberof MerchantReportSchedulesFilterAPIModel
     */
    predicates: MerchantReportSchedulePredicatesAPIModel;
    /**
     * 
     * @type {PageReportScheduleSortByAPIModel}
     * @memberof MerchantReportSchedulesFilterAPIModel
     */
    page: PageReportScheduleSortByAPIModel;
}

/**
 * Check if a given object implements the MerchantReportSchedulesFilterAPIModel interface.
 */
export function instanceOfMerchantReportSchedulesFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function MerchantReportSchedulesFilterAPIModelFromJSON(json: any): MerchantReportSchedulesFilterAPIModel {
    return MerchantReportSchedulesFilterAPIModelFromJSONTyped(json, false);
}

export function MerchantReportSchedulesFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantReportSchedulesFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': MerchantReportSchedulePredicatesAPIModelFromJSON(json['predicates']),
        'page': PageReportScheduleSortByAPIModelFromJSON(json['page']),
    };
}

export function MerchantReportSchedulesFilterAPIModelToJSON(value?: MerchantReportSchedulesFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': MerchantReportSchedulePredicatesAPIModelToJSON(value['predicates']),
        'page': PageReportScheduleSortByAPIModelToJSON(value['page']),
    };
}

