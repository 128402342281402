/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DonationTransactionPredicatesAPIModel } from './DonationTransactionPredicatesAPIModel';
import {
    DonationTransactionPredicatesAPIModelFromJSON,
    DonationTransactionPredicatesAPIModelFromJSONTyped,
    DonationTransactionPredicatesAPIModelToJSON,
} from './DonationTransactionPredicatesAPIModel';
import type { PagePushTransactionSortByAPIModel } from './PagePushTransactionSortByAPIModel';
import {
    PagePushTransactionSortByAPIModelFromJSON,
    PagePushTransactionSortByAPIModelFromJSONTyped,
    PagePushTransactionSortByAPIModelToJSON,
} from './PagePushTransactionSortByAPIModel';

/**
 * 
 * @export
 * @interface DonationTransactionFilterAPIModel
 */
export interface DonationTransactionFilterAPIModel {
    /**
     * 
     * @type {DonationTransactionPredicatesAPIModel}
     * @memberof DonationTransactionFilterAPIModel
     */
    predicates: DonationTransactionPredicatesAPIModel;
    /**
     * 
     * @type {PagePushTransactionSortByAPIModel}
     * @memberof DonationTransactionFilterAPIModel
     */
    page: PagePushTransactionSortByAPIModel;
}

/**
 * Check if a given object implements the DonationTransactionFilterAPIModel interface.
 */
export function instanceOfDonationTransactionFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function DonationTransactionFilterAPIModelFromJSON(json: any): DonationTransactionFilterAPIModel {
    return DonationTransactionFilterAPIModelFromJSONTyped(json, false);
}

export function DonationTransactionFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationTransactionFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': DonationTransactionPredicatesAPIModelFromJSON(json['predicates']),
        'page': PagePushTransactionSortByAPIModelFromJSON(json['page']),
    };
}

export function DonationTransactionFilterAPIModelToJSON(value?: DonationTransactionFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': DonationTransactionPredicatesAPIModelToJSON(value['predicates']),
        'page': PagePushTransactionSortByAPIModelToJSON(value['page']),
    };
}

