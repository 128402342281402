import React from 'react';

import {
  type CompanyActiveAssetsForSelectIssue,
  CompanyActiveAssetsForSelectIssueRender,
  useActiveAssetsForSelect,
} from '@/features/company-settings/hooks';
import { AssetAmountSelectItem } from '@/features/dictionary/blockchain/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { SubscriptionPlanAmountItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const excludeAPI = [BlockchainAPITypeAPIModel.BTC, BlockchainAPITypeAPIModel.Tron];

const SubscriptionPlanAmountItem = <Values extends Store = Store>(props: SubscriptionPlanAmountItemProps<Values>) => {
  const assets = useActiveAssetsForSelect({ disableWalletWithIssue: true, excludeAPI });
  return (
    <AssetAmountSelectItem<Values, CompanyActiveAssetsForSelectIssue | undefined>
      {...props}
      assets={assets}
      Issues={CompanyActiveAssetsForSelectIssueRender}
    />
  );
};

const SubscriptionPlanAmountItemMemo = React.memo(SubscriptionPlanAmountItem) as typeof SubscriptionPlanAmountItem;

export default SubscriptionPlanAmountItemMemo;
