import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import PayoutsBreadcrumb from '@/pages/payouts/components/PayoutsBreadcrumb';

import PayoutEditLink from '../PayoutEditLink';

import type { PayoutEditBreadcrumbProps } from './types';

const PayoutEditBreadcrumb: React.FC<PayoutEditBreadcrumbProps> = ({
  items = [],
  payoutId,
  disabled,
  'data-test': dataTest,
}) => (
  <PayoutsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPagePayouts.PAYOUT_EDIT_COMPONENTS_BREADCRUMB_TITLE} values={{ id: payoutId }} />
        ) : (
          <PayoutEditLink
            value={payoutId}
            title={
              <FormattedMessage
                id={I18nPagePayouts.PAYOUT_EDIT_COMPONENTS_BREADCRUMB_TITLE}
                values={{ id: payoutId }}
              />
            }
            mode="link"
          />
        ),
      },
      ...items,
    ]}
  />
);

const PayoutEditBreadcrumbMemo = React.memo(PayoutEditBreadcrumb);

export default PayoutEditBreadcrumbMemo;
