import { Space } from 'antd';

import { FormattedMessageWithMarkup } from '@/components';
import {
  OperationRefreshQRDelegateContracts,
  QRDelegateContractIssueBanner,
} from '@/features/company-settings/components';
import { useQRDelegateContract } from '@/features/company-settings/hooks';
import type { QRDelegateContract } from '@/features/company-settings/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withQRDelegateContractDataLoading = <
  Original extends { data: QRDelegateContract } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { bt: BlockchainTypeAPIModel; address?: string } = Omit<Original, 'data'> & {
    bt: BlockchainTypeAPIModel;
    address?: string;
  },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithQRDelegateContractDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useQRDelegateContract(props.bt);
    return withCardDataLoading<QRDelegateContract, Original>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title: (
        <FormattedMessageWithMarkup id={I18nPageAudit.QR_CONTRACT_VIEW_COMPONENTS_CARD} values={{ bt: props.bt }} />
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshQRDelegateContracts data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
      noData: <QRDelegateContractIssueBanner bt={props.bt} mode="alert" type="action" />,
    })(Component)(props);
  });

export default withQRDelegateContractDataLoading;
