import { Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { withCollectSchedule } from '@/features/collectable/hocs';
import { useCompanyPermissions } from '@/features/company/hooks';
import { CollectPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { asType } from '@/infrastructure/utils/ts';
import ActualBalancesLink from '@/pages/balances/actual/components/ActualBalancesLink';

import CollectPeriodLabel from '../CollectPeriodLabel';

import type { CollectScheduleViewProps } from './types';

const CollectScheduleView: React.FC<CollectScheduleViewProps> = ({
  'data-test': dataTest,
  style,
  className,
  schedule,
}) => {
  const {
    data: { data: permissions },
  } = useCompanyPermissions();
  return (
    <Typography.Paragraph data-test={dataTest} style={style} className={className}>
      {schedule && schedule.period !== CollectPeriodAPIModel.NotSet ? (
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_VIEW_DEFINED}
          values={{
            balance: (text: React.ReactNode) => <ActualBalancesLink title={text} mode="text" />,
            schedule: (
              <strong>
                <CollectPeriodLabel value={schedule.period} />
              </strong>
            ),
            tokens: <strong>{schedule.unifiedMinAmount}</strong>,
          }}
        />
      ) : permissions?.collectManagement ? (
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_VIEW_NOT_SET_PERMISSIONS_STANDALONE}
          values={{ balance: (text: React.ReactNode) => <ActualBalancesLink title={text} mode="text" /> }}
        />
      ) : (
        <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_VIEW_NOT_SET_PERMISSIONS_PARTNERED} />
      )}
    </Typography.Paragraph>
  );
};

const CollectScheduleViewHOC = withCollectSchedule<
  Omit<CollectScheduleViewProps, 'schedule'> & Required<Pick<CollectScheduleViewProps, 'schedule'>>
>(CollectScheduleView)(asType<React.FC<Omit<CollectScheduleViewProps, 'schedule'>>>(CollectScheduleView));

const CollectScheduleViewMemo = React.memo(CollectScheduleViewHOC);

export default CollectScheduleViewMemo;
