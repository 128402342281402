import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditQRDelegateContractViewLink } from '@/pages/audit/routes';

import type { QRDelegateContractLinkProps } from './types';

const QRDelegateContractLink: React.FC<QRDelegateContractLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={auditQRDelegateContractViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageAudit.QR_CONTRACT_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const QRDelegateContractLinkMemo = React.memo(QRDelegateContractLink);

export default QRDelegateContractLinkMemo;
