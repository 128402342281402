import { Alert, Tag } from 'antd';
import React, { useMemo } from 'react';

import { WarningTooltip } from '@/components';
import { useNativeBalance } from '@/features/dictionary/blockchain/hooks';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import { withGasWallet } from '@/features/gas-wallets/hocs';

import type { GasWalletNativeBalanceCheckBannerProps } from './types';

const type = 'native-balance';

const GasWalletNativeBalanceCheckBanner: React.FC<GasWalletNativeBalanceCheckBannerProps> = ({
  'data-test': dataTest,
  wallet,
  mode,
}) => {
  const {
    data: { data: balance },
  } = useNativeBalance(wallet);
  const checkPassed = useMemo(
    () => !balance?.amount || !wallet.lowWatermark || balance.amount.gt(wallet.lowWatermark),
    [balance?.amount, wallet.lowWatermark],
  );
  if (checkPassed) {
    return null;
  }
  switch (mode) {
    case 'alert':
      return (
        <Alert
          message={<GasWalletInvalidStateMessage mode="short" wallet={wallet} type={type} />}
          description={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}
          data-test={dataTest}
          type="warning"
          showIcon
        />
      );
    case 'icon':
      return (
        <WarningTooltip title={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}>
          <Tag color="warning">
            <GasWalletInvalidStateMessage mode="tag" wallet={wallet} type={type} />
          </Tag>
        </WarningTooltip>
      );
    case 'message':
      return <GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />;
  }
};

const GasWalletNativeBalanceCheckBannerHOC = withGasWallet<GasWalletNativeBalanceCheckBannerProps>(
  GasWalletNativeBalanceCheckBanner,
);

const GasWalletNativeBalanceCheckBannerMemo = React.memo(
  GasWalletNativeBalanceCheckBannerHOC,
) as typeof GasWalletNativeBalanceCheckBannerHOC;

export default GasWalletNativeBalanceCheckBannerMemo;
