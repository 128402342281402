import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ParamsNotFound } from '@/hocs/withPageParams/components';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPageQuery =
  <
    K extends string,
    P extends Record<K, unknown>,
    PC extends Record<K, string | undefined> = Record<K, string | undefined>,
    T extends object & { pageQuery: P } = { pageQuery: P },
  >(
    pageQueryParser: (params: Partial<PC>, props: Omit<T, 'pageQuery'>) => P | undefined,
    ErrorPage: React.FC = ParamsNotFound,
  ) =>
  (Component: React.ComponentType<Omit<T, 'pageQuery'> & { pageQuery: P }>): React.FC<Omit<T, 'pageQuery'>> =>
    namedHOC<Omit<T, 'pageQuery'> & { pageQuery: P }, Omit<T, 'pageQuery'>>(
      Component,
      'WithPageQuery',
    )((props) => {
      const { search } = useLocation();
      const query = useMemo(() => new URLSearchParams(search), [search]);
      // TODO: it's unsafe
      const pageQuery = useMemo(
        () => pageQueryParser(Object.fromEntries(query.entries()) as unknown as Partial<PC>, props),
        [props, query],
      );
      return pageQuery ? <Component {...props} pageQuery={pageQuery} /> : <ErrorPage />;
    });

export default withPageQuery;
