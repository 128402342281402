import { ErrorBoundary } from '@/hocs/withErrorBoundary/components';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withErrorBoundary = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithErrorBoundary',
  )((props) => (
    <ErrorBoundary withLayout>
      <Component {...props} />
    </ErrorBoundary>
  ));

export default withErrorBoundary;
