import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import { useSubmitting } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';

import { IconAction, OperationTooltip } from './components';
import { withConfirmation } from './hocs';

import type { DropdownOperationProps, OperationProps } from './types';

const { Link } = Typography;

const Operation: React.FC<OperationProps> = ({
  'data-test': dataTest,
  title,
  tooltip,
  primary,
  icon: baseIcon,
  onClick,
  disabled,
  disabledMessage,
  inProgress: baseInProgress = false,
  mode = 'inline',
  className,
  style,
  ButtonProps,
  ...props
}) => {
  const items = (props as Partial<DropdownOperationProps>).items ?? [];
  const [processing, withProcessing] = useSubmitting(false);
  const inProgress = processing || baseInProgress;
  const icon = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      inProgress ? (
        <LoadingOutlined />
      ) : disabled && baseIcon ? (
        React.cloneElement(baseIcon, {
          ...baseIcon.props,
          'data-test': dataTest,
          style: { ...baseIcon.props.style, color: 'lightgray' },
        })
      ) : (
        baseIcon
      ),
    [baseIcon, dataTest, disabled, inProgress],
  );
  const buttonType = primary && 'primary';
  const testLocator = useMemo(() => dataTest && `${dataTest}-action`, [dataTest]);
  const handleClick = useMemo(
    () => (inProgress || disabled ? noop : withProcessing(onClick)),
    [disabled, inProgress, onClick, withProcessing],
  );
  return (
    <>
      {mode === 'inline'
        && (items.length ? (
          <Dropdown.Button
            icon={<CaretDownOutlined data-test={dataTest && `${dataTest}-submenu`} />}
            disabled={inProgress || disabled}
            data-test={testLocator}
            onClick={handleClick}
            type={buttonType}
            className={className}
            style={style}
            size={ButtonProps?.size}
            menu={{ items }}
          >
            <OperationTooltip
              title={tooltip ?? title}
              disabledMessage={disabledMessage}
              disabled={disabled}
              hide={inProgress}
              component={icon}
            />
          </Dropdown.Button>
        ) : (
          <OperationTooltip
            title={tooltip ?? title}
            disabledMessage={disabledMessage}
            disabled={disabled}
            hide={inProgress}
            component={
              <Button
                icon={icon}
                disabled={inProgress || disabled}
                data-test={testLocator}
                onClick={handleClick}
                type={buttonType}
                className={className}
                style={style}
                htmlType={ButtonProps?.htmlType}
                danger={ButtonProps?.danger}
                size={ButtonProps?.size}
                shape={ButtonProps?.shape}
              />
            }
          />
        ))}
      {mode === 'link'
        && (items.length ? (
          <Space>
            <OperationTooltip
              title={tooltip}
              disabledMessage={disabledMessage}
              disabled={disabled}
              hide={inProgress}
              component={
                <Link
                  disabled={inProgress || disabled}
                  data-test={testLocator}
                  onClick={handleClick}
                  className={className}
                  style={{ ...style, ...(inProgress || disabled ? { color: 'lightgray' } : {}) }}
                >
                  {title}
                </Link>
              }
            />
            <Dropdown menu={{ items }} placement="bottomRight">
              <CaretDownOutlined />
            </Dropdown>
          </Space>
        ) : (
          <OperationTooltip
            title={tooltip}
            disabledMessage={disabledMessage}
            disabled={disabled}
            hide={inProgress}
            component={
              <Link
                disabled={inProgress || disabled}
                data-test={testLocator}
                onClick={handleClick}
                className={className}
                style={{ ...style, ...(inProgress || disabled ? { color: 'lightgray' } : {}) }}
              >
                {title}
              </Link>
            }
          />
        ))}
      {mode === 'link_icon'
        && (items.length ? (
          <Space>
            <OperationTooltip
              title={tooltip}
              disabledMessage={disabledMessage}
              disabled={disabled}
              hide={inProgress}
              component={
                <Link
                  disabled={inProgress || disabled}
                  data-test={testLocator}
                  onClick={handleClick}
                  className={className}
                  style={{ ...style, ...(inProgress || disabled ? { color: 'lightgray' } : {}) }}
                >
                  <span>
                    <span>{icon}</span>
                    <span style={{ paddingLeft: 10 }}>{title}</span>
                  </span>
                </Link>
              }
            />
            <Dropdown menu={{ items }} placement="bottomRight">
              <CaretDownOutlined />
            </Dropdown>
          </Space>
        ) : (
          <OperationTooltip
            title={tooltip}
            disabledMessage={disabledMessage}
            disabled={disabled}
            hide={inProgress}
            component={
              <Link
                disabled={inProgress || disabled}
                data-test={testLocator}
                onClick={handleClick}
                className={className}
                style={{ ...style, ...(inProgress || disabled ? { color: 'lightgray' } : {}) }}
              >
                <span>
                  <span>{icon}</span>
                  <span style={{ paddingLeft: 10 }}>{title}</span>
                </span>
              </Link>
            }
          />
        ))}
      {mode === 'menu_item' && (
        <Menu.Item
          disabled={inProgress || disabled}
          data-test={testLocator}
          onClick={handleClick}
          className={className}
          style={style}
        >
          <OperationTooltip
            title={tooltip ?? title}
            disabledMessage={disabledMessage}
            disabled={disabled}
            hide={inProgress}
            component={
              <Space>
                {icon}
                {title}
              </Space>
            }
          />
        </Menu.Item>
      )}
      {mode === 'button'
        && (items.length ? (
          <Dropdown.Button
            icon={<CaretDownOutlined data-test={dataTest && `${dataTest}-submenu`} />}
            disabled={inProgress || disabled}
            data-test={testLocator}
            onClick={handleClick}
            type={buttonType}
            className={className}
            style={style}
            title="title"
            size={ButtonProps?.size}
            menu={{ items }}
          >
            <OperationTooltip
              title={tooltip}
              disabledMessage={disabledMessage}
              disabled={disabled}
              hide={inProgress}
              component={
                <Space>
                  {icon}
                  <span>{title}</span>
                </Space>
              }
            />
          </Dropdown.Button>
        ) : (
          <OperationTooltip
            title={tooltip}
            disabledMessage={disabledMessage}
            disabled={disabled}
            hide={inProgress}
            component={
              <Button
                icon={icon}
                disabled={inProgress || disabled}
                data-test={testLocator}
                onClick={handleClick}
                type={buttonType}
                className={className}
                style={style}
                htmlType={ButtonProps?.htmlType}
                danger={ButtonProps?.danger}
                size={ButtonProps?.size}
                shape={ButtonProps?.shape}
              >
                <span>{title}</span>
              </Button>
            }
          />
        ))}
      {mode === 'icon' && (
        <OperationTooltip
          title={tooltip ?? title}
          disabledMessage={disabledMessage}
          disabled={disabled}
          hide={inProgress}
          component={
            <span className={className} style={style}>
              <IconAction
                icon={baseIcon}
                disabled={inProgress || disabled}
                data-test={testLocator}
                onClick={handleClick}
                inProgress={inProgress}
              />
            </span>
          }
        />
      )}
    </>
  );
};

const ConfiguredOperation = withConfirmation(Operation);

export default ConfiguredOperation;
