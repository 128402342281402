/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalSortByAPIModel } from './WithdrawalSortByAPIModel';
import {
    WithdrawalSortByAPIModelFromJSON,
    WithdrawalSortByAPIModelFromJSONTyped,
    WithdrawalSortByAPIModelToJSON,
} from './WithdrawalSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeWithdrawalSortByAPIModel
 */
export interface SortAttributeWithdrawalSortByAPIModel {
    /**
     * 
     * @type {WithdrawalSortByAPIModel}
     * @memberof SortAttributeWithdrawalSortByAPIModel
     */
    attr: WithdrawalSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeWithdrawalSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeWithdrawalSortByAPIModel interface.
 */
export function instanceOfSortAttributeWithdrawalSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeWithdrawalSortByAPIModelFromJSON(json: any): SortAttributeWithdrawalSortByAPIModel {
    return SortAttributeWithdrawalSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeWithdrawalSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeWithdrawalSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': WithdrawalSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeWithdrawalSortByAPIModelToJSON(value?: SortAttributeWithdrawalSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': WithdrawalSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

