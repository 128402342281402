/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface NewInvoiceProofAPIModel
 */
export interface NewInvoiceProofAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewInvoiceProofAPIModel
     */
    invoiceId: string;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof NewInvoiceProofAPIModel
     */
    proof?: SignatureComponentsAPIModel;
}

/**
 * Check if a given object implements the NewInvoiceProofAPIModel interface.
 */
export function instanceOfNewInvoiceProofAPIModel(value: object): boolean {
    if (!('invoiceId' in value)) return false;
    return true;
}

export function NewInvoiceProofAPIModelFromJSON(json: any): NewInvoiceProofAPIModel {
    return NewInvoiceProofAPIModelFromJSONTyped(json, false);
}

export function NewInvoiceProofAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewInvoiceProofAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'proof': json['proof'] == null ? undefined : SignatureComponentsAPIModelFromJSON(json['proof']),
    };
}

export function NewInvoiceProofAPIModelToJSON(value?: NewInvoiceProofAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceId': value['invoiceId'],
        'proof': SignatureComponentsAPIModelToJSON(value['proof']),
    };
}

