import pLimit from 'p-limit';
import { encodePacked, keccak256, zeroAddress } from 'viem';

import { createAppAsyncThunk } from '@/app/actions';
import type { PayoutSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { loadingDataLoaded, loadingStateToLoaded, mapLoadingState, storedDataError } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createNestedListParametersActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import { listStateToSliceRequest, mapLoadingSliceStateToListData } from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { notEmpty } from '@/infrastructure/utils/ts';

import {
  queryPayout,
  queryPayoutBatches,
  queryPayoutBySettlement,
  queryPayoutDestinations,
  queryPayouts,
  requestCreatePayout,
  requestDeletePayout,
  requestStartMerklePayout,
  requestUpdatePayout,
  requestUpdatePayoutTitle,
} from './api';
import {
  makeSelectPayout,
  makeSelectPayoutBatches,
  makeSelectPayoutDestinations,
  makeSelectPayoutForSettlement,
  makeSelectPayoutListData,
  makeSelectPayoutListParameters,
} from './selectors';
import { NAMESPACE } from './types';

import type {
  MerklePayoutSignatureData,
  NewPayout,
  PayoutBatch,
  PayoutBatchId,
  PayoutDestination,
  PayoutDestinationDetailedFilter,
  PayoutDestinationDetailedSortByAPIModel,
  PayoutDestinationFilter,
  PayoutDestinationSortByAPIModel,
  PayoutFilterPredicate,
  PayoutMerkleTreeSignatureData,
  PayoutSummary,
  PayoutUpdate,
  SimplePayoutSignatureData,
} from './types';
import type { Address, Hex } from 'viem';

export const {
  storePayout,
  storeMultiplePayout,
  storeRemovePayout,
  storePayoutListData,
  storePayoutListParameters,
  markPayoutDirty,
  markPayoutListDirty,
} = createNormalizedListActions<PayoutSummary, 'Payout', PayoutFilterPredicate, PayoutSortByAPIModel>(
  NAMESPACE,
  'Payout',
);

const payoutFetchLimit = pLimit(1);
export const fetchPayout = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayout`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    payoutFetchLimit(async () => {
      const saved = makeSelectPayout(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryPayout, 'unable to fetch payout')(id, { signal });
      dispatch(storePayout({ id, data }));

      return makeSelectPayout(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const payoutsFetchLimit = pLimit(1);
export const fetchPayouts = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayouts`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    payoutsFetchLimit(async () => {
      const saved = makeSelectPayoutListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryPayouts, 'unable to fetch payouts')(
        listStateToSliceRequest({ data: saved, ...makeSelectPayoutListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storePayoutListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectPayoutListData()(getState());
    }),
);

export const { storePayoutBatches, markPayoutBatchesDirty } = createSingleActions<PayoutBatch[], 'PayoutBatches'>(
  NAMESPACE,
  'PayoutBatches',
);

const payoutFetchBatchesLimit = pLimit(1);
export const fetchPayoutBatches = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayoutBatches`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    payoutFetchBatchesLimit(async () => {
      const saved = makeSelectPayoutBatches(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryPayoutBatches, 'unable to fetch payout batches')(id, {
        signal,
      });
      dispatch(storePayoutBatches({ id, data }));

      return makeSelectPayoutBatches(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const { storePayoutDestinations, markPayoutDestinationsDirty } = createSingleActions<
  PayoutDestination[],
  'PayoutDestinations'
>(NAMESPACE, 'PayoutDestinations');

const payoutFetchDestinationsLimit = pLimit(1);
export const fetchPayoutDestinations = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayoutDestinations`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    payoutFetchDestinationsLimit(async () => {
      const saved = makeSelectPayoutDestinations(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryPayoutDestinations, 'unable to fetch payout destinations')(id, {
        signal,
      });
      dispatch(storePayoutDestinations({ id, data }));

      return makeSelectPayoutDestinations(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const createPayout = createAppAsyncThunk(
  `${NAMESPACE}/createPayout`,
  async (payout: NewPayout, { dispatch, signal }) => {
    const data = await withAPICall(requestCreatePayout, 'unable to create the payout')(payout, { signal });

    if (data.data) {
      dispatch(storePayout({ id: data.data.id, data }));
      dispatch(markPayoutListDirty());
    }

    return data;
  },
);

export const updatePayout = createAppAsyncThunk(
  `${NAMESPACE}/updatePayout`,
  async ({ id, ...payout }: PayoutUpdate & { id: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdatePayout, 'unable to update the payout')(id, payout, { signal });

    if (data.data) {
      dispatch(storePayout({ id: data.data.id, data }));
      dispatch(storePayoutDestinations({ id, data: loadingDataLoaded(payout.destinations) }));
      dispatch(markPayoutListDirty());
    }

    return data;
  },
);

export const updatePayoutTitle = createAppAsyncThunk(
  `${NAMESPACE}/updatePayoutTitle`,
  async ({ id, title }: { id: string; title: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdatePayoutTitle, 'unable to update payout title')(id, title, { signal });

    if (data.data) {
      dispatch(storePayout({ id: data.data.id, data }));
      dispatch(markPayoutListDirty());
    }

    return data;
  },
);

export const startPayout = createAppAsyncThunk(
  `${NAMESPACE}/startPayout`,
  async (
    { id, signature }: { id: string; signature: MerklePayoutSignatureData | SimplePayoutSignatureData },
    { dispatch, signal },
  ) => {
    if ('destinations' in signature) {
      return storedDataError<PayoutSummary>('Simple type is not supported');
    }

    const data = await withAPICall(requestStartMerklePayout, 'unable to start payout')(id, signature, { signal });

    const payout = data.data;
    if (payout) {
      dispatch(storePayout({ id: payout.id, data }));
      dispatch(markPayoutDestinationsDirty(payout.id));
      dispatch(markPayoutBatchesDirty(payout.id));
      dispatch(markPayoutListDirty());
    }

    return data;
  },
);

export const removePayout = createAppAsyncThunk(
  `${NAMESPACE}/removePayout`,
  async ({ id }: { id: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestDeletePayout, 'unable to delete payout')(id, { signal });

    if (!data.error) {
      dispatch(storeRemovePayout(id));
      dispatch(markPayoutListDirty());
    }

    return data;
  },
);

const destinationsPerBatch = 256;
const minDestinationsAmount = 1024;
export const prepareMerklePayoutData = createAppAsyncThunk(
  `${NAMESPACE}/prepareMerklePayoutData`,
  async (
    { id, destinations }: { id: string; destinations: { num: number; address: Address; rawAmount: bigint }[] },
    { dispatch },
  ) => {
    const payoutState = await dispatch(fetchPayout({ id })).unwrap();
    const payout = payoutState.data;
    if (!payout) {
      return storedDataError<PayoutMerkleTreeSignatureData>(payoutState.error || 'Payout not found');
    }

    const meaningfulBatches = Math.ceil(destinations.length / destinationsPerBatch);
    const requiredDestinationsCount = (() => {
      let count = minDestinationsAmount;
      while (destinations.length > count) {
        count *= 2;
      }
      return count;
    })();
    const normalizedDestinations =
      requiredDestinationsCount === destinations.length
        ? destinations
        : [
            ...destinations,
            ...new Array<{ num: undefined; address: Address; rawAmount: bigint }>(
              requiredDestinationsCount - destinations.length,
            ).fill({ num: undefined, address: zeroAddress, rawAmount: BigInt(0) }),
          ];

    const leaves = normalizedDestinations.map(({ address, rawAmount }) =>
      keccak256(encodePacked(['address', 'uint256'], [address, rawAmount])),
    );
    const { MerkleTree } = await import('merkletreejs');
    const tree = new MerkleTree(leaves, keccak256, { sort: false });

    const hexByLayers = tree.getHexLayers();
    const bottomProofs = hexByLayers[9].map((hex) => hex as Hex);
    const batchProofs = hexByLayers[8].map((hex) => hex as Hex);

    const result: PayoutMerkleTreeSignatureData = {
      rootProof: tree.getHexRoot() as Hex,
      bottomProofs,
      batches: batchProofs
        .filter((_, idx) => idx < meaningfulBatches)
        .map((proof, idx) => ({
          proof,
          adjacentProof: batchProofs[idx % 2 === 0 ? idx + 1 : idx - 1],
          destinations: normalizedDestinations
            .slice(idx * destinationsPerBatch, (idx + 1) * destinationsPerBatch)
            .map(({ num }) => num)
            .filter(notEmpty),
        })),
    };

    return loadingDataLoaded(result);
  },
);

export const { storeDestinationsPerPayoutListParameters } = createNestedListParametersActions<
  'DestinationsPerPayout',
  PayoutDestinationDetailedFilter,
  PayoutDestinationDetailedSortByAPIModel
>(NAMESPACE, 'DestinationsPerPayout');

export const { storeDestinationsPerBatchListParameters } = createNestedListParametersActions<
  'DestinationsPerBatch',
  PayoutDestinationFilter,
  PayoutDestinationSortByAPIModel,
  PayoutBatchId
>(NAMESPACE, 'DestinationsPerBatch');

export const { storePayoutForSettlement, markPayoutForSettlementDirty } =
  createSingleActions<string, 'PayoutForSettlement'>(NAMESPACE, 'PayoutForSettlement');

const payoutFetchForSettlementLimit = pLimit(1);
export const fetchPayoutForSettlement = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayoutForSettlement`,
  async ({ force, settlementId }: { force?: boolean; settlementId: string }, { dispatch, getState, signal }) =>
    payoutFetchForSettlementLimit(async (): Promise<LoadingStateWithDirty<PayoutSummary>> => {
      const saved = makeSelectPayoutForSettlement(settlementId)(getState());
      if (!force && !saved.isDirty) {
        return saved.data
          ? dispatch(fetchPayout({ id: saved.data, force })).unwrap()
          : storedDataError<PayoutSummary>(saved.error ?? 'no data');
      }

      const data = await withAPICall(queryPayoutBySettlement, 'unable to fetch payout')(settlementId, {
        signal,
      });
      dispatch(storePayoutForSettlement({ id: settlementId, data: mapLoadingState(data, ({ id }) => id) }));
      if (data.data) {
        dispatch(storePayout({ id: data.data.id, data }));
      }

      return loadingStateToLoaded(data);
    }),
  { idGenerator: ({ settlementId }) => settlementId },
);
