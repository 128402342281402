import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectAvailableBalances } from '@/features/collectable/actions';
import {
  makeSelectCollectAvailableBalanceListData,
  makeSelectCollectAvailableBalanceListParameters,
} from '@/features/collectable/selectors';
import type { CollectableBalance, CollectableBalanceFilterPredicate } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { CollectableAddressBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseCollectAvailableBalanceList = UseAppListData<
  CollectableBalance,
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<CollectableBalance>, Parameters<typeof fetchCollectAvailableBalances>[0]> =>
  fetchCollectAvailableBalances({ force });

const dataFetchingSelector = makeSelectPending(fetchCollectAvailableBalances);
const dataSelector = makeSelectCollectAvailableBalanceListData();
const parametersSelector = makeSelectCollectAvailableBalanceListParameters();

export default function useCollectAvailableBalanceList(): UseCollectAvailableBalanceList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
