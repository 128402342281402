import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { FilterForm } from '@/components';
import { BlockchainItem } from '@/features/dictionary/blockchain/components';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { MerchantWalletRunningBalanceFilterPredicate } from '@/features/merchant-wallet-balance/types';
import { nameof } from '@/infrastructure/utils/ts';

import { MerchantWalletLikeFilterItem, MerchantWalletRunningBalanceBroadcastAtRangeFilterItem } from './components';

import type { MerchantWalletRunningBalanceFilterFormProps } from './types';

const fields = [
  nameof<MerchantWalletRunningBalanceFilterPredicate>('walletLike'),
  nameof<MerchantWalletRunningBalanceFilterPredicate>('btIn'),
  nameof<MerchantWalletRunningBalanceFilterPredicate>('broadcastAtRange'),
];

const selectNetwork = makeSelectSelectedNetwork();

const MerchantWalletRunningBalanceFilterForm: React.FC<
  MerchantWalletRunningBalanceFilterFormProps<MerchantWalletRunningBalanceFilterPredicate>
> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const network = useAppSelector(selectNetwork);
  return (
    <FilterForm fields={fields} {...props}>
      <MerchantWalletLikeFilterItem
        data-test={dataTest && `${dataTest}-wallet`}
        name={nameof<MerchantWalletRunningBalanceFilterPredicate>('walletLike')}
        readonly={readonly}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<MerchantWalletRunningBalanceFilterPredicate>('btIn')}
        multiselect
        readonly={readonly}
        network={!readonly ? network : undefined}
        required={false}
      />
      <MerchantWalletRunningBalanceBroadcastAtRangeFilterItem<MerchantWalletRunningBalanceFilterPredicate>
        data-test={dataTest && `${dataTest}-broadcastAt`}
        name={nameof<MerchantWalletRunningBalanceFilterPredicate>('broadcastAtRange')}
        readonly={readonly}
        relativeUpdateKey="broadcastAtRangeRelative"
      />
    </FilterForm>
  );
};

const MerchantWalletRunningBalanceFilterFormMemo = React.memo(MerchantWalletRunningBalanceFilterForm);

export default MerchantWalletRunningBalanceFilterFormMemo;
