import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import type {
  MerchantWalletRunningBalance,
  MerchantWalletRunningBalanceFilterPredicate,
} from '@/features/merchant-wallet-balance/types';
import { MerchantWalletRunningBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';
import { useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import MerchantWalletRunningBalanceConfirmationStatusView from '../MerchantWalletRunningBalanceConfirmationStatusView';
import MerchantWalletRunningBalanceView from '../MerchantWalletRunningBalanceView';

import type { MerchantWalletRunningBalancesTableProps } from './types';
import type { ProColumnType } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<MerchantWalletRunningBalance>('blockNum')]: MerchantWalletRunningBalanceSortByAPIModel.blockNum,
  [nameof<MerchantWalletRunningBalance>('wallet')]: MerchantWalletRunningBalanceSortByAPIModel.wallet,
};
const sortColumnToModel = (s: string) => sortMapping[s];
const extractKey = ({ id }: MerchantWalletRunningBalance) => id;

const MerchantWalletRunningBalancesTable = <
  FilterType extends MerchantWalletRunningBalanceFilterPredicate = MerchantWalletRunningBalanceFilterPredicate,
>({
  'data-test': dataTest,
  data,
  loading,
  page,
  filter,
  columnState,
  sortBy,
  forceRefresh,
  updateParameters,
  ExportOperation,
  FilterForm,
  MerchantWalletRunningBalanceLink,
}: MerchantWalletRunningBalancesTableProps<FilterType>) => {
  const columns: ProColumnType<MerchantWalletRunningBalance>[] = useMemo(
    () => [
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_WALLET} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('wallet'),
        render: (_, { id, blockchain, wallet }) => (
          <AddressLink data-test={dataTest && `${dataTest}-${id}-wallet`} address={wallet} bt={blockchain} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_BLOCK} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('blockNum'),
        sortOrder: sortOrderToTable(sortBy.blockNum),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, blockNum }) => (
          <ReadonlyComponent value={blockNum} data-test={dataTest && `${dataTest}-${id}-blockNum`} />
        ),
        width: 100,
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_BROADCAST_AT} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('broadcastAt'),
        valueType: 'dateTime',
        render: (_, { id, broadcastAt }) => (
          <ReadonlyDateTime value={broadcastAt} data-test={dataTest && `${dataTest}-${id}-broadcastAt`} />
        ),
        width: 160,
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_AMOUNT} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('amount'),
        fixed: 'right',
        width: 120,
        render: (_, { amount: { value, asset } }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                data-test={dataTest && `${dataTest}-amount`}
                assetId={asset}
                value={value}
                withCurrency
                withBlockchainMark
              />
            }
            copyable={false}
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_BALANCE} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('balance'),
        fixed: 'right',
        width: 120,
        render: (_, { balance: { value, asset } }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                data-test={dataTest && `${dataTest}-balance`}
                assetId={asset}
                value={value}
                withCurrency
                withBlockchainMark
              />
            }
            copyable={false}
          />
        ),
      },

      {
        title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_CONFIRMATION} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('confirmation'),
        fixed: 'right',
        render: (_, { id, confirmation }) => (
          <MerchantWalletRunningBalanceConfirmationStatusView
            value={confirmation}
            data-test={dataTest && `${dataTest}-${id}-confirmation`}
            mode="icon"
          />
        ),
        align: 'center',
        width: 80,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: nameof<MerchantWalletRunningBalance>('id'),
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {MerchantWalletRunningBalanceLink && (
              <MerchantWalletRunningBalanceLink value={id} data-test={dataTest && `${dataTest}-${id}-link`} />
            )}
          </StopPropagationContainer>
        ),
      },
    ],
    [MerchantWalletRunningBalanceLink, dataTest, sortBy.blockNum],
  );

  const preview = useCallback(
    (value: MerchantWalletRunningBalance) => ({
      title: MerchantWalletRunningBalanceLink ? (
        <TitleWithDetails
          title={
            <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_BALANCE_TABLE_BALANCE_DIALOG_TITLE} />
          }
          link={
            <MerchantWalletRunningBalanceLink
              value={value.id}
              mode="text"
              title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} />}
            />
          }
          data-test={dataTest && `${dataTest}-dialog-title`}
        />
      ) : (
        <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_BALANCE_TABLE_BALANCE_DIALOG_TITLE} />
      ),
      content: (
        <MerchantWalletRunningBalanceView
          data-test={dataTest && `${dataTest}-dialog-view`}
          data={value}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
      maskClosable: true,
      width: 650,
    }),
    [MerchantWalletRunningBalanceLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'broadcastAtRange', 'broadcastAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey={nameof<FilterType, 'broadcastAtRange'>('broadcastAtRange')}
              relativeKey={nameof<FilterType, 'broadcastAtRangeRelative'>('broadcastAtRangeRelative')}
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      ExportOperation,
      FilterForm,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<MerchantWalletRunningBalance, FilterType, MerchantWalletRunningBalanceSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={forceRefresh}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const MerchantWalletRunningBalancesTableMemo = React.memo(
  MerchantWalletRunningBalancesTable,
) as typeof MerchantWalletRunningBalancesTable;

export default MerchantWalletRunningBalancesTableMemo;
