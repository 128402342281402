import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloudServerOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { ForwarderDelegateStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRDelegateContractStatusViewProps } from './types';

const i18n: Record<ForwarderDelegateStatusAPIModel, I18nMessages> = {
  [ForwarderDelegateStatusAPIModel.Defined]: I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_STATUS_VIEW_DEFINED,
  [ForwarderDelegateStatusAPIModel.Pending]: I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_STATUS_VIEW_PENDING,
  [ForwarderDelegateStatusAPIModel.Deployed]: I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_STATUS_VIEW_DEPLOYED,
  [ForwarderDelegateStatusAPIModel.Failed]: I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_STATUS_VIEW_FAILED,
};

const icons: Record<ForwarderDelegateStatusAPIModel, React.ReactNode> = {
  [ForwarderDelegateStatusAPIModel.Defined]: <ClockCircleOutlined style={{ color: 'blue' }} />,
  [ForwarderDelegateStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <CloudServerOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [ForwarderDelegateStatusAPIModel.Deployed]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [ForwarderDelegateStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const QRDelegateContractStatusView: React.FC<QRDelegateContractStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const QRDelegateContractStatusViewMemo = React.memo(QRDelegateContractStatusView);

export default QRDelegateContractStatusViewMemo;
