import { useMemo } from 'react';

import { useMerchantWallets } from '@/features/merchant-wallets/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';

export default function useMerchantWallet(bt: BlockchainTypeAPIModel | undefined, address?: string) {
  const { loading, forceRefresh, data: walletsState } = useMerchantWallets();
  const data = useMemo(
    () =>
      mapStoredState(walletsState, (wallets) =>
        wallets.find(
          (wallet) => wallet.blockchain === bt && (address ? wallet.address === address : wallet.isFactoryActive),
        ),
      ),
    [address, bt, walletsState],
  );

  return useMemo(() => ({ loading, forceRefresh, data }), [data, forceRefresh, loading]);
}
