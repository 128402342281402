import { CheckCircleOutlined, ExclamationCircleOutlined, HourglassOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PaymentTransactionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeaturePaymentTransactions } from '@/generated/i18n/i18n';

import type { PaymentTransactionStatusViewProps } from './types';

const icons: Record<PaymentTransactionStatusAPIModel, React.ReactNode> = {
  [PaymentTransactionStatusAPIModel.Confirmed]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [PaymentTransactionStatusAPIModel.Confirming]: <HourglassOutlined spin style={{ color: 'blue' }} />,
  [PaymentTransactionStatusAPIModel.Invalid]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const i18n: Record<PaymentTransactionStatusAPIModel, I18nMessages> = {
  [PaymentTransactionStatusAPIModel.Confirmed]:
    I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_STATUS_CONFIRMED,
  [PaymentTransactionStatusAPIModel.Confirming]:
    I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_STATUS_CONFIRMING,
  [PaymentTransactionStatusAPIModel.Invalid]:
    I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_STATUS_INVALID,
};

const PaymentTransactionStatusViewRaw: React.FC<PaymentTransactionStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const PaymentTransactionStatusView = React.memo(
  PaymentTransactionStatusViewRaw,
) as typeof PaymentTransactionStatusViewRaw;

export default PaymentTransactionStatusView;
