import { useMemo } from 'react';

import { noSingleDataIdFn, type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonationIdByAddress } from '@/features/donations/actions';
import { makeSelectDonationIdByAddress } from '@/features/donations/selectors';
import type { Donation } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

import useDonation from './useDonation';

export type UseDonationByAddressId = UseAppSingleData<Donation, unknown>;

const fetchFactory = (
  addressId: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<string>, Parameters<typeof fetchDonationIdByAddress>[0]> =>
  fetchDonationIdByAddress({ addressId, force });

export default function useDonationByAddressId(addressId: string | undefined): UseDonationByAddressId {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchDonationIdByAddress, addressId), [addressId]);
  const idState = useAppSingleData(
    fetchFactory,
    makeSelectDonationIdByAddress,
    noSingleDataIdFn(),
    addressId,
    dataFetchingSelector,
  );
  const donationState = useDonation(idState.data.data);
  return {
    forceRefresh: idState.forceRefresh,
    loading: idState.loading || donationState.loading,
    data: donationState.data,
  };
}
