import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import PayoutsLink from '@/pages/payouts/payouts/components/PayoutsLink';

import type { PayoutsBreadcrumbProps } from './types';

const PayoutsBreadcrumb: React.FC<PayoutsBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPagePayouts.PAYOUTS_COMPONENTS_BREADCRUMB} />
        ) : (
          <PayoutsLink mode="text" title={<FormattedMessage id={I18nPagePayouts.PAYOUTS_COMPONENTS_BREADCRUMB} />} />
        ),
      },
      ...items,
    ]}
  />
);

const PayoutsBreadcrumbMemo = React.memo(PayoutsBreadcrumb);

export default PayoutsBreadcrumbMemo;
