/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { NewPayoutDestinationAPIModel } from './NewPayoutDestinationAPIModel';
import {
    NewPayoutDestinationAPIModelFromJSON,
    NewPayoutDestinationAPIModelFromJSONTyped,
    NewPayoutDestinationAPIModelToJSON,
} from './NewPayoutDestinationAPIModel';
import type { PayoutTypeAPIModel } from './PayoutTypeAPIModel';
import {
    PayoutTypeAPIModelFromJSON,
    PayoutTypeAPIModelFromJSONTyped,
    PayoutTypeAPIModelToJSON,
} from './PayoutTypeAPIModel';

/**
 * 
 * @export
 * @interface NewPayoutAPIModel
 */
export interface NewPayoutAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewPayoutAPIModel
     */
    asset: string;
    /**
     * 
     * @type {PayoutTypeAPIModel}
     * @memberof NewPayoutAPIModel
     */
    mode: PayoutTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof NewPayoutAPIModel
     */
    title: string;
    /**
     * 
     * @type {Array<NewPayoutDestinationAPIModel>}
     * @memberof NewPayoutAPIModel
     */
    destinations?: Array<NewPayoutDestinationAPIModel>;
}

/**
 * Check if a given object implements the NewPayoutAPIModel interface.
 */
export function instanceOfNewPayoutAPIModel(value: object): boolean {
    if (!('asset' in value)) return false;
    if (!('mode' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function NewPayoutAPIModelFromJSON(json: any): NewPayoutAPIModel {
    return NewPayoutAPIModelFromJSONTyped(json, false);
}

export function NewPayoutAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPayoutAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'mode': PayoutTypeAPIModelFromJSON(json['mode']),
        'title': json['title'],
        'destinations': json['destinations'] == null ? undefined : ((json['destinations'] as Array<any>).map(NewPayoutDestinationAPIModelFromJSON)),
    };
}

export function NewPayoutAPIModelToJSON(value?: NewPayoutAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'asset': value['asset'],
        'mode': PayoutTypeAPIModelToJSON(value['mode']),
        'title': value['title'],
        'destinations': value['destinations'] == null ? undefined : ((value['destinations'] as Array<any>).map(NewPayoutDestinationAPIModelToJSON)),
    };
}

