import dayjs from 'dayjs';

import type {
  CollectableEntityProcessTransactionAPIModel,
  CollectableEntityTransactionAPIModel,
  CollectScheduleAPIModel,
  CollectTaskAPIModel,
  CollectTaskPredicatesAPIModel,
  CollectTaskSummaryAPIModel,
  CollectTaskAddressAPIModel,
  CollectTaskSortByAPIModel,
  CollectableAddressBalanceSortByAPIModel,
  CollectableAddressBalanceAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { CollectableApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import type { SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { amountFromAPI, pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';

import type {
  CollectableEntityProcessTransaction,
  CollectTask,
  CollectTaskAddress,
  CollectTaskSummary,
  CollectableEntityTransaction,
  CollectableBalanceFilterPredicate,
  CollectableBalance,
  CollectableEntityTypedId,
} from './types';

const collectableApi = new CollectableApi(coreConfigurationFactory('auth'));

const parseTaskSummary = ({ collectedAmount, ...task }: CollectTaskSummaryAPIModel): CollectTaskSummary => ({
  ...task,
  staleAt: dayjs(task.processAt).add(1, 'day').toDate(),
  collectedAmount: collectedAmount ? amountFromAPI(collectedAmount) : undefined,
});

const parseCollectAddress = ({ collectedAmount, ...address }: CollectTaskAddressAPIModel): CollectTaskAddress => ({
  ...address,
  collectedAmount: collectedAmount ? amountFromAPI(collectedAmount) : undefined,
});

const parseTask = ({ collectedAmount, addresses, ...task }: CollectTaskAPIModel): CollectTask => ({
  ...task,
  staleAt: dayjs(task.processAt).add(1, 'day').toDate(),
  collectedAmount: collectedAmount ? amountFromAPI(collectedAmount) : undefined,
  addresses: addresses?.map(parseCollectAddress) ?? [],
});

export const parseCollectableTransaction = ({
  amount,
  ...tx
}: CollectableEntityTransactionAPIModel): CollectableEntityTransaction => ({
  ...tx,
  amount: amountFromAPI(amount),
});

export const parseProcessTransaction = (
  tx: CollectableEntityProcessTransactionAPIModel,
): CollectableEntityProcessTransaction => ({
  ...tx,
});

export const queryCollectSchedule = (initOverrides?: RequestInit) => collectableApi.getCollectSchedule(initOverrides);

export const requestCollectNow = async (asset: string, initOverrides?: RequestInit) =>
  collectableApi.triggerCollectNow({ asset }, initOverrides);

export const requestUpdateCollectSchedule = (update: CollectScheduleAPIModel, initOverrides?: RequestInit) =>
  collectableApi.updateCollectSchedule(update, initOverrides);

export const requestDeleteCollectSchedule = (initOverrides?: RequestInit) =>
  collectableApi.deleteCollectSchedule(initOverrides);

export const queryCollectTasks = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<CollectTaskPredicatesAPIModel, CollectTaskSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await collectableApi.searchCollectTasks(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseTaskSummary,
  );

export const queryCollectTask = async (taskId: string, initOverrides?: RequestInit) =>
  parseTask(await collectableApi.getCollectTask(taskId, initOverrides));

export const queryCollectTaskProcessTransaction = async (txId: string, initOverrides?: RequestInit) =>
  parseProcessTransaction(await collectableApi.getCollectTaskProcessTransaction(txId, initOverrides));

export const queryCollectThreshold = async (initOverrides?: RequestInit) =>
  ((await collectableApi.getCollectThresholds(initOverrides)).thresholds ?? []).map(({ amount }) =>
    amountFromAPI(amount),
  );

const parseCollectableBalance = ({ balance, ...data }: CollectableAddressBalanceAPIModel): CollectableBalance => ({
  ...data,
  balance: amountFromAPI(balance),
});

export const queryCollectableBalances = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<CollectableBalanceFilterPredicate, CollectableAddressBalanceSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await collectableApi.searchCollectableAddressBalances(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseCollectableBalance,
  );

export const queryCollectableBalance = async (id: CollectableEntityTypedId, initOverrides?: RequestInit) =>
  parseCollectableBalance(await collectableApi.getCollectableAddressBalance(id.kind, id.id, initOverrides));
