/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionResultAPIModel {
    Success = 'Success',
    Error = 'Error'
}


export function TransactionResultAPIModelFromJSON(json: any): TransactionResultAPIModel {
    return TransactionResultAPIModelFromJSONTyped(json, false);
}

export function TransactionResultAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionResultAPIModel {
    return json as TransactionResultAPIModel;
}

export function TransactionResultAPIModelToJSON(value?: TransactionResultAPIModel | null): any {
    return value as any;
}

