import { Alert, Tag, Tooltip } from 'antd';
import React from 'react';

import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import { withGasWallet } from '@/features/gas-wallets/hocs';

import type { GasWalletRefundingStateCheckBannerProps } from './types';

const type = 'derived-balance';

const GasWalletRefundingStateCheckBanner: React.FC<GasWalletRefundingStateCheckBannerProps> = ({
  'data-test': dataTest,
  wallet,
  mode,
}) => {
  if (!wallet.isAnyRefunding) {
    return null;
  }

  switch (mode) {
    case 'alert':
      return (
        <Alert
          message={<GasWalletInvalidStateMessage mode="short" wallet={wallet} type={type} />}
          description={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}
          data-test={dataTest}
          type="info"
          showIcon
        />
      );
    case 'icon':
      return (
        <Tooltip title={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}>
          <Tag color="success">
            <GasWalletInvalidStateMessage mode="tag" wallet={wallet} type={type} />
          </Tag>
        </Tooltip>
      );
    case 'message':
      return <GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />;
  }
};

const GasWalletRefundingStateCheckBannerHOC = withGasWallet<GasWalletRefundingStateCheckBannerProps>(
  GasWalletRefundingStateCheckBanner,
);

const GasWalletRefundingStateCheckBannerMemo = React.memo(
  GasWalletRefundingStateCheckBannerHOC,
) as typeof GasWalletRefundingStateCheckBannerHOC;

export default GasWalletRefundingStateCheckBannerMemo;
