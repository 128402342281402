import React from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useDonationByAddressId } from '@/features/donations/hooks';
import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { donationViewLink } from '@/pages/donations/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { DonationEntityLinkProps } from './types';

const DonationEntityLink: React.FC<DonationEntityLinkProps> = ({ 'data-test': dataTest, value }) => {
  const data = useDonationByAddressId(value.id);
  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={donationViewLink(data.data.data.id)} replace />;
  }

  return (
    <EntityNotFoundAlert data-test={dataTest} value={CollectableKindAPIModel.Donation} onRetry={data.forceRefresh} />
  );
};

const DonationEntityLinkMemo = React.memo(DonationEntityLink);

export default DonationEntityLinkMemo;
