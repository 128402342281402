import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import QRDelegateContractsBreadcrumb from '@/pages/audit/qr-contracts/components/QRDelegateContractsBreadcrumb';

import QRDelegateContractLink from '../QRDelegateContractLink';

import type { QRDelegateContractBreadcrumbProps } from './types';

const QRDelegateContractBreadcrumb: React.FC<QRDelegateContractBreadcrumbProps> = ({
  'data-test': dataTest,
  bt,
  disabled,
  items = [],
}) => (
  <QRDelegateContractsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.QR_CONTRACT_VIEW_COMPONENTS_BREADCRUMB} values={{ bt }} />
        ) : (
          <QRDelegateContractLink
            mode="text"
            value={bt}
            title={<FormattedMessage id={I18nPageAudit.QR_CONTRACT_VIEW_COMPONENTS_BREADCRUMB} values={{ bt }} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const QRDelegateContractBreadcrumbMemo = React.memo(QRDelegateContractBreadcrumb);

export default QRDelegateContractBreadcrumbMemo;
