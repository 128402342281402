import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchTokenHistoryBalance } from '@/features/dictionary/blockchain/actions';
import { makeSelectTokenHistoryBalance } from '@/features/dictionary/blockchain/selectors';
import type {
  AssetAddressHistoryKey,
  FetchAssetAddressHistoryBalancePayload,
  AssetHistoryBalanceData,
} from '@/features/dictionary/blockchain/types';
import { createAssetAddressHistoryKey } from '@/features/dictionary/blockchain/utils';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export type UseTokenHistoryBalanceType = UseAppSingleData<AssetHistoryBalanceData>;

const fetchFactory = (
  id: AssetAddressHistoryKey,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<AssetHistoryBalanceData>, FetchAssetAddressHistoryBalancePayload> =>
  fetchTokenHistoryBalance({ ...id, force });

export default function useTokenHistoryBalance(id: AssetAddressHistoryKey | undefined): UseTokenHistoryBalanceType {
  return useAppSingleData(
    fetchFactory,
    makeSelectTokenHistoryBalance,
    noSingleDataIdFn(),
    id,
    useMemo(() => makeSelectPending(fetchTokenHistoryBalance, id && createAssetAddressHistoryKey(id)), [id]),
  );
}
