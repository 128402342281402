import { Form } from 'antd';
import BigNumber from 'bignumber.js';
import isNil from 'lodash-es/isNil';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import type { PayoutDestinationRow } from '@/features/payouts/components';
import type { PayoutDestinationTableEditFormValues } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { PayoutTableRowFormValueContext } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { usePrevious, useStateMountSafe } from '@/hooks';
import type { TestableProps } from '@/infrastructure/utils/react';

export interface UsePayoutTableEditableRow {
  data: PayoutDestinationRow[];
  editingKey?: number;
  onAdd: () => unknown;
  onCancel: () => unknown;
  onEdit: (value: PayoutDestinationRow) => unknown;
  onDelete: (value: PayoutDestinationRow) => unknown;
  onSave: (value: PayoutDestinationRow) => unknown;
}

export type UsePayoutTableEditableRowParams = TestableProps & {
  data: PayoutDestinationRow[];
  onChange: (data: PayoutDestinationRow[]) => unknown;
};

export default function usePayoutTableEditableRow({
  data,
  onChange,
}: UsePayoutTableEditableRowParams): UsePayoutTableEditableRow {
  const { formItemName, setInitialValue, editingKey, setEditingKey } = useContext(PayoutTableRowFormValueContext);
  const form = Form.useFormInstance();

  const prevEditingKey = usePrevious(editingKey);
  useEffect(() => {
    if (prevEditingKey !== editingKey) {
      const value = data.find(({ num }) => num === editingKey);
      const newInitialValue: Partial<PayoutDestinationTableEditFormValues> = {
        address: value?.address,
        amount: value?.amount ? { value: value.amount, inputValue: value.amount.toString(10), valid: true } : undefined,
      };
      form.setFieldsValue({ [formItemName]: newInitialValue });
      setInitialValue(newInitialValue);
    }
  }, [data, editingKey, form, formItemName, prevEditingKey, setInitialValue]);

  const [rowToAdd, setRowToAdd] = useStateMountSafe<PayoutDestinationRow | undefined>();
  const editingData = useMemo<PayoutDestinationRow[]>(() => (rowToAdd ? [rowToAdd, ...data] : data), [rowToAdd, data]);

  const onDelete = useCallback(
    async ({ num }: PayoutDestinationRow) => {
      const newData = data.filter((item) => item.num !== num);
      if (newData.length !== data.length) {
        await onChange(newData);
      }
    },
    [data, onChange],
  );

  const onEdit = useCallback(({ num }: PayoutDestinationRow) => setEditingKey(num), [setEditingKey]);
  const onAdd = useCallback(() => {
    const newRow = {
      num: Math.max(...(data.length ? data.map(({ num }) => num) : [0])) + 1,
      amount: BigNumber(0),
      address: '',
    };
    setRowToAdd(newRow);
    setEditingKey(newRow.num);
  }, [data, setEditingKey, setRowToAdd]);

  const onSave = useCallback(
    async (value: PayoutDestinationRow) => {
      const index = data.findIndex((item) => value.num === item.num);
      const newData =
        index > -1
          ? (() => {
              const updated = [...data];
              updated[index] = value;
              return updated;
            })()
          : [value, ...data];
      await onChange(newData);
      setEditingKey(undefined);
      setRowToAdd(undefined);
    },
    [data, onChange, setEditingKey, setRowToAdd],
  );
  const onCancel = useCallback(() => {
    if (isNil(editingKey)) {
      return;
    }
    setEditingKey(undefined);
    setRowToAdd(undefined);
  }, [editingKey, setEditingKey, setRowToAdd]);

  return { data: editingData, editingKey, onAdd, onCancel, onDelete, onEdit, onSave };
}
