import { Alert, Tag } from 'antd';
import React from 'react';

import { WarningTooltip } from '@/components';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';

import type { GasWalletPresenceCheckBannerProps } from './types';

const type = 'presence';

const GasWalletPresenceCheckBanner: React.FC<GasWalletPresenceCheckBannerProps> = ({
  'data-test': dataTest,
  bt,
  mode,
}) => {
  const { data, loading } = useMerchantGasWallet(bt);
  if (!!data.data || !!data.error || data.isDirty || loading) {
    return null;
  }
  switch (mode) {
    case 'alert':
      return (
        <Alert
          message={<GasWalletInvalidStateMessage mode="short" bt={bt} type={type} />}
          description={<GasWalletInvalidStateMessage mode="full" bt={bt} type={type} />}
          data-test={dataTest}
          type="error"
          showIcon
        />
      );
    case 'icon':
      return (
        <WarningTooltip type="error" title={<GasWalletInvalidStateMessage mode="full" bt={bt} type={type} />}>
          <Tag color="error">
            <GasWalletInvalidStateMessage mode="tag" bt={bt} type={type} />
          </Tag>
        </WarningTooltip>
      );
    case 'message':
      return <GasWalletInvalidStateMessage mode="full" bt={bt} type={type} />;
  }
};
const GasWalletPresenceCheckBannerMemo = React.memo(GasWalletPresenceCheckBanner);

export default GasWalletPresenceCheckBannerMemo;
