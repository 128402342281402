import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import { useAsset, useBlockchain } from '@/features/dictionary/blockchain/hooks';
import PaymentTransactionKindView from '@/features/payment-transactions/components/PaymentTransactionKindView';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePaymentTransactions } from '@/generated/i18n/i18n';

import PaymentTransactionStatusView from '../PaymentTransactionStatusView';

import type { PaymentTransactionViewProps } from './types';

const PaymentTransactionView: React.FC<PaymentTransactionViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
  PaymentLink,
  SettlementLink,
}) => {
  const asset = useAsset(data.amount.asset);
  const bt = asset.data.data?.blockchain;
  const bc = useBlockchain(bt);
  return (
    <Descriptions
      style={style}
      className={className}
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={<PaymentTransactionStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
      column={columns}
    >
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_KIND} />}>
        <PaymentTransactionKindView value={data.kind} data-test={dataTest && `${dataTest}-kind`} />
      </Descriptions.Item>
      {PaymentLink && (
        <Descriptions.Item
          label={
            <FormattedMessage
              id={I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_VIEW_ROW_TITLE_PAYMENT}
            />
          }
        >
          <PaymentLink data-test={dataTest && `${dataTest}-payment`} value={data.paymentId} />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_TX_ID} />}>
        <TransactionLink data-test={dataTest && `${dataTest}-txId`} bt={data.blockchain} tx={data.txId} />
      </Descriptions.Item>
      {bc.data.data?.apiType === BlockchainAPITypeAPIModel.BTC && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_IDX} />}>
          <ReadonlyComponent data-test={dataTest && `${dataTest}-idx`} value={data.index} />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_AMOUNT} />}>
        <AssetAmount
          value={data.amount.value}
          assetId={data.amount.asset}
          withCurrency
          currencyMode="long"
          data-test={dataTest && `${dataTest}-amount`}
        />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_PAID_AT} />}>
        <ReadonlyDateTime value={data.paidAt} data-test={dataTest && `${dataTest}-paidAt`} />
      </Descriptions.Item>
      {SettlementLink && data.withdrawnWith && (
        <Descriptions.Item
          label={
            <FormattedMessage
              id={I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_VIEW_ROW_TITLE_SETTLEMENT}
            />
          }
        >
          <SettlementLink data-test={dataTest && `${dataTest}-settlement`} value={data.withdrawnWith} />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const PaymentTransactionViewMemo = React.memo(PaymentTransactionView);

export default PaymentTransactionViewMemo;
