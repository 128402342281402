import { Modal, Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { withWeb3Context } from '@/features/web3/hocs';
import { Web3AuthConnectorId } from '@/features/web3/hocs/withWeb3Support/web3auth-connector-id';
import { useWeb3, useWeb3Singleton } from '@/features/web3/hooks';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useStateMountSafe, useSubmitting } from '@/hooks';

import OperationDeployMerchantWalletWithConnector from '../OperationDisconnectWeb3AuthWithConnector';

import type { DisconnectWeb3AuthModalProps } from './types';

const { Text } = Typography;

const DisconnectWeb3AuthModal: React.FC<DisconnectWeb3AuthModalProps> = (props) => {
  const { 'data-test': dataTest, state, onSuccess, onCancel } = props;
  const [retrieved, setRetrieved] = useStateMountSafe(false);
  const { orderedConnectors } = useWeb3();
  const { withReloadIfInitialized } = useWeb3Singleton();
  const [inProgress, withInProgress] = useSubmitting(false);
  const doSuccess = useMemo(() => withReloadIfInitialized(onSuccess), [onSuccess, withReloadIfInitialized]);
  const doCancel = useMemo(() => withReloadIfInitialized(onCancel), [onCancel, withReloadIfInitialized]);

  return (
    <Modal
      open
      maskClosable={false}
      title={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_DIALOG_TITLE} />}
      data-test={dataTest && `${dataTest}-modal`}
      onOk={doSuccess}
      onCancel={!inProgress ? doCancel : undefined}
      closable={!inProgress}
      footer=""
    >
      <Space direction="vertical">
        <FormattedMessageWithMarkup
          id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_DIALOG_DESCRIPTION}
          tagName="span"
          values={{
            retrieve: (title: React.ReactNode) => (
              <Operation mode="link" title={title} onClick={() => setRetrieved(true)} icon={null} />
            ),
          }}
        />
        {retrieved && (
          <FormattedMessage
            id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_DIALOG_PRIVATE_KEY}
            tagName="span"
            values={{
              key: (
                <Text code copyable className="ym-hide-content">
                  {state.privateKey}
                </Text>
              ),
            }}
          />
        )}
        <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
          {orderedConnectors
            .filter(({ id }) => id !== Web3AuthConnectorId)
            .map((connector) => (
              <OperationDeployMerchantWalletWithConnector
                {...props}
                key={connector.id}
                onSuccess={doSuccess}
                connectorId={connector.id}
                withInProgress={withInProgress}
              />
            ))}
        </Space>
      </Space>
    </Modal>
  );
};

const DisconnectWeb3AuthModalContexted = withWeb3Context(DisconnectWeb3AuthModal, React.Fragment);

const DisconnectWeb3AuthModalMemo = React.memo(
  DisconnectWeb3AuthModalContexted,
) as typeof DisconnectWeb3AuthModalContexted;

export default DisconnectWeb3AuthModalMemo;
