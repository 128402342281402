import React from 'react';

import { FormattedMessage } from '@/components';
import { CollectPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectPeriodLabelProps } from './types';

const i18n: Record<CollectPeriodAPIModel, I18nMessages> = {
  [CollectPeriodAPIModel.NotSet]: I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_PERIOD_LABEL_NOT_SET,
  [CollectPeriodAPIModel.LastDayOfMonth]:
    I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_PERIOD_LABEL_LAST_DAY_OF_MONTH,
  [CollectPeriodAPIModel.LastDayOfWeek]:
    I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_PERIOD_LABEL_LAST_DAY_OF_WEEK,
};

const CollectPeriodLabel: React.FC<CollectPeriodLabelProps> = ({ value }) => <FormattedMessage id={i18n[value]} />;

const CollectPeriodLabelMemo = React.memo(CollectPeriodLabel);

export default CollectPeriodLabelMemo;
