import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { markBalancesDirty } from '@/features/statistics/actions';
import type {
  CollectableAddressBalanceSortByAPIModel,
  CollectableAddressLinkAPIModel,
  CollectTaskSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { loadingDataLoaded, mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { identity } from '@/infrastructure/utils/functions';

import {
  queryCollectTask,
  queryCollectTasks,
  queryCollectSchedule,
  requestDeleteCollectSchedule,
  requestCollectNow,
  requestUpdateCollectSchedule,
  queryCollectTaskProcessTransaction,
  queryCollectThreshold,
  queryCollectableBalances,
  queryCollectableBalance,
} from './api';
import {
  makeSelectCollectTask,
  makeSelectCollectSchedule,
  makeSelectMultipleCollectTaskSummary,
  makeSelectDirtyCollectTaskSummaryIds,
  makeSelectCollectTaskSummaryListData,
  makeSelectCollectTaskSummaryListParameters,
  makeSelectCollectEntityProcessTransaction,
  makeSelectCollectTasksForAddressesListData,
  makeSelectCollectTasksForAddressesListParameters,
  makeSelectCollectThreshold,
  makeSelectCollectAvailableBalanceListData,
  makeSelectCollectAvailableBalanceListParameters,
  makeSelectCollectLockedBalanceListData,
  makeSelectCollectLockedBalanceListParameters,
  makeSelectCollectableBalance,
} from './selectors';
import { NAMESPACE } from './types';
import { collectableTaskLinksToId, extractCollectTaskId } from './utils';

import type {
  CollectTaskSummary,
  CollectTaskFilterPredicate,
  CollectableEntityProcessTransaction,
  CollectableEntityTransaction,
  CollectTask,
  CollectSchedule,
  CollectScheduleUpdate,
  CollectThreshold,
  CollectableBalance,
  CollectableBalanceFilterPredicate,
  CollectableEntityTypedId,
} from './types';

export const { storeCollectTask, markCollectTaskDirty } = createSingleActions<CollectTask, 'CollectTask'>(
  NAMESPACE,
  'CollectTask',
);

export const { storeCollectEntityProcessTransaction, markCollectEntityProcessTransactionDirty } = createSingleActions<
  CollectableEntityProcessTransaction,
  'CollectEntityProcessTransaction'
>(NAMESPACE, 'CollectEntityProcessTransaction');

export const {
  storeCollectTaskSummary,
  storeCollectTaskSummaryListData,
  storeMultipleCollectTaskSummary,
  storeCollectTaskSummaryListParameters,
  markCollectTaskSummaryDirty,
  markCollectTaskSummaryListDirty,
} = createNormalizedListActions<
  CollectTaskSummary,
  'CollectTaskSummary',
  CollectTaskFilterPredicate,
  CollectTaskSortByAPIModel
>(NAMESPACE, 'CollectTaskSummary');

export const { storeCollectableTransaction, storeMultipleCollectableTransaction, markCollectableTransactionDirty } =
  createSingleActions<CollectableEntityTransaction, 'CollectableTransaction'>(NAMESPACE, 'CollectableTransaction');

const collectTaskFetchLimit = pLimit(1);
export const fetchCollectTask = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectTask`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    collectTaskFetchLimit(async () => {
      const saved = makeSelectCollectTask(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTask, 'unable to fetch collect task')(id, { signal });
      dispatch(storeCollectTask({ id, data }));
      return makeSelectCollectTask(id)(getState());
    }),
  { idGenerator: extractCollectTaskId },
);

const collectTaskProcessTransactionFetchLimit = pLimit(1);
export const fetchCollectTaskProcessTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectableTransaction`,
  async ({ force, taskId }: { force?: boolean; taskId: string }, { dispatch, getState, signal }) =>
    collectTaskProcessTransactionFetchLimit(async () => {
      const saved = makeSelectCollectEntityProcessTransaction(taskId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTaskProcessTransaction, 'unable to fetch process transaction')(
        taskId,
        { signal },
      );
      dispatch(storeCollectEntityProcessTransaction({ id: taskId, data }));

      return makeSelectCollectEntityProcessTransaction(taskId)(getState());
    }),
);

const collectTaskSummariesFetchLimit = pLimit(1);
export const fetchCollectTaskSummaries = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectTaskSummaries`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectTaskSummariesFetchLimit(async () => {
      const saved = makeSelectCollectTaskSummaryListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTasks, 'unable to fetch collect tasks')(
        listStateToSliceRequest({ data: saved, ...makeSelectCollectTaskSummaryListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storeCollectTaskSummaryListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectCollectTaskSummaryListData()(getState());
    }),
);

const multipleCollectTaskSummaryFetchLimit = pLimit(1);
export const fetchMultipleCollectTaskSummary = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleCollectTaskSummary`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleCollectTaskSummaryFetchLimit(async () => {
      const absent = makeSelectDirtyCollectTaskSummaryIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleCollectTaskSummary(ids)(getState());
      }

      const data = await withAPICall(queryCollectTasks, 'unable to fetch collect tasks')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleCollectTaskSummary(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractCollectTaskId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleCollectTaskSummary(ids)(getState());
    }),
);

export const {
  storeCollectTasksForAddressesListData,
  storeCollectTasksForAddressesListParameters,
  markCollectTasksForAddressesListDirty,
} = createNestedListActions<
  CollectTaskSummary,
  'CollectTasksForAddresses',
  CollectTaskFilterPredicate,
  CollectTaskSortByAPIModel,
  CollectableAddressLinkAPIModel[]
>(NAMESPACE, 'CollectTasksForAddresses');

const settlementsPerAssetFetchLimit = pLimit(1);
export const fetchCollectTasksForAddresses = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectTasksForAddresses`,
  async (
    { force, addresses }: { force?: boolean; addresses: CollectableAddressLinkAPIModel[] },
    { dispatch, getState, signal },
  ) =>
    settlementsPerAssetFetchLimit(async () => {
      const saved = makeSelectCollectTasksForAddressesListData(addresses)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTasks, 'unable to fetch tasks')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectCollectTasksForAddressesListParameters(addresses)(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(
        storeCollectTasksForAddressesListData({
          parentId: addresses,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleCollectTaskSummary(sliceToMultipleEntities(data.data, extractCollectTaskId)));
      }

      return makeSelectCollectTasksForAddressesListData(addresses)(getState());
    }),
  { idGenerator: ({ addresses }) => collectableTaskLinksToId(addresses) },
);

export const { storeCollectSchedule, markCollectScheduleDirty } = createLoadingDataActions<
  CollectSchedule,
  'CollectSchedule'
>(NAMESPACE, 'CollectSchedule');

const collectableScheduleFetchLimit = pLimit(1);
export const fetchCollectSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectSchedule`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectableScheduleFetchLimit(async () => {
      const saved = makeSelectCollectSchedule()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectSchedule, 'unable to fetch collect schedule')({ signal });
      dispatch(storeCollectSchedule(data));

      return makeSelectCollectSchedule()(getState());
    }),
);

export const updateCollectSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectSchedule`,
  async (newSchedule: CollectScheduleUpdate | undefined, { dispatch, signal }) => {
    const data = await (newSchedule
      ? withAPICall(requestUpdateCollectSchedule, 'unable to update the collectable schedule')(newSchedule, {
          signal,
        })
      : withAPICall(requestDeleteCollectSchedule, 'unable to remove the collectable schedule')({ signal }));

    const schedule = data.data;
    if (schedule) {
      dispatch(storeCollectSchedule(loadingDataLoaded(schedule)));
      return loadingDataLoaded(schedule);
    } else {
      return dispatch(fetchCollectSchedule({ force: true })).unwrap();
    }
  },
);

export const collectNow = createAppAsyncThunk(
  `${NAMESPACE}/collectNow`,
  async ({ asset }: { asset: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestCollectNow, 'unable to update trigger the collectable')(asset, { signal });

    if (!data.error) {
      dispatch(markCollectTaskSummaryListDirty());
      dispatch(markBalancesDirty());
    }

    return data;
  },
);

export const { storeCollectThreshold, markCollectThresholdDirty } = createLoadingDataActions<
  CollectThreshold[],
  'CollectThreshold'
>(NAMESPACE, 'CollectThreshold');

const collectThresholdFetchLimit = pLimit(1);
export const fetchCollectThreshold = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectThreshold`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectThresholdFetchLimit(async () => {
      const saved = makeSelectCollectThreshold()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectThreshold, 'unable to fetch collect threshold')({ signal });
      dispatch(storeCollectThreshold(data));

      return makeSelectCollectThreshold()(getState());
    }),
);

export const {
  storeCollectAvailableBalance,
  storeCollectAvailableBalanceListData,
  storeMultipleCollectAvailableBalance,
  storeCollectAvailableBalanceListParameters,
  markCollectAvailableBalanceDirty,
  markCollectAvailableBalanceListDirty,
} = createNormalizedListActions<
  CollectableBalance,
  'CollectAvailableBalance',
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel,
  CollectableEntityTypedId
>(NAMESPACE, 'CollectAvailableBalance');

export const { storeCollectableBalance, markCollectableBalanceDirty } = createSingleActions<
  CollectableBalance,
  'CollectableBalance',
  CollectableEntityTypedId
>(NAMESPACE, 'CollectableBalance');

const collectableBalanceFetchLimit = pLimit(1);
export const fetchCollectableBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectableBalance`,
  async ({ id, force }: { id: CollectableEntityTypedId; force?: boolean }, { dispatch, getState, signal }) =>
    collectableBalanceFetchLimit(async () => {
      const saved = makeSelectCollectableBalance(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectableBalance, 'unable to fetch collectable balance')(id, { signal });
      dispatch(storeCollectableBalance({ id, data }));

      return makeSelectCollectableBalance(id)(getState());
    }),
);

const collectAvailableBalancesFetchLimit = pLimit(1);
export const fetchCollectAvailableBalances = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectAvailableBalances`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectAvailableBalancesFetchLimit(async () => {
      const saved = makeSelectCollectAvailableBalanceListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectableBalances, 'unable to fetch collectable balances')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectCollectAvailableBalanceListParameters()(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(storeCollectAvailableBalanceListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectCollectAvailableBalanceListData()(getState());
    }),
);

export const {
  storeCollectLockedBalance,
  storeCollectLockedBalanceListData,
  storeMultipleCollectLockedBalance,
  storeCollectLockedBalanceListParameters,
  markCollectLockedBalanceDirty,
  markCollectLockedBalanceListDirty,
} = createNormalizedListActions<
  CollectableBalance,
  'CollectLockedBalance',
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel,
  CollectableEntityTypedId
>(NAMESPACE, 'CollectLockedBalance');

const collectLockedBalancesFetchLimit = pLimit(1);
export const fetchCollectLockedBalances = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectLockedBalances`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectLockedBalancesFetchLimit(async () => {
      const saved = makeSelectCollectLockedBalanceListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectableBalances, 'unable to fetch collectable balances')(
        listStateToSliceRequest({ data: saved, ...makeSelectCollectLockedBalanceListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storeCollectLockedBalanceListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectCollectLockedBalanceListData()(getState());
    }),
);
