/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { LongRunningTaskStatusAPIModel } from './LongRunningTaskStatusAPIModel';
import {
    LongRunningTaskStatusAPIModelFromJSON,
    LongRunningTaskStatusAPIModelFromJSONTyped,
    LongRunningTaskStatusAPIModelToJSON,
} from './LongRunningTaskStatusAPIModel';
import type { SubscriptionDiscountAPIModel } from './SubscriptionDiscountAPIModel';
import {
    SubscriptionDiscountAPIModelFromJSON,
    SubscriptionDiscountAPIModelFromJSONTyped,
    SubscriptionDiscountAPIModelToJSON,
} from './SubscriptionDiscountAPIModel';
import type { SubscriptionStatusAPIModel } from './SubscriptionStatusAPIModel';
import {
    SubscriptionStatusAPIModelFromJSON,
    SubscriptionStatusAPIModelFromJSONTyped,
    SubscriptionStatusAPIModelToJSON,
} from './SubscriptionStatusAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionSummaryAPIModel
 */
export interface SubscriptionSummaryAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryAPIModel
     */
    contractAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryAPIModel
     */
    planId: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SubscriptionSummaryAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryAPIModel
     */
    customerId: string;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionSummaryAPIModel
     */
    nextChargeAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryAPIModel
     */
    payer: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryAPIModel
     */
    metadata?: string;
    /**
     * 
     * @type {SubscriptionStatusAPIModel}
     * @memberof SubscriptionSummaryAPIModel
     */
    status: SubscriptionStatusAPIModel;
    /**
     * 
     * @type {Array<LongRunningTaskStatusAPIModel>}
     * @memberof SubscriptionSummaryAPIModel
     */
    pendingStatuses?: Array<LongRunningTaskStatusAPIModel>;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionSummaryAPIModel
     */
    startFrom: Date;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSummaryAPIModel
     */
    maxPeriods: number;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionSummaryAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryAPIModel
     */
    allowance: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSummaryAPIModel
     */
    trialPeriodSeconds?: number;
    /**
     * 
     * @type {Array<SubscriptionDiscountAPIModel>}
     * @memberof SubscriptionSummaryAPIModel
     */
    discounts?: Array<SubscriptionDiscountAPIModel>;
}

/**
 * Check if a given object implements the SubscriptionSummaryAPIModel interface.
 */
export function instanceOfSubscriptionSummaryAPIModel(value: object): boolean {
    if (!('contractAddress' in value)) return false;
    if (!('planId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('customerId' in value)) return false;
    if (!('nextChargeAt' in value)) return false;
    if (!('payer' in value)) return false;
    if (!('status' in value)) return false;
    if (!('startFrom' in value)) return false;
    if (!('maxPeriods' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('allowance' in value)) return false;
    return true;
}

export function SubscriptionSummaryAPIModelFromJSON(json: any): SubscriptionSummaryAPIModel {
    return SubscriptionSummaryAPIModelFromJSONTyped(json, false);
}

export function SubscriptionSummaryAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionSummaryAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'contractAddress': json['contractAddress'],
        'planId': json['planId'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'customerId': json['customerId'],
        'nextChargeAt': (new Date(json['nextChargeAt'])),
        'payer': json['payer'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'status': SubscriptionStatusAPIModelFromJSON(json['status']),
        'pendingStatuses': json['pendingStatuses'] == null ? undefined : ((json['pendingStatuses'] as Array<any>).map(LongRunningTaskStatusAPIModelFromJSON)),
        'startFrom': (new Date(json['startFrom'])),
        'maxPeriods': json['maxPeriods'],
        'createdAt': (new Date(json['createdAt'])),
        'allowance': json['allowance'],
        'trialPeriodSeconds': json['trialPeriodSeconds'] == null ? undefined : json['trialPeriodSeconds'],
        'discounts': json['discounts'] == null ? undefined : ((json['discounts'] as Array<any>).map(SubscriptionDiscountAPIModelFromJSON)),
    };
}

export function SubscriptionSummaryAPIModelToJSON(value?: SubscriptionSummaryAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractAddress': value['contractAddress'],
        'planId': value['planId'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'customerId': value['customerId'],
        'nextChargeAt': ((value['nextChargeAt']).toISOString()),
        'payer': value['payer'],
        'metadata': value['metadata'],
        'status': SubscriptionStatusAPIModelToJSON(value['status']),
        'pendingStatuses': value['pendingStatuses'] == null ? undefined : ((value['pendingStatuses'] as Array<any>).map(LongRunningTaskStatusAPIModelToJSON)),
        'startFrom': ((value['startFrom']).toISOString()),
        'maxPeriods': value['maxPeriods'],
        'createdAt': ((value['createdAt']).toISOString()),
        'allowance': value['allowance'],
        'trialPeriodSeconds': value['trialPeriodSeconds'],
        'discounts': value['discounts'] == null ? undefined : ((value['discounts'] as Array<any>).map(SubscriptionDiscountAPIModelToJSON)),
    };
}

