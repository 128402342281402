import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Form, List, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { MoneyAmountInputValue } from '@/components';
import { FormattedMessage, FormCompletenessItem, Operation } from '@/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { asType, nameof } from '@/infrastructure/utils/ts';

import DonationAssetAmountsItem from '../DonationAssetAmountsItem';
import DonationAssetItem from '../DonationAssetItem';

import type { DonationAssetEditFormItemInputProps } from './types';

export interface DonationAssetFormData {
  asset: string;
  amounts: MoneyAmountInputValue[];
}

const DonationAssetEditFormItemInput: React.FC<DonationAssetEditFormItemInputProps> = ({
  'data-test': dataTest,
  name: baseName,
  fullName,
  value,
  onChange,
  onFinish,
  onCancel,
  selectedAssets,
}) => {
  const [isChildFormComplete, setChildFormComplete] = useStateMountSafe(false);
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const baseNameAsArray = useMemo(() => (Array.isArray(baseName) ? baseName : [baseName]), [baseName]);
  const fullNameAsArray = useMemo(() => (Array.isArray(fullName) ? fullName : [fullName]), [fullName]);
  const [tempDataName] = useStateMountSafe(() => `assets-temp-${uuid()}`);
  const requiredFields = useMemo(
    () => [[...fullNameAsArray, tempDataName, nameof<DonationAssetFormData>('asset')]],
    [fullNameAsArray, tempDataName],
  );
  const fieldsToValidate = useMemo(
    () => [[...fullNameAsArray, tempDataName, nameof<DonationAssetFormData>('amounts')]],
    [fullNameAsArray, tempDataName],
  );
  const form = Form.useFormInstance();
  const doSave = useCallback(() => {
    const val: DonationAssetFormData = form.getFieldValue([...fullNameAsArray, tempDataName]);
    onChange?.({
      asset: val.asset,
      defaultAmounts: val.amounts
        .map(({ value }) => ({
          asset: val.asset,
          value: value!,
        }))
        .sort((v1, v2) => v1.value.comparedTo(v2.value)),
      isActive: value.isActive,
      id: value.id,
    });
    onFinish?.();
  }, [form, fullNameAsArray, onChange, onFinish, tempDataName, value.id, value.isActive]);
  return (
    <>
      <List.Item
        className={css`
          align-items: start !important;
        `}
        actions={[
          <Space key="actions">
            <Operation
              key="submit"
              data-test={dataTest && `${dataTest}-opSubmit`}
              title={
                <FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_CONFIRM_TITLE} />
              }
              icon={<CheckOutlined style={{ color: 'green' }} />}
              disabled={!isFormComplete || !isChildFormComplete}
              onClick={doSave}
            />
            <Operation
              key="cancel"
              data-test={dataTest && `${dataTest}-opCancel`}
              title={
                <FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_CANCEL_TITLE} />
              }
              icon={<StopOutlined style={{ color: 'red' }} />}
              onClick={onCancel}
            />
          </Space>,
        ]}
      >
        <List.Item.Meta
          title={
            <DonationAssetItem
              data-test={dataTest && `${dataTest}-asset`}
              name={[...baseNameAsArray, tempDataName, nameof<DonationAssetFormData>('asset')]}
              readonly={!!value.id}
              selectedAssets={selectedAssets}
              initialValue={asType<DonationAssetFormData['asset']>(value.asset)}
            />
          }
          description={
            <DonationAssetAmountsItem
              data-test={dataTest && `${dataTest}-amounts`}
              name={[...baseNameAsArray, tempDataName, nameof<DonationAssetFormData>('amounts')]}
              fullName={[...fullNameAsArray, tempDataName, nameof<DonationAssetFormData>('amounts')]}
              asset={value.asset}
              initialValues={value.defaultAmounts}
              onStateChange={setChildFormComplete}
            />
          }
        />
      </List.Item>
      <FormCompletenessItem requiredFields={requiredFields} fields={fieldsToValidate} onChange={setFormComplete} />
    </>
  );
};

const DonationAssetEditFormItemInputMemo = React.memo(
  DonationAssetEditFormItemInput,
) as typeof DonationAssetEditFormItemInput;

export default DonationAssetEditFormItemInputMemo;
