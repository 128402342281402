import { CreditCardOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { QRPaymentIcon } from '@/features/company-settings/components';
import { PaymentTransactionKind } from '@/features/payment-transactions/types';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeaturePaymentTransactions } from '@/generated/i18n/i18n';

import type { PaymentTransactionKindViewProps } from './types';

const icons: Record<PaymentTransactionKind, React.ReactNode> = {
  [PaymentTransactionKind.DIRECT]: <CreditCardOutlined />,
  [PaymentTransactionKind.FORWARDER]: <QRPaymentIcon />,
};

const i18n: Record<PaymentTransactionKind, I18nMessages> = {
  [PaymentTransactionKind.DIRECT]: I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_KIND_DIRECT,
  [PaymentTransactionKind.FORWARDER]: I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTION_KIND_FORWARDER,
};

const PaymentTransactionKindView: React.FC<PaymentTransactionKindViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const PaymentTransactionKindViewMemo = React.memo(PaymentTransactionKindView);

export default PaymentTransactionKindViewMemo;
