import { Alert } from 'antd';
import dayjs from 'dayjs';
import noop from 'lodash-es/noop';
import React, { useEffect } from 'react';

import { FormattedMessage } from '@/components';
import { withSubscriptionDataLoading, withSubscriptionStatusGuard } from '@/features/subscriptions/hocs';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useLocaleSettings, useStateMountSafe } from '@/hooks';

import type { SubscriptionDeployAlertProps } from './types';

const SubscriptionDeployAlertRaw: React.FC<SubscriptionDeployAlertProps> = ({ 'data-test': dataTest, data }) => {
  const { formatDateTime } = useLocaleSettings();
  const [isPassed, setPassed] = useStateMountSafe(() => dayjs(data.startFrom).isBefore());
  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | undefined;
    if (!isPassed) {
      const msToStart = dayjs(data.startFrom).diff(Date.now(), 'ms');
      timerId = setTimeout(() => {
        setPassed(true);
      }, msToStart);
    }
    return timerId ? () => clearTimeout(timerId) : noop;
  }, [data.startFrom, isPassed, setPassed]);
  return (
    <Alert
      message={
        isPassed ? (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_DEPLOY_ALERT_TITLE_AFTER_START} />
        ) : (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_DEPLOY_ALERT_TITLE_BEFORE_START}
            values={{ date: formatDateTime(data.startFrom) }}
          />
        )
      }
      data-test={dataTest}
      type="info"
      showIcon
    />
  );
};

const SubscriptionDeployAlertLoaded = withSubscriptionDataLoading(
  withSubscriptionStatusGuard([SubscriptionStatusAPIModel.Pending])(SubscriptionDeployAlertRaw),
  true,
  true,
);

const SubscriptionDeployAlert = React.memo(SubscriptionDeployAlertLoaded) as typeof SubscriptionDeployAlertLoaded;

export default SubscriptionDeployAlert;
