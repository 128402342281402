import { useCallback, useMemo } from 'react';

import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectThreshold } from '@/features/collectable/actions';
import { makeSelectCollectThreshold } from '@/features/collectable/selectors';
import type { CollectThreshold } from '@/features/collectable/types';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<CollectThreshold[]>, Parameters<typeof fetchCollectThreshold>[0]> =>
  fetchCollectThreshold({ force });
const selectFetching = makeSelectPending(fetchCollectThreshold);
const selectData = makeSelectCollectThreshold();

export default function useCollectThreshold(network?: BlockchainNetworkTypeAPIModel) {
  const delegatesState = useAppLoadableData(fetch, selectData, selectFetching);

  const assetsState = useListAssets(network);
  const networkAssets = useMemo(
    () =>
      ((network ? assetsState.data.data?.filter(({ net }) => network === net) : assetsState.data.data) ?? []).map(
        ({ code }) => code,
      ),
    [assetsState.data.data, network],
  );
  const forceRefresh = useCallback(
    async () =>
      mapStoredState(await delegatesState.forceRefresh(), (delegates) =>
        network ? delegates.filter(({ asset }) => networkAssets.includes(asset)) : delegates,
      ),
    [network, networkAssets, delegatesState],
  );

  const data = useMemo(
    () =>
      mapStoredState(delegatesState.data, (delegates) =>
        network ? delegates.filter(({ asset }) => networkAssets.includes(asset)) : delegates,
      ),
    [network, networkAssets, delegatesState.data],
  );

  return { forceRefresh, data, loading: delegatesState.loading };
}
