import React from 'react';

import { MerchantWalletsList } from '@/features/merchant-wallets/components';

import { withMerchantWalletsActualDataLoading } from './hocs';

const MerchantWalletsActualCard = withMerchantWalletsActualDataLoading(MerchantWalletsList);

const MerchantWalletsActualCardMemo: typeof MerchantWalletsActualCard = React.memo(
  MerchantWalletsActualCard,
) as typeof MerchantWalletsActualCard;

export default MerchantWalletsActualCardMemo;
