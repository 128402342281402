import { BankOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, Operation } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useCollectAssetActions } from '@/features/collectable/hooks';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { MerchantWalletIssueBanner } from '@/features/merchant-wallets/components';
import OperationRefreshBalances from '@/features/statistics/components/OperationRefreshBalances';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationCollectNowProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const OperationCollectNow: React.FC<OperationCollectNowProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  assetId,
}) => {
  const login = useAppSelector(selectAuthToken);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { collectNow } = useCollectAssetActions(assetId);

  const {
    data: { data: asset },
  } = useAsset(assetId);
  const bt = asset?.blockchain;

  const disabledMessage = useMemo(() => {
    switch (collectNow.unavailabilityReason) {
      case 'empty-balance':
        return (
          <FormattedMessage
            id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_EMPTY_BALANCE}
            values={{
              ln: (text: React.ReactNode) => (
                <OperationRefreshBalances
                  data-test={dataTest && `${dataTest}-disable-opRefresh`}
                  mode="link"
                  title={text}
                />
              ),
            }}
          />
        );
      case 'no-data':
        return <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_LOADING} />;
      case 'no-deployed-wallet':
        return bt ? <MerchantWalletIssueBanner bt={bt} mode="alert" type="link" /> : undefined;
      case undefined:
        return undefined;
    }

    return undefined;
  }, [collectNow.unavailabilityReason, bt, dataTest]);

  const tooltip = useMemo(
    () => (
      <FormattedMessage
        id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_TOOLTIP}
        values={{
          asset: <AssetLabel data-test={dataTest && `${dataTest}-tooltip-asset`} value={assetId} mode="short" />,
          address: login.data?.info.address,
        }}
      />
    ),
    [assetId, dataTest, login.data?.info.address],
  );

  const doCollect = useMemo(
    () =>
      withSuppressError(
        withSuccess(withDefaultError(collectNow.act), () => ({
          message: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_SUCCESS_MESSAGE} />,
        })),
      ),
    [withDefaultError, withSuccess, collectNow.act],
  );

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_TITLE} />}
      tooltip={tooltip}
      disabled={!collectNow.available || !!disabledMessage}
      disabledMessage={disabledMessage}
      icon={<BankOutlined />}
      onClick={doCollect}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationCollectNowMemo = React.memo(withCompanyPermissionsGuard('collectManagement')(OperationCollectNow));

export default OperationCollectNowMemo;
