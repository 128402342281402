import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { storeCollectEntityProcessTransaction } from '@/features/collectable/actions';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import { extractEntityTransactionId } from '@/features/collectable/utils';
import { donationAssetIdToKey, extractDonationId } from '@/features/donations/utils';
import type { PushAddressSortByAPIModel, PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapLoadingState, storedDataLoaded, withApiRequest } from '@/infrastructure/model';
import { amountToAPI, defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { stringFormat } from '@/infrastructure/utils/string';

import {
  queryDonation,
  queryDonationDeployTransaction,
  queryDonations,
  queryDonationTransaction,
  queryDonationTransactions,
  requestCreateDonation,
  requestUpdateDonation,
  requestUpdateDonationImage,
  requestUpdateDonationStatus,
} from './api';
import {
  makeSelectDonation,
  makeSelectDonationsListParameters,
  makeSelectDonationListData,
  makeSelectDonationTransactionForDonationListData,
  makeSelectDonationTransactionForDonationListParameters,
  makeSelectDonationTransactionListData,
  makeSelectDonationTransactionListParameters,
  makeSelectDonationTransaction,
  makeSelectDonationIdByAddress,
} from './selectors';
import { NAMESPACE } from './types';

import type {
  Donation,
  DonationFilterPredicate,
  DonationTransactionFilterPredicate,
  NewDonation,
  DonationTransaction,
  UpdateDonation,
} from './types';

export const {
  storeDonationTransactionListData,
  storeDonationTransactionListParameters,
  markDonationTransactionListDirty,
  markDonationTransactionDirty,
  storeDonationTransaction,
  storeMultipleDonationTransaction,
} = createNormalizedListActions<
  DonationTransaction,
  'DonationTransaction',
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>(NAMESPACE, 'DonationTransaction');

const donationTransactionFetchLimit = pLimit(1);
export const fetchDonationTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonationTransaction`,
  async ({ force, txId }: { force?: boolean; txId: string }, { dispatch, getState, signal }) =>
    donationTransactionFetchLimit(async () => {
      const saved = makeSelectDonationTransaction(txId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonationTransaction, 'unable to fetch transaction')(txId, { signal });
      dispatch(storeDonationTransaction({ id: txId, data }));

      return makeSelectDonationTransaction(txId)(getState());
    }),
  { idGenerator: ({ txId }) => txId },
);

const donationTransactionsFetchLimit = pLimit(1);
export const fetchDonationTransactions = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonationTransactions`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    donationTransactionsFetchLimit(async () => {
      const saved = makeSelectDonationTransactionListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonationTransactions, 'unable to fetch transactions')(
        listStateToSliceRequest({ data: saved, ...makeSelectDonationTransactionListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storeDonationTransactionListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));
      if (data.data) {
        dispatch(storeMultipleDonationTransaction(sliceToMultipleEntities(data.data, extractEntityTransactionId)));
      }

      return makeSelectDonationTransactionListData()(getState());
    }),
);

export const {
  storeDonationTransactionForDonationListData,
  storeDonationTransactionForDonationListParameters,
  markDonationTransactionForDonationListDirty,
} = createNestedListActions<
  DonationTransaction,
  'DonationTransactionForDonation',
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>(NAMESPACE, 'DonationTransactionForDonation');

const donationTransactionForDonationFetchLimit = pLimit(1);
export const fetchDonationTransactionForDonation = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonationTransactionForDonation`,
  async ({ force, donationId }: { force?: boolean; donationId: string }, { dispatch, getState, signal }) =>
    donationTransactionForDonationFetchLimit(async () => {
      const saved = makeSelectDonationTransactionForDonationListData(donationId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonationTransactions, 'unable to fetch transactions')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectDonationTransactionForDonationListParameters(donationId)(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(
        storeDonationTransactionForDonationListData({
          parentId: donationId,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleDonationTransaction(sliceToMultipleEntities(data.data, extractEntityTransactionId)));
      }

      return makeSelectDonationTransactionForDonationListData(donationId)(getState());
    }),
  { idGenerator: ({ donationId }) => donationId },
);

export const {
  storeDonation,
  storeMultipleDonation,
  storeDonationListData,
  storeDonationListParameters,
  markDonationDirty,
  markDonationListDirty,
} = createNormalizedListActions<Donation, 'Donation', DonationFilterPredicate, PushAddressSortByAPIModel>(
  NAMESPACE,
  'Donation',
);

const donationFetchLimit = pLimit(1);
export const fetchDonation = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonation`,
  async ({ force, donationId }: { force?: boolean; donationId: string }, { dispatch, getState, signal }) =>
    donationFetchLimit(async () => {
      const saved = makeSelectDonation(donationId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonation, 'unable to fetch donation')(donationId, { signal });
      dispatch(storeDonation({ id: donationId, data }));

      return makeSelectDonation(donationId)(getState());
    }),
  { idGenerator: ({ donationId }) => donationId },
);

const donationsFetchLimit = pLimit(1);
export const fetchDonations = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonations`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    donationsFetchLimit(async () => {
      const saved = makeSelectDonationListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonations, 'unable to fetch donations')(
        listStateToSliceRequest({ data: saved, ...makeSelectDonationsListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storeDonationListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));
      if (data.data) {
        dispatch(storeMultipleDonation(sliceToMultipleEntities(data.data, extractDonationId)));
      }

      return makeSelectDonationListData()(getState());
    }),
);

export const createDonation = createAppAsyncThunk(
  `${NAMESPACE}/createDonation`,
  async ({ defaultAmounts, ...donation }: NewDonation, { dispatch, signal }) => {
    const data = await withApiRequest(requestCreateDonation)(
      { ...donation, defaultAmounts: defaultAmounts?.map(amountToAPI) },
      { signal },
    );
    if (data.data) {
      dispatch(storeDonation({ id: data.data.id, data }));
      dispatch(markDonationListDirty());
    }
    return data;
  },
);

export const updateDonation = createAppAsyncThunk(
  `${NAMESPACE}/updateDonation`,
  async ({ id, defaultAmounts, ...donation }: UpdateDonation, { dispatch, signal }) => {
    const data = await withApiRequest(requestUpdateDonation)(
      id,
      { ...donation, defaultAmounts: defaultAmounts?.map(amountToAPI) },
      { signal },
    );
    if (data.data) {
      dispatch(storeDonation({ id: data.data.id, data }));
      dispatch(markDonationListDirty());
    }
    return data;
  },
);

export const updateDonationStatus = createAppAsyncThunk(
  `${NAMESPACE}/updateDonationStatus`,
  async ({ id, isActive }: { id: string; isActive: boolean }, { dispatch, signal }) => {
    const data = await withApiRequest(requestUpdateDonationStatus)(id, isActive, { signal });
    if (data.data) {
      dispatch(storeDonation({ id: data.data.id, data }));
      dispatch(markDonationListDirty());
    }
    return data;
  },
);

export const updateDonationImage = createAppAsyncThunk(
  `${NAMESPACE}/updateDonationImage`,
  async ({ id, imageKey }: { id: string; imageKey: string }, { dispatch, signal }) => {
    const data = await withApiRequest(requestUpdateDonationImage)(id, imageKey, { signal });
    if (data.data) {
      dispatch(storeDonation({ id: data.data.id, data }));
      dispatch(markDonationListDirty());
    }
    return data;
  },
);

const donationDeployTransactionFetchLimit = pLimit(1);
export const fetchDonationDeployTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonationDeployTransaction`,
  async (
    { force, donationId, asset }: { force?: boolean; donationId: string; asset: string },
    { dispatch, getState, signal },
  ) =>
    donationDeployTransactionFetchLimit(async () => {
      const key = donationAssetIdToKey({ donationId, asset });
      const saved = makeSelectCollectEntityProcessTransaction(key)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonationDeployTransaction, 'unable to fetch deploy transaction')(
        donationId,
        asset,
        { signal },
      );
      dispatch(storeCollectEntityProcessTransaction({ id: key, data }));

      return makeSelectCollectEntityProcessTransaction(key)(getState());
    }),
  { idGenerator: donationAssetIdToKey },
);

export const generateDonationURL = createAppAsyncThunk(`${NAMESPACE}/generateDonationURL`, (donationId: string) =>
  storedDataLoaded(stringFormat(window.env.NCPS_DONATIONS_WIDGET_URL_TEMPLATE, { donationId })),
);

export const { storeDonationIdByAddress, markDonationIdByAddressDirty } = createSingleActions<string, string>(
  NAMESPACE,
  'DonationIdByAddress',
);

const donationIdByAddressFetchLimit = pLimit(1);
export const fetchDonationIdByAddress = createAppAsyncThunk(
  `${NAMESPACE}/fetchDonationIdByAddress`,
  async ({ force, addressId }: { force?: boolean; addressId: string }, { dispatch, getState, signal }) =>
    donationIdByAddressFetchLimit(async () => {
      const saved = makeSelectDonationIdByAddress(addressId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryDonations, 'unable to fetch deploy transaction')(
        { page: defaultPageFn({ perPage: 1 }), filter: { addressIdIn: [addressId] } },
        { signal },
      );
      dispatch(storeDonationIdByAddress({ id: addressId, data: mapLoadingState(data, ({ list }) => list[0].id) }));
      if (data.data?.list[0]) {
        dispatch(storeDonation({ id: data.data.list[0].id, data: storedDataLoaded(data.data.list[0]) }));
      }

      return makeSelectDonationIdByAddress(addressId)(getState());
    }),
  { idGenerator: ({ addressId }) => addressId },
);
