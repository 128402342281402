import { List, Space, Switch, Tag, Tooltip } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AddressLink, AssetAmount, AssetIcon, AssetLabel } from '@/features/dictionary/blockchain/components';
import { donationAssetStatusIcons } from '@/features/donations/components/DonationAssetStatusView';
import { notificationOptions } from '@/features/donations/components/OperationUpdateDonationAssetStatus';
import { useDonationAssetActions } from '@/features/donations/hooks';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { DonationAssetItemProps } from './types';

const DonationAssetItem: React.FC<DonationAssetItemProps> = ({
  'data-test': dataTest,
  data: { address, asset, isActive, id, defaultAmounts, balance },
  donationId,
  DonationAssetLink,
}) => {
  const { withNotification } = useNotification();
  const { activateAsset: activateAssetAction, deactivateAsset: deactivateAssetAction } = useDonationAssetActions(
    donationId,
    asset,
  );
  const action = isActive ? deactivateAssetAction : activateAssetAction;

  const doUpdateStatus = useMemo(
    () => withSuppressError(withNotification(action.act, notificationOptions)),
    [withNotification, action.act],
  );

  return (
    <List.Item
      key={id}
      actions={[
        <Space key="actions">
          {DonationAssetLink && (
            <DonationAssetLink data-test={dataTest && `${dataTest}-link`} value={{ donationId, asset }} />
          )}
          <Tooltip
            title={
              <FormattedMessage
                id={
                  isActive
                    ? I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_DEACTIVATE_TITLE
                    : I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ACTIVATE_TITLE
                }
              />
            }
          >
            <Switch
              data-test={dataTest && `${dataTest}-updateOp`}
              value={isActive}
              onChange={doUpdateStatus}
              checkedChildren={donationAssetStatusIcons.true}
              unCheckedChildren={donationAssetStatusIcons.false}
              disabled={!action.available || action.inAction}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <List.Item.Meta
        key={`${address}-meta`}
        style={{ alignItems: 'center' }}
        avatar={<AssetIcon style={{ fontSize: 32 }} type={asset} data-test={dataTest && `${dataTest}-icon`} />}
        title={
          <Space>
            <AssetLabel mode="short" value={asset} data-test={dataTest && `${dataTest}-asset`} withBlockchainMark />
            {defaultAmounts.map((amount, index) => (
              <Tag key={index}>
                <AssetAmount
                  data-test={dataTest && `${dataTest}-amount-${amount.asset}`}
                  value={amount.value}
                  assetId={amount.asset}
                  withCurrency
                />
              </Tag>
            ))}
          </Space>
        }
        description={
          <Space>
            <AddressLink assetId={asset} address={address} data-test={dataTest && `${dataTest}-address`} />
          </Space>
        }
      />
      <Tag color="default">
        <AssetAmount
          data-test={dataTest && `${dataTest}-balance`}
          assetId={balance.asset}
          value={balance.value}
          withCurrency
          withBlockchainMark
        />
      </Tag>
    </List.Item>
  );
};

const DonationAssetItemMemo = React.memo(DonationAssetItem);

export default DonationAssetItemMemo;
