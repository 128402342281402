import type { CollectableAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { stringComparator } from '@/infrastructure/model/comparators';

import type {
  CollectableBalance,
  CollectableEntityTransaction,
  CollectableEntityTypedId,
  CollectTask,
  CollectTaskSummary,
} from './types';

export const extractCollectTaskId = ({ id }: Pick<CollectTask, 'id'> | Pick<CollectTaskSummary, 'id'>) => id;
export const extractEntityTransactionId = ({ id }: Pick<CollectableEntityTransaction, 'id'>) => id;
export const extractCollectableBalanceId = ({ id, kind }: Pick<CollectableBalance, 'id' | 'kind'>) => ({ id, kind });

export const createCollectableBalanceKey = ({ id, kind }: CollectableEntityTypedId) => `${kind}_${id}`;

const addressLinkComparator = stringComparator<CollectableAddressLinkAPIModel>(
  ({ address, asset }) => `${asset}${address}`,
);
export const collectableTaskLinksToId = (addresses: CollectableAddressLinkAPIModel[]) =>
  [...addresses]
    .sort(addressLinkComparator('DESC'))
    .map(({ address, asset }) => `${asset}${address}`)
    .join('_');
