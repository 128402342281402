import { Tooltip } from 'antd';
import React from 'react';

import KYBRequiredBanner from '../KYBRequiredBanner';

import type { KYBRequiredTooltipProps } from './types';

const KYBRequiredTooltip: React.FC<KYBRequiredTooltipProps> = ({
  'data-test': dataTest,
  style,
  className,
  mode = 'full',
  children,
}) => (
  <Tooltip
    title={
      <KYBRequiredBanner
        data-test={dataTest}
        mode={mode === 'full' ? 'full' : 'medium'}
        hideable={false}
        style={style}
        className={className}
      />
    }
    showArrow={false}
    placement="bottom"
    styles={{ body: { width: 600, padding: 0 } }}
  >
    <>{children}</>
  </Tooltip>
);

const KYBRequiredTooltipMemo = React.memo(KYBRequiredTooltip);

export default KYBRequiredTooltipMemo;
