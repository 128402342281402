import { Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { AssetsForm } from '@/features/dictionary/blockchain/components';
import type { AssetsInputRow } from '@/features/dictionary/blockchain/components';
import { GasWalletStateCheckBanner } from '@/features/gas-wallets/components';
import { MerchantWalletIssueBanner, OperationDeployMerchantWallet } from '@/features/merchant-wallets/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { CompanyActiveAssetsViewProps } from './types';

const CompanyActiveAssetsView: React.FC<CompanyActiveAssetsViewProps> = (props) => {
  const { 'data-test': dataTest } = props;

  return (
    <AssetsForm
      {...props}
      readonly
      labels={useCallback(
        (value: AssetsInputRow) =>
          value.blockchain
            ? [
                <GasWalletStateCheckBanner
                  key="presence"
                  data-test={dataTest && `${dataTest}-gasWalletPresence`}
                  type="presence"
                  bt={value.blockchain}
                  mode="icon"
                />,
                <GasWalletStateCheckBanner
                  key="all-service"
                  data-test={dataTest && `${dataTest}-gasWalletService`}
                  type="all-service"
                  bt={value.blockchain}
                  mode="icon"
                />,
                <MerchantWalletIssueBanner
                  key="wallet"
                  data-test={dataTest && `${dataTest}-merchantWallet`}
                  bt={value.blockchain}
                  mode="tag"
                  type="link"
                />,
              ]
            : [],
        [dataTest],
      )}
      actions={useCallback(
        (value: AssetsInputRow) =>
          value.blockchain
            ? [
                <Space key="actions">
                  <OperationDeployMerchantWallet
                    data-test={dataTest && `${dataTest}-opManageWallet`}
                    bt={value.blockchain}
                    mode="inline"
                  />
                </Space>,
              ]
            : [],
        [dataTest],
      )}
      description={
        <FormattedMessageWithMarkup id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_VIEW_DESCRIPTION} />
      }
    />
  );
};

const CompanyActiveAssetsViewMemo = React.memo(CompanyActiveAssetsView);

export default CompanyActiveAssetsViewMemo;
