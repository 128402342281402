import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { AddressLink, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { MerchantWalletIssueBanner } from '@/features/merchant-wallets/components';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import MerchantWalletActiveStatusView from '../MerchantWalletActiveStatusView';
import MerchantWalletStatusView from '../MerchantWalletStatusView';

import type { MerchantWalletViewProps } from './types';

const MerchantWalletView: React.FC<MerchantWalletViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<MerchantWalletActiveStatusView data-test={dataTest && `${dataTest}-active`} value={data.isFactoryActive} />}
    column={columns}
  >
    {data.issue && (
      <Descriptions.Item span={3}>
        <MerchantWalletIssueBanner
          bt={data.blockchain}
          address={data.address}
          data-test={dataTest && `${dataTest}-banner`}
          type="action"
          mode="alert"
        />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_WALLET_STATE} />}>
      <MerchantWalletStatusView data-test={dataTest && `${dataTest}-status`} value={data.status} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_WALLET_ADDRESS} />}>
      <AddressLink data-test={dataTest && `${dataTest}-address`} bt={data.blockchain} address={data.address} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_WALLET_BLOCKCHAIN} />}>
      <BlockchainLabel data-test={dataTest && `${dataTest}-blockchain`} bt={data.blockchain} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_WALLET_FACTORY} />}>
      <AddressLink
        data-test={dataTest && `${dataTest}-factoryAddress`}
        bt={data.blockchain}
        address={data.factoryAddress}
      />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_WALLET_CREATED_AT} />}>
      <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
    </Descriptions.Item>
  </Descriptions>
);
const MerchantWalletViewMemo = React.memo(MerchantWalletView);

export default MerchantWalletViewMemo;
