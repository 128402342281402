import { FormattedMessage, PageContainer } from '@/components';
import type { SettlementViewProps } from '@/features/settlements/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import PayoutBatchBySettlementViewLink from '@/pages/payouts/payout-batch-view/components/PayoutBatchBySettlementViewLink';

import {
  SettlementViewBreadcrumb,
  SettlementCard,
  SettlementReconciliationCard,
  SettlementReconciliationOutgoingTransfersCard,
  SettlementReconciliationIncomingTransfersCard,
} from './components';
import { withSettlementViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type SettlementPageProps = PropsWithChildren<TestableProps & { pageParams: { settlementId: string } }>;

const PayoutLink: SettlementViewProps['EmptyDestinationComponent'] = ({ value, ...props }) => (
  <PayoutBatchBySettlementViewLink
    {...props}
    value={value.id}
    mode="text"
    title={<FormattedMessage id={I18nPageSettlements.SETTLEMENT_VIEW_PAYOUT_LINK_TITLE} />}
  />
);

const SettlementPage: React.FC<SettlementPageProps> = ({
  'data-test': dataTest = 'settlement',
  pageParams: { settlementId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SettlementViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} settlementId={settlementId} disabled />
    }
  >
    <SettlementCard
      data-test={dataTest && `${dataTest}-card`}
      settlementId={settlementId}
      EmptyDestinationComponent={PayoutLink}
    />
    <SettlementReconciliationOutgoingTransfersCard
      data-test={dataTest && `${dataTest}-outgoing`}
      settlementId={settlementId}
    />
    <SettlementReconciliationCard data-test={dataTest && `${dataTest}-reconciliation`} settlementId={settlementId} />
    <SettlementReconciliationIncomingTransfersCard
      data-test={dataTest && `${dataTest}-incoming`}
      settlementId={settlementId}
    />
  </PageContainer>
);

const SettlementPageWithParam = withSettlementViewPageParams(SettlementPage);

export default SettlementPageWithParam;
