import { PageContainer } from '@/components';
import MerchantWalletLink from '@/pages/audit/wallet-view/components/MerchantWalletLink';

import {
  MerchantWalletActualListOperations,
  MerchantWalletsActualCard,
  MerchantWalletsBreadcrumb,
  MerchantWalletsHistoryCard,
} from './components';

import type React from 'react';

const dataTest: string | undefined = 'mw';

const MerchantWalletsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<MerchantWalletsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <MerchantWalletsActualCard
      data-test={dataTest && `${dataTest}-actual`}
      WalletLink={MerchantWalletLink}
      Operations={MerchantWalletActualListOperations}
    />
    <MerchantWalletsHistoryCard data-test={dataTest && `${dataTest}-history`} WalletLink={MerchantWalletLink} />
  </PageContainer>
);

export default MerchantWalletsPage;
