/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { ReportScheduleStatusAPIModel } from './ReportScheduleStatusAPIModel';
import {
    ReportScheduleStatusAPIModelFromJSON,
    ReportScheduleStatusAPIModelFromJSONTyped,
    ReportScheduleStatusAPIModelToJSON,
} from './ReportScheduleStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantReportSchedulePredicatesAPIModel
 */
export interface MerchantReportSchedulePredicatesAPIModel {
    /**
     * 
     * @type {Array<ReportScheduleStatusAPIModel>}
     * @memberof MerchantReportSchedulePredicatesAPIModel
     */
    statuses?: Array<ReportScheduleStatusAPIModel>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof MerchantReportSchedulePredicatesAPIModel
     */
    createdRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof MerchantReportSchedulePredicatesAPIModel
     */
    nextGeneratingRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantReportSchedulePredicatesAPIModel
     */
    templates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantReportSchedulePredicatesAPIModel
     */
    ids?: Array<string>;
}

/**
 * Check if a given object implements the MerchantReportSchedulePredicatesAPIModel interface.
 */
export function instanceOfMerchantReportSchedulePredicatesAPIModel(value: object): boolean {
    return true;
}

export function MerchantReportSchedulePredicatesAPIModelFromJSON(json: any): MerchantReportSchedulePredicatesAPIModel {
    return MerchantReportSchedulePredicatesAPIModelFromJSONTyped(json, false);
}

export function MerchantReportSchedulePredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantReportSchedulePredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'statuses': json['statuses'] == null ? undefined : ((json['statuses'] as Array<any>).map(ReportScheduleStatusAPIModelFromJSON)),
        'createdRange': json['createdRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdRange']),
        'nextGeneratingRange': json['nextGeneratingRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['nextGeneratingRange']),
        'templates': json['templates'] == null ? undefined : json['templates'],
        'ids': json['ids'] == null ? undefined : json['ids'],
    };
}

export function MerchantReportSchedulePredicatesAPIModelToJSON(value?: MerchantReportSchedulePredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statuses': value['statuses'] == null ? undefined : ((value['statuses'] as Array<any>).map(ReportScheduleStatusAPIModelToJSON)),
        'createdRange': DateTimeRangeAPIModelToJSON(value['createdRange']),
        'nextGeneratingRange': DateTimeRangeAPIModelToJSON(value['nextGeneratingRange']),
        'templates': value['templates'],
        'ids': value['ids'],
    };
}

