import { GoldOutlined, LockOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { CollectableAddressBalanceStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectableBalanceStatusViewProps } from './types';

const i18n: Record<CollectableAddressBalanceStatusAPIModel, I18nMessages> = {
  [CollectableAddressBalanceStatusAPIModel.Locked]:
    I18nFeatureCollectable.COMPONENTS_COLLECT_BALANCE_STATUS_VIEW_LOCKED,
  [CollectableAddressBalanceStatusAPIModel.Collectable]:
    I18nFeatureCollectable.COMPONENTS_COLLECT_BALANCE_STATUS_VIEW_COLLECTABLE,
};

const icons: Record<CollectableAddressBalanceStatusAPIModel, React.ReactNode> = {
  [CollectableAddressBalanceStatusAPIModel.Locked]: <LockOutlined />,
  [CollectableAddressBalanceStatusAPIModel.Collectable]: <GoldOutlined />,
};

const CollectableBalanceStatusView: React.FC<CollectableBalanceStatusViewProps> = ({
  value,
  isCollecting,
  'data-test': dataTest,
  mode = 'full',
}) =>
  value ? (
    <StatusViewElement
      data-test={dataTest}
      i18n={i18n[value]}
      icon={
        isCollecting ? (
          <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
            {icons[value]}
          </Badge>
        ) : (
          icons[value]
        )
      }
      mode={mode}
    />
  ) : null;

const CollectableBalanceStatusViewMemo = React.memo(CollectableBalanceStatusView);

export default CollectableBalanceStatusViewMemo;
