import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Space, Tooltip } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { FormattedMessage, Operation } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useFirstRenderEffect, usePrevious, useFormVisible } from '@/hooks';
import { noop, suppressPromise } from '@/infrastructure/utils/functions';
import { asType } from '@/infrastructure/utils/ts';

import { MetadataInput } from './components';

import type { MetadataInternalValue, MetadataItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Store } from 'rc-field-form/es/interface';

const MetadataItem = <Values extends Store = Store>({ name, 'data-test': dataTest }: MetadataItemProps<Values>) => {
  const valueName = useMemo(() => uuid(), []);
  const { visible: isSelected, show, hide } = useFormVisible(false);
  const wasSelected = usePrevious<boolean>(isSelected);
  const form = Form.useFormInstance();

  const rules = useMemo<FormItemProps<Values>['rules']>(
    () => [
      {
        validator: async () => {
          if (!isSelected) return;
          const valueToTest: MetadataInternalValue | undefined = form.getFieldValue(valueName);
          if (valueToTest?.inputValue && !valueToTest.isValid) {
            return Promise.reject(new Error());
          }
        },
        message: (
          <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_ERROR_INVALID_JSON} />
        ),
      },
    ],
    [form, isSelected, valueName],
  );

  const valueRules = useMemo<FormItemProps<unknown>['rules']>(
    () => [
      {
        validator: async (_, valueToTest?: MetadataInternalValue) => {
          if (!isSelected) return;
          if (valueToTest?.inputValue && !valueToTest.isValid) {
            return Promise.reject(new Error());
          }
        },
        message: (
          <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_ERROR_INVALID_JSON} />
        ),
      },
    ],
    [isSelected],
  );

  useFirstRenderEffect(() => {
    const value: object | undefined = form.getFieldValue(name);
    setTimeout(() => {
      form.setFieldValue(
        valueName,
        asType<MetadataInternalValue>({
          value,
          inputValue: value ? JSON.stringify(value) : undefined,
          isValid: !!value,
        }),
      );
      (value ? show : hide)();
    }, 0);
  });
  useEffect(() => {
    if (isSelected !== wasSelected) {
      suppressPromise(form.validateFields([name, valueName]), noop);
    }
  }, [form, isSelected, name, valueName, wasSelected]);

  const inputValue = Form.useWatch<MetadataInternalValue | undefined>(valueName);
  const isValid = !!inputValue?.isValid || !inputValue?.inputValue;
  const wasValid = usePrevious(isValid);
  useEffect(() => {
    setTimeout(() => {
      form.setFieldValue(name, inputValue?.value);
      suppressPromise(form.validateFields([name]), noop);
    }, 0);
  }, [form, inputValue?.value, name]);
  useEffect(() => {
    if (isValid !== wasValid) {
      suppressPromise(form.validateFields([name]), noop);
    }
  }, [form, isValid, name, wasValid]);

  return (
    <Form.Item<Values> noStyle>
      <Form.Item<Values> name={name} hidden rules={rules} noStyle preserve />
      <Form.Item
        name={valueName}
        label={
          isSelected ? (
            <Operation
              title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_HIDE_LABEL} />}
              icon={null}
              onClick={hide}
              mode="link"
            />
          ) : (
            <Space>
              <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_METADATA} />
              <Tooltip
                title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_HELP} />}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          )
        }
        rules={valueRules}
      >
        {isSelected ? (
          <MetadataInput data-test={dataTest} />
        ) : (
          <Operation
            title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_SHOW} />}
            icon={null}
            onClick={show}
            mode="link"
          />
        )}
      </Form.Item>
    </Form.Item>
  );
};

const MetadataItemMemo = React.memo(MetadataItem) as typeof MetadataItem;

export default MetadataItemMemo;
