import { getAddress } from 'viem';

import { MerchantCompaniesApi, type OnboardRequestAPIModel } from '@/generated/api/ncps-api';
import { CompanyApi, CompanyUserApi, UserApi } from '@/generated/api/ncps-core/merchant-bo';
import type {
  CompanyUserAPIModel,
  UpdateCompanyNameAPIModel,
  CompanyUserSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { apiConfigurationFactory, coreConfigurationFactory } from '@/infrastructure/api.provider';
import type { SliceDataRequest } from '@/infrastructure/model/api';
import { pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { CompanyUser } from './types';
import type { Address } from 'viem';

const userApi = new UserApi(coreConfigurationFactory('auth'));
const companyApi = new CompanyApi(coreConfigurationFactory('auth'));
const bffCompaniesApi = new MerchantCompaniesApi(apiConfigurationFactory('auth'));
const companyUserApi = new CompanyUserApi(coreConfigurationFactory('auth'));

const parseUser = ({ address, ...user }: CompanyUserAPIModel): CompanyUser => ({
  ...user,
  address: getAddress(address),
});

export const queryCompanies = async (initOverrides?: RequestInit) =>
  (await userApi.getUserCompanies(initOverrides)).companies ?? [];

export type OnboardRequestModel = Omit<OnboardRequestAPIModel, 'emailTokenProvider' | 'emailToken'> &
  (Required<Pick<OnboardRequestAPIModel, 'emailToken' | 'emailTokenProvider'>> | EmptyObject);
export const requestCreateCompany = async (newCompany: OnboardRequestModel, initOverrides?: RequestInit) =>
  (await bffCompaniesApi.onboard(newCompany, initOverrides)).company;

export const requestUpdateCompanyName = async (company: UpdateCompanyNameAPIModel, initOverrides?: RequestInit) =>
  companyApi.updateName(company, initOverrides);

export const queryCompanyUsers = async (
  { page, orderBy }: Omit<SliceDataRequest<EmptyObject, CompanyUserSortByAPIModel>, 'filter'>,
  initOverrides?: RequestInit,
) => sliceFromAPI(await companyUserApi.getCompanyUsers({ page: pageToAPI(page, orderBy) }, initOverrides), parseUser);

export const requestAddCompanyUser = async (address: Address, initOverrides?: RequestInit) =>
  parseUser(await companyUserApi.createCompanyUser({ address }, initOverrides));
export const requestDeleteCompanyUser = (address: Address, initOverrides?: RequestInit): Promise<unknown> =>
  companyUserApi.removeCompanyUser(address, initOverrides);

export const queryPermissions = async (initOverrides?: RequestInit) =>
  await companyApi.getMerchantPermissions(initOverrides);
