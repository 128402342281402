import isNil from 'lodash-es/isNil';
import React, { useMemo } from 'react';

import { FormattedMessage, NCPSTable, ReadonlyComponent, StopPropagationContainer } from '@/components';
import { AddressLink, AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import type { PayoutDestinationDetailed } from '@/features/payouts/types';
import { PayoutDestinationDetailedSortByAPIModel } from '@/features/payouts/types';
import { I18nComponents, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import PayoutBatchStatusView from '../PayoutBatchStatusView';

import type { BasePayoutFilter, PayoutDestinationDetailedTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<PayoutDestinationDetailed>('num')]: PayoutDestinationDetailedSortByAPIModel.Num,
  [nameof<PayoutDestinationDetailed>('batchHash')]: PayoutDestinationDetailedSortByAPIModel.Batch,
  [nameof<PayoutDestinationDetailed>('address')]: PayoutDestinationDetailedSortByAPIModel.Address,
};
const extractKey = ({ num }: PayoutDestinationDetailed) => `${num}`;
const sortColumnToModel = (s: string) => sortMapping[s];

const PayoutDestinationDetailedTable = <FilterType extends BasePayoutFilter = BasePayoutFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  BatchLink,
  Operations,
}: PayoutDestinationDetailedTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<PayoutDestinationDetailed>[] = useMemo(
    () => [
      {
        key: nameof<PayoutDestinationDetailed>('num'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_DESTINATION_ID} />,
        dataIndex: nameof<PayoutDestinationDetailed>('num'),
        sortOrder: sortOrderToTable(sortBy.Num),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { num }) => (
          <ReadonlyComponent data-test={dataTest && `${dataTest}-${num}-id`} value={num} copyable={false} />
        ),
      },
      {
        key: nameof<PayoutDestinationDetailed>('address'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_DESTINATION_ADDRESS} />,
        dataIndex: nameof<PayoutDestinationDetailed>('address'),
        sortOrder: sortOrderToTable(sortBy.Address),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { num, address, amount }) => (
          <AddressLink data-test={dataTest && `${dataTest}-${num}-address`} assetId={amount.asset} address={address} />
        ),
      },
      {
        key: nameof<PayoutDestinationDetailed>('amount'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_DESTINATION_AMOUNT} />,
        dataIndex: nameof<PayoutDestinationDetailed>('amount'),
        fixed: 'right',
        render: (_, { num, amount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                data-test={dataTest && `${dataTest}-${num}-amount`}
                assetId={amount.asset}
                value={amount.value}
                withCurrency
                withBlockchainMark
              />
            }
            copyable={false}
          />
        ),
      },
      {
        key: nameof<PayoutDestinationDetailed>('batchHash'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_TX} />,
        dataIndex: nameof<PayoutDestinationDetailed>('batchHash'),
        sortOrder: sortOrderToTable(sortBy.Batch),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { num, batchHash, amount }) =>
          batchHash ? (
            <TransactionLink data-test={dataTest && `${dataTest}-${num}-tx`} assetId={amount.asset} tx={batchHash} />
          ) : undefined,
      },
      {
        key: nameof<PayoutDestinationDetailed>('batchStatus'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_STATUS} />,
        dataIndex: nameof<PayoutDestinationDetailed>('batchStatus'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { num, batchStatus }) => (
          <PayoutBatchStatusView data-test={dataTest && `${dataTest}-${num}-status`} value={batchStatus} mode="icon" />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {BatchLink && !isNil(value.batchNum) && (
              <BatchLink value={value.batchNum} data-test={dataTest && `${dataTest}-${value.batchNum}-link`} />
            )}
            {Operations && <Operations value={value} data-test={dataTest && `${dataTest}-${value.num}-op`} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [sortBy.Num, sortBy.Address, sortBy.Batch, dataTest, BatchLink, Operations],
  );

  const { ref, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            filterComponent,
          ]
        : undefined,
    [ExportOperation, FilterForm, dataTest, exportMode, data.data?.total],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<PayoutDestinationDetailed, FilterType, PayoutDestinationDetailedSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const PayoutDestinationDetailedTableMemo = React.memo(
  PayoutDestinationDetailedTable,
) as typeof PayoutDestinationDetailedTable;

export default PayoutDestinationDetailedTableMemo;
