import { useMerchantWallet } from '@/features/merchant-wallets/hooks';
import type { MerchantWalletId } from '@/features/merchant-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

export const withActualMerchantWalletAddressLoad = <T extends MerchantWalletId>(
  Component: React.ComponentType<Omit<T, 'address'> & { address: string }>,
  EmptyComponent: React.ComponentType<Omit<T, 'address'>>,
) =>
  namedHOC<Omit<T, 'address'> & { address: string }, Omit<T, 'address'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WithActualMerchantWalletAddressLoad',
  )((props) => {
    const { bt } = props;
    const { data: wallet } = useMerchantWallet(bt);
    return wallet.data?.address ? (
      <Component {...props} bt={bt} address={wallet.data.address} />
    ) : (
      <EmptyComponent {...props} />
    );
  });

const withMerchantWalletAddressGuard = <T extends MerchantWalletId>(
  Component: React.ComponentType<Omit<T, 'address'> & { address: string }>,
  EmptyComponent: React.ComponentType<Omit<T, 'address'>> = emptyComponent,
) => {
  const MerchantWalletComponent = withActualMerchantWalletAddressLoad<T>(Component, EmptyComponent);
  return namedHOC<
    Omit<T, 'address'> & { address: string },
    (Omit<T, 'address'> & { address: string }) | (Omit<T, 'address'> & { bt: BlockchainTypeAPIModel })
  >(
    Component,
    'WithMerchantWalletAddressGuard',
  )((props) =>
    'address' in props && props.address ? <Component {...props} /> : <MerchantWalletComponent {...props} />,
  );
};

export default withMerchantWalletAddressGuard;
