import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';

import {
  markQRDelegateContractsDirty,
  storeQRDelegateContracts,
  markCompanySettingsDirty,
  storeCompanySettings,
  markAPIKeysDirty,
  storeAPIKeys,
} from './actions';
import { type CompanySettingsState } from './types';

import type { Draft } from 'immer';

const initialState: CompanySettingsState = {
  settings: storedDirtyData,
  keys: storedDirtyData,
  delegates: storedDirtyData,
};

const { storeCompanySettingsReducer, markCompanySettingsDirtyReducer } = createLoadingDataReducers(
  'CompanySettings',
  (state: Draft<CompanySettingsState>) => state.settings,
  (state, settings) => ({ ...state, settings }),
);

const { storeAPIKeysReducer, markAPIKeysDirtyReducer } = createLoadingDataReducers(
  'APIKeys',
  (state: Draft<CompanySettingsState>) => state.keys,
  (state, keys) => ({ ...state, keys }),
);

const { storeQRDelegateContractsReducer, markQRDelegateContractsDirtyReducer } = createLoadingDataReducers(
  'QRDelegateContracts',
  (state: Draft<CompanySettingsState>) => state.delegates,
  (state, delegates) => ({ ...state, delegates }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCompanySettingsDirty, markCompanySettingsDirtyReducer)
    .addCase(storeCompanySettings, storeCompanySettingsReducer)

    .addCase(markAPIKeysDirty, markAPIKeysDirtyReducer)
    .addCase(storeAPIKeys, storeAPIKeysReducer)

    .addCase(markQRDelegateContractsDirty, markQRDelegateContractsDirtyReducer)
    .addCase(storeQRDelegateContracts, storeQRDelegateContractsReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
