/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalIntentSortByAPIModel } from './WithdrawalIntentSortByAPIModel';
import {
    WithdrawalIntentSortByAPIModelFromJSON,
    WithdrawalIntentSortByAPIModelFromJSONTyped,
    WithdrawalIntentSortByAPIModelToJSON,
} from './WithdrawalIntentSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeWithdrawalIntentSortByAPIModel
 */
export interface SortAttributeWithdrawalIntentSortByAPIModel {
    /**
     * 
     * @type {WithdrawalIntentSortByAPIModel}
     * @memberof SortAttributeWithdrawalIntentSortByAPIModel
     */
    attr: WithdrawalIntentSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeWithdrawalIntentSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeWithdrawalIntentSortByAPIModel interface.
 */
export function instanceOfSortAttributeWithdrawalIntentSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeWithdrawalIntentSortByAPIModelFromJSON(json: any): SortAttributeWithdrawalIntentSortByAPIModel {
    return SortAttributeWithdrawalIntentSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeWithdrawalIntentSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeWithdrawalIntentSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': WithdrawalIntentSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeWithdrawalIntentSortByAPIModelToJSON(value?: SortAttributeWithdrawalIntentSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': WithdrawalIntentSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

