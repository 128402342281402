import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { collectableLockedLink } from '@/pages/balances/routes';

import type { LockedBalancesLinkProps } from './types';

const LockedBalancesLink: React.FC<LockedBalancesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    mode={mode}
    to={collectableLockedLink()}
    data-test={dataTest}
    title={title ?? <FormattedMessage id={I18nPageBalances.LOCKED_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const LockedBalancesLinkMemo = React.memo(LockedBalancesLink);

export default LockedBalancesLinkMemo;
