import { Form } from 'antd';
import isNil from 'lodash-es/isNil';
import React from 'react';

import type { FormCompletenessItemProps } from './types';
import type { FormInstance } from 'antd/es/form';
import type { Store } from 'rc-field-form/es/interface';

const FormCompletenessItem = <Values extends Store = Store>({
  fields = [],
  requiredFields = [],
  hasAnyValue,
  checkIsComplete,
  onChange,
}: FormCompletenessItemProps<Values>) => (
  <Form.Item<Values> shouldUpdate hidden>
    {(form: FormInstance<Values>) => {
      const allRequiredHaveValue = requiredFields.reduce(
        (result: boolean, field) => result && !isNil(form.getFieldValue(field)),
        true,
      );
      const anyHasValue =
        !hasAnyValue || !![...fields, ...requiredFields].find((field) => !isNil(form.getFieldValue(field)));
      const someValidating = [...fields, ...requiredFields].reduce(
        (result: boolean, field) => result || form.isFieldValidating(field),
        false,
      );
      const anyHasError = [...fields, ...requiredFields].reduce(
        (result: boolean, field) => result || !!form.getFieldError(field).length,
        false,
      );
      const extraComplete = checkIsComplete ? checkIsComplete(form) : true;
      setTimeout(() =>
        onChange(anyHasValue && allRequiredHaveValue && !someValidating && !anyHasError && extraComplete, form),
      );
      return null;
    }}
  </Form.Item>
);

const FormCompletenessItemMemo = React.memo(FormCompletenessItem) as typeof FormCompletenessItem;

export default FormCompletenessItemMemo;
