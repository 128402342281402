/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverallPlanChurnRateAPIModel
 */
export interface OverallPlanChurnRateAPIModel {
    /**
     * 
     * @type {number}
     * @memberof OverallPlanChurnRateAPIModel
     */
    chargesCount: number;
    /**
     * 
     * @type {number}
     * @memberof OverallPlanChurnRateAPIModel
     */
    percentage: number;
}

/**
 * Check if a given object implements the OverallPlanChurnRateAPIModel interface.
 */
export function instanceOfOverallPlanChurnRateAPIModel(value: object): boolean {
    if (!('chargesCount' in value)) return false;
    if (!('percentage' in value)) return false;
    return true;
}

export function OverallPlanChurnRateAPIModelFromJSON(json: any): OverallPlanChurnRateAPIModel {
    return OverallPlanChurnRateAPIModelFromJSONTyped(json, false);
}

export function OverallPlanChurnRateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverallPlanChurnRateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'chargesCount': json['chargesCount'],
        'percentage': json['percentage'],
    };
}

export function OverallPlanChurnRateAPIModelToJSON(value?: OverallPlanChurnRateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'chargesCount': value['chargesCount'],
        'percentage': value['percentage'],
    };
}

