import {
  CheckCircleOutlined,
  ClusterOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PayoutBatchStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutBatchStatusViewProps } from './types';

const i18n: Record<PayoutBatchStatusAPIModel, I18nFeaturePayouts> = {
  [PayoutBatchStatusAPIModel.Created]: I18nFeaturePayouts.COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_CREATED,
  [PayoutBatchStatusAPIModel.Pending]: I18nFeaturePayouts.COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_PENDING,
  [PayoutBatchStatusAPIModel.Succeeded]: I18nFeaturePayouts.COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_SUCCEEDED,
  [PayoutBatchStatusAPIModel.Failed]: I18nFeaturePayouts.COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_FAILED,
};

export const payoutBatchStatusIcons: Record<PayoutBatchStatusAPIModel, React.ReactNode> = {
  [PayoutBatchStatusAPIModel.Created]: <FileOutlined style={{ color: 'blue' }} />,
  [PayoutBatchStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <ClusterOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [PayoutBatchStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [PayoutBatchStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const PayoutBatchStatusView: React.FC<PayoutBatchStatusViewProps> = ({ value, ...props }) =>
  value ? <StatusViewElement i18n={i18n[value]} icon={payoutBatchStatusIcons[value]} {...props} /> : null;

const PayoutBatchStatusViewMemo = React.memo(PayoutBatchStatusView) as typeof PayoutBatchStatusView;

export default PayoutBatchStatusViewMemo;
