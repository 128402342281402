import { FileExcelOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useMerchantWalletTransfersActions } from '@/features/merchant-wallet-transfers/hooks';
import { ReportDownloader } from '@/features/reports/components';
import type { Report } from '@/features/reports/types';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';
import ReportViewLink from '@/pages/reports/report-view/components/ReportViewLink';

import type { OperationExportMerchantWalletTransfersProps } from './types';

const OperationExportMerchantWalletTransfersRaw: React.FC<OperationExportMerchantWalletTransfersProps> = ({
  'data-test': dataTest,
  disabled,
  mode = 'inline',
  rows,
}) => {
  const [reportId, setReportId] = useStateMountSafe<string>();
  const { withNotification } = useNotification();
  const { exportTransfers: exportTransfersAction } = useMerchantWalletTransfersActions();

  const exportPayments = useCallback(async () => {
    const report: Report = await withNotification(exportTransfersAction.act, {
      error: () => (
        <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_EXPORT_ERROR_MESSAGE} />
      ),
      success: (r: Report) => (
        <FormattedMessage
          id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_EXPORT_GENERATING_MESSAGE}
          values={{
            ln: (text: React.ReactNode) => (
              <ReportViewLink value={r.id} title={text} data-test={dataTest && `${dataTest}-reportLink`} mode="text" />
            ),
          }}
        />
      ),
    })();
    setReportId(report.id);
  }, [dataTest, exportTransfersAction.act, setReportId, withNotification]);

  return (
    <>
      <Operation
        title={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_EXPORT_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_EXPORT_TOOLTIP} />}
        icon={<FileExcelOutlined />}
        onClick={exportPayments}
        disabled={disabled || !exportTransfersAction.available || !rows}
        inProgress={exportTransfersAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
      {reportId && <ReportDownloader data-test={dataTest && `${dataTest}-report`} reportId={reportId} />}
    </>
  );
};

const OperationExportMerchantWalletTransfers = React.memo(OperationExportMerchantWalletTransfersRaw);

export default OperationExportMerchantWalletTransfers;
