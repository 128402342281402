import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { useActiveCompany } from '@/features/company/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import type { CompanyKYBStateItemProps } from './types';

const CompanyKYBStateItem: React.FC<CompanyKYBStateItemProps> = ({ 'data-test': dataTest, style, className }) => {
  const {
    data: { data: company },
  } = useActiveCompany();
  const { formatMessage } = useIntl();
  return (
    <Form.Item
      label={
        <>
          <FormattedMessage
            id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_LABEL}
            tagName="span"
          />
          {!company?.isKYBFinished && (
            <>
              &nbsp;
              <Tooltip
                title={<KYBRequiredBanner hideable={false} />}
                showArrow={false}
                styles={{ body: { width: 600 } }}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          )}
        </>
      }
    >
      <Input
        style={style}
        className={className}
        disabled
        data-test={dataTest}
        value={
          company?.isKYBFinished
            ? formatMessage({
                id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_VALUE_FINISHED,
              })
            : formatMessage({
                id: I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_VALUE_UNFINISHED,
              })
        }
      />
    </Form.Item>
  );
};

const CompanyKYBStateItemMemo = React.memo(CompanyKYBStateItem);

export default CompanyKYBStateItemMemo;
