/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPayoutDestinationAPIModel
 */
export interface NewPayoutDestinationAPIModel {
    /**
     * 
     * @type {number}
     * @memberof NewPayoutDestinationAPIModel
     */
    num: number;
    /**
     * 
     * @type {string}
     * @memberof NewPayoutDestinationAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof NewPayoutDestinationAPIModel
     */
    amount: string;
}

/**
 * Check if a given object implements the NewPayoutDestinationAPIModel interface.
 */
export function instanceOfNewPayoutDestinationAPIModel(value: object): boolean {
    if (!('num' in value)) return false;
    if (!('address' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function NewPayoutDestinationAPIModelFromJSON(json: any): NewPayoutDestinationAPIModel {
    return NewPayoutDestinationAPIModelFromJSONTyped(json, false);
}

export function NewPayoutDestinationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPayoutDestinationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'num': json['num'],
        'address': json['address'],
        'amount': json['amount'],
    };
}

export function NewPayoutDestinationAPIModelToJSON(value?: NewPayoutDestinationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'num': value['num'],
        'address': value['address'],
        'amount': value['amount'],
    };
}

