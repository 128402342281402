import React, { useMemo } from 'react';

import { useCompanySettings, useQRDelegateContract } from '@/features/company-settings/hooks';
import { QRDelegateContractIssue } from '@/features/company-settings/types';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';

import {
  QRDelegateContractNotDeployedIssueBanner,
  QRDelegateContractDeployingIssueBanner,
  QRDelegateContractNotExistIssueBanner,
} from './components';

import type { QRDelegateContractIssueBannerProps } from './types';

const QRDelegateContractIssueBanner: React.FC<QRDelegateContractIssueBannerProps> = ({
  respectSelection,
  ...props
}) => {
  const { data: contract, loading } = useQRDelegateContract(props.bt);
  const { data: settingsState } = useCompanySettings();
  const isQREnabled = useMemo(
    () => !!settingsState.data?.qrSupport.find(({ blockchain }) => blockchain === props.bt)?.isEnabled,
    [props.bt, settingsState.data?.qrSupport],
  );

  if ((respectSelection && !isQREnabled) || (!contract.data && (loading || contract.isDirty))) {
    return null;
  }

  const issue = contract.data ? contract.data.issue : QRDelegateContractIssue.NOT_EXIST;
  if (!issue) {
    return null;
  }
  switch (issue) {
    case QRDelegateContractIssue.FAILED:
    case QRDelegateContractIssue.NOT_DEPLOYED:
      return <QRDelegateContractNotDeployedIssueBanner {...props} />;
    case QRDelegateContractIssue.DEPLOYING:
      return <QRDelegateContractDeployingIssueBanner {...props} />;
    case QRDelegateContractIssue.NOT_EXIST:
      return <QRDelegateContractNotExistIssueBanner {...props} />;
  }
};

const QRDelegateContractIssueBannerHOCed =
  withBlockchainType<QRDelegateContractIssueBannerProps>(QRDelegateContractIssueBanner);

const QRDelegateContractIssueBannerMemo = React.memo(
  QRDelegateContractIssueBannerHOCed,
) as typeof QRDelegateContractIssueBannerHOCed;

export default QRDelegateContractIssueBannerMemo;
