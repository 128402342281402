import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { CollectableKindView } from '@/features/collectable/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';

import type { EntityNotFoundAlertProps } from './types';

const EntityNotFoundAlert: React.FC<EntityNotFoundAlertProps> = ({ value, onRetry, 'data-test': dataTest }) => (
  <Alert
    data-test={dataTest}
    type="warning"
    message={
      <FormattedMessage
        id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_FOUND_ALERT_TITLE}
        values={{ entity: <CollectableKindView value={value} mode="text" /> }}
      />
    }
    description={
      <FormattedMessage
        id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_FOUND_ALERT_TITLE}
        values={{
          retry: (text: React.ReactNode) => (
            <OperationRefresh
              data-test={dataTest && `${dataTest}-opRefresh`}
              mode="link"
              refresh={onRetry}
              messages={{ title: text }}
            />
          ),
          entity: <CollectableKindView value={value} mode="text" />,
        }}
      />
    }
  />
);

const EntityNotFoundAlertMemo = React.memo(EntityNotFoundAlert);

export default EntityNotFoundAlertMemo;
