import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useCollectableBalance } from '@/features/collectable/hooks';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { OperationRefreshCollectableBalanceProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECTABLE_BALANCE_TOOLTIP} />,
  success: {
    message: (
      <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECTABLE_BALANCE_MESSAGE_SUCCESS} />
    ),
  },
};

const OperationRefreshCollectableBalance: React.FC<OperationRefreshCollectableBalanceProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  id,
}) => {
  const { forceRefresh } = useCollectableBalance(id);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={messages} />;
};

const OperationRefreshCollectableBalanceMemo = React.memo(OperationRefreshCollectableBalance);

export default OperationRefreshCollectableBalanceMemo;
