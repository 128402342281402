/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { SubscriptionStatusAPIModel } from './SubscriptionStatusAPIModel';
import {
    SubscriptionStatusAPIModelFromJSON,
    SubscriptionStatusAPIModelFromJSONTyped,
    SubscriptionStatusAPIModelToJSON,
} from './SubscriptionStatusAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionPredicatesAPIModel
 */
export interface SubscriptionPredicatesAPIModel {
    /**
     * 
     * @type {SubscriptionStatusAPIModel}
     * @memberof SubscriptionPredicatesAPIModel
     */
    status?: SubscriptionStatusAPIModel;
    /**
     * 
     * @type {Array<SubscriptionStatusAPIModel>}
     * @memberof SubscriptionPredicatesAPIModel
     */
    statusIn?: Array<SubscriptionStatusAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPredicatesAPIModel
     */
    planId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPredicatesAPIModel
     */
    planIdIn?: Array<string>;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SubscriptionPredicatesAPIModel
     */
    blockchain?: BlockchainTypeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof SubscriptionPredicatesAPIModel
     */
    networkType?: BlockchainNetworkTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPredicatesAPIModel
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPredicatesAPIModel
     */
    payer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPredicatesAPIModel
     */
    ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPredicatesAPIModel
     */
    contractAddress?: string;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof SubscriptionPredicatesAPIModel
     */
    createdAt?: DateTimeRangeAPIModel;
}

/**
 * Check if a given object implements the SubscriptionPredicatesAPIModel interface.
 */
export function instanceOfSubscriptionPredicatesAPIModel(value: object): boolean {
    return true;
}

export function SubscriptionPredicatesAPIModelFromJSON(json: any): SubscriptionPredicatesAPIModel {
    return SubscriptionPredicatesAPIModelFromJSONTyped(json, false);
}

export function SubscriptionPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : SubscriptionStatusAPIModelFromJSON(json['status']),
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(SubscriptionStatusAPIModelFromJSON)),
        'planId': json['planId'] == null ? undefined : json['planId'],
        'planIdIn': json['planIdIn'] == null ? undefined : json['planIdIn'],
        'blockchain': json['blockchain'] == null ? undefined : BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'networkType': json['networkType'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkType']),
        'customerId': json['customerId'] == null ? undefined : json['customerId'],
        'payer': json['payer'] == null ? undefined : json['payer'],
        'ids': json['ids'] == null ? undefined : json['ids'],
        'contractAddress': json['contractAddress'] == null ? undefined : json['contractAddress'],
        'createdAt': json['createdAt'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdAt']),
    };
}

export function SubscriptionPredicatesAPIModelToJSON(value?: SubscriptionPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': SubscriptionStatusAPIModelToJSON(value['status']),
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(SubscriptionStatusAPIModelToJSON)),
        'planId': value['planId'],
        'planIdIn': value['planIdIn'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'networkType': BlockchainNetworkTypeAPIModelToJSON(value['networkType']),
        'customerId': value['customerId'],
        'payer': value['payer'],
        'ids': value['ids'],
        'contractAddress': value['contractAddress'],
        'createdAt': DateTimeRangeAPIModelToJSON(value['createdAt']),
    };
}

