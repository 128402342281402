import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantWallets } from '@/features/merchant-wallets/hooks';

import type { OperationRefreshMerchantWalletsProps } from './types';

const OperationRefreshMerchantWallets: React.FC<OperationRefreshMerchantWalletsProps> = ({
  'data-test': dataTest,
  title,
  mode = 'inline',
}) => {
  const { forceRefresh } = useMerchantWallets();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={{ title }} />;
};

const OperationRefreshMerchantWalletsMemo = React.memo(OperationRefreshMerchantWallets);

export default OperationRefreshMerchantWalletsMemo;
