import useListDataView from '@/app/hooks/useAppListDataView';
import { storePaymentListParameters } from '@/features/payments/actions';

import usePaymentList from '../usePaymentList';

import type { UsePaymentListView } from './types';

export default function usePaymentListView(): UsePaymentListView {
  return useListDataView(usePaymentList(), storePaymentListParameters);
}
