import { Descriptions } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import CollectableBalanceStatusView from '@/features/collectable/components/CollectableBalanceStatusView';
import CollectableTransactionIcon from '@/features/collectable/components/CollectableTransactionIcon';
import type { CollectableEntityTypedId } from '@/features/collectable/types';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import CollectableKindView from '../CollectableKindView';

import type { CollectableBalanceViewProps } from './types';

const CollectableBalanceView: React.FC<CollectableBalanceViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
  CollectTaskLink,
  CollectableEntityLink,
}) => {
  const id: CollectableEntityTypedId = useMemo(() => ({ id: data.id, kind: data.kind }), [data.id, data.kind]);
  return (
    <Descriptions
      style={style}
      className={className}
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={
        <CollectableBalanceStatusView
          value={data.status}
          isCollecting={!!data.collectTaskId}
          data-test={dataTest && `${dataTest}-status`}
        />
      }
      column={columns}
    >
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_KIND} />}>
        <CollectableKindView data-test={dataTest && `${dataTest}-kind`} value={data.kind} mode="full" />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_ADDRESS} />}>
        <AddressLink data-test={dataTest && `${dataTest}-address`} address={data.address} bt={data.blockchain} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_BALANCE} />}>
        <AssetAmount
          data-test={dataTest && `${dataTest}-balance`}
          value={data.balance.value}
          assetId={data.balance.asset}
          withCurrency
          withBlockchainMark
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_CALCULATED_AT} />}
      >
        <ReadonlyDateTime value={data.calculatedAt} data-test={dataTest && `${dataTest}-calculatedAt`} />
      </Descriptions.Item>
      {CollectableEntityLink ? (
        <Descriptions.Item label={<CollectableKindView value={data.kind} mode="text" />}>
          <CollectableEntityLink data-test={dataTest && `${dataTest}-entity`} value={id} mode="text" />
        </Descriptions.Item>
      ) : null}
      {data.collectTaskId && CollectTaskLink ? (
        <Descriptions.Item
          label={<FormattedMessage id={I18nFeatureCollectable.LABELS_BALANCE_ROW_TITLE_COLLECT_TASK} />}
        >
          <CollectTaskLink
            data-test={dataTest && `${dataTest}-task`}
            value={data.collectTaskId}
            mode="text"
            icon={<CollectableTransactionIcon />}
          />
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

const CollectableBalanceViewMemo = React.memo(CollectableBalanceView);

export default CollectableBalanceViewMemo;
