import { Alert, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { FormattedMessage, SupportEmail, WarningTooltip } from '@/components';
import { withCollectTask } from '@/features/collectable/hocs';
import type { CollectTask, CollectTaskSummary } from '@/features/collectable/types';
import { useActiveCompany } from '@/features/company/hooks';
import { CollectTaskStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import CollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';

import type { CollectableTaskStatusBannerProps } from './types';

type BannerState = 'none' | 'pending' | 'stale';
const calculateState = (intent: CollectTask | CollectTaskSummary): BannerState => {
  if (intent.status !== CollectTaskStatusAPIModel.Pending) {
    return 'none';
  }
  const now = dayjs();
  if (now.isBefore(intent.processAt)) {
    return 'none';
  }
  if (now.isAfter(intent.staleAt)) {
    return 'stale';
  }
  return 'pending';
};

const CollectableTaskStatusBanner: React.FC<CollectableTaskStatusBannerProps> = ({
  'data-test': dataTest,
  task,
  mode,
  style,
  className,
}) => {
  const { data: company } = useActiveCompany();
  const [state, setState] = useStateMountSafe(() => calculateState(task));
  useEffect(() => {
    const now = dayjs();
    if (now.isBefore(task.processAt)) {
      const timeout = setTimeout(() => setState(calculateState(task)), task.processAt.getMilliseconds() - Date.now());
      return () => clearTimeout(timeout);
    }
    if (now.isBefore(task.staleAt)) {
      const timeout = setTimeout(() => setState(calculateState(task)), task.staleAt.getMilliseconds() - Date.now());
      return () => clearTimeout(timeout);
    }
    return noop;
  }, [task, setState]);
  if (state === 'none') {
    return null;
  }
  if (state === 'stale') {
    return mode === 'tag' ? (
      <WarningTooltip
        type="error"
        title={
          <FormattedMessage
            id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) => (
                <SupportEmail title={text} subject={`[${company.data?.id}]: Stale collect task ${task.id}`} />
              ),
            }}
          />
        }
      >
        <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
          <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_TAG} />
        </Tag>
      </WarningTooltip>
    ) : (
      <Alert
        data-test={dataTest}
        className={className}
        style={style}
        message={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_TITLE} />}
        description={
          <FormattedMessage
            id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) => (
                <SupportEmail title={text} subject={`[${company.data?.id}]: Stale collect ${task.id}`} />
              ),
            }}
          />
        }
        type="error"
        showIcon
      />
    );
  }
  return mode === 'tag' ? (
    <Tooltip
      title={
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_DESCRIPTION}
          values={{
            ln: (text: React.ReactNode) => <CollectTaskLink value={task.id} title={text} mode="text" />,
          }}
        />
      }
    >
      <Tag data-test={dataTest} color="processing" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_TAG} />
      </Tag>
    </Tooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_TITLE} />}
      description={
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_DESCRIPTION}
          values={{
            ln: (text: React.ReactNode) => <CollectTaskLink value={task.id} title={text} mode="text" />,
          }}
        />
      }
      type="info"
      showIcon
    />
  );
};

const CollectableTaskStatusBannerHOC = withCollectTask<CollectableTaskStatusBannerProps>()(CollectableTaskStatusBanner);

const CollectableTaskStatusBannerMemo = React.memo(
  CollectableTaskStatusBannerHOC,
) as typeof CollectableTaskStatusBannerHOC;

export default CollectableTaskStatusBannerMemo;
