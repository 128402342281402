import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import PayoutsBreadcrumb from '@/pages/payouts/components/PayoutsBreadcrumb';

import PayoutAddLink from '../PayoutAddLink';

import type { PayoutAddBreadcrumbProps } from './types';

const PayoutAddBreadcrumb: React.FC<PayoutAddBreadcrumbProps> = ({ 'data-test': dataTest, items = [], disabled }) => (
  <PayoutsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPagePayouts.PAYOUT_ADD_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <PayoutAddLink mode="link" />
        ),
      },
      ...items,
    ]}
  />
);

const PayoutAddBreadcrumbMemo = React.memo(PayoutAddBreadcrumb);

export default PayoutAddBreadcrumbMemo;
