import { Switch } from 'antd';
import isNil from 'lodash-es/isNil';
import React, { useCallback } from 'react';

import { BrandingLogoPosition } from '@/features/branding/types';

import { BrandingLogoBottomPosition, BrandingLogoTopPosition } from './components';

import type { BrandingLogoPositionSwitchProps } from './types';

const BrandingLogoPositionSwitch = React.forwardRef<HTMLButtonElement, BrandingLogoPositionSwitchProps>(
  function BrandingLogoPositionSwitch({ 'data-test': dataTest, value, onChange, SwitchProps }, ref) {
    const onSwitch = useCallback(
      (checked: boolean) => onChange?.(checked ? BrandingLogoPosition.top : BrandingLogoPosition.bottom),
      [onChange],
    );

    return (
      <Switch
        ref={ref}
        data-test={dataTest}
        onChange={onSwitch}
        {...(!isNil(value) ? { checked: value === BrandingLogoPosition.top } : {})}
        checkedChildren={<BrandingLogoTopPosition />}
        unCheckedChildren={<BrandingLogoBottomPosition />}
        {...SwitchProps}
      />
    );
  },
);

const BrandingLogoPositionSwitchMemo = React.memo(BrandingLogoPositionSwitch) as typeof BrandingLogoPositionSwitch;

export default BrandingLogoPositionSwitchMemo;
