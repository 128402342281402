import React from 'react';

import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import { DonationEntityLink, RechargeEntityLink, PaymentEntityLink } from './components';
import { withCollectableBalanceEntityDataLoading } from './hocs';

import type { CollectableBalanceEntityCardProps } from './types';

const CollectableBalanceEntityCard: React.FC<CollectableBalanceEntityCardProps> = ({ 'data-test': dataTest, data }) => {
  switch (data.kind) {
    case CollectableKindAPIModel.Forwarder:
      return <PaymentEntityLink data-test={dataTest} value={data} />;
    case CollectableKindAPIModel.Donation:
      return <DonationEntityLink data-test={dataTest} value={data} />;
    case CollectableKindAPIModel.Recharge:
      return <RechargeEntityLink data-test={dataTest} value={data} />;
  }

  return null;
};

const CollectableBalanceEntityCardHOC = withCollectableBalanceEntityDataLoading(CollectableBalanceEntityCard);

const CollectableBalanceEntityCardMemo: typeof CollectableBalanceEntityCardHOC = React.memo(
  CollectableBalanceEntityCardHOC,
);

export default CollectableBalanceEntityCardMemo;
