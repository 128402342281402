import { Tooltip } from 'antd';
import React from 'react';

import WarningTooltip from '@/components/WarningTooltip';

import type { OperationTooltipProps } from './types';

const OperationTooltip: React.FC<OperationTooltipProps> = ({ title, hide, disabled, disabledMessage, component }) => {
  if (hide) {
    return component ?? null;
  }
  if (disabled && disabledMessage) {
    return <WarningTooltip title={disabledMessage}>{component}</WarningTooltip>;
  }
  if (title) {
    return <Tooltip title={title}>{component}</Tooltip>;
  }
  return component ?? null;
};

const OperationTooltipMemo = React.memo(OperationTooltip);

export default OperationTooltipMemo;
