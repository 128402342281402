import { CloudUploadOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import type { UseQRDelegateContractActions } from '@/features/company-settings/hooks';
import { useQRDelegateContract, useQRDelegateContractActions } from '@/features/company-settings/hooks';
import { I18nCommon, I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import type { MaybePromiseType } from '@/infrastructure/utils/ts';
import QRDelegateContractLink from '@/pages/audit/qr-contract-view/components/QRDelegateContractLink';
import MerchantWalletLink from '@/pages/audit/wallet-view/components/MerchantWalletLink';

import type { OperationDeployQRDelegateContractProps } from './types';

const messages = {
  success: (result: MaybePromiseType<ReturnType<UseQRDelegateContractActions['deploy']['act']>>) => ({
    message: (
      <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_MESSAGE_SUCCESS_TITLE} />
    ),
    description: (
      <FormattedMessage
        id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_MESSAGE_SUCCESS_DESCRIPTION}
        values={{
          link: (text: React.ReactNode) => (
            <QRDelegateContractLink value={result.blockchain} title={text} mode="text" />
          ),
        }}
      />
    ),
  }),
  error: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_MESSAGE_ERROR} />
  ),
};

const OperationDeployQRDelegateContract: React.FC<OperationDeployQRDelegateContractProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  value,
}) => {
  const { deploy: deployAction } = useQRDelegateContractActions(value);
  const { data: qrContract } = useQRDelegateContract(value);
  const { withNotification } = useNotification();

  const doDeploy = useMemo(() => withNotification(deployAction.act, messages), [deployAction.act, withNotification]);
  const bt = qrContract.data?.blockchain;

  const disabledMessage = useMemo(() => {
    switch (deployAction.unavailabilityReason) {
      case 'already-deployed':
        return (
          <FormattedMessage
            id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_ERROR_ALREADY_DEPLOYED}
          />
        );
      case 'no-data':
        return (
          <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_ERROR_NO_DATA} />
        );
      case 'no-merchant-wallet':
        return (
          <FormattedMessage
            id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_ERROR_NO_MERCHANT_WALLET}
            values={{
              link: bt
                ? (text) => (
                    <MerchantWalletLink
                      value={{ bt: bt, address: qrContract.data?.merchantWallet }}
                      title={text}
                      mode="text"
                    />
                  )
                : undefined,
            }}
          />
        );
      case 'loading':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'deploying':
        return (
          <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_ERROR_DEPLOYING} />
        );
      case undefined:
        return undefined;
    }
  }, [bt, deployAction.unavailabilityReason, qrContract.data?.merchantWallet]);

  return (
    <Operation
      title={
        title ?? <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_TITLE} />
      }
      tooltip={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DEPLOY_QR_CONTRACT_TOOLTIP} />}
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
      icon={<CloudUploadOutlined />}
      inProgress={deployAction.inAction}
      onClick={doDeploy}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeployQRDelegateContractMemo = React.memo(OperationDeployQRDelegateContract);

export default OperationDeployQRDelegateContractMemo;
