/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { CollectTaskStatusAPIModel } from './CollectTaskStatusAPIModel';
import {
    CollectTaskStatusAPIModelFromJSON,
    CollectTaskStatusAPIModelFromJSONTyped,
    CollectTaskStatusAPIModelToJSON,
} from './CollectTaskStatusAPIModel';

/**
 * 
 * @export
 * @interface CollectTaskSummaryAPIModel
 */
export interface CollectTaskSummaryAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectTaskSummaryAPIModel
     */
    id: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof CollectTaskSummaryAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskSummaryAPIModel
     */
    asset: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectTaskSummaryAPIModel
     */
    processAt: Date;
    /**
     * 
     * @type {CollectTaskStatusAPIModel}
     * @memberof CollectTaskSummaryAPIModel
     */
    status: CollectTaskStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskSummaryAPIModel
     */
    collectedAmount?: string;
}

/**
 * Check if a given object implements the CollectTaskSummaryAPIModel interface.
 */
export function instanceOfCollectTaskSummaryAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('processAt' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CollectTaskSummaryAPIModelFromJSON(json: any): CollectTaskSummaryAPIModel {
    return CollectTaskSummaryAPIModelFromJSONTyped(json, false);
}

export function CollectTaskSummaryAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectTaskSummaryAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'asset': json['asset'],
        'processAt': (new Date(json['processAt'])),
        'status': CollectTaskStatusAPIModelFromJSON(json['status']),
        'collectedAmount': json['collectedAmount'] == null ? undefined : json['collectedAmount'],
    };
}

export function CollectTaskSummaryAPIModelToJSON(value?: CollectTaskSummaryAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'asset': value['asset'],
        'processAt': ((value['processAt']).toISOString()),
        'status': CollectTaskStatusAPIModelToJSON(value['status']),
        'collectedAmount': value['collectedAmount'],
    };
}

