import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectableBalance } from '@/features/collectable/actions';
import { makeSelectCollectableBalance } from '@/features/collectable/selectors';
import type { CollectableBalance, CollectableEntityTypedId } from '@/features/collectable/types';
import { createCollectableBalanceKey } from '@/features/collectable/utils';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseCollectableBalance = UseAppSingleData<CollectableBalance>;

const fetchFactory = (
  id: CollectableEntityTypedId,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<CollectableBalance>, Parameters<typeof fetchCollectableBalance>[0]> =>
  fetchCollectableBalance({ id, force });

export default function useCollectableBalance(id: CollectableEntityTypedId | undefined): UseCollectableBalance {
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchCollectableBalance, id ? createCollectableBalanceKey(id) : undefined),
    [id],
  );
  return useAppSingleData(fetchFactory, makeSelectCollectableBalance, noSingleDataIdFn(), id, dataFetchingSelector);
}
