import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import type { AssetSelectItemMessages } from '../types';

const useMessages = (messages: AssetSelectItemMessages) => {
  return useMemo(
    () => ({
      label: messages.label || <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_LABEL} />,
      dataEmpty: messages.empty || <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_EMPTY_ERROR} />,
      placeholder: messages.placeholder || (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_PLACEHOLDER} />
      ),
      required: messages.required ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_REQUIRED} />,
    }),
    [messages.empty, messages.label, messages.placeholder, messages.required],
  );
};

export default useMessages;
