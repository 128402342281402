import { useAppDispatch } from '@/app/hooks';
import { PageContainer } from '@/components';
import { markBalancesDirty } from '@/features/statistics/actions';
import type { ActualBalancesViewProps } from '@/features/statistics/components';
import { useFirstRenderEffect } from '@/hooks';
import CollectableBalancesLink from '@/pages/balances/collectable/components/CollectableBalancesLink';
import LockedBalancesLink from '@/pages/balances/locked/components/LockedBalancesLink';

import { ActualBalancesBreadcrumb, ActualBalancesCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'balances';

const links: ActualBalancesViewProps['links'] = {
  CollectableLink: CollectableBalancesLink,
  LockedLink: LockedBalancesLink,
};

const ActualBalancesPage: React.FC = () => {
  const { withDispatch } = useAppDispatch();
  useFirstRenderEffect(withDispatch(markBalancesDirty));
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<ActualBalancesBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <ActualBalancesCard data-test={dataTest && `${dataTest}-card`} links={links} />
    </PageContainer>
  );
};

export default ActualBalancesPage;
