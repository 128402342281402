import { Navigate } from 'react-router-dom';

import { PageLoading } from '@/components';
import { usePreferences } from '@/features/user/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import MessageLayout from '@/layouts/MessageLayout';
import { onboardLink } from '@/pages/onboard/routes';

import type React from 'react';

const withOnboardedGuard = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithOnboardedGuard',
  )((props) => {
    const {
      data: { data: preferences, isDirty },
      loading,
    } = usePreferences();

    if ((!preferences && isDirty) || loading) {
      return (
        <MessageLayout>
          <PageLoading type="WithOnboardedGuard" />
        </MessageLayout>
      );
    }

    if (preferences && !preferences.onboarded) {
      return <Navigate to={onboardLink()} />;
    }
    return <Component {...props} />;
  });

export default withOnboardedGuard;
