import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { collectableTaskLinksToId, createCollectableBalanceKey } from '@/features/collectable/utils';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { CollectableAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import {
  CollectableAddressBalanceStatusAPIModel,
  CollectTaskStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { uniqueBy } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';

import { defaultCollectTaskForAddressListState, NAMESPACE } from './types';

import type { CollectTaskSummary, CollectTask, CollectTaskForAddressListState } from './types';

export const { makeSelectCollectableTransaction } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].transactions,
  'CollectableTransaction' as const,
  undefined,
);

export const { makeSelectCollectEntityProcessTransaction } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].gwtTransactions,
  'CollectEntityProcessTransaction' as const,
  undefined,
);

export const makeSelectCollectThreshold = () => (state: AppRootState) => state[NAMESPACE].threshold;

export const { makeSelectCollectTask } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].tasks.entities,
  'CollectTask' as const,
  undefined,
);

export const {
  makeSelectCollectTaskSummaryListData,
  makeSelectCollectTaskSummaryListParameters: baseMakeSelectCollectTaskSummaryListParameters,
  makeSelectDirtyCollectTaskSummaryIds,
  makeSelectMultipleCollectTaskSummary,
} = createNormalizedListSelectors(
  (state: AppRootState) => ({ ...state[NAMESPACE].tasks.list, columnState: state[NAMESPACE].tasks.columnState }),
  (state: AppRootState) => state[NAMESPACE].tasks.summaries,
  'CollectTaskSummary' as const,
  undefined,
);

export const makeSelectCollectTaskSummaryListParameters = () =>
  createSelector(
    baseMakeSelectCollectTaskSummaryListParameters(),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkEq,
    ): ReturnType<ReturnType<typeof baseMakeSelectCollectTaskSummaryListParameters>> => ({
      ...parameters,
      filter: { ...filter, networkEq },
    }),
  );

export const pendingCollectTaskFilter = ({ status, processAt }: CollectTaskSummary | CollectTask) =>
  status === CollectTaskStatusAPIModel.Pending && dayjs().isAfter(processAt);

export const activeCollectTaskFilter = ({ status, processAt, staleAt }: CollectTaskSummary | CollectTask) =>
  status === CollectTaskStatusAPIModel.Pending && dayjs().isAfter(processAt) && dayjs().isBefore(staleAt);

export const staleCollectTaskFilter = ({ status, staleAt }: CollectTaskSummary | CollectTask) =>
  status === CollectTaskStatusAPIModel.Pending && dayjs().isAfter(staleAt);

const makesSelectFilteredCollectTasks = (filter: (intent: CollectTaskSummary | CollectTask) => boolean) => () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].tasks.entities,
    (state: AppRootState) => state[NAMESPACE].tasks.summaries,
    (entities, summaries) =>
      [...Object.values(entities).map((task) => task?.data), ...Object.values(summaries).map((task) => task?.data)]
        .filter(notEmpty)
        .filter(filter)
        .filter(uniqueBy(({ id }) => id))
        .map(({ id, asset }) => ({ assetId: asset, id })),
  );

export const makeSelectActivePendingCollectTasks = makesSelectFilteredCollectTasks(activeCollectTaskFilter);
export const makeSelectStalePendingCollectTasks = makesSelectFilteredCollectTasks(staleCollectTaskFilter);
export const makeSelectPendingCollectTasks = makesSelectFilteredCollectTasks(pendingCollectTaskFilter);

export const makeSelectCollectSchedule = () => (state: AppRootState) => state[NAMESPACE].schedule;

export const {
  makeSelectCollectTasksForAddressesListData,
  makeSelectCollectTasksForAddressesListParameters: baseMakeSelectCollectTasksForAddressesListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, addressesId: CollectableAddressLinkAPIModel[] | undefined): CollectTaskForAddressListState =>
    (addressesId && state[NAMESPACE].tasks.byAddresses[collectableTaskLinksToId(addressesId)])
    ?? defaultCollectTaskForAddressListState,
  (state) => state[NAMESPACE].tasks.columnState,
  (state) => state[NAMESPACE].tasks.summaries,
  'CollectTasksForAddresses' as const,
);

export const makeSelectCollectTasksForAddressesListParameters: typeof baseMakeSelectCollectTasksForAddressesListParameters =
  (addresses: CollectableAddressLinkAPIModel[] | undefined) =>
    createSelector(
      baseMakeSelectCollectTasksForAddressesListParameters(addresses),
      makeSelectSelectedNetwork(),
      ({
        filter,
        ...parameters
      }): ReturnType<ReturnType<typeof baseMakeSelectCollectTasksForAddressesListParameters>> => ({
        ...parameters,
        filter: { ...filter, addressIn: addresses! },
      }),
    );

export const { makeSelectCollectableBalanceIsDirty, makeSelectCollectableBalance } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].balances.entities,
  'CollectableBalance',
  createCollectableBalanceKey,
);

export const {
  makeSelectCollectAvailableBalanceListData,
  makeSelectCollectAvailableBalanceListParameters: baseMakeSelectCollectAvailableBalanceListParameters,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].balances.available,
  (state: AppRootState) => state[NAMESPACE].balances.entities,
  'CollectAvailableBalance' as const,
  createCollectableBalanceKey,
);

export const makeSelectCollectAvailableBalanceListParameters = () =>
  createSelector(
    baseMakeSelectCollectAvailableBalanceListParameters(),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkEq,
    ): ReturnType<ReturnType<typeof baseMakeSelectCollectAvailableBalanceListParameters>> => ({
      ...parameters,
      filter: {
        ...filter,
        networkEq,
        statusEq: CollectableAddressBalanceStatusAPIModel.Collectable,
        balanceRange: { gte: 0.00001 },
      },
    }),
  );

export const {
  makeSelectCollectLockedBalanceListData,
  makeSelectCollectLockedBalanceListParameters: baseMakeSelectCollectLockedBalanceListParameters,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].balances.locked,
  (state: AppRootState) => state[NAMESPACE].balances.entities,
  'CollectLockedBalance' as const,
  createCollectableBalanceKey,
);

export const makeSelectCollectLockedBalanceListParameters = () =>
  createSelector(
    baseMakeSelectCollectLockedBalanceListParameters(),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkEq,
    ): ReturnType<ReturnType<typeof baseMakeSelectCollectLockedBalanceListParameters>> => ({
      ...parameters,
      filter: {
        ...filter,
        networkEq,
        statusEq: CollectableAddressBalanceStatusAPIModel.Locked,
        balanceRange: { gte: 0.00001 },
      },
    }),
  );
