import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { fetchWeb3Asset, fetchWeb3CompatBlockchainAPI } from '@/features/dictionary/blockchain/actions';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { markReportListDirty, storeReport } from '@/features/reports/actions';
import { queryDistributeFee } from '@/features/settlements/web3-api';
import { markBalancesDirty } from '@/features/statistics/actions';
import type { WithdrawalSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { WithdrawalIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { loadingDataError, mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall, withFetchAllDataOrThrow } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { createNormalizedPartialActions } from '@/infrastructure/model/partial/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { numberFromRaw, numberToRaw } from '@/infrastructure/utils/bigNumber';
import { identity } from '@/infrastructure/utils/functions';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import {
  querySettlementIntent,
  querySettlementIntents,
  querySettlementSchedule,
  requestDeleteSettlementSchedule,
  requestSettleNow,
  requestUpdateSettlementSchedule,
  querySettlement,
  querySettlementBatch,
  querySettlements,
  requestExportSettlementReconciliations,
} from './api';
import {
  makeSelectDirtySettlementIntentIds,
  makeSelectMultipleSettlementIntent,
  makeSelectScheduledIntentsBatch,
  makeSelectSettlementIntent,
  makeSelectSettlementSchedule,
  makeSelectSettlement,
  makeSelectSettlementBatch,
  makeSelectSettlementsForAssetListData,
  makeSelectSettlementsForAssetListParameters,
  makeSelectSettlementsListData,
  makeSelectSettlementsListParameters,
  makeSelectDistributeFee,
} from './selectors';
import { NAMESPACE } from './types';
import { createDistributeFeeKey, extractSettlementId, extractSettlementIntentId } from './utils';

import type {
  DistributeFeeId,
  FetchDistributeFeePayload,
  SettlementIntent,
  SettlementSchedule,
  SettlementScheduleUpdate,
  Settlement,
  SettlementBatch,
  SettlementFilterPredicate,
} from './types';
import type BigNumber from 'bignumber.js';

export const { storeSettlement, storeMultipleSettlement, markSettlementDirty } = createSingleActions<
  Settlement,
  'Settlement'
>(NAMESPACE, 'Settlement');

const settlementFetchLimit = pLimit(1);
export const fetchSettlement = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlement`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    settlementFetchLimit(async () => {
      const saved = makeSelectSettlement(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlement, 'unable to fetch settlement')(id, {
        signal,
      });
      dispatch(storeSettlement({ id, data }));

      return makeSelectSettlement(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const { storeSettlementBatch, markSettlementBatchDirty } = createSingleActions<
  SettlementBatch,
  'SettlementBatch'
>(NAMESPACE, 'SettlementBatch');

const settlementFetchBatchLimit = pLimit(1);
export const fetchSettlementBatch = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementBatch`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    settlementFetchBatchLimit(async () => {
      const saved = makeSelectSettlementBatch(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementBatch, 'unable to fetch settlement batch')(id, {
        signal,
      });
      dispatch(storeSettlementBatch({ id, data }));

      return makeSelectSettlementBatch(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const {
  storeSettlementsForAssetListData,
  storeSettlementsForAssetListParameters,
  markSettlementsForAssetListDirty,
} = createNestedListActions<Settlement, 'SettlementsForAsset', SettlementFilterPredicate, WithdrawalSortByAPIModel>(
  NAMESPACE,
  'SettlementsForAsset',
);

const settlementsPerAssetFetchLimit = pLimit(1);
export const fetchSettlementsForAsset = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementsForAsset`,
  async ({ force, asset }: { force?: boolean; asset: string }, { dispatch, getState, signal }) =>
    settlementsPerAssetFetchLimit(async () => {
      const saved = makeSelectSettlementsForAssetListData(asset)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlements, 'unable to fetch settlements')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectSettlementsForAssetListParameters(asset)(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(
        storeSettlementsForAssetListData({
          parentId: asset,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleSettlement(sliceToMultipleEntities(data.data, extractSettlementId)));
      }

      return makeSelectSettlementsForAssetListData(asset)(getState());
    }),
  { idGenerator: ({ asset }) => asset },
);

export const { storeSettlementsListData, storeSettlementsListParameters, markSettlementsListDirty } =
  createNormalizedListActions<Settlement, 'Settlements', SettlementFilterPredicate, WithdrawalSortByAPIModel>(
    NAMESPACE,
    'Settlements',
  );

const settlementsFetchLimit = pLimit(1);
export const fetchSettlements = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlements`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    settlementsFetchLimit(async () => {
      const saved = makeSelectSettlementsListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlements, 'unable to fetch settlements')(
        listStateToSliceRequest({ data: saved, ...makeSelectSettlementsListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storeSettlementsListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectSettlementsListData()(getState());
    }),
);

export const exportReconciliationsBySettlement = createAppAsyncThunk(
  `${NAMESPACE}/exportReconciliationsBySettlement`,
  async (
    { predicates, includeTransactions }: { predicates: SettlementFilterPredicate; includeTransactions?: boolean },
    { dispatch, signal },
  ) => {
    const data = await withAPICall(requestExportSettlementReconciliations, 'unable to export settlements')(
      predicates,
      Boolean(includeTransactions),
      { signal },
    );

    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
);

export const { storeSettlementIntent, storeMultipleSettlementIntent, markSettlementIntentDirty } = createSingleActions<
  SettlementIntent,
  'SettlementIntent'
>(NAMESPACE, 'SettlementIntent');

const settlementIntentFetchLimit = pLimit(1);
export const fetchSettlementIntent = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementIntent`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    settlementIntentFetchLimit(async () => {
      const saved = makeSelectSettlementIntent(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementIntent, 'unable to fetch settlement intent')(id, {
        signal,
      });
      dispatch(storeSettlementIntent({ id, data }));

      return makeSelectSettlementIntent(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const { storeScheduledIntentsBatch, markScheduledIntentsBatchDirty } = createNormalizedPartialActions<
  SettlementIntent,
  'ScheduledIntents'
>(NAMESPACE, 'ScheduledIntents');

const activeIntentsBatchFetchLimit = pLimit(1);
export const fetchScheduledIntentsBatch = createAppAsyncThunk(
  `${NAMESPACE}/fetchScheduledIntentsBatch`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    activeIntentsBatchFetchLimit(async () => {
      const saved = makeSelectScheduledIntentsBatch()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const network = makeSelectSelectedNetwork()(getState());
      const data = await withAPICall(
        withFetchAllDataOrThrow(
          (page) => () =>
            querySettlementIntents(
              { filter: { network, status: WithdrawalIntentStatusAPIModel.Pending }, page },
              { signal },
            ),
        ),
        'unable to fetch settlement intents',
      )();
      dispatch(storeScheduledIntentsBatch(mapLoadingState(data, ({ list }) => list)));

      return makeSelectScheduledIntentsBatch()(getState());
    }),
);

const multipleSettlementIntentsFetchLimit = pLimit(1);
export const fetchMultipleSettlementIntent = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSettlementIntent`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleSettlementIntentsFetchLimit(async () => {
      const absent = makeSelectDirtySettlementIntentIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSettlementIntent(ids)(getState());
      }

      const data = await withAPICall(querySettlementIntents, 'unable to fetch company')(
        { filter: { ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSettlementIntent(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractSettlementIntentId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleSettlementIntent(ids)(getState());
    }),
);

export const { storeSettlementSchedule, markSettlementScheduleDirty } = createLoadingDataActions<
  SettlementSchedule,
  'SettlementSchedule'
>(NAMESPACE, 'SettlementSchedule');

const settlementScheduleFetchLimit = pLimit(1);
export const fetchSettlementSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementSchedule`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    settlementScheduleFetchLimit(async () => {
      const saved = makeSelectSettlementSchedule()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementSchedule, 'unable to fetch settlement schedule')({ signal });
      dispatch(storeSettlementSchedule(data));

      return makeSelectSettlementSchedule()(getState());
    }),
);

export const updateSettlementSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementSchedule`,
  async (newSchedule: SettlementScheduleUpdate | undefined, { dispatch, signal }) => {
    const data = await (newSchedule
      ? withAPICall(requestUpdateSettlementSchedule, 'unable to update the settlement schedule')(newSchedule, {
          signal,
        })
      : withAPICall(requestDeleteSettlementSchedule, 'unable to remove the settlement schedule')({ signal }));

    return !data.error
      ? dispatch(fetchSettlementSchedule({ force: true })).unwrap()
      : loadingDataError<SettlementSchedule>(data.error);
  },
);

export const settleNow = createAppAsyncThunk(
  `${NAMESPACE}/settleNow`,
  async ({ asset }: { asset: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestSettleNow, 'unable to trigger the settlement')(asset, { signal });

    if (data.data) {
      dispatch(storeSettlementIntent({ id: data.data.id, data }));
      dispatch(markScheduledIntentsBatchDirty());
      dispatch(markBalancesDirty());
    }

    return data;
  },
);

export const { storeDistributeFee, markDistributeFeeDirty } = createSingleActions<
  BigNumber,
  'DistributeFee',
  DistributeFeeId
>(NAMESPACE, 'DistributeFee');
const distributeFeeFetchLimit = pLimit(1);
export const fetchDistributeFee = createAppAsyncThunk(
  `${NAMESPACE}/fetchDistributeFee`,
  async ({ force, ...key }: FetchDistributeFeePayload, { getState, dispatch }) =>
    distributeFeeFetchLimit(async () => {
      const stored = makeSelectDistributeFee(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const data = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ asset: key.asset, force })).unwrap();
        const asset = await dispatch(fetchWeb3Asset({ asset: key.asset, force })).unwrap();
        return mapLoadingState(
          await withAPICall(queryDistributeFee)(bcMeta, key.wallet, numberToRaw(key.amount, asset.formatDecimals)),
          numberFromRaw,
        );
      });
      dispatch(storeDistributeFee({ id: key, data }));
      return makeSelectDistributeFee(key)(getState());
    }),
  { idGenerator: createDistributeFeeKey },
);
