import { base58 } from '@scure/base';
import { getAddress, sha256 } from 'viem';

export const ADDRESS_SIZE = 34;
export const ADDRESS_PREFIX = '41';
export const ADDRESS_PREFIX_BYTE = 0x41;
export const ADDRESS_PREFIX_REGEX = /^(41)/;

export const TRON_BIP39_PATH_PREFIX = "m/44'/195'";
export const TRON_BIP39_PATH_INDEX_0 = `${TRON_BIP39_PATH_PREFIX}/0'/0/0`;

const arrayify = (value: string): Uint8Array => {
  const hex = value.substring(2);
  const result: number[] = [];
  for (let i = 0; i < hex.length; i += 2) {
    result.push(parseInt(hex.substring(i, i + 2), 16));
  }
  return new Uint8Array(result);
};

const SHA256 = (msgBytes: Uint8Array) => {
  const msgHex = Buffer.from(msgBytes).toString('hex');
  const hashHex = sha256(`0x${msgHex}`);
  return arrayify(hashHex);
};

export const addressFromHex = (address: string) => {
  const addressBytes = arrayify(`0x${address.replace(/^0x/, ADDRESS_PREFIX)}`);
  const hash0 = SHA256(addressBytes);
  const hash1 = SHA256(hash0);

  const checkSum = hash1.slice(0, 4);
  const bytes = new Uint8Array([...addressBytes, ...checkSum]);

  return base58.encode(bytes);
};

export const addressToHex = (address: string) => {
  const addressBytes = Buffer.from(base58.decode(address));
  const addressHex = addressBytes.subarray(0, addressBytes.length - 4).toString('hex');
  return getAddress(addressHex.replace(ADDRESS_PREFIX, '0x'));
};

export const isAddressValid = (base58Str: string) => {
  if (base58Str.length !== ADDRESS_SIZE) return false;

  let address = base58.decode(base58Str);

  if (address.length !== 25) return false;
  if (address[0] !== ADDRESS_PREFIX_BYTE) return false;

  const checkSum = address.slice(21);
  address = address.slice(0, 21);

  const hash0 = SHA256(address);
  const hash1 = SHA256(hash0);
  const checkSum1 = hash1.slice(0, 4);

  return (
    checkSum[0] === checkSum1[0]
    && checkSum[1] === checkSum1[1]
    && checkSum[2] === checkSum1[2]
    && checkSum[3] === checkSum1[3]
  );
};
