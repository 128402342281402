import type {
  SubscriptionPlanAPIModel,
  SubscriptionPlanSortByAPIModel,
  SubscriptionPlanStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { SubscriptionPlanApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import type { DataSlice, SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { amountFromAPI, amountToAPI, pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';

import type {
  NewSubscriptionPlan,
  SubscriptionPlan,
  SubscriptionPlanFilterPredicate,
  UpdateSubscriptionPlan,
} from './types';

const subscriptionPlanApi = new SubscriptionPlanApi(coreConfigurationFactory('auth'));

export const parseSubscriptionPlan = ({
  amount,
  tags,
  ...subscriptionPlan
}: SubscriptionPlanAPIModel): SubscriptionPlan => ({
  ...subscriptionPlan,
  amount: amountFromAPI(amount),
  tags: tags ?? [],
});

export const querySubscriptionPlans = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<SubscriptionPlanFilterPredicate, SubscriptionPlanSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<SubscriptionPlan, SubscriptionPlanSortByAPIModel>> =>
  sliceFromAPI(
    await subscriptionPlanApi.searchSubscriptionPlans(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseSubscriptionPlan,
  );

export const querySubscriptionPlan = async (
  subscriptionPlanId: string,
  initOverrides?: RequestInit,
): Promise<SubscriptionPlan> =>
  parseSubscriptionPlan(await subscriptionPlanApi.getSubscriptionPlan(subscriptionPlanId, initOverrides));

export const requestCreateSubscriptionPlan = async (
  { amount, ...rest }: NewSubscriptionPlan,
  initOverrides?: RequestInit,
): Promise<SubscriptionPlan> =>
  parseSubscriptionPlan(
    await subscriptionPlanApi.createSubscriptionPlan({ ...rest, amount: amountToAPI(amount) }, initOverrides),
  );

export const requestDeleteSubscriptionPlan = async (
  subscriptionPlanId: string,
  initOverrides?: RequestInit,
): Promise<void> => {
  await subscriptionPlanApi.deleteSubscriptionPlan(subscriptionPlanId, initOverrides);
};

export const requestUpdateSubscriptionPlan = async (
  id: string,
  data: UpdateSubscriptionPlan,
  initOverrides?: RequestInit,
): Promise<SubscriptionPlan> =>
  parseSubscriptionPlan(
    await subscriptionPlanApi.updateSubscriptionPlan(id, { ...data, amount: amountToAPI(data.amount) }, initOverrides),
  );

export const requestUpdateSubscriptionPlanStatus = async (
  id: string,
  newStatus: SubscriptionPlanStatusAPIModel,
  initOverrides?: RequestInit,
): Promise<SubscriptionPlan> =>
  parseSubscriptionPlan(await subscriptionPlanApi.changeSubscriptionPlanStatus(id, { newStatus }, initOverrides));
