/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicDonationAddressAPIModel
 */
export interface PublicDonationAddressAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PublicDonationAddressAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof PublicDonationAddressAPIModel
     */
    asset: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicDonationAddressAPIModel
     */
    defaultAmounts?: Array<string>;
}

/**
 * Check if a given object implements the PublicDonationAddressAPIModel interface.
 */
export function instanceOfPublicDonationAddressAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('asset' in value)) return false;
    return true;
}

export function PublicDonationAddressAPIModelFromJSON(json: any): PublicDonationAddressAPIModel {
    return PublicDonationAddressAPIModelFromJSONTyped(json, false);
}

export function PublicDonationAddressAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicDonationAddressAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'asset': json['asset'],
        'defaultAmounts': json['defaultAmounts'] == null ? undefined : json['defaultAmounts'],
    };
}

export function PublicDonationAddressAPIModelToJSON(value?: PublicDonationAddressAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'asset': value['asset'],
        'defaultAmounts': value['defaultAmounts'],
    };
}

