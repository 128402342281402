import React from 'react';

import { StatusViewElement } from '@/components';
import QRPaymentIcon from '@/features/company-settings/components/QRPaymentIcon';
import DonationsIcon from '@/features/donations/components/DonationsIcon';
import RechargesIcon from '@/features/recharges/components/RechargesIcon';
import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectableKindViewProps } from './types';

const i18n: Record<CollectableKindAPIModel, I18nMessages> = {
  [CollectableKindAPIModel.Donation]: I18nFeatureCollectable.COMPONENTS_COLLECTABLE_KIND_VIEW_DONATION,
  [CollectableKindAPIModel.Recharge]: I18nFeatureCollectable.COMPONENTS_COLLECTABLE_KIND_VIEW_RECHARGE,
  [CollectableKindAPIModel.Forwarder]: I18nFeatureCollectable.COMPONENTS_COLLECTABLE_KIND_VIEW_FORWARDER,
};

const icons: Record<CollectableKindAPIModel, React.ReactNode> = {
  [CollectableKindAPIModel.Donation]: <DonationsIcon />,
  [CollectableKindAPIModel.Recharge]: <RechargesIcon />,
  [CollectableKindAPIModel.Forwarder]: <QRPaymentIcon />,
};

const CollectableKindView: React.FC<CollectableKindViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const CollectableKindViewMemo = React.memo(CollectableKindView);

export default CollectableKindViewMemo;
