import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudServerOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { MerchantWalletStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import type { MerchantWalletStatusViewProps } from './types';

const i18n: Record<MerchantWalletStatusAPIModel, I18nMessages> = {
  [MerchantWalletStatusAPIModel.Defined]: I18nFeatureMerchantWallets.COMPONENTS_MERCHANT_WALLET_STATUS_VIEW_DEFINED,
  [MerchantWalletStatusAPIModel.Deploying]: I18nFeatureMerchantWallets.COMPONENTS_MERCHANT_WALLET_STATUS_VIEW_DEPLOYING,
  [MerchantWalletStatusAPIModel.Deployed]: I18nFeatureMerchantWallets.COMPONENTS_MERCHANT_WALLET_STATUS_VIEW_DEPLOYED,
  [MerchantWalletStatusAPIModel.DeployFailed]:
    I18nFeatureMerchantWallets.COMPONENTS_MERCHANT_WALLET_STATUS_VIEW_DEPLOYFAILED,
  [MerchantWalletStatusAPIModel.Invalid]: I18nFeatureMerchantWallets.COMPONENTS_MERCHANT_WALLET_STATUS_VIEW_INVALID,
};

const icons: Record<MerchantWalletStatusAPIModel, React.ReactNode> = {
  [MerchantWalletStatusAPIModel.Defined]: <ClockCircleOutlined style={{ color: 'blue' }} />,
  [MerchantWalletStatusAPIModel.Deploying]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <CloudServerOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [MerchantWalletStatusAPIModel.Deployed]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [MerchantWalletStatusAPIModel.DeployFailed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  [MerchantWalletStatusAPIModel.Invalid]: <CloseCircleOutlined style={{ color: 'red' }} />,
};

const MerchantWalletStatusView: React.FC<MerchantWalletStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const MerchantWalletStatusViewMemo = React.memo(MerchantWalletStatusView);

export default MerchantWalletStatusViewMemo;
