/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BigDecimalRangeAPIModel
 */
export interface BigDecimalRangeAPIModel {
    /**
     * 
     * @type {number}
     * @memberof BigDecimalRangeAPIModel
     */
    gte?: number;
    /**
     * 
     * @type {number}
     * @memberof BigDecimalRangeAPIModel
     */
    lte?: number;
}

/**
 * Check if a given object implements the BigDecimalRangeAPIModel interface.
 */
export function instanceOfBigDecimalRangeAPIModel(value: object): boolean {
    return true;
}

export function BigDecimalRangeAPIModelFromJSON(json: any): BigDecimalRangeAPIModel {
    return BigDecimalRangeAPIModelFromJSONTyped(json, false);
}

export function BigDecimalRangeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BigDecimalRangeAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'gte': json['gte'] == null ? undefined : json['gte'],
        'lte': json['lte'] == null ? undefined : json['lte'],
    };
}

export function BigDecimalRangeAPIModelToJSON(value?: BigDecimalRangeAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gte': value['gte'],
        'lte': value['lte'],
    };
}

