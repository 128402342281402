import isNil from 'lodash-es/isNil';
import React from 'react';
import isEqual from 'react-fast-compare';

import type { FormItemInputProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

const withAntDesignInputMemo = <T = unknown, CT extends FormItemInputProps<T> = FormItemInputProps<T>>(
  Component: React.FC<CT>,
) =>
  namedHOC(
    Component,
    'WithAntDesignInputMemo',
  )(
    React.memo(
      Component,
      ({ onChange: prevOnChange, ...prevProps }, { onChange: nextOnchange, ...nextProps }) =>
        isEqual(prevProps, nextProps)
        && (prevOnChange === nextOnchange || (!isNil(prevOnChange) && !isNil(prevOnChange))),
    ),
  );

export default withAntDesignInputMemo;
