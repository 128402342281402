import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { CollectPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import CollectPeriodLabel from '../CollectPeriodLabel';

import type { CollectPeriodItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CollectPeriodItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  messages,
  rules: extraRules = [],
  ItemProps = {},
}: CollectPeriodItemProps<Values>) => {
  const rules = useMemo(
    () => [
      ...extraRules,
      ...(!readonly && required
        ? [
            {
              required: true,
              message: (
                <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_PERIOD_ITEM_REQUIRED} />
              ),
            },
          ]
        : []),
    ],
    [extraRules, required, readonly],
  );

  return (
    <Form.Item
      label={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_PERIOD_ITEM_LABEL} />}
      rules={rules}
      required={!readonly && required}
      {...ItemProps}
      name={name}
    >
      <Select
        disabled={readonly}
        data-test={dataTest}
        options={useMemo(
          () =>
            enumValues(CollectPeriodAPIModel)
              .filter((code) => code !== CollectPeriodAPIModel.NotSet)
              .map((code) => ({
                value: code,
                label: <CollectPeriodLabel data-test={dataTest && `${dataTest}-${code}`} value={code} />,
              })),
          [dataTest],
        )}
        placeholder={
          !readonly
            ? (messages?.placeholder ?? (
                <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_SCHEDULE_PERIOD_ITEM_PLACEHOLDER} />
              ))
            : undefined
        }
      />
    </Form.Item>
  );
};

const CollectPeriodItemMemo = React.memo(CollectPeriodItem) as typeof CollectPeriodItem;

export default CollectPeriodItemMemo;
