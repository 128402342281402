import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import { payoutViewLink } from '@/pages/payouts/routes';

import type { PayoutViewLinkProps } from './types';

const PayoutViewLink: React.FC<PayoutViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={payoutViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPagePayouts.PAYOUT_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const PayoutViewLinkMemo = React.memo(PayoutViewLink);

export default PayoutViewLinkMemo;
