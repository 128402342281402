/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SeedStoragePolicyTypeAPIModel } from './SeedStoragePolicyTypeAPIModel';
import {
    SeedStoragePolicyTypeAPIModelFromJSON,
    SeedStoragePolicyTypeAPIModelFromJSONTyped,
    SeedStoragePolicyTypeAPIModelToJSON,
} from './SeedStoragePolicyTypeAPIModel';

/**
 * 
 * @export
 * @interface UpdatePassEncryptedCompanySeedAPIModel
 */
export interface UpdatePassEncryptedCompanySeedAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePassEncryptedCompanySeedAPIModel
     */
    retrieveByNonce: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassEncryptedCompanySeedAPIModel
     */
    encryptedSeed: string;
    /**
     * 
     * @type {SeedStoragePolicyTypeAPIModel}
     * @memberof UpdatePassEncryptedCompanySeedAPIModel
     */
    policyType: SeedStoragePolicyTypeAPIModel;
}

/**
 * Check if a given object implements the UpdatePassEncryptedCompanySeedAPIModel interface.
 */
export function instanceOfUpdatePassEncryptedCompanySeedAPIModel(value: object): boolean {
    if (!('retrieveByNonce' in value)) return false;
    if (!('encryptedSeed' in value)) return false;
    if (!('policyType' in value)) return false;
    return true;
}

export function UpdatePassEncryptedCompanySeedAPIModelFromJSON(json: any): UpdatePassEncryptedCompanySeedAPIModel {
    return UpdatePassEncryptedCompanySeedAPIModelFromJSONTyped(json, false);
}

export function UpdatePassEncryptedCompanySeedAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePassEncryptedCompanySeedAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'retrieveByNonce': json['retrieveByNonce'],
        'encryptedSeed': json['encryptedSeed'],
        'policyType': SeedStoragePolicyTypeAPIModelFromJSON(json['policyType']),
    };
}

export function UpdatePassEncryptedCompanySeedAPIModelToJSON(value?: UpdatePassEncryptedCompanySeedAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'retrieveByNonce': value['retrieveByNonce'],
        'encryptedSeed': value['encryptedSeed'],
        'policyType': SeedStoragePolicyTypeAPIModelToJSON(value['policyType']),
    };
}

