/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWithdrawalReconciliationPredicatesAPIModel } from './MerchantWithdrawalReconciliationPredicatesAPIModel';
import {
    MerchantWithdrawalReconciliationPredicatesAPIModelFromJSON,
    MerchantWithdrawalReconciliationPredicatesAPIModelFromJSONTyped,
    MerchantWithdrawalReconciliationPredicatesAPIModelToJSON,
} from './MerchantWithdrawalReconciliationPredicatesAPIModel';
import type { PageWithdrawalReconciliationSortByAPIModel } from './PageWithdrawalReconciliationSortByAPIModel';
import {
    PageWithdrawalReconciliationSortByAPIModelFromJSON,
    PageWithdrawalReconciliationSortByAPIModelFromJSONTyped,
    PageWithdrawalReconciliationSortByAPIModelToJSON,
} from './PageWithdrawalReconciliationSortByAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalReconciliationFilterAPIModel
 */
export interface WithdrawalReconciliationFilterAPIModel {
    /**
     * 
     * @type {MerchantWithdrawalReconciliationPredicatesAPIModel}
     * @memberof WithdrawalReconciliationFilterAPIModel
     */
    predicates: MerchantWithdrawalReconciliationPredicatesAPIModel;
    /**
     * 
     * @type {PageWithdrawalReconciliationSortByAPIModel}
     * @memberof WithdrawalReconciliationFilterAPIModel
     */
    page: PageWithdrawalReconciliationSortByAPIModel;
}

/**
 * Check if a given object implements the WithdrawalReconciliationFilterAPIModel interface.
 */
export function instanceOfWithdrawalReconciliationFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function WithdrawalReconciliationFilterAPIModelFromJSON(json: any): WithdrawalReconciliationFilterAPIModel {
    return WithdrawalReconciliationFilterAPIModelFromJSONTyped(json, false);
}

export function WithdrawalReconciliationFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalReconciliationFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': MerchantWithdrawalReconciliationPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageWithdrawalReconciliationSortByAPIModelFromJSON(json['page']),
    };
}

export function WithdrawalReconciliationFilterAPIModelToJSON(value?: WithdrawalReconciliationFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': MerchantWithdrawalReconciliationPredicatesAPIModelToJSON(value['predicates']),
        'page': PageWithdrawalReconciliationSortByAPIModelToJSON(value['page']),
    };
}

