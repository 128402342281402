import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import PaymentsBreadcrumb from '@/pages/payments/all/components/PaymentsBreadcrumb';

import PaymentAddLink from '../PaymentAddLink';

import type { PaymentAddBreadcrumbProps } from './types';

const PaymentAddBreadcrumb: React.FC<PaymentAddBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <PaymentsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPagePayments.PAYMENT_ADD_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <PaymentAddLink
            title={<FormattedMessage id={I18nPagePayments.PAYMENT_ADD_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="link"
          />
        ),
      },
      ...items,
    ]}
  />
);

const PaymentAddBreadcrumbMemo = React.memo(PaymentAddBreadcrumb);

export default PaymentAddBreadcrumbMemo;
