import { Modal, Typography } from 'antd';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { recoverMessageAddress } from 'viem';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  authEmail,
  authWeb3,
  fetchAuthParameters,
  logout,
  relogin,
  storeAuthStatus,
  tryInitFromJWT,
} from '@/features/auth/actions';
import TKeyContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { makeSelectAuthStatus, makeSelectAuthToken } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { useActionPending } from '@/features/global/hooks';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import type { OpenIdProviderTypeAPIModel } from '@/generated/api/ncps-api';
import type { SignatureComponentsAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { JWTTokenInfo } from '@/infrastructure/security/jwt/types';
import { withOnError } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import type { Cancellable, Rejected } from '@/infrastructure/utils/ui';
import { rejected, rejection } from '@/infrastructure/utils/ui';

import type React from 'react';
import type { Hex, Address } from 'viem';

const { Paragraph } = Typography;

export interface UseAuthActionsType {
  login: HookAction<
    [
      { signMessage: (message: string) => Promise<SignatureComponentsAPIModel>; address: Address } & (
        | { emailToken: string; emailProvider: OpenIdProviderTypeAPIModel }
        | {
            withRecaptcha: <T extends unknown[] = [], R = unknown>(
              func: (v: ReCaptchaParams) => Func<T, R>,
            ) => (...args: T) => Promise<R>;
          }
      ),
      { cancellationPromise?: Cancellable; onBeforeSignUp?: Func<[]> }?,
    ],
    JWTTokenInfo
  >;
  relogin: HookAction<[number], JWTTokenInfo>;
  disconnectWeb3Auth: HookAction<
    [{ signMessage: (message: string) => Promise<Hex> }, { cancellationPromise?: Cancellable }?],
    boolean
  >;
  logout: HookAction<[boolean?]>;
  tryInitJWT: HookAction<[], void>;
}

const selectJWTState = makeSelectAuthToken();
const selectAuthStatus = makeSelectAuthStatus();

export default function useAuthActions(): UseAuthActionsType {
  const { formatMessage } = useIntl();
  const { withExtractDataDispatch, withDispatch, withVoidDispatch } = useAppDispatch();
  const jwtState = useAppSelector(selectJWTState);
  const authStatus = useAppSelector(selectAuthStatus);

  const web3Auth = useContext(TKeyContext);

  const [loggingIn, withLoggingIn] = useSubmitting(false);
  const loginAction: UseAuthActionsType['login']['act'] = useMemo(
    () =>
      withOnError(
        withLoggingIn(async ({ signMessage, address, ...rest }, { cancellationPromise, onBeforeSignUp } = {}) => {
          withDispatch(storeAuthStatus)({ newState: AuthStatus.PROCESSING });
          const params = await withExtractDataDispatch(fetchAuthParameters)({ address });
          if (params.nonce === '0' && onBeforeSignUp) {
            const signedUp: unknown = await (cancellationPromise
              ? Promise.race([onBeforeSignUp(), cancellationPromise])
              : onBeforeSignUp());
            if (signedUp === rejection) {
              throw rejected();
            }
          }
          const message =
            // eslint-disable-next-line no-nested-ternary
            params.nonce === '0'
              ? params.brokerAddress
                ? formatMessage(
                    { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_WITH_BROKER },
                    { address: params.brokerAddress.toLowerCase() },
                  )
                : formatMessage(
                    { id: I18nFeatureAuth.HOOKS_ACTIONS_SIGNUP_MESSAGE_NO_BROKER },
                    { address: address.toLowerCase() },
                  )
              : formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGIN_MESSAGE }, { nonce: params.nonce });
          const signature = await (cancellationPromise
            ? Promise.race([signMessage(message), cancellationPromise])
            : signMessage(message));
          if (signature === rejection) {
            throw rejected();
          }

          return 'withRecaptcha' in rest
            ? rest.withRecaptcha(
                (reCaptcha) => () =>
                  withExtractDataDispatch(authWeb3)({
                    credentials: { signature, message, address, isNewScheme: true, partner: params.brokerAddress },
                    reCaptcha,
                  }),
              )()
            : withExtractDataDispatch(authEmail)({
                credentials: { signature, message, address, isNewScheme: true, partner: params.brokerAddress },
                emailToken: rest.emailToken,
                emailProvider: rest.emailProvider,
              });
        }),
        () => withDispatch(storeAuthStatus)({ newState: AuthStatus.UNAUTHORIZED, expected: AuthStatus.PROCESSING }),
      ),
    [formatMessage, withDispatch, withExtractDataDispatch, withLoggingIn],
  );
  const login = useMemo(
    () => ({ act: loginAction, available: !jwtState.data, inAction: loggingIn }),
    [loginAction, jwtState.data, loggingIn],
  );

  const reloginAction: UseAuthActionsType['relogin']['act'] = useMemo(
    () => withLoggingIn((companyId: number) => withExtractDataDispatch(relogin)({ companyId })),
    [withExtractDataDispatch, withLoggingIn],
  );
  const reloginHook = useMemo(
    () => ({ act: reloginAction, available: !!jwtState.data, inAction: loggingIn }),
    [jwtState.data, loggingIn, reloginAction],
  );

  const [loggingOut, withLoggingOut] = useSubmitting(false);
  const logoutAction = useMemo(
    () =>
      withLoggingOut(async (clearWeb3AuthKey?: boolean) => {
        const doLogout = async (deviceKeyCleanup?: boolean) => {
          if (web3Auth.web3Auth) {
            await web3Auth.web3Auth.logout(deviceKeyCleanup);
          }
          await withVoidDispatch(logout)({});
        };
        if (clearWeb3AuthKey) {
          await doLogout(clearWeb3AuthKey);
        } else if (web3Auth.web3Auth?.isAuthorized() && web3Auth.web3Auth.deviceKey.isDefined) {
          const result = await new Promise<boolean>((resolve, reject) => {
            Modal.confirm({
              closable: true,
              title: formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGOUT_CONFIRMATION_TITLE }),
              content: formatMessage(
                { id: I18nFeatureAuth.HOOKS_ACTIONS_LOGOUT_CONFIRMATION_CONTENT },
                { p: (...content: React.ReactNode[]) => <Paragraph>{content}</Paragraph> },
              ),
              okText: formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGOUT_CONFIRMATION_OK_TITLE }),
              onOk: () => resolve(true),
              cancelText: formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_LOGOUT_CONFIRMATION_CANCEL_TITLE }),
              // f.name either is empty if dialog is closed or has a names if pressed 'no'
              onCancel: (f?: (...args: unknown[]) => unknown) => (f?.name ? resolve(false) : reject(rejected())),
            });
          });
          await doLogout(result);
        } else {
          await doLogout();
        }
      }),
    [formatMessage, web3Auth.web3Auth, withLoggingOut, withVoidDispatch],
  );
  const logoutHook = useMemo(
    () => ({ act: logoutAction, available: !!jwtState.data, inAction: loggingOut }),
    [logoutAction, jwtState.data, loggingOut],
  );

  const [initializing, withInitializing] = useSubmitting(false);
  const initializingJWT = useActionPending(tryInitFromJWT);
  const initJWTAction = useMemo(
    () => withInitializing(withVoidDispatch(tryInitFromJWT)),
    [withInitializing, withVoidDispatch],
  );
  const tryInitJWT = useMemo(
    () => ({
      act: initJWTAction,
      available: authStatus === AuthStatus.NOT_INITIALIZED,
      inAction: initializingJWT || initializing,
    }),
    [authStatus, initJWTAction, initializing, initializingJWT],
  );

  const [disconnecting, withDisconnecting] = useSubmitting(false);
  const disconnectWeb3AuthAction: UseAuthActionsType['disconnectWeb3Auth']['act'] = useMemo(
    () =>
      withDisconnecting(async ({ signMessage }, { cancellationPromise } = {}) => {
        const address = web3Auth.web3Auth?.state?.address;
        if (!address) {
          throw new Error('Web3AuthNotInitialized');
        }
        const validateAddress = async () => {
          const message = formatMessage({ id: I18nFeatureAuth.HOOKS_ACTIONS_WEB3AUTH_DISCONNECT_MESSAGE }, { address });
          const signature = await signMessage(message);
          const recovered = await recoverMessageAddress({ message, signature });
          return recovered === address;
        };

        const validationResult: Rejected | boolean = await (cancellationPromise
          ? Promise.race([validateAddress(), cancellationPromise])
          : validateAddress());
        if (validationResult === rejection) {
          throw rejected();
        }
        if (validationResult) {
          await web3Auth.web3Auth?.reset();
          await logoutHook.act(true);
        }
        return validationResult;
      }),
    [formatMessage, logoutHook, web3Auth.web3Auth, withDisconnecting],
  );
  const disconnectWeb3Auth = useMemo(
    () => ({
      act: disconnectWeb3AuthAction,
      available: web3Auth.initialized && !!web3Auth.web3Auth?.isAuthorized(),
      inAction: disconnecting,
    }),
    [disconnectWeb3AuthAction, web3Auth.initialized, web3Auth.web3Auth, disconnecting],
  );

  return {
    login,
    relogin: reloginHook,
    logout: logoutHook,
    tryInitJWT,
    disconnectWeb3Auth,
  };
}
