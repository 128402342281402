import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { collectableAvailableLink } from '@/pages/balances/routes';

import type { CollectableBalancesLinkProps } from './types';

const CollectableBalancesLink: React.FC<CollectableBalancesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    mode={mode}
    to={collectableAvailableLink()}
    data-test={dataTest}
    title={title ?? <FormattedMessage id={I18nPageBalances.COLLECTABLE_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const CollectableBalancesLinkMemo = React.memo(CollectableBalancesLink);

export default CollectableBalancesLinkMemo;
