import React from 'react';

import {
  type CompanyActiveAssetsForSelectIssue,
  CompanyActiveAssetsForSelectIssueRender,
} from '@/features/company-settings/hooks';
import { AssetSelectItem } from '@/features/dictionary/blockchain/components';
import { useAssetsForDonation } from '@/features/donations/hooks';

import type { PayoutAssetItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SelectInputProps = { allowClear: false };

const PayoutAssetItem = <Values extends Store = Store>({ initialValue, ...props }: PayoutAssetItemProps<Values>) => {
  const assets = useAssetsForDonation();
  return (
    <AssetSelectItem<Values, CompanyActiveAssetsForSelectIssue | undefined>
      {...props}
      SelectInputProps={SelectInputProps}
      assets={assets}
      Issues={CompanyActiveAssetsForSelectIssueRender}
    />
  );
};

const PayoutAssetItemMemo = React.memo(PayoutAssetItem) as typeof PayoutAssetItem;

export default PayoutAssetItemMemo;
