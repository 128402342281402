import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum PayoutsPageRoutes {
  ALL = '',
  ADD = 'new',
  VIEW = 'view/:payoutId',
  EDIT = 'edit/:payoutId',
  BATCH_VIEW = 'view/:payoutId/batch/:batchNum',
}

export const payoutsRoutes = (page: PayoutsPageRoutes) =>
  `${appRoute(AppPageRoutes.PAYOUTS)}${page ? `/${page}` : ''}`;

export const payoutsLink = () => payoutsRoutes(PayoutsPageRoutes.ALL);
export const payoutAddLink = (copyOf?: string) =>
  `${payoutsRoutes(PayoutsPageRoutes.ADD)}${copyOf ? `?copy-of=${copyOf}` : ''}`;
export const payoutViewLink = (payoutId: string) => `${payoutsLink()}/view/${payoutId}`;
export const payoutEditLink = (payoutId: string) => `${payoutsLink()}/edit/${payoutId}`;

export const payoutBatchViewLink = (payoutId: string, batchNum: number) =>
  `${payoutViewLink(payoutId)}/batch/${batchNum}`;
