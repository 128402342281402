import { List, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { ReadonlyComponent } from '@/components';
import type { QRDelegateContract } from '@/features/company-settings/types';
import { AddressLink, BlockchainIcon } from '@/features/dictionary/blockchain/components';
import { stringComparator } from '@/infrastructure/model/comparators';

import QRDelegateContractStatusView from '../QRDelegateContractStatusView';

import type { QRDelegateContractsListProps } from './types';

const style: React.CSSProperties = { maxHeight: 400, overflowY: 'auto', width: '100%' };

const contractComparator = stringComparator<QRDelegateContract>(({ blockchain, id }) => `${blockchain}_${id}`)('ASC');

const QRDelegateContractsList: React.FC<QRDelegateContractsListProps> = ({
  'data-test': dataTest,
  data,
  DelegateLink,
  Operations,
}) => {
  const orderedData = useMemo(() => [...data].sort(contractComparator), [data]);
  return (
    <List
      data-test={dataTest}
      itemLayout="horizontal"
      loadMore={false}
      style={style}
      dataSource={orderedData}
      renderItem={useCallback(
        (delegate: QRDelegateContract) => {
          const id = delegate.id;
          return (
            <List.Item
              key={id}
              actions={[
                <Space key="actions">
                  {DelegateLink && (
                    <DelegateLink data-test={dataTest && `${dataTest}-${id}-link`} value={delegate.blockchain} />
                  )}
                  {Operations && <Operations data-test={dataTest && `${dataTest}-${id}-operations`} value={delegate} />}
                </Space>,
              ]}
            >
              <List.Item.Meta
                style={{ alignItems: 'center' }}
                avatar={
                  <BlockchainIcon
                    style={{ fontSize: 32 }}
                    bt={delegate.blockchain}
                    data-test={dataTest && `${dataTest}-${id}-bt`}
                  />
                }
                title={
                  <ReadonlyComponent
                    value={
                      <QRDelegateContractStatusView
                        data-test={dataTest && `${dataTest}-${id}-status`}
                        value={delegate.status}
                        mode="full"
                      />
                    }
                    style={{
                      display: 'inline-flex',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  />
                }
                description={
                  delegate.address && (
                    <AddressLink
                      data-test={dataTest && `${dataTest}-${id}-address`}
                      bt={delegate.blockchain}
                      address={delegate.address}
                    />
                  )
                }
              />
            </List.Item>
          );
        },
        [DelegateLink, Operations, dataTest],
      )}
    />
  );
};

const QRDelegateContractsListMemo = React.memo(QRDelegateContractsList);

export default QRDelegateContractsListMemo;
