/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeCollectTaskSortByAPIModel } from './SortAttributeCollectTaskSortByAPIModel';
import {
    SortAttributeCollectTaskSortByAPIModelFromJSON,
    SortAttributeCollectTaskSortByAPIModelFromJSONTyped,
    SortAttributeCollectTaskSortByAPIModelToJSON,
} from './SortAttributeCollectTaskSortByAPIModel';

/**
 * 
 * @export
 * @interface PageCollectTaskSortByAPIModel
 */
export interface PageCollectTaskSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageCollectTaskSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectTaskSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeCollectTaskSortByAPIModel>}
     * @memberof PageCollectTaskSortByAPIModel
     */
    sortBy?: Array<SortAttributeCollectTaskSortByAPIModel>;
}

/**
 * Check if a given object implements the PageCollectTaskSortByAPIModel interface.
 */
export function instanceOfPageCollectTaskSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageCollectTaskSortByAPIModelFromJSON(json: any): PageCollectTaskSortByAPIModel {
    return PageCollectTaskSortByAPIModelFromJSONTyped(json, false);
}

export function PageCollectTaskSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageCollectTaskSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeCollectTaskSortByAPIModelFromJSON)),
    };
}

export function PageCollectTaskSortByAPIModelToJSON(value?: PageCollectTaskSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeCollectTaskSortByAPIModelToJSON)),
    };
}

