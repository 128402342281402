/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GasWalletDeletePermissionAPIModel
 */
export interface GasWalletDeletePermissionAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof GasWalletDeletePermissionAPIModel
     */
    restricted: boolean;
    /**
     * 
     * @type {string}
     * @memberof GasWalletDeletePermissionAPIModel
     */
    reason?: string;
}

/**
 * Check if a given object implements the GasWalletDeletePermissionAPIModel interface.
 */
export function instanceOfGasWalletDeletePermissionAPIModel(value: object): boolean {
    if (!('restricted' in value)) return false;
    return true;
}

export function GasWalletDeletePermissionAPIModelFromJSON(json: any): GasWalletDeletePermissionAPIModel {
    return GasWalletDeletePermissionAPIModelFromJSONTyped(json, false);
}

export function GasWalletDeletePermissionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GasWalletDeletePermissionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'restricted': json['restricted'],
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function GasWalletDeletePermissionAPIModelToJSON(value?: GasWalletDeletePermissionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'restricted': value['restricted'],
        'reason': value['reason'],
    };
}

