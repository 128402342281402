import type { MerchantWalletDeployTransaction, MerchantWalletId } from './types';

export const merchantWalletIdToStoreKey = ({ bt, address }: MerchantWalletId) => `${bt}_${address}`;
export const extractWalletIdToFromTransaction = ({
  blockchain,
  address,
}: MerchantWalletDeployTransaction): MerchantWalletId => ({
  bt: blockchain,
  address,
});
