/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PlanChurnRateRecordAPIModel } from './PlanChurnRateRecordAPIModel';
import {
    PlanChurnRateRecordAPIModelFromJSON,
    PlanChurnRateRecordAPIModelFromJSONTyped,
    PlanChurnRateRecordAPIModelToJSON,
} from './PlanChurnRateRecordAPIModel';

/**
 * 
 * @export
 * @interface PlanChurnRateAPIModel
 */
export interface PlanChurnRateAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PlanChurnRateAPIModel
     */
    planId: string;
    /**
     * 
     * @type {Array<PlanChurnRateRecordAPIModel>}
     * @memberof PlanChurnRateAPIModel
     */
    perCharge?: Array<PlanChurnRateRecordAPIModel>;
}

/**
 * Check if a given object implements the PlanChurnRateAPIModel interface.
 */
export function instanceOfPlanChurnRateAPIModel(value: object): boolean {
    if (!('planId' in value)) return false;
    return true;
}

export function PlanChurnRateAPIModelFromJSON(json: any): PlanChurnRateAPIModel {
    return PlanChurnRateAPIModelFromJSONTyped(json, false);
}

export function PlanChurnRateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanChurnRateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'planId': json['planId'],
        'perCharge': json['perCharge'] == null ? undefined : ((json['perCharge'] as Array<any>).map(PlanChurnRateRecordAPIModelFromJSON)),
    };
}

export function PlanChurnRateAPIModelToJSON(value?: PlanChurnRateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'planId': value['planId'],
        'perCharge': value['perCharge'] == null ? undefined : ((value['perCharge'] as Array<any>).map(PlanChurnRateRecordAPIModelToJSON)),
    };
}

