/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DonationTransactionAPIModel } from './DonationTransactionAPIModel';
import {
    DonationTransactionAPIModelFromJSON,
    DonationTransactionAPIModelFromJSONTyped,
    DonationTransactionAPIModelToJSON,
} from './DonationTransactionAPIModel';
import type { PagePushTransactionSortByAPIModel } from './PagePushTransactionSortByAPIModel';
import {
    PagePushTransactionSortByAPIModelFromJSON,
    PagePushTransactionSortByAPIModelFromJSONTyped,
    PagePushTransactionSortByAPIModelToJSON,
} from './PagePushTransactionSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceDonationTransactionPushTransactionSortByAPIModel
 */
export interface SliceDonationTransactionPushTransactionSortByAPIModel {
    /**
     * 
     * @type {Array<DonationTransactionAPIModel>}
     * @memberof SliceDonationTransactionPushTransactionSortByAPIModel
     */
    list?: Array<DonationTransactionAPIModel>;
    /**
     * 
     * @type {PagePushTransactionSortByAPIModel}
     * @memberof SliceDonationTransactionPushTransactionSortByAPIModel
     */
    page: PagePushTransactionSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceDonationTransactionPushTransactionSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceDonationTransactionPushTransactionSortByAPIModel interface.
 */
export function instanceOfSliceDonationTransactionPushTransactionSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceDonationTransactionPushTransactionSortByAPIModelFromJSON(json: any): SliceDonationTransactionPushTransactionSortByAPIModel {
    return SliceDonationTransactionPushTransactionSortByAPIModelFromJSONTyped(json, false);
}

export function SliceDonationTransactionPushTransactionSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceDonationTransactionPushTransactionSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(DonationTransactionAPIModelFromJSON)),
        'page': PagePushTransactionSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceDonationTransactionPushTransactionSortByAPIModelToJSON(value?: SliceDonationTransactionPushTransactionSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(DonationTransactionAPIModelToJSON)),
        'page': PagePushTransactionSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

