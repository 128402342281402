import type { DateTimeRelativeQuickFilter } from '@/components';
import type {
  CollectScheduleAPIModel,
  CollectTaskAddressAPIModel,
  CollectTaskAPIModel,
  CollectTaskSummaryAPIModel,
  CollectTaskPredicatesAPIModel,
  CollectableEntityProcessTransactionAPIModel,
  CollectableEntityTransactionAPIModel,
  CollectableAddressBalanceAPIModel,
  CollectableAddressBalancePredicatesAPIModel,
  CollectableKindAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import {
  CollectableAddressBalanceSortByAPIModel,
  CollectTaskSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { defaultListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'collectable';

export type CollectScheduleUpdate = CollectScheduleAPIModel;

export type CollectSchedule = CollectScheduleAPIModel;

export interface CollectTaskAddress extends Omit<CollectTaskAddressAPIModel, 'collectedAmount'> {
  collectedAmount?: AssetAmountValue;
}

export interface CollectableEntityTransaction extends Omit<CollectableEntityTransactionAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export type CollectableEntityProcessTransaction = CollectableEntityProcessTransactionAPIModel;

export type CollectThreshold = AssetAmountValue;

export interface CollectTask extends Omit<CollectTaskAPIModel, 'addresses' | 'collectedAmount'> {
  staleAt: Date;
  addresses: CollectTaskAddress[];
  collectedAmount?: AssetAmountValue;
}

export interface CollectTaskSummary extends Omit<CollectTaskSummaryAPIModel, 'collectedAmount'> {
  staleAt: Date;
  collectedAmount?: AssetAmountValue;
}

export interface CollectTaskFilterPredicate extends CollectTaskPredicatesAPIModel {
  processAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type CollectTaskForAddressFilterPredicate = Omit<CollectTaskFilterPredicate, 'addressLike'>;

export type CollectTaskListState = ListStateNoColumns<string, CollectTaskFilterPredicate, CollectTaskSortByAPIModel>;

export type CollectTaskForAddressListState = ListStateNoColumns<
  string,
  CollectTaskForAddressFilterPredicate,
  CollectTaskSortByAPIModel
>;

export interface CollectableEntityTypedId {
  id: string;
  kind: CollectableKindAPIModel;
}

export type CollectTaskSortBy = ListSortBy<CollectTaskSortByAPIModel>;

export interface CollectableBalance extends Omit<CollectableAddressBalanceAPIModel, 'balance'> {
  balance: AssetAmountValue;
}

export interface CollectableBalanceFilterPredicate
  extends Pick<
    CollectableAddressBalancePredicatesAPIModel,
    'addressLike' | 'assetIn' | 'btIn' | 'kindIn' | 'networkEq' | 'statusEq' | 'calculatedAtRange' | 'balanceRange'
  > {
  calculatedAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type CollectableBalanceListState = ListState<
  string,
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel
>;

export type CollectableBalanceSortBy = ListSortBy<CollectableAddressBalanceSortByAPIModel>;

export const defaultSortBy: CollectTaskSortBy = { [CollectTaskSortByAPIModel.ProcessAt]: 'DESC' };
export const defaultCollectTaskListState: ListState<string, CollectTaskFilterPredicate, CollectTaskSortByAPIModel> =
  defaultListState({}, defaultSortBy);
export const defaultCollectTaskForAddressListState: CollectTaskForAddressListState = defaultListState(
  {},
  defaultSortBy,
);

export const defaultBalanceSortBy: CollectableBalanceSortBy = {
  [CollectableAddressBalanceSortByAPIModel.CalculatedAt]: 'DESC',
};
export const defaultBalanceListState: CollectableBalanceListState = defaultListState({}, defaultBalanceSortBy);

export interface CollectableState {
  schedule: LoadingStateWithDirty<CollectSchedule>;
  transactions: SingleState<CollectableEntityTransaction>;
  gwtTransactions: SingleState<CollectableEntityProcessTransaction>;

  threshold: LoadingFullDataState<CollectThreshold>;

  balances: {
    entities: SingleState<CollectableBalance>;
    available: CollectableBalanceListState;
    locked: CollectableBalanceListState;
  };

  tasks: {
    columnState: ListColumnState;
    list: CollectTaskListState;
    byAddresses: Partial<Record<string, CollectTaskForAddressListState>>;
    summaries: SingleState<CollectTaskSummary>;

    entities: SingleState<CollectTask>;
  };
}
