import type { PaymentTransactionAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'payment-transactions';

export enum PaymentTransactionKind {
  DIRECT = 'DIRECT',
  FORWARDER = 'FORWARDER',
}

export interface PaymentTransaction extends Omit<PaymentTransactionAPIModel, 'amount' | 'invoiceId' | 'isForwarder'> {
  paymentId: string;
  amount: AssetAmountValue;
  kind: PaymentTransactionKind;
}

export interface PaymentTransactionsState {
  entities: SingleState<PaymentTransaction>;
}
