import React from 'react';

import type { Web3Auth } from '@/infrastructure/security/web3-auth/types';

export interface Web3AuthContextType {
  web3Auth?: Web3Auth | undefined;
  initialized: boolean;
}

const Web3AuthContext = React.createContext<Web3AuthContextType>({ initialized: false });

export default Web3AuthContext;
