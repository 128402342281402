import { css } from '@emotion/css';
import { Modal, Table } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import type { PaymentTransaction } from '@/features/payment-transactions/types';
import { txToSearchId } from '@/features/payment-transactions/utils';
import { I18nComponents, I18nFeaturePaymentTransactions } from '@/generated/i18n/i18n';
import { asType, nameof } from '@/infrastructure/utils/ts';

import PaymentTransactionKindView from '../PaymentTransactionKindView';
import PaymentTransactionStatusView from '../PaymentTransactionStatusView';
import PaymentTransactionView from '../PaymentTransactionView';

import type { PaymentTransactionsTableProps } from './types';
import type { ColumnType } from 'antd/lib/table/interface';

const doExtractKey = ({ paymentId, txId, index }: PaymentTransaction) => `${paymentId}_${txId}_${index}`;

const PaymentTransactionsTable: React.FC<PaymentTransactionsTableProps> = ({
  'data-test': dataTest,
  data,
  loading,
  PaymentLink,
  PaymentTransactionLink,
  SettlementLink,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const columns: ColumnType<PaymentTransaction>[] = useMemo(
    () => [
      {
        key: nameof<PaymentTransaction>('txId'),
        title: <FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_TX_ID} />,
        dataIndex: nameof<PaymentTransaction>('txId'),
        className: css`
          max-width: 100px;
        `,
        render: (_, { txId, blockchain }, idx) => (
          <TransactionLink data-test={dataTest && `${dataTest}-${idx}-txId`} bt={blockchain} tx={txId} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_AMOUNT} />,
        dataIndex: nameof<PaymentTransaction>('amount'),
        fixed: 'right',
        width: 120,
        render: (_, { amount: { value, asset } }, idx) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={asset}
                value={value}
                withCurrency
                data-test={dataTest && `${dataTest}-${idx}-amount`}
                withBlockchainMark
              />
            }
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_KIND} />,
        dataIndex: nameof<PaymentTransaction>('kind'),
        width: 1,
        fixed: 'right',
        render: (_, { kind }, idx) => (
          <PaymentTransactionKindView value={kind} data-test={dataTest && `${dataTest}-${idx}-kind`} mode="icon" />
        ),
        align: 'center',
      },
      {
        title: <FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_PAID_AT} />,
        dataIndex: nameof<PaymentTransaction>('paidAt'),
        fixed: 'right',
        width: 120,
        render: (_, { paidAt }, idx) => (
          <ReadonlyDateTime data-test={dataTest && `${dataTest}-${idx}-paidAt`} value={paidAt} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeaturePaymentTransactions.LABELS_ROW_TITLE_STATUS} />,
        dataIndex: nameof<PaymentTransaction>('status'),
        width: 1,
        fixed: 'right',
        render: (_, { status }, idx) => (
          <PaymentTransactionStatusView
            value={status}
            data-test={dataTest && `${dataTest}-${idx}-status`}
            mode="icon"
          />
        ),
        align: 'center',
      },
      ...((PaymentLink ?? SettlementLink)
        ? [
            asType<ColumnType<PaymentTransaction>>({
              key: 'options',
              title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
              width: 1,
              fixed: 'right',
              render: (_, { withdrawnWith, paymentId }, idx) => (
                <StopPropagationContainer>
                  {PaymentLink && (
                    <PaymentLink value={paymentId} data-test={dataTest && `${dataTest}-${idx}-payment`} />
                  )}
                  {withdrawnWith && SettlementLink && (
                    <SettlementLink value={withdrawnWith} data-test={dataTest && `${dataTest}-${idx}-settlement`} />
                  )}
                </StopPropagationContainer>
              ),
            }),
          ]
        : []),
    ],
    [PaymentLink, SettlementLink, dataTest],
  );

  const onRowClick = useCallback(
    (value: PaymentTransaction) => ({
      onClick: () =>
        modal.info({
          title: PaymentTransactionLink ? (
            <TitleWithDetails
              title={
                <FormattedMessage
                  id={I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTIONS_TABLE_PAYMENT_DIALOG_TITLE}
                />
              }
              link={
                <PaymentTransactionLink
                  value={txToSearchId(value)}
                  mode="text"
                  title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} />}
                />
              }
              data-test={dataTest && `${dataTest}-dialog-title`}
            />
          ) : (
            <FormattedMessage
              id={I18nFeaturePaymentTransactions.COMPONENTS_PAYMENT_TRANSACTIONS_TABLE_PAYMENT_DIALOG_TITLE}
            />
          ),
          content: (
            <PaymentTransactionView
              data-test={dataTest && `${dataTest}-dialog-view`}
              data={value}
              style={{ paddingTop: '10px' }}
              columns={1}
            />
          ),
          maskClosable: true,
          width: 650,
        }),
    }),
    [PaymentTransactionLink, dataTest, modal],
  );

  return (
    <>
      {contextHolder}
      <Table<PaymentTransaction>
        data-test={dataTest}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        onRow={onRowClick}
        rowKey={doExtractKey}
      />
    </>
  );
};
const PaymentTransactionsTableMemo = React.memo(PaymentTransactionsTable);

export default PaymentTransactionsTableMemo;
