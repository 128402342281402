import type { AssetWithNetwork } from '@/features/dictionary/blockchain/types';
import type { MerchantWalletIssue } from '@/features/merchant-wallets/types';
import type {
  BlockchainNetworkTypeAPIModel,
  CompanyApiFullKeyAPIModel,
  CompanyApiKeyAPIModel,
  CompanySettingsAPIModel,
  ForwarderDelegateAPIModel,
  ForwarderSettingsDetailsAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';

export const NAMESPACE = 'settings';

export interface APIKeyId {
  key: string;
  network: BlockchainNetworkTypeAPIModel;
}

export interface APIKey extends Omit<CompanyApiKeyAPIModel, 'companyId' | 'network'> {
  network: BlockchainNetworkTypeAPIModel;
}

export interface APIKeyFull extends Omit<CompanyApiFullKeyAPIModel, 'companyId' | 'network'> {
  network: BlockchainNetworkTypeAPIModel;
}

export interface NewWebhook {
  url: string;
  network: BlockchainNetworkTypeAPIModel;
}

export interface Webhook extends NewWebhook {
  id: string;
}

export type QRSupport = ForwarderSettingsDetailsAPIModel;

export interface CompanySettings
  extends Omit<
    CompanySettingsAPIModel,
    'gasWallets' | 'activeAssets' | 'notificationSettings' | 'forwarderSettings' | 'defaultAsset'
  > {
  activeAssets: string[];
  webhooks: Webhook[];
  qrSupport: QRSupport[];
  statisticsAsset: string;
}

export interface RenewalTokenExpiration {
  expiresAt: Date;
}

export interface CompanyAssetWithWalletState extends AssetWithNetwork {
  walletIssue: MerchantWalletIssue | undefined;
}

export const MAX_API_KEYS_PER_NETWORK = 3;

export type ConfirmCompanyEmailParams =
  | { email: string; token: string; companyId?: undefined }
  | { email: string; token: string; companyId: number };

export interface QRDelegateContract extends ForwarderDelegateAPIModel {
  issue?: QRDelegateContractIssue;
}

export enum QRDelegateContractIssue {
  NOT_EXIST = 'not-exist',
  NOT_DEPLOYED = 'not-deployed',
  DEPLOYING = 'deploying',
  FAILED = 'failed',
}

export interface CompanySettingsState {
  settings: LoadingStateWithDirty<CompanySettings>;
  keys: LoadingStateWithDirty<APIKey[]>;
  delegates: LoadingFullDataState<QRDelegateContract>;
}
