import type { JWTTokenWithInfo } from '@/infrastructure/security/jwt/types';
import { parseJWT } from '@/infrastructure/security/jwt/types';

export const store = (token: JWTTokenWithInfo) => {
  sessionStorage.setItem(window.env.AUTH_SESSION_KEY, token.token);
};

export const cleanUp = () => {
  sessionStorage.removeItem(window.env.AUTH_SESSION_KEY);
};

export const load = () => {
  const token = sessionStorage.getItem(window.env.AUTH_SESSION_KEY);
  if (token) {
    try {
      return parseJWT(token);
    } catch (error) {
      cleanUp();
      console.error('Token restore has failed, token was cleaned up', error);
    }
  }
  return null;
};
