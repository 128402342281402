import { QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Divider, Form, type SelectProps, Space, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import type { AssetSelectItemMessages, AssetSelectItemProps } from '@/features/dictionary/blockchain/components';
import { AssetSelectItem } from '@/features/dictionary/blockchain/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { QRSupportErrorItem } from '@/features/payments/components/CreatePaymentForm/components';
import { type AssetForPaymentIssue, AssetForPaymentIssueRender, useAssetsForPayment } from '@/features/payments/hooks';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { noop, suppressPromise } from '@/infrastructure/utils/functions';

import { OperationAddCryptoPeggedAssets, OperationAddFiatPeggedAssets } from './components';

import type { AuxAmountsItemProps } from './types';
import type { QRSupportErrorData } from '../QRSupportErrorItem/types';
import type { Store } from 'rc-field-form/es/interface';

const messages: AssetSelectItemMessages = {
  label: (
    <Space>
      <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_LABEL} />
      <Tooltip title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_HELP} />}>
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  ),
};

const SelectInputProps: SelectProps<{ value: string; label: string }> = { mode: 'multiple' };
const validation = { allowMultipleBlockchains: true };

const AuxAmountsItem = <Values extends Store = Store>({ mainAsset, amount, ...props }: AuxAmountsItemProps<Values>) => {
  const qrSupportName = useMemo(() => uuid(), []);
  const allAssets = useAssetsForPayment();
  const mainAssetMeta = useAsset(mainAsset);
  const assets = useMemo(
    () => ({
      ...allAssets,
      data: mapStoredState(allAssets.data, (all) =>
        all
          .filter(({ peggedToFiat }) => !!peggedToFiat && peggedToFiat === mainAssetMeta.data.data?.peggedToFiat)
          .filter(({ code }) => code !== mainAsset),
      ),
    }),
    [allAssets, mainAsset, mainAssetMeta.data.data?.peggedToFiat],
  );
  const selectedAux = Form.useWatch<string[] | undefined>(props.name);
  const selected = useMemo(() => (mainAsset ? [mainAsset, ...(selectedAux ?? [])] : []), [mainAsset, selectedAux]);
  const { setFieldValue } = Form.useFormInstance();
  const onSelect = useCallback(
    (newAssets: string[]) => {
      setFieldValue(props.name, [...(selectedAux ?? []), ...newAssets]);
    },
    [props.name, selectedAux, setFieldValue],
  );

  const qrError = Form.useWatch<QRSupportErrorData | undefined>(qrSupportName);
  const rules = useMemo<AssetSelectItemProps<Values, AssetForPaymentIssue | undefined>['rules']>(
    () => [
      {
        validator: async () => {
          if (qrError?.errors?.length) {
            return Promise.reject(new Error());
          }
        },
        message: (
          <FormattedMessageWithMarkup id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ERRORS_QR} />
        ),
      },
    ],
    [qrError?.errors?.length],
  );

  const { validateFields } = Form.useFormInstance();
  useEffect(() => {
    suppressPromise(validateFields([props.name]), noop);
  }, [props.name, validateFields, qrError?.errors?.length, selected, mainAsset, amount]);
  const [isValid, setIsValid] = useStateMountSafe<boolean>(false);
  const ItemProps = useMemo(
    () =>
      mainAssetMeta.data.data && isValid
        ? {
            help: (
              <Space
                className={css`
                  > div > a {
                    font-size: small;
                  }
                `}
              >
                <OperationAddFiatPeggedAssets
                  data-test={props['data-test'] && `${props['data-test']}-addFiat`}
                  mainAsset={mainAssetMeta.data.data}
                  assets={assets.data.data}
                  selectedAssets={selected}
                  onSelect={onSelect}
                />
                <Divider type="vertical" />
                <OperationAddCryptoPeggedAssets
                  data-test={props['data-test'] && `${props['data-test']}-addCrypto`}
                  mainAsset={mainAssetMeta.data.data}
                  assets={assets.data.data}
                  selectedAssets={selected}
                  onSelect={onSelect}
                />
              </Space>
            ),
          }
        : undefined,
    [assets.data.data, isValid, mainAssetMeta.data.data, onSelect, props, selected],
  );
  return (
    <>
      <Form.Item shouldUpdate noStyle hidden>
        {({ getFieldError }) => {
          setTimeout(() => setIsValid(!getFieldError(props.name).length), 0);
          return null;
        }}
      </Form.Item>
      <AssetSelectItem<Values, AssetForPaymentIssue | undefined>
        {...props}
        required={false}
        assets={assets}
        messages={messages}
        selectedAssets={selected}
        rules={rules}
        Issues={AssetForPaymentIssueRender}
        validation={validation}
        ItemProps={ItemProps}
        SelectInputProps={SelectInputProps}
      />
      <QRSupportErrorItem
        data-test={props['data-test'] && `${props['data-test']}-forwarder`}
        name={qrSupportName}
        assets={selectedAux}
        amount={amount}
      />
    </>
  );
};

const AuxAmountsItemMemo = React.memo(AuxAmountsItem) as typeof AuxAmountsItem;

export default AuxAmountsItemMemo;
