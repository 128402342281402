/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletSignatureCreateAPIModel
 */
export interface MerchantWalletSignatureCreateAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletSignatureCreateAPIModel
     */
    brokerAddress: string;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof MerchantWalletSignatureCreateAPIModel
     */
    signature: SignatureComponentsAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletSignatureCreateAPIModel
     */
    bootstrapMessage: string;
}

/**
 * Check if a given object implements the MerchantWalletSignatureCreateAPIModel interface.
 */
export function instanceOfMerchantWalletSignatureCreateAPIModel(value: object): boolean {
    if (!('brokerAddress' in value)) return false;
    if (!('signature' in value)) return false;
    if (!('bootstrapMessage' in value)) return false;
    return true;
}

export function MerchantWalletSignatureCreateAPIModelFromJSON(json: any): MerchantWalletSignatureCreateAPIModel {
    return MerchantWalletSignatureCreateAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletSignatureCreateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletSignatureCreateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'brokerAddress': json['brokerAddress'],
        'signature': SignatureComponentsAPIModelFromJSON(json['signature']),
        'bootstrapMessage': json['bootstrapMessage'],
    };
}

export function MerchantWalletSignatureCreateAPIModelToJSON(value?: MerchantWalletSignatureCreateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'brokerAddress': value['brokerAddress'],
        'signature': SignatureComponentsAPIModelToJSON(value['signature']),
        'bootstrapMessage': value['bootstrapMessage'],
    };
}

