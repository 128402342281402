import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { collectableViewLink } from '@/pages/balances/routes';

import type { CollectableBalanceViewLinkProps } from './types';

const CollectableBalanceViewLink: React.FC<CollectableBalanceViewLinkProps> = ({
  value,
  title,
  mode = 'inline',
  icon,
  'data-test': dataTest,
}) => {
  return (
    <Link
      data-test={dataTest}
      to={collectableViewLink(value)}
      icon={icon}
      disabledMessage={<FormattedMessage id={I18nPageBalances.COLLECTABLE_VIEW_COMPONENTS_LINK_TITLE} />}
      mode={mode}
      title={title || <FormattedMessage id={I18nPageBalances.COLLECTABLE_VIEW_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const CollectableBalanceViewLinkMemo = React.memo(CollectableBalanceViewLink);

export default CollectableBalanceViewLinkMemo;
