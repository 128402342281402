import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useActiveMerchantWallet from '../useActiveMerchantWallet';

const useIsActiveMerchantWalletDeployed = (bt: BlockchainTypeAPIModel | undefined) => {
  const { data, loading, forceRefresh } = useActiveMerchantWallet(bt);
  return { data: data.data?.status === MerchantWalletStatusAPIModel.Deployed, loading, forceRefresh };
};

export default useIsActiveMerchantWalletDeployed;
