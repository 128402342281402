import { useCallback, useMemo } from 'react';

import {
  CompanyActiveAssetsForSelectIssueRender,
  useActiveAssetsForSelect,
  useQRDelegateContracts,
} from '@/features/company-settings/hooks';
import type { AssetForSelection } from '@/features/dictionary/blockchain/components';
import type { AssetSelectIssueRender } from '@/features/dictionary/blockchain/components/AssetSelectItem/types';
import { useBlockchainSystemInfos } from '@/features/dictionary/blockchain/hooks';
import { type LoadingStateWithDirty, mapStoredState } from '@/infrastructure/model';

import { AssetForPaymentQRIssueBanner } from './components';

import type { AssetForPaymentIssue } from './types';

export const AssetForPaymentIssueRender: Record<AssetForPaymentIssue, AssetSelectIssueRender> = {
  ...CompanyActiveAssetsForSelectIssueRender,
  'qr-issue': AssetForPaymentQRIssueBanner,
};

export default function useAssetsForPayment() {
  const assetsState = useActiveAssetsForSelect({ disableInvalidWallet: true });
  const qrContractState = useQRDelegateContracts();
  const blockchainState = useBlockchainSystemInfos();

  const data = useMemo<LoadingStateWithDirty<AssetForSelection<AssetForPaymentIssue | undefined>[]>>(
    () =>
      mapStoredState(assetsState.data, (assets) => {
        return assets.map((asset) => {
          const qrContract = qrContractState.data.data?.find(
            (qrContract) => qrContract.blockchain === asset.blockchain,
          );
          const blockchain = blockchainState.data.data?.find((bc) => bc.bt === asset.blockchain);
          return {
            ...asset,
            issue:
              asset.issue
              || (blockchain?.forwarder === 'required' && (!qrContract || qrContract.issue) ? 'qr-issue' : undefined),
          };
        });
      }),
    [assetsState.data, blockchainState.data.data, qrContractState.data.data],
  );

  const loading = assetsState.loading || qrContractState.loading;
  const forceRefresh = useCallback(
    async () => Promise.all([assetsState.forceRefresh(), qrContractState.forceRefresh()]),
    [assetsState, qrContractState],
  );

  return { data, forceRefresh, loading };
}
