/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum SeedStoragePolicyTypeAPIModel {
    CompanySeedWeb3EncryptedStorage = 'CompanySeedWeb3EncryptedStorage',
    CompanySeedPassEncryptedStorage = 'CompanySeedPassEncryptedStorage'
}


export function SeedStoragePolicyTypeAPIModelFromJSON(json: any): SeedStoragePolicyTypeAPIModel {
    return SeedStoragePolicyTypeAPIModelFromJSONTyped(json, false);
}

export function SeedStoragePolicyTypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeedStoragePolicyTypeAPIModel {
    return json as SeedStoragePolicyTypeAPIModel;
}

export function SeedStoragePolicyTypeAPIModelToJSON(value?: SeedStoragePolicyTypeAPIModel | null): any {
    return value as any;
}

