import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import { fetchBlockchainsSystemInfo } from '@/features/dictionary/blockchain/actions';
import { makeSelectBlockchainSystemInfo } from '@/features/dictionary/blockchain/selectors';
import type { BlockchainSystemInfo } from '@/features/dictionary/blockchain/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export type UseBlockchainSystemInfo = UseAppSingleData<BlockchainSystemInfo, unknown>;

const dataFetchingSelector = makeSelectPending(fetchBlockchainsSystemInfo);
const fetchFactory = (_: unknown, force: boolean) => fetchBlockchainsSystemInfo({ force });

export default function useBlockchainSystemInfo(bt: BlockchainTypeAPIModel | undefined): UseBlockchainSystemInfo {
  return useAppSingleData<BlockchainTypeAPIModel, BlockchainSystemInfo, unknown>(
    fetchFactory,
    makeSelectBlockchainSystemInfo,
    noSingleDataIdFn(),
    bt,
    dataFetchingSelector,
  );
}
