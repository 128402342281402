import { useAppNestedListDataView } from '@/app/hooks';
import { storeCollectTasksForAddressesListParameters } from '@/features/collectable/actions';
import type { CollectableAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useCollectTaskSummaryForAddresses from '../useCollectTaskSummaryForAddresses';

export default function useCollectTaskSummaryForAddressesListView(
  addresses: CollectableAddressLinkAPIModel[] | undefined,
) {
  return useAppNestedListDataView(
    useCollectTaskSummaryForAddresses(addresses),
    storeCollectTasksForAddressesListParameters,
    addresses,
  );
}
