import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { createNestedListParametersReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markPayoutBatchesDirty,
  markPayoutDestinationsDirty,
  markPayoutDirty,
  markPayoutForSettlementDirty,
  markPayoutListDirty,
  storeDestinationsPerBatchListParameters,
  storeDestinationsPerPayoutListParameters,
  storeMultiplePayout,
  storePayout,
  storePayoutBatches,
  storePayoutDestinations,
  storePayoutForSettlement,
  storePayoutListData,
  storePayoutListParameters,
  storeRemovePayout,
} from './actions';
import {
  defaultPayoutDestinationDetailedParametersState,
  defaultPayoutDestinationParametersState,
  defaultPayoutListState,
} from './types';
import { extractPayoutId, payoutBatchIdToKey } from './utils';

import type { PayoutBatchId, PayoutsState } from './types';
import type { Draft } from 'immer';

const initialState: PayoutsState = {
  entities: {},
  list: defaultPayoutListState,
  bySettlement: {},
  destinations: {},
  batches: {},
  destinationsPerPayout: {
    parameters: {},
    columnState: {},
  },
  destinationsPerBatch: {
    parameters: {},
    columnState: {},
  },
};

const {
  storePayoutReducer,
  storeMultiplePayoutReducer,
  storePayoutListParametersReducer,
  storeRemovePayoutReducer,
  storePayoutListDataReducer,
  markPayoutDirtyReducer,
  markPayoutListDirtyReducer,
} = createNormalizedListReducers(
  'Payout',
  (state: Draft<PayoutsState>) => state.list,
  (state, list) => ({ ...state, list }),
  defaultPayoutListState,
  (state: Draft<PayoutsState>) => state.entities as PayoutsState['entities'],
  (global, entities) => ({ ...global, entities }),
  extractPayoutId,
);

const { storePayoutBatchesReducer, markPayoutBatchesDirtyReducer } = createSingleReducers(
  'PayoutBatches',
  (state: Draft<PayoutsState>) => state.batches,
  (global, batches) => ({ ...global, batches }),
);

const { storePayoutDestinationsReducer, markPayoutDestinationsDirtyReducer } = createSingleReducers(
  'PayoutDestinations',
  (state: Draft<PayoutsState>) => state.destinations,
  (global, destinations) => ({ ...global, destinations }),
);

const { storePayoutForSettlementReducer, markPayoutForSettlementDirtyReducer } = createSingleReducers(
  'PayoutForSettlement',
  (state: Draft<PayoutsState>) => state.bySettlement,
  (global, bySettlement) => ({ ...global, bySettlement }),
);

const { storeDestinationsPerPayoutListParametersReducer } = createNestedListParametersReducers(
  'DestinationsPerPayout',
  (state: Draft<PayoutsState>, payoutId: string | undefined) =>
    payoutId ? state.destinationsPerPayout.parameters[payoutId] : undefined,
  (global, payoutId: string, newListState) => ({
    ...global,
    destinationsPerPayout: {
      ...global.destinationsPerPayout,
      parameters: { ...global.destinationsPerPayout.parameters, [payoutId]: newListState },
    },
  }),
  (state: Draft<PayoutsState>) => state.destinationsPerPayout.columnState,
  (global, columnState) => ({
    ...global,
    destinationsPerPayout: { ...global.destinationsPerPayout, columnState },
  }),
  defaultPayoutDestinationDetailedParametersState,
);

const { storeDestinationsPerBatchListParametersReducer } = createNestedListParametersReducers(
  'DestinationsPerBatch',
  (state: Draft<PayoutsState>, batchId: PayoutBatchId | undefined) =>
    batchId ? state.destinationsPerBatch.parameters[payoutBatchIdToKey(batchId)] : undefined,
  (global, batchId: PayoutBatchId, newListState) => ({
    ...global,
    destinationsPerBatch: {
      ...global.destinationsPerBatch,
      parameters: { ...global.destinationsPerBatch.parameters, [payoutBatchIdToKey(batchId)]: newListState },
    },
  }),
  (state: Draft<PayoutsState>) => state.destinationsPerBatch.columnState,
  (global, columnState) => ({
    ...global,
    destinationsPerBatch: { ...global.destinationsPerBatch, columnState },
  }),
  defaultPayoutDestinationParametersState,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storePayout, storePayoutReducer)
    .addCase(storeRemovePayout, storeRemovePayoutReducer)
    .addCase(storeMultiplePayout, storeMultiplePayoutReducer)
    .addCase(storePayoutListData, storePayoutListDataReducer)
    .addCase(storePayoutListParameters, storePayoutListParametersReducer)
    .addCase(markPayoutListDirty, markPayoutListDirtyReducer)
    .addCase(markPayoutDirty, markPayoutDirtyReducer)

    .addCase(storePayoutBatches, storePayoutBatchesReducer)
    .addCase(markPayoutBatchesDirty, markPayoutBatchesDirtyReducer)

    .addCase(storePayoutDestinations, storePayoutDestinationsReducer)
    .addCase(markPayoutDestinationsDirty, markPayoutDestinationsDirtyReducer)

    .addCase(storePayoutForSettlement, storePayoutForSettlementReducer)
    .addCase(markPayoutForSettlementDirty, markPayoutForSettlementDirtyReducer)

    .addCase(storeDestinationsPerPayoutListParameters, storeDestinationsPerPayoutListParametersReducer)
    .addCase(storeDestinationsPerBatchListParameters, storeDestinationsPerBatchListParametersReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
