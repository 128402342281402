import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshMerchantWalletDeployTransactions } from '@/features/merchant-wallets/components';
import useMerchantWalletDeployTransactions from '@/features/merchant-wallets/hooks/useMerchantWalletDeployTransactions';
import type { MerchantWalletDeployTransaction, MerchantWalletId } from '@/features/merchant-wallets/types';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withMerchantWalletTransactionsDataLoading = <
  Original extends { data: MerchantWalletDeployTransaction[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & MerchantWalletId = Omit<Original, 'data'> & MerchantWalletId,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithMerchantWalletTransactionsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useMerchantWalletDeployTransactions(props.bt, props.address);
    return withCardDataLoading<MerchantWalletDeployTransaction[], Original>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title:
        !data.data.data?.length || data.data.data.length > 1 ? (
          <FormattedMessage id={I18nPageAudit.WALLET_VIEW_COMPONENTS_TRANSACTIONS_CARD_FEW} />
        ) : (
          <FormattedMessage id={I18nPageAudit.WALLET_VIEW_COMPONENTS_TRANSACTIONS_CARD_SINGLE} />
        ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshMerchantWalletDeployTransactions
              bt={props.bt}
              address={props.address}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withMerchantWalletTransactionsDataLoading;
