import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import MerchantWalletsBreadcrumb from '@/pages/audit/wallets/components/MerchantWalletsBreadcrumb';

import MerchantWalletLink from '../MerchantWalletLink';

import type { MerchantWalletBreadcrumbProps } from './types';

const MerchantWalletBreadcrumb: React.FC<MerchantWalletBreadcrumbProps> = ({
  'data-test': dataTest,
  bt,
  address,
  disabled,
  items = [],
}) => {
  const id = useMemo(() => ({ bt, address }), [bt, address]);
  return (
    <MerchantWalletsBreadcrumb
      data-test={dataTest}
      items={[
        {
          title: disabled ? (
            <FormattedMessage id={I18nPageAudit.WALLET_VIEW_COMPONENTS_BREADCRUMB} values={{ bt, address }} />
          ) : (
            <MerchantWalletLink
              mode="text"
              value={id}
              title={<FormattedMessage id={I18nPageAudit.WALLET_VIEW_COMPONENTS_BREADCRUMB} values={{ bt, address }} />}
            />
          ),
        },
        ...items,
      ]}
    />
  );
};

const MerchantWalletBreadcrumbMemo = React.memo(MerchantWalletBreadcrumb);

export default MerchantWalletBreadcrumbMemo;
