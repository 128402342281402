import { List, Space } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import QRDelegateContractIssueBanner from '@/features/company-settings/components/QRDelegateContractIssueBanner';
import QRMinimumAmountMessage from '@/features/company-settings/components/QRMinimumAmountMessage';
import { AssetLabel, BlockchainIcon, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRSupportItemProps } from './types';

const QRSupportItem: React.FC<QRSupportItemProps> = ({
  'data-test': dataTest,
  settings,
  assets,
  Actions,
  Operations,
}) => (
  <List.Item
    key={settings.blockchain}
    actions={
      Actions && [
        <Actions
          key={`${settings.blockchain}-actions`}
          data-test={dataTest && `${dataTest}-actions`}
          settings={settings}
          assets={assets}
        />,
      ]
    }
  >
    <List.Item.Meta
      key={`${settings.blockchain}-meta`}
      style={{ alignItems: 'center' }}
      avatar={
        <BlockchainIcon style={{ fontSize: 32 }} bt={settings.blockchain} data-test={dataTest && `${dataTest}-icon`} />
      }
      description={
        <>
          <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_QR_SUPPORT_LIST_ITEM_ASSETS} />
          {assets.length
            ? assets.map((asset, idx) => (
                <React.Fragment key={asset.code}>
                  <>
                    <AssetLabel
                      data-test={dataTest && `${dataTest}-asset-${asset.code}`}
                      value={asset}
                      mode="short"
                      style={{ display: 'inline' }}
                    />
                    &nbsp;
                    <QRMinimumAmountMessage
                      data-test={dataTest && `${dataTest}-min-${asset.code}`}
                      asset={asset.code}
                    />
                  </>
                  {idx < assets.length - 1 && ', '}
                </React.Fragment>
              ))
            : null}
        </>
      }
      title={
        <Space>
          <BlockchainLabel data-test={dataTest && `${dataTest}-title`} bt={settings.blockchain} mode="short" />
          <QRDelegateContractIssueBanner
            data-test={dataTest && `${dataTest}-banner`}
            bt={settings.blockchain}
            respectSelection
            mode="tag"
            type="link"
          />
        </Space>
      }
    />
    {Operations && <Operations data-test={dataTest && `${dataTest}-ops`} settings={settings} assets={assets} />}
  </List.Item>
);

const QRSupportItemMemo = React.memo(QRSupportItem);

export default QRSupportItemMemo;
