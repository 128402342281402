/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportStatusAPIModel {
    Generating = 'Generating',
    Success = 'Success',
    Error = 'Error'
}


export function ReportStatusAPIModelFromJSON(json: any): ReportStatusAPIModel {
    return ReportStatusAPIModelFromJSONTyped(json, false);
}

export function ReportStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStatusAPIModel {
    return json as ReportStatusAPIModel;
}

export function ReportStatusAPIModelToJSON(value?: ReportStatusAPIModel | null): any {
    return value as any;
}

