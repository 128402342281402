import { Space } from 'antd';
import React, { useMemo } from 'react';

import type { LinkMode, OperationMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { PayoutsTable, PayoutsDocumentationLink } from '@/features/payouts/components';
import { usePayoutListView } from '@/features/payouts/hooks';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import { useDimensions } from '@/hooks';
import PayoutAddLink from '@/pages/payouts/payout-add/components/PayoutAddLink';

import type { PayoutsCardProps } from './types';
import type { FC } from 'react';

const PayoutsCard: FC<PayoutsCardProps> = (props) => {
  const parameters = usePayoutListView();
  const [ref, dimension] = useDimensions();
  const documentationLinkMode: LinkMode = useMemo(
    () => ((dimension?.width ?? 0) < 530 ? 'icon' : 'text_icon'),
    [dimension?.width],
  );
  const createMode: OperationMode = useMemo(
    () => ((dimension?.width ?? 0) < 430 ? 'inline' : 'button'),
    [dimension?.width],
  );

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPagePayouts.PAYOUTS_COMPONENTS_PAYOUTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <PayoutsDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
          <PayoutAddLink data-test={dataTest && `${dataTest}-create`} mode={createMode} />
        </Space>
      }
    >
      <PayoutsTable {...parameters} {...props} />
    </PageCard>
  );
};

const PayoutsCardMemo = React.memo(PayoutsCard);

export default PayoutsCardMemo;
