import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import isNil from 'lodash-es/isNil';
import React from 'react';

import { StatusViewElement } from '@/components';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationAssetStatusViewProps } from './types';

export const donationAssetStatusI18n: Record<'true' | 'false', I18nMessages> = {
  true: I18nFeatureDonations.COMPONENTS_DONATION_ADDRESS_STATUS_VIEW_LABEL_ACTIVE,
  false: I18nFeatureDonations.COMPONENTS_DONATION_ADDRESS_STATUS_VIEW_LABEL_DISABLED,
};

export const donationAssetStatusIcons: Record<'true' | 'false', React.ReactElement> = {
  true: <PlayCircleOutlined style={{ color: 'green' }} />,
  false: <PauseCircleOutlined style={{ color: 'orange' }} />,
};

const DonationAssetStatusView: React.FC<DonationAssetStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) =>
  !isNil(value) ? (
    <StatusViewElement
      data-test={dataTest}
      i18n={donationAssetStatusI18n[`${value}`]}
      icon={donationAssetStatusIcons[`${value}`]}
      mode={mode}
    />
  ) : null;

const DonationAssetStatusViewMemo = React.memo(DonationAssetStatusView);

export default DonationAssetStatusViewMemo;
