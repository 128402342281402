/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MonthlyRevenuePerPlanAPIModel } from './MonthlyRevenuePerPlanAPIModel';
import {
    MonthlyRevenuePerPlanAPIModelFromJSON,
    MonthlyRevenuePerPlanAPIModelFromJSONTyped,
    MonthlyRevenuePerPlanAPIModelToJSON,
} from './MonthlyRevenuePerPlanAPIModel';
import type { MonthlyRevenueRecordAPIModel } from './MonthlyRevenueRecordAPIModel';
import {
    MonthlyRevenueRecordAPIModelFromJSON,
    MonthlyRevenueRecordAPIModelFromJSONTyped,
    MonthlyRevenueRecordAPIModelToJSON,
} from './MonthlyRevenueRecordAPIModel';

/**
 * 
 * @export
 * @interface MonthlyRevenueStatisticsAPIModel
 */
export interface MonthlyRevenueStatisticsAPIModel {
    /**
     * 
     * @type {Array<MonthlyRevenuePerPlanAPIModel>}
     * @memberof MonthlyRevenueStatisticsAPIModel
     */
    perPlan?: Array<MonthlyRevenuePerPlanAPIModel>;
    /**
     * 
     * @type {Array<MonthlyRevenueRecordAPIModel>}
     * @memberof MonthlyRevenueStatisticsAPIModel
     */
    total?: Array<MonthlyRevenueRecordAPIModel>;
    /**
     * 
     * @type {any}
     * @memberof MonthlyRevenueStatisticsAPIModel
     */
    mean?: any;
    /**
     * 
     * @type {any}
     * @memberof MonthlyRevenueStatisticsAPIModel
     */
    median?: any;
}

/**
 * Check if a given object implements the MonthlyRevenueStatisticsAPIModel interface.
 */
export function instanceOfMonthlyRevenueStatisticsAPIModel(value: object): boolean {
    return true;
}

export function MonthlyRevenueStatisticsAPIModelFromJSON(json: any): MonthlyRevenueStatisticsAPIModel {
    return MonthlyRevenueStatisticsAPIModelFromJSONTyped(json, false);
}

export function MonthlyRevenueStatisticsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyRevenueStatisticsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'perPlan': json['perPlan'] == null ? undefined : ((json['perPlan'] as Array<any>).map(MonthlyRevenuePerPlanAPIModelFromJSON)),
        'total': json['total'] == null ? undefined : ((json['total'] as Array<any>).map(MonthlyRevenueRecordAPIModelFromJSON)),
        'mean': json['mean'] == null ? undefined : json['mean'],
        'median': json['median'] == null ? undefined : json['median'],
    };
}

export function MonthlyRevenueStatisticsAPIModelToJSON(value?: MonthlyRevenueStatisticsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'perPlan': value['perPlan'] == null ? undefined : ((value['perPlan'] as Array<any>).map(MonthlyRevenuePerPlanAPIModelToJSON)),
        'total': value['total'] == null ? undefined : ((value['total'] as Array<any>).map(MonthlyRevenueRecordAPIModelToJSON)),
        'mean': value['mean'],
        'median': value['median'],
    };
}

