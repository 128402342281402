import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import AuditBreadcrumb from '@/pages/audit/components/AuditBreadcrumb';

import MerchantWalletsLink from '../MerchantWalletsLink';

import type { MerchantWalletsBreadcrumbProps } from './types';

const MerchantWalletsBreadcrumb: React.FC<MerchantWalletsBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  items = [],
}) => (
  <AuditBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.WALLETS_COMPONENTS_BREADCRUMB} />
        ) : (
          <MerchantWalletsLink
            mode="text"
            title={<FormattedMessage id={I18nPageAudit.WALLETS_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantWalletsBreadcrumbMemo = React.memo(MerchantWalletsBreadcrumb);

export default MerchantWalletsBreadcrumbMemo;
