import { Space } from 'antd';
import React, { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { OperationRefreshMerchantWallets } from '@/features/merchant-wallets/components';
import { useMerchantWallets } from '@/features/merchant-wallets/hooks';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

const selectNetworkType = makeSelectSelectedNetwork();
const withMerchantWalletsHistoryDataLoading = <
  Original extends { data: MerchantWallet[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'> = Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithMerchantWalletsHistoryDataLoading',
  )((props) => {
    const dataTest = props['data-test'];

    const network = useAppSelector(selectNetworkType);
    const { data: walletState, loading, forceRefresh } = useMerchantWallets(network);
    const data = useMemo(
      () => mapStoredState(walletState, (wallets) => wallets.filter(({ isFactoryActive }) => !isFactoryActive)),
      [walletState],
    );
    return withCardDataLoading<MerchantWallet[], Original>({
      data,
      loading,
      forceRefresh,
      'data-test': dataTest,
      title: (
        <Space>
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
          <FormattedMessage id={I18nPageAudit.WALLETS_COMPONENTS_HISTORY_CARD_TITLE} />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshMerchantWallets data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withMerchantWalletsHistoryDataLoading;
