/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { TransactionResultAPIModel } from './TransactionResultAPIModel';
import {
    TransactionResultAPIModelFromJSON,
    TransactionResultAPIModelFromJSONTyped,
    TransactionResultAPIModelToJSON,
} from './TransactionResultAPIModel';

/**
 * 
 * @export
 * @interface MerchantGasHistoryRowAPIModel
 */
export interface MerchantGasHistoryRowAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    txHash: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    sponsorAddress: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    functionName: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    gasPrice: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    gasLimit: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    gasSpent?: string;
    /**
     * 
     * @type {TransactionResultAPIModel}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    result?: TransactionResultAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof MerchantGasHistoryRowAPIModel
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the MerchantGasHistoryRowAPIModel interface.
 */
export function instanceOfMerchantGasHistoryRowAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('txHash' in value)) return false;
    if (!('address' in value)) return false;
    if (!('sponsorAddress' in value)) return false;
    if (!('functionName' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('gasPrice' in value)) return false;
    if (!('gasLimit' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function MerchantGasHistoryRowAPIModelFromJSON(json: any): MerchantGasHistoryRowAPIModel {
    return MerchantGasHistoryRowAPIModelFromJSONTyped(json, false);
}

export function MerchantGasHistoryRowAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantGasHistoryRowAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'txHash': json['txHash'],
        'address': json['address'],
        'sponsorAddress': json['sponsorAddress'],
        'functionName': json['functionName'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'gasPrice': json['gasPrice'],
        'gasLimit': json['gasLimit'],
        'gasSpent': json['gasSpent'] == null ? undefined : json['gasSpent'],
        'result': json['result'] == null ? undefined : TransactionResultAPIModelFromJSON(json['result']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function MerchantGasHistoryRowAPIModelToJSON(value?: MerchantGasHistoryRowAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'txHash': value['txHash'],
        'address': value['address'],
        'sponsorAddress': value['sponsorAddress'],
        'functionName': value['functionName'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'gasPrice': value['gasPrice'],
        'gasLimit': value['gasLimit'],
        'gasSpent': value['gasSpent'],
        'result': TransactionResultAPIModelToJSON(value['result']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

