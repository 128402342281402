import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { PayoutFilterForm, PayoutsDocumentationBanner } from '@/features/payouts/components';
import PayoutsBreadcrumb from '@/pages/payouts/components/PayoutsBreadcrumb';
import PayoutViewLink from '@/pages/payouts/payout-view/components/PayoutViewLink';

import { PayoutsCard, PayoutsTableOperations } from './components';

import type React from 'react';

const dataTest: string | undefined = 'payouts';

const PayoutsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<PayoutsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test="kyb-banner" PageProps={{ pageSize: 'big' }} />
    <PayoutsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <PayoutsCard
      data-test={dataTest}
      PayoutLink={PayoutViewLink}
      FilterForm={PayoutFilterForm}
      Operations={PayoutsTableOperations}
    />
  </PageContainer>
);

export default PayoutsPage;
