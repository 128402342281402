/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import type { CompanySeedPassEncryptedStorageAPIModel } from './CompanySeedPassEncryptedStorageAPIModel';
import {
    instanceOfCompanySeedPassEncryptedStorageAPIModel,
    CompanySeedPassEncryptedStorageAPIModelFromJSON,
    CompanySeedPassEncryptedStorageAPIModelFromJSONTyped,
    CompanySeedPassEncryptedStorageAPIModelToJSON,
} from './CompanySeedPassEncryptedStorageAPIModel';
import type { CompanySeedWeb3EncryptedStorageAPIModel } from './CompanySeedWeb3EncryptedStorageAPIModel';
import {
    instanceOfCompanySeedWeb3EncryptedStorageAPIModel,
    CompanySeedWeb3EncryptedStorageAPIModelFromJSON,
    CompanySeedWeb3EncryptedStorageAPIModelFromJSONTyped,
    CompanySeedWeb3EncryptedStorageAPIModelToJSON,
} from './CompanySeedWeb3EncryptedStorageAPIModel';

/**
 * @type CompanySeedStoragePolicyAPIModel
 * 
 * @export
 */
export type CompanySeedStoragePolicyAPIModel = { policyType: 'CompanySeedPassEncryptedStorage' } & CompanySeedPassEncryptedStorageAPIModel | { policyType: 'CompanySeedWeb3EncryptedStorage' } & CompanySeedWeb3EncryptedStorageAPIModel;

export function CompanySeedStoragePolicyAPIModelFromJSON(json: any): CompanySeedStoragePolicyAPIModel {
    return CompanySeedStoragePolicyAPIModelFromJSONTyped(json, false);
}

export function CompanySeedStoragePolicyAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySeedStoragePolicyAPIModel {
    if (json == null) {
        return json;
    }
    switch (json['policyType']) {
        case 'CompanySeedPassEncryptedStorage':
            return {...CompanySeedPassEncryptedStorageAPIModelFromJSONTyped(json, true), policyType: 'CompanySeedPassEncryptedStorage'};
        case 'CompanySeedWeb3EncryptedStorage':
            return {...CompanySeedWeb3EncryptedStorageAPIModelFromJSONTyped(json, true), policyType: 'CompanySeedWeb3EncryptedStorage'};
        default:
            throw new Error(`No variant of CompanySeedStoragePolicyAPIModel exists with 'policyType=${json['policyType']}'`);
    }
}

export function CompanySeedStoragePolicyAPIModelToJSON(value?: CompanySeedStoragePolicyAPIModel | null): any {
    if (value == null) {
        return value;
    }
    switch (value['policyType']) {
        case 'CompanySeedPassEncryptedStorage':
            return CompanySeedPassEncryptedStorageAPIModelToJSON(value);
        case 'CompanySeedWeb3EncryptedStorage':
            return CompanySeedWeb3EncryptedStorageAPIModelToJSON(value);
        default:
            throw new Error(`No variant of CompanySeedStoragePolicyAPIModel exists with 'policyType=${value['policyType']}'`);
    }

}

