import { CheckCircleOutlined, CloudServerOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { ContractDeployStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import type { MerchantWalletDeployTransactionStatusViewProps } from './types';

const i18n: Record<ContractDeployStatusAPIModel, I18nMessages> = {
  [ContractDeployStatusAPIModel.Pending]: I18nFeatureMerchantWallets.COMPONENTS_DEPLOY_TRANSACTION_STATUS_VIEW_PENDING,
  [ContractDeployStatusAPIModel.Succeeded]:
    I18nFeatureMerchantWallets.COMPONENTS_DEPLOY_TRANSACTION_STATUS_VIEW_SUCCEEDED,
  [ContractDeployStatusAPIModel.Failed]: I18nFeatureMerchantWallets.COMPONENTS_DEPLOY_TRANSACTION_STATUS_VIEW_FAILED,
};

const icons: Record<ContractDeployStatusAPIModel, React.ReactNode> = {
  [ContractDeployStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <CloudServerOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [ContractDeployStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [ContractDeployStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const MerchantWalletDeployTransactionStatusView: React.FC<MerchantWalletDeployTransactionStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
  style,
  className,
}) =>
  value ? (
    <StatusViewElement
      data-test={dataTest}
      i18n={i18n[value]}
      icon={icons[value]}
      mode={mode}
      style={style}
      className={className}
    />
  ) : null;

const MerchantWalletDeployTransactionStatusViewMemo = React.memo(MerchantWalletDeployTransactionStatusView);

export default MerchantWalletDeployTransactionStatusViewMemo;
