import { type UseAppListDataView, useAppListDataView } from '@/app/hooks';
import { storeCollectAvailableBalanceListParameters } from '@/features/collectable/actions';
import type { CollectableBalance, CollectableBalanceFilterPredicate } from '@/features/collectable/types';
import type { CollectableAddressBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useCollectAvailableBalanceList from '../useCollectAvailableBalanceList';

export type UseCollectAvailableBalanceListView = UseAppListDataView<
  CollectableBalance,
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel
>;

export default function useCollectAvailableBalanceListView(): UseCollectAvailableBalanceListView {
  return useAppListDataView(useCollectAvailableBalanceList(), storeCollectAvailableBalanceListParameters);
}
