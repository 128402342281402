import React from 'react';

import { QRDelegateContractIssueBanner } from '@/features/company-settings/components';
import type { AssetSelectIssueRender } from '@/features/dictionary/blockchain/components/AssetSelectItem/types';

const AssetForPaymentQRIssueBanner: AssetSelectIssueRender = ({ 'data-test': dataTest, assetId }) => (
  <QRDelegateContractIssueBanner
    data-test={dataTest && `${dataTest}-${assetId}-banner`}
    assetId={assetId}
    mode="message"
    type="link"
  />
);

const AssetForPaymentQRIssueBannerMemo = React.memo(AssetForPaymentQRIssueBanner);

export default AssetForPaymentQRIssueBannerMemo;
