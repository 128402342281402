import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import SettlementsHistoryPage from './history';
import SettlementIntentPage from './intent-view';
import PendingIntentsPage from './pending-intents';
import { settlementsHistoryLink, SettlementsPageRoutes } from './routes';
import SettlementSchedulePage from './schedule';
import SettlementViewPage from './settlement-view';

import type React from 'react';

export const SettlementsDefaultRoute: React.FC = () => <Navigate to={settlementsHistoryLink()} replace />;

const SettlementPagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={SettlementsPageRoutes.HISTORY} Component={SettlementsHistoryPage} />
        <Route path={SettlementsPageRoutes.INTENT_VIEW} Component={SettlementIntentPage} />
        <Route path={SettlementsPageRoutes.SETTLEMENT_VIEW} Component={SettlementViewPage} />
        <Route path={SettlementsPageRoutes.PENDING_INTENTS} Component={PendingIntentsPage} />
        <Route path={SettlementsPageRoutes.SCHEDULE} Component={SettlementSchedulePage} />
        <Route path={SettlementsPageRoutes.ASSET_SCHEDULE} Component={SettlementSchedulePage} />
        <Route path="*" Component={SettlementsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const SettlementPages: React.FC = withBasicPageGuard(SettlementPagesRaw);

export default (
  <Route key={AppPageRoutes.SETTLEMENTS} path={`${AppPageRoutes.SETTLEMENTS}/*`} Component={SettlementPages} />
);
