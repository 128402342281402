import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshMerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/components';
import { useMerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/hooks';
import type { MerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/types';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withMerchantWalletRunningBalanceDataLoading = <
  Original extends { data: MerchantWalletRunningBalance } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { balanceId: string } = Omit<Original, 'data'> & { balanceId: string },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithMerchantWalletRunningBalanceDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useMerchantWalletRunningBalance(props.balanceId);
    return withCardDataLoading<MerchantWalletRunningBalance, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageAudit.BALANCE_VIEW_COMPONENTS_CARD} values={{ id: props.balanceId }} />,
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshMerchantWalletRunningBalance
              id={props.balanceId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withMerchantWalletRunningBalanceDataLoading;
