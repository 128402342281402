/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DonationPredicatesAPIModel } from './DonationPredicatesAPIModel';
import {
    DonationPredicatesAPIModelFromJSON,
    DonationPredicatesAPIModelFromJSONTyped,
    DonationPredicatesAPIModelToJSON,
} from './DonationPredicatesAPIModel';
import type { PageDonationSortByAPIModel } from './PageDonationSortByAPIModel';
import {
    PageDonationSortByAPIModelFromJSON,
    PageDonationSortByAPIModelFromJSONTyped,
    PageDonationSortByAPIModelToJSON,
} from './PageDonationSortByAPIModel';

/**
 * 
 * @export
 * @interface DonationFilterAPIModel
 */
export interface DonationFilterAPIModel {
    /**
     * 
     * @type {DonationPredicatesAPIModel}
     * @memberof DonationFilterAPIModel
     */
    predicates: DonationPredicatesAPIModel;
    /**
     * 
     * @type {PageDonationSortByAPIModel}
     * @memberof DonationFilterAPIModel
     */
    page: PageDonationSortByAPIModel;
}

/**
 * Check if a given object implements the DonationFilterAPIModel interface.
 */
export function instanceOfDonationFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function DonationFilterAPIModelFromJSON(json: any): DonationFilterAPIModel {
    return DonationFilterAPIModelFromJSONTyped(json, false);
}

export function DonationFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': DonationPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageDonationSortByAPIModelFromJSON(json['page']),
    };
}

export function DonationFilterAPIModelToJSON(value?: DonationFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': DonationPredicatesAPIModelToJSON(value['predicates']),
        'page': PageDonationSortByAPIModelToJSON(value['page']),
    };
}

