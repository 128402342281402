import { List, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { ReadonlyDateTime } from '@/components';
import { TransactionLink } from '@/features/dictionary/blockchain/components';
import { MerchantWalletDeployTransactionStatusView } from '@/features/merchant-wallets/components';
import type { MerchantWalletDeployTransaction } from '@/features/merchant-wallets/types';
import { stringComparator } from '@/infrastructure/model/comparators';

import type { MerchantWalletDeployTransactionsListProps } from './types';

const style: React.CSSProperties = { maxHeight: 400, overflowY: 'auto', width: '100%' };

const walletComparator = stringComparator<MerchantWalletDeployTransaction>(
  ({ blockchain, address }) => `${blockchain}_${address}`,
)('ASC');

const MerchantWalletDeployTransactionsList: React.FC<MerchantWalletDeployTransactionsListProps> = ({
  'data-test': dataTest,
  data,
  WalletLink,
  Operations,
}) => {
  const orderedData = useMemo(() => [...data].sort(walletComparator), [data]);
  return (
    <List
      data-test={dataTest}
      itemLayout="horizontal"
      loadMore={false}
      style={style}
      dataSource={orderedData}
      renderItem={useCallback(
        (tx: MerchantWalletDeployTransaction) => {
          const id = `${tx.blockchain}_${tx.address}`;
          return (
            <List.Item
              key={id}
              actions={[
                <Space key="actions">
                  {WalletLink && (
                    <WalletLink
                      data-test={dataTest && `${dataTest}-${id}-link`}
                      value={{ bt: tx.blockchain, address: tx.address }}
                    />
                  )}
                  {Operations && <Operations data-test={dataTest && `${dataTest}-${id}-operations`} value={tx} />}
                </Space>,
              ]}
            >
              <List.Item.Meta
                style={{ alignItems: 'center' }}
                avatar={
                  <MerchantWalletDeployTransactionStatusView
                    data-test={dataTest && `${dataTest}-${id}-status`}
                    style={{ fontSize: 32 }}
                    value={tx.status}
                    mode="text"
                  />
                }
                title={
                  <ReadonlyDateTime
                    value={tx.updatedAt}
                    style={{
                      display: 'inline-flex',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  />
                }
                description={
                  tx.hash ? (
                    <TransactionLink data-test={dataTest && `${dataTest}-${id}-hash`} bt={tx.blockchain} tx={tx.hash} />
                  ) : null
                }
              />
            </List.Item>
          );
        },
        [WalletLink, Operations, dataTest],
      )}
    />
  );
};

const MerchantWalletDeployTransactionsListMemo = React.memo(MerchantWalletDeployTransactionsList);

export default MerchantWalletDeployTransactionsListMemo;
