/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { WithdrawalJobStatusAPIModel } from './WithdrawalJobStatusAPIModel';
import {
    WithdrawalJobStatusAPIModelFromJSON,
    WithdrawalJobStatusAPIModelFromJSONTyped,
    WithdrawalJobStatusAPIModelToJSON,
} from './WithdrawalJobStatusAPIModel';
import type { WithdrawalReconciliationStatusAPIModel } from './WithdrawalReconciliationStatusAPIModel';
import {
    WithdrawalReconciliationStatusAPIModelFromJSON,
    WithdrawalReconciliationStatusAPIModelFromJSONTyped,
    WithdrawalReconciliationStatusAPIModelToJSON,
} from './WithdrawalReconciliationStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalPredicatesAPIModel
 */
export interface WithdrawalPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof WithdrawalPredicatesAPIModel
     */
    ids?: Array<string>;
    /**
     * 
     * @type {Array<WithdrawalJobStatusAPIModel>}
     * @memberof WithdrawalPredicatesAPIModel
     */
    statuses?: Array<WithdrawalJobStatusAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WithdrawalPredicatesAPIModel
     */
    hashes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalPredicatesAPIModel
     */
    isFull?: boolean;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof WithdrawalPredicatesAPIModel
     */
    network?: BlockchainNetworkTypeAPIModel;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof WithdrawalPredicatesAPIModel
     */
    btEq?: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalPredicatesAPIModel
     */
    asset?: string;
    /**
     * 
     * @type {Array<WithdrawalReconciliationStatusAPIModel>}
     * @memberof WithdrawalPredicatesAPIModel
     */
    reconciliationStatusIn?: Array<WithdrawalReconciliationStatusAPIModel>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof WithdrawalPredicatesAPIModel
     */
    withdrawnAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawalPredicatesAPIModel
     */
    isPayout?: boolean;
}

/**
 * Check if a given object implements the WithdrawalPredicatesAPIModel interface.
 */
export function instanceOfWithdrawalPredicatesAPIModel(value: object): boolean {
    return true;
}

export function WithdrawalPredicatesAPIModelFromJSON(json: any): WithdrawalPredicatesAPIModel {
    return WithdrawalPredicatesAPIModelFromJSONTyped(json, false);
}

export function WithdrawalPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'] == null ? undefined : json['ids'],
        'statuses': json['statuses'] == null ? undefined : ((json['statuses'] as Array<any>).map(WithdrawalJobStatusAPIModelFromJSON)),
        'hashes': json['hashes'] == null ? undefined : json['hashes'],
        'isFull': json['isFull'] == null ? undefined : json['isFull'],
        'network': json['network'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['network']),
        'btEq': json['btEq'] == null ? undefined : BlockchainTypeAPIModelFromJSON(json['btEq']),
        'asset': json['asset'] == null ? undefined : json['asset'],
        'reconciliationStatusIn': json['reconciliationStatusIn'] == null ? undefined : ((json['reconciliationStatusIn'] as Array<any>).map(WithdrawalReconciliationStatusAPIModelFromJSON)),
        'withdrawnAtRange': json['withdrawnAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['withdrawnAtRange']),
        'isPayout': json['isPayout'] == null ? undefined : json['isPayout'],
    };
}

export function WithdrawalPredicatesAPIModelToJSON(value?: WithdrawalPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ids': value['ids'],
        'statuses': value['statuses'] == null ? undefined : ((value['statuses'] as Array<any>).map(WithdrawalJobStatusAPIModelToJSON)),
        'hashes': value['hashes'],
        'isFull': value['isFull'],
        'network': BlockchainNetworkTypeAPIModelToJSON(value['network']),
        'btEq': BlockchainTypeAPIModelToJSON(value['btEq']),
        'asset': value['asset'],
        'reconciliationStatusIn': value['reconciliationStatusIn'] == null ? undefined : ((value['reconciliationStatusIn'] as Array<any>).map(WithdrawalReconciliationStatusAPIModelToJSON)),
        'withdrawnAtRange': DateTimeRangeAPIModelToJSON(value['withdrawnAtRange']),
        'isPayout': value['isPayout'],
    };
}

