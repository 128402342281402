/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  BlockchainTypeAPIModel,
  MerchantWalletAPIModel,
  MerchantWalletDeployTransactionsAPIModel,
  MerchantWalletSignatureAPIModel,
  MerchantWalletSignatureCreateAPIModel,
  MerchantWalletsAPIModel,
  UserCompaniesAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelToJSON,
    MerchantWalletAPIModelFromJSON,
    MerchantWalletAPIModelToJSON,
    MerchantWalletDeployTransactionsAPIModelFromJSON,
    MerchantWalletDeployTransactionsAPIModelToJSON,
    MerchantWalletSignatureAPIModelFromJSON,
    MerchantWalletSignatureAPIModelToJSON,
    MerchantWalletSignatureCreateAPIModelFromJSON,
    MerchantWalletSignatureCreateAPIModelToJSON,
    MerchantWalletsAPIModelFromJSON,
    MerchantWalletsAPIModelToJSON,
    UserCompaniesAPIModelFromJSON,
    UserCompaniesAPIModelToJSON,
} from '../models/index';

export interface ConfirmOwnedCompanyWalletOwnershipRequest {
    merchantWalletSignatureCreateAPIModel: MerchantWalletSignatureCreateAPIModel;
}

export interface GetWalletDeployTransactionsRequest {
    blockchain: BlockchainTypeAPIModel;
    address: string;
}

export interface RefreshMerchantWalletRequest {
    blockchain: BlockchainTypeAPIModel;
    body: object;
    address?: string;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * Saves merchant wallet ownership confirmation for owned company
     * @param {MerchantWalletSignatureCreateAPIModel} merchantWalletSignatureCreateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    confirmOwnedCompanyWalletOwnershipRaw(requestParameters: ConfirmOwnedCompanyWalletOwnershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>>;

    /**
     * Saves merchant wallet ownership confirmation for owned company
     */
    confirmOwnedCompanyWalletOwnership(merchantWalletSignatureCreateAPIModel: MerchantWalletSignatureCreateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel>;

    /**
     * Returns currently authenticated user merchant wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMerchantWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletsAPIModel>>;

    /**
     * Returns currently authenticated user merchant wallets
     */
    getMerchantWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletsAPIModel>;

    /**
     * Returns merchant wallet ownership confirmation for owned company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getOwnedCompanyWalletSignatureRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>>;

    /**
     * Returns merchant wallet ownership confirmation for owned company
     */
    getOwnedCompanyWalletSignature(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel>;

    /**
     * Returns companies that this user has access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompaniesAPIModel>>;

    /**
     * Returns companies that this user has access to
     */
    getUserCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompaniesAPIModel>;

    /**
     * Returns currently authenticated user merchant wallet deploy transactions
     * @param {BlockchainTypeAPIModel} blockchain 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getWalletDeployTransactionsRaw(requestParameters: GetWalletDeployTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletDeployTransactionsAPIModel>>;

    /**
     * Returns currently authenticated user merchant wallet deploy transactions
     */
    getWalletDeployTransactions(blockchain: BlockchainTypeAPIModel, address: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletDeployTransactionsAPIModel>;

    /**
     * Force refresh merchant wallet on blockchain (refreshes the active factory one if no address provided)
     * @param {BlockchainTypeAPIModel} blockchain 
     * @param {object} body 
     * @param {string} [address] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    refreshMerchantWalletRaw(requestParameters: RefreshMerchantWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletAPIModel>>;

    /**
     * Force refresh merchant wallet on blockchain (refreshes the active factory one if no address provided)
     */
    refreshMerchantWallet(blockchain: BlockchainTypeAPIModel, body: object, address?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletAPIModel>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * Saves merchant wallet ownership confirmation for owned company
     */
    async confirmOwnedCompanyWalletOwnershipRaw(requestParameters: ConfirmOwnedCompanyWalletOwnershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>> {
        if (requestParameters['merchantWalletSignatureCreateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantWalletSignatureCreateAPIModel',
                'Required parameter "merchantWalletSignatureCreateAPIModel" was null or undefined when calling confirmOwnedCompanyWalletOwnership().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/owned-company/signature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantWalletSignatureCreateAPIModelToJSON(requestParameters['merchantWalletSignatureCreateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletSignatureAPIModelFromJSON(jsonValue));
    }

    /**
     * Saves merchant wallet ownership confirmation for owned company
     */
    async confirmOwnedCompanyWalletOwnership(merchantWalletSignatureCreateAPIModel: MerchantWalletSignatureCreateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel> {
        const response = await this.confirmOwnedCompanyWalletOwnershipRaw({ merchantWalletSignatureCreateAPIModel: merchantWalletSignatureCreateAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns currently authenticated user merchant wallets
     */
    async getMerchantWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns currently authenticated user merchant wallets
     */
    async getMerchantWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletsAPIModel> {
        const response = await this.getMerchantWalletsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant wallet ownership confirmation for owned company
     */
    async getOwnedCompanyWalletSignatureRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletSignatureAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/owned-company/signature`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletSignatureAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant wallet ownership confirmation for owned company
     */
    async getOwnedCompanyWalletSignature(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletSignatureAPIModel> {
        const response = await this.getOwnedCompanyWalletSignatureRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns companies that this user has access to
     */
    async getUserCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompaniesAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompaniesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns companies that this user has access to
     */
    async getUserCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompaniesAPIModel> {
        const response = await this.getUserCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns currently authenticated user merchant wallet deploy transactions
     */
    async getWalletDeployTransactionsRaw(requestParameters: GetWalletDeployTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletDeployTransactionsAPIModel>> {
        if (requestParameters['blockchain'] == null) {
            throw new runtime.RequiredError(
                'blockchain',
                'Required parameter "blockchain" was null or undefined when calling getWalletDeployTransactions().'
            );
        }

        if (requestParameters['address'] == null) {
            throw new runtime.RequiredError(
                'address',
                'Required parameter "address" was null or undefined when calling getWalletDeployTransactions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets/{blockchain}/{address}/deploy-transactions`.replace(`{${"blockchain"}}`, encodeURIComponent(String(requestParameters['blockchain']))).replace(`{${"address"}}`, encodeURIComponent(String(requestParameters['address']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletDeployTransactionsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns currently authenticated user merchant wallet deploy transactions
     */
    async getWalletDeployTransactions(blockchain: BlockchainTypeAPIModel, address: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletDeployTransactionsAPIModel> {
        const response = await this.getWalletDeployTransactionsRaw({ blockchain: blockchain, address: address }, initOverrides);
        return await response.value();
    }

    /**
     * Force refresh merchant wallet on blockchain (refreshes the active factory one if no address provided)
     */
    async refreshMerchantWalletRaw(requestParameters: RefreshMerchantWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantWalletAPIModel>> {
        if (requestParameters['blockchain'] == null) {
            throw new runtime.RequiredError(
                'blockchain',
                'Required parameter "blockchain" was null or undefined when calling refreshMerchantWallet().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling refreshMerchantWallet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['address'] != null) {
            queryParameters['address'] = requestParameters['address'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets/{blockchain}/refresh`.replace(`{${"blockchain"}}`, encodeURIComponent(String(requestParameters['blockchain']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantWalletAPIModelFromJSON(jsonValue));
    }

    /**
     * Force refresh merchant wallet on blockchain (refreshes the active factory one if no address provided)
     */
    async refreshMerchantWallet(blockchain: BlockchainTypeAPIModel, body: object, address?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantWalletAPIModel> {
        const response = await this.refreshMerchantWalletRaw({ blockchain: blockchain, body: body, address: address }, initOverrides);
        return await response.value();
    }

}
