/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BigDecimalRangeAPIModel } from './BigDecimalRangeAPIModel';
import {
    BigDecimalRangeAPIModelFromJSON,
    BigDecimalRangeAPIModelFromJSONTyped,
    BigDecimalRangeAPIModelToJSON,
} from './BigDecimalRangeAPIModel';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { CollectableAddressBalanceStatusAPIModel } from './CollectableAddressBalanceStatusAPIModel';
import {
    CollectableAddressBalanceStatusAPIModelFromJSON,
    CollectableAddressBalanceStatusAPIModelFromJSONTyped,
    CollectableAddressBalanceStatusAPIModelToJSON,
} from './CollectableAddressBalanceStatusAPIModel';
import type { CollectableKindAPIModel } from './CollectableKindAPIModel';
import {
    CollectableKindAPIModelFromJSON,
    CollectableKindAPIModelFromJSONTyped,
    CollectableKindAPIModelToJSON,
} from './CollectableKindAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';

/**
 * 
 * @export
 * @interface CollectableAddressBalancePredicatesAPIModel
 */
export interface CollectableAddressBalancePredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {Array<CollectableKindAPIModel>}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    kindIn?: Array<CollectableKindAPIModel>;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {CollectableAddressBalanceStatusAPIModel}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    statusEq?: CollectableAddressBalanceStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    addressLike?: string;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    calculatedAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BigDecimalRangeAPIModel}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    balanceRange?: BigDecimalRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof CollectableAddressBalancePredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the CollectableAddressBalancePredicatesAPIModel interface.
 */
export function instanceOfCollectableAddressBalancePredicatesAPIModel(value: object): boolean {
    return true;
}

export function CollectableAddressBalancePredicatesAPIModelFromJSON(json: any): CollectableAddressBalancePredicatesAPIModel {
    return CollectableAddressBalancePredicatesAPIModelFromJSONTyped(json, false);
}

export function CollectableAddressBalancePredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectableAddressBalancePredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'kindIn': json['kindIn'] == null ? undefined : ((json['kindIn'] as Array<any>).map(CollectableKindAPIModelFromJSON)),
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'statusEq': json['statusEq'] == null ? undefined : CollectableAddressBalanceStatusAPIModelFromJSON(json['statusEq']),
        'addressLike': json['addressLike'] == null ? undefined : json['addressLike'],
        'calculatedAtRange': json['calculatedAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['calculatedAtRange']),
        'balanceRange': json['balanceRange'] == null ? undefined : BigDecimalRangeAPIModelFromJSON(json['balanceRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function CollectableAddressBalancePredicatesAPIModelToJSON(value?: CollectableAddressBalancePredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'kindIn': value['kindIn'] == null ? undefined : ((value['kindIn'] as Array<any>).map(CollectableKindAPIModelToJSON)),
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'statusEq': CollectableAddressBalanceStatusAPIModelToJSON(value['statusEq']),
        'addressLike': value['addressLike'],
        'calculatedAtRange': DateTimeRangeAPIModelToJSON(value['calculatedAtRange']),
        'balanceRange': BigDecimalRangeAPIModelToJSON(value['balanceRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

