import React from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { usePayment } from '@/features/payments/hooks';
import { CollectableKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { paymentViewLink } from '@/pages/payments/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { PaymentEntityLinkProps } from './types';

const PaymentEntityLink: React.FC<PaymentEntityLinkProps> = ({ 'data-test': dataTest, value }) => {
  const data = usePayment(value.id);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data) {
    return <Navigate to={paymentViewLink(value.id)} replace />;
  }

  return (
    <EntityNotFoundAlert data-test={dataTest} value={CollectableKindAPIModel.Forwarder} onRetry={data.forceRefresh} />
  );
};

const PaymentEntityLinkMemo = React.memo(PaymentEntityLink);

export default PaymentEntityLinkMemo;
