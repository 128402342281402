/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { NewPayoutDestinationAPIModel } from './NewPayoutDestinationAPIModel';
import {
    NewPayoutDestinationAPIModelFromJSON,
    NewPayoutDestinationAPIModelFromJSONTyped,
    NewPayoutDestinationAPIModelToJSON,
} from './NewPayoutDestinationAPIModel';
import type { PayoutTypeAPIModel } from './PayoutTypeAPIModel';
import {
    PayoutTypeAPIModelFromJSON,
    PayoutTypeAPIModelFromJSONTyped,
    PayoutTypeAPIModelToJSON,
} from './PayoutTypeAPIModel';

/**
 * 
 * @export
 * @interface PayoutUpdateAPIModel
 */
export interface PayoutUpdateAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PayoutUpdateAPIModel
     */
    id: string;
    /**
     * 
     * @type {PayoutTypeAPIModel}
     * @memberof PayoutUpdateAPIModel
     */
    mode: PayoutTypeAPIModel;
    /**
     * 
     * @type {Array<NewPayoutDestinationAPIModel>}
     * @memberof PayoutUpdateAPIModel
     */
    destinations?: Array<NewPayoutDestinationAPIModel>;
}

/**
 * Check if a given object implements the PayoutUpdateAPIModel interface.
 */
export function instanceOfPayoutUpdateAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('mode' in value)) return false;
    return true;
}

export function PayoutUpdateAPIModelFromJSON(json: any): PayoutUpdateAPIModel {
    return PayoutUpdateAPIModelFromJSONTyped(json, false);
}

export function PayoutUpdateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutUpdateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mode': PayoutTypeAPIModelFromJSON(json['mode']),
        'destinations': json['destinations'] == null ? undefined : ((json['destinations'] as Array<any>).map(NewPayoutDestinationAPIModelFromJSON)),
    };
}

export function PayoutUpdateAPIModelToJSON(value?: PayoutUpdateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'mode': PayoutTypeAPIModelToJSON(value['mode']),
        'destinations': value['destinations'] == null ? undefined : ((value['destinations'] as Array<any>).map(NewPayoutDestinationAPIModelToJSON)),
    };
}

