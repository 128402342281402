import { Form } from 'antd';
import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { assertNotNil, noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { asType, type Func, nameof, notEmpty } from '@/infrastructure/utils/ts';

import { DonationAssetsItem, DonationDescriptionItem, DonationImageItem, DonationTitleItem } from './components';

import type { EditDonationFormAssetData, EditDonationFormData, EditDonationFormProps } from './types';
import type { FormInstance } from 'antd/es/form';

const isSomeAssetsChanged = (formAssets: EditDonationFormAssetData[], addresses?: EditDonationFormAssetData[]) => {
  if (formAssets.length !== addresses?.length) {
    return true;
  }

  if (
    addresses.find(
      ({ asset, isActive }) =>
        !formAssets.find((formAsset) => formAsset.asset === asset && formAsset.isActive === isActive),
    )
  ) {
    return true;
  }

  return !!formAssets.find(({ asset, defaultAmounts }) => {
    const addressAmounts = addresses.find((address) => address.asset === asset)?.defaultAmounts ?? [];
    if (addressAmounts.length !== defaultAmounts.filter(notEmpty).length) {
      return true;
    }

    return !!addressAmounts.find(
      (addressAmount) =>
        !defaultAmounts.filter(notEmpty).find((tokenAmount) => tokenAmount.value.eq(addressAmount.value)),
    );
  });
};

const formMessages = {
  submit: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_SUBMIT_BUTTON} tagName="span" />,
};

const baseRequiredFields = [
  nameof<EditDonationFormData>('title'),
  nameof<EditDonationFormData>('description'),
  nameof<EditDonationFormData>('assets'),
];

const EditDonationForm: React.FC<EditDonationFormProps> = ({
  'data-test': dataTest,
  isNew,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  initialValues,
  style,
  className,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const previousValues = useRef(initialValues);
  const { form, withResetForm } = useForm<EditDonationFormData>();
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useMemo(
    () =>
      withSubmitting(async ({ title, description, ...value }: EditDonationFormData) => {
        assertNotNil(title, () => new Error('invalid form'));
        assertNotNil(description, () => new Error('invalid form'));
        await onSubmit({ ...value, title: title.trim(), description: description.trim() });
        setTimeout(doReset, 0);
      }),
    [doReset, onSubmit, withSubmitting],
  );

  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const [isBeingEdited, setBeingEdited] = useStateMountSafe(false);
  const [isFormReady, setForReady] = useStateMountSafe(false);

  const checkIsComplete = useMemo(() => {
    const values = initialValues;
    if (!values || isNew) {
      return () => true;
    }
    return ({ getFieldValue }: FormInstance<EditDonationFormData>) => {
      const formAssets: EditDonationFormAssetData[] = getFieldValue(nameof<EditDonationFormData>('assets')) || [];
      const formTitle = asType<string | undefined>(getFieldValue(nameof<EditDonationFormData>('title')))?.trim();
      const formDescription = asType<string | undefined>(
        getFieldValue(nameof<EditDonationFormData>('description')),
      )?.trim();
      const formImageKey: string | undefined = getFieldValue(nameof<EditDonationFormData>('imageKey'));
      return (
        values.title !== formTitle
        || values.description !== formDescription
        || !!formImageKey
        || isSomeAssetsChanged(formAssets, initialValues.assets)
      );
    };
  }, [initialValues, isNew]);

  const [doSubmitForm, setDoSubmitForm] = useStateMountSafe<Func | undefined>(undefined);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const isValueNonInitial = !initialValues || !isEqual(initialValues, value);
      const isCompleteAndUpdated = complete && isValueNonInitial;
      if (isFormComplete !== isCompleteAndUpdated) {
        setFormComplete(isCompleteAndUpdated);
      }
    },
    [form, initialValues, isFormComplete, setFormComplete],
  );
  useEffect(() => {
    const value = form.getFieldsValue();
    const isValueUpdated = !previousValues.current || !isEqual(previousValues.current, value);
    const readiness = isFormComplete && !isBeingEdited;
    if (readiness !== isFormReady) {
      setForReady(readiness);
    }
    if (isValueUpdated) {
      previousValues.current = value;
    }
    if (readiness !== isFormReady || isValueUpdated) {
      const submitFn = readiness
        ? withVoidOrThrow(
            wrap(
              async () => doSubmit(value),
              () => onSubmitting?.(true),
              () => onSubmitting?.(false),
            ),
          )
        : undefined;
      submitCallback?.(submitFn);
      setDoSubmitForm(() => submitFn);
    }
  }, [
    doSubmit,
    doSubmitForm,
    form,
    isBeingEdited,
    isFormComplete,
    isFormReady,
    onSubmitting,
    setDoSubmitForm,
    setForReady,
    submitCallback,
  ]);

  const requiredFields = useMemo(
    () => (isNew ? [...baseRequiredFields, nameof<EditDonationFormData>('imageKey')] : baseRequiredFields),
    [isNew],
  );
  const fieldsToValidate = useMemo(() => (isNew ? [] : [nameof<EditDonationFormData>('imageKey')]), [isNew]);

  return (
    <Form<EditDonationFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      style={style}
      className={className}
      {...layout}
      initialValues={initialValues}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <DonationTitleItem
        name={nameof<EditDonationFormData>('title')}
        data-test={dataTest && `${dataTest}-title`}
        required
      />
      <DonationDescriptionItem
        name={nameof<EditDonationFormData>('description')}
        data-test={dataTest && `${dataTest}-description`}
        required
      />
      <DonationImageItem
        name={nameof<EditDonationFormData>('imageKey')}
        data-test={dataTest && `${dataTest}-image`}
        required={isNew}
      />
      <DonationAssetsItem
        name={nameof<EditDonationFormData>('assets')}
        data-test={dataTest && `${dataTest}-assets`}
        onStateChange={setBeingEdited}
        required
      />
      <FormCompletenessItem<EditDonationFormData>
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateComplete}
        checkIsComplete={checkIsComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { display: 'none' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const EditDonationFormMemo = React.memo(EditDonationForm);

export default EditDonationFormMemo;
