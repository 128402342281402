import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import { withCollectableEntityPageParams } from '@/pages/balances/hocs';
import type { CollectableBalanceEntityParams } from '@/pages/balances/hocs/withCollectableEntityPageParams';

import { CollectableBalanceEntityBreadcrumb, CollectableBalanceEntityCard } from './components';

import type React from 'react';

type CollectableBalanceEntityPageProps = { pageParams: CollectableBalanceEntityParams } & TestableProps;

const CollectableBalanceEntityPage: React.FC<CollectableBalanceEntityPageProps> = ({
  'data-test': dataTest = 'collectable',
  pageParams,
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <CollectableBalanceEntityBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled id={pageParams} />
    }
  >
    <CollectableBalanceEntityCard data-test={dataTest && `${dataTest}-entity`} id={pageParams} />
  </PageContainer>
);

const CollectableBalanceEntityPageWithParam = withCollectableEntityPageParams(CollectableBalanceEntityPage);

export default CollectableBalanceEntityPageWithParam;
