import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum AuditPageRoutes {
  BALANCES = 'balance',
  BALANCE_VIEW = 'balance/:balanceId/view',
  TRANSFERS = 'transfers',
  TRANSFER_VIEW = 'transfers/:transferId/view',
  TRANSFER_ENTITY = 'transfers/:transferId/entity',
  QR_CONTRACTS = 'qr-contracts',
  QR_CONTRACT_VIEW = 'qr-contracts/:bt/view',
  WALLETS = 'wallets',
  WALLET_VIEW = 'wallets/:bt/view',
}

export const auditRoute = (page: AuditPageRoutes) => `${appRoute(AppPageRoutes.AUDIT)}/${page}`;

export const auditWalletTransfersLink = () => `${appRoute(AppPageRoutes.AUDIT)}/${AuditPageRoutes.TRANSFERS}`;
export const auditWalletTransferViewLink = (id: string) => `${auditWalletTransfersLink()}/${id}/view`;
export const auditWalletTransferEntityLink = (id: string) => `${auditWalletTransfersLink()}/${id}/entity`;

export const auditWalletRunningBalancesLink = () => `${appRoute(AppPageRoutes.AUDIT)}/${AuditPageRoutes.BALANCES}`;
export const auditWalletRunningBalanceViewLink = (id: string) => `${auditWalletRunningBalancesLink()}/${id}/view`;

export const auditWalletsLink = () => `${appRoute(AppPageRoutes.AUDIT)}/${AuditPageRoutes.WALLETS}`;
export const auditWalletViewLink = (bt: BlockchainTypeAPIModel, address?: string) =>
  `${auditWalletsLink()}/${bt}/view${address ? `?address=${address}` : ''}`;

export const auditQRDelegateContractsLink = () => `${appRoute(AppPageRoutes.AUDIT)}/${AuditPageRoutes.QR_CONTRACTS}`;
export const auditQRDelegateContractViewLink = (bt: BlockchainTypeAPIModel) =>
  `${auditQRDelegateContractsLink()}/${bt}/view`;
