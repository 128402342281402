import type { User } from '@/features/user/types';
import type { Web3AddressAuthParamsAPIModel, Web3CredentialsAPIModel } from '@/generated/api/ncps-api';
import type { InitState, LoadingStateWithDirty } from '@/infrastructure/model';
import type { SingleState } from '@/infrastructure/model/single/types';
import type { JWTTokenWithInfo } from '@/infrastructure/security/jwt/types';

export const NAMESPACE = 'auth';

export enum AuthStatus {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  PROCESSING = 'PROCESSING',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface JWTTokenWithUser {
  token: string;
  user: User;
}

export type Web3AddressAuthAuthParameters = Web3AddressAuthParamsAPIModel;
export type Web3Credentials = Web3CredentialsAPIModel;

export interface AuthState {
  status: AuthStatus;
  token: LoadingStateWithDirty<JWTTokenWithInfo>;

  addressSignupStatus: SingleState<boolean>; // by address;
  emailResetAllowance: SingleState<boolean>; // by email

  termsOfService: InitState<boolean>;
}
