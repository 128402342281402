/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSubscriptionChargeSortByAPIModel } from './PageSubscriptionChargeSortByAPIModel';
import {
    PageSubscriptionChargeSortByAPIModelFromJSON,
    PageSubscriptionChargeSortByAPIModelFromJSONTyped,
    PageSubscriptionChargeSortByAPIModelToJSON,
} from './PageSubscriptionChargeSortByAPIModel';
import type { SubscriptionChargePredicatesAPIModel } from './SubscriptionChargePredicatesAPIModel';
import {
    SubscriptionChargePredicatesAPIModelFromJSON,
    SubscriptionChargePredicatesAPIModelFromJSONTyped,
    SubscriptionChargePredicatesAPIModelToJSON,
} from './SubscriptionChargePredicatesAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionChargeFilterAPIModel
 */
export interface SubscriptionChargeFilterAPIModel {
    /**
     * 
     * @type {SubscriptionChargePredicatesAPIModel}
     * @memberof SubscriptionChargeFilterAPIModel
     */
    predicates: SubscriptionChargePredicatesAPIModel;
    /**
     * 
     * @type {PageSubscriptionChargeSortByAPIModel}
     * @memberof SubscriptionChargeFilterAPIModel
     */
    page: PageSubscriptionChargeSortByAPIModel;
}

/**
 * Check if a given object implements the SubscriptionChargeFilterAPIModel interface.
 */
export function instanceOfSubscriptionChargeFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function SubscriptionChargeFilterAPIModelFromJSON(json: any): SubscriptionChargeFilterAPIModel {
    return SubscriptionChargeFilterAPIModelFromJSONTyped(json, false);
}

export function SubscriptionChargeFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChargeFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': SubscriptionChargePredicatesAPIModelFromJSON(json['predicates']),
        'page': PageSubscriptionChargeSortByAPIModelFromJSON(json['page']),
    };
}

export function SubscriptionChargeFilterAPIModelToJSON(value?: SubscriptionChargeFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': SubscriptionChargePredicatesAPIModelToJSON(value['predicates']),
        'page': PageSubscriptionChargeSortByAPIModelToJSON(value['page']),
    };
}

