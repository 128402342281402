import { SaveOutlined, StopOutlined } from '@ant-design/icons';
import { PageContainer as ProPageContainer } from '@ant-design/pro-components';
import { useMemo } from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import { notEmpty } from '@/infrastructure/utils/ts';

import FormattedMessage from '../FormattedMessage';
import Operation from '../Operation';

import type { PageContainerProps } from './types';
import type React from 'react';

const PageContainer: React.FC<PageContainerProps> = ({
  'data-test': dataTest,
  breadcrumb,
  children,
  PageContainerProps,
  submitting: baseSubmitting,
  submit,
  cancel,
}) => {
  const breadcrumbRender = useMemo(() => (breadcrumb ? () => breadcrumb : undefined), [breadcrumb]);
  const [submitting, withSubmitting] = useSubmitting(false);
  const doSubmit = useMemo(
    () => (submit ? withSubmitting(typeof submit === 'function' ? submit : submit.onClick) : undefined),
    [submit, withSubmitting],
  );
  return (
    <ProPageContainer
      title={false}
      fixedHeader
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      tabProps={useMemo(() => ({ 'data-test': dataTest && `${dataTest}-headerTabs` }), [dataTest])}
      breadcrumbRender={breadcrumbRender}
      footer={useMemo(
        () =>
          (submit ?? cancel)
            ? [
                submit && doSubmit ? (
                  <Operation
                    key="submit"
                    data-test={dataTest && `${dataTest}-submit`}
                    title={
                      (typeof submit !== 'function' && submit.title) || (
                        <FormattedMessage id={I18nComponents.PAGE_CONTAINER_FOOTER_SUBMIT_TITLE} />
                      )
                    }
                    icon={<SaveOutlined />}
                    disabled={typeof submit !== 'function' ? submit.disabled : submitting}
                    inProgress={submitting || baseSubmitting}
                    onClick={doSubmit}
                    primary
                    mode="button"
                  />
                ) : undefined,
                cancel ? (
                  <Operation
                    key="cancel"
                    data-test={dataTest && `${dataTest}-cancel`}
                    title={
                      (typeof cancel !== 'function' && cancel.title) || (
                        <FormattedMessage id={I18nComponents.PAGE_CONTAINER_FOOTER_CANCEL_TITLE} />
                      )
                    }
                    icon={<StopOutlined />}
                    onClick={typeof cancel === 'function' ? cancel : cancel.onClick}
                    mode="button"
                    disabled={submitting || baseSubmitting}
                  />
                ) : undefined,
              ].filter(notEmpty)
            : undefined,
        [baseSubmitting, cancel, dataTest, doSubmit, submit, submitting],
      )}
      {...PageContainerProps}
    >
      {children}
    </ProPageContainer>
  );
};

export default PageContainer;
