import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';
import { enumByKey } from '@/infrastructure/utils/ts';

import useQRDelegateContracts from '../useQRDelegateContracts';

export default function useQRDelegateContract(btOrId: string | BlockchainTypeAPIModel | undefined) {
  const { data, ...state } = useQRDelegateContracts();
  const isBlockchain = !!enumByKey(BlockchainTypeAPIModel, btOrId);
  return {
    ...state,
    data: mapStoredState(data, (contracts) =>
      contracts.find(({ id, blockchain }) => (isBlockchain ? blockchain === btOrId : id === btOrId)),
    ),
  };
}
