import type {
  MerchantReportAPIModel,
  MerchantReportPredicatesAPIModel,
  ReportSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { ReportApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import type { SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';
import { asType } from '@/infrastructure/utils/ts';

import type { Report } from './types';

const reportApi = new ReportApi(coreConfigurationFactory('auth'));

export const parseReport = ({ parameters, ...report }: MerchantReportAPIModel): Report => ({
  ...report,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  parameters: asType<Report['parameters']>(parameters),
});

export const queryReports = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<MerchantReportPredicatesAPIModel, ReportSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await reportApi.searchReports(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseReport,
  );

export const queryReport = async (reportId: string, initOverrides?: RequestInit) =>
  parseReport(await reportApi.getReport(reportId, initOverrides));
export const queryReportDownloadLink = (reportId: string, initOverrides?: RequestInit) =>
  reportApi.downloadReport(reportId, true, initOverrides);

export const requestGenerateReport = async (
  template: string,
  params: Record<string, unknown>,
  initOverrides?: RequestInit,
) => parseReport(await reportApi.generateReport({ template, params }, initOverrides));

export const requestDeleteReport = async (reportId: string, initOverrides?: RequestInit) =>
  reportApi.deleteReport(reportId, initOverrides);
