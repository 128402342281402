import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { updateDonation } from '@/features/donations/actions';
import type { Donation, DonationAddress } from '@/features/donations/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction, LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import { assertNotNil, someOrFail } from '@/infrastructure/utils/functions';

import useDonation from './useDonation';

export type DonationAssetActivateUnavailabilityReason = 'loading' | 'no-data' | 'invalid-status';
export type DonationAssetDeactivateUnavailabilityReason = DonationAssetActivateUnavailabilityReason | 'last-asset';

export interface UseDonationAssetActions {
  data: LoadingStateWithDirty<DonationAddress>;
  activateAsset: HookAction<[], Donation, DonationAssetActivateUnavailabilityReason>;
  deactivateAsset: HookAction<[], Donation, DonationAssetDeactivateUnavailabilityReason>;
}

export default function useDonationAssetActions(
  donationId: string | undefined,
  asset: string | undefined,
): UseDonationAssetActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: donationData, loading } = useDonation(donationId);
  const data = useMemo(
    () => mapStoredState(donationData, ({ addresses }) => addresses.find((address) => address.asset === asset)),
    [asset, donationData],
  );

  const isUpdating = useActionPending(updateDonation);
  // eslint-disable-next-line no-nested-ternary
  const unavailabilityReason = !data.data ? (loading || data.isDirty ? 'loading' : 'no-data') : undefined;

  const activateUnavailabilityReason: DonationAssetActivateUnavailabilityReason | undefined =
    unavailabilityReason || (data.data?.isActive ? 'invalid-status' : undefined);
  const activateAssetAction: UseDonationAssetActions['activateAsset']['act'] = useCallback(() => {
    const donation = someOrFail(donationData.data);
    assertNotNil(data);
    assertNotNil(asset);
    const activeAssets = donation.addresses.filter((address) => address.isActive).map((address) => address.asset);
    return withExtractDataDispatch(updateDonation)({
      id: donation.id,
      title: donation.title,
      description: donation.description,
      activeAssets: [...activeAssets, asset],
      defaultAmounts: donation.addresses.flatMap(({ defaultAmounts }) => defaultAmounts),
    });
  }, [asset, data, donationData.data, withExtractDataDispatch]);
  const activateAssetHook: UseDonationAssetActions['activateAsset'] = useMemo(
    () => ({
      act: activateAssetAction,
      available: !activateUnavailabilityReason,
      inAction: isUpdating,
      unavailabilityReason: activateUnavailabilityReason,
    }),
    [activateAssetAction, isUpdating, activateUnavailabilityReason],
  );

  const deactivateUnavailabilityReason: DonationAssetDeactivateUnavailabilityReason | undefined = useMemo(() => {
    if (unavailabilityReason) return unavailabilityReason;
    if (!data.data?.isActive) return 'invalid-status';
    if (donationData.data?.addresses.filter(({ isActive }) => isActive).length === 1) return 'last-asset';
  }, [data.data?.isActive, donationData.data?.addresses, unavailabilityReason]);
  const deactivateAssetAction: UseDonationAssetActions['deactivateAsset']['act'] = useCallback(() => {
    const donation = someOrFail(donationData.data);
    assertNotNil(data);
    assertNotNil(asset);
    const activeAssets = donation.addresses.filter((address) => address.isActive).map((address) => address.asset);
    return withExtractDataDispatch(updateDonation)({
      id: donation.id,
      title: donation.title,
      description: donation.description,
      activeAssets: activeAssets.filter((old) => old !== asset),
      defaultAmounts: donation.addresses.flatMap(({ defaultAmounts }) => defaultAmounts),
    });
  }, [asset, data, donationData.data, withExtractDataDispatch]);
  const deactivateAssetHook: UseDonationAssetActions['deactivateAsset'] = useMemo(
    () => ({
      act: deactivateAssetAction,
      available: !deactivateUnavailabilityReason,
      inAction: isUpdating,
      unavailabilityReason: deactivateUnavailabilityReason,
    }),
    [deactivateAssetAction, isUpdating, deactivateUnavailabilityReason],
  );

  return {
    data,
    activateAsset: activateAssetHook,
    deactivateAsset: deactivateAssetHook,
  };
}
