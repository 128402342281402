import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { generatePaymentURL } from '@/features/payments/actions';
import { PaymentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import usePayment from '../usePayment';

import type { UsePaymentActions } from './types';

export default function usePaymentActions(paymentId: string): UsePaymentActions {
  const { data } = usePayment(paymentId);
  const isGenerating = useActionPending(generatePaymentURL, paymentId);
  const { withExtractDataDispatch } = useAppDispatch();
  const generatePaymentURLAction = {
    act: useCallback(
      () => withExtractDataDispatch(generatePaymentURL)({ paymentId }),
      [paymentId, withExtractDataDispatch],
    ),
    available:
      data.data?.status === PaymentStatusAPIModel.Created
      || (data.data?.status === PaymentStatusAPIModel.InProgress && data.data.statusDetails === 'UnderPaid'),
    inAction: isGenerating,
  };

  return { generatePaymentURL: generatePaymentURLAction };
}
