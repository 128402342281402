import { Divider, Form, List } from 'antd';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import React, { useCallback, useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import {
  DonationAssetListItem,
  OperationAddDonationAsset,
} from '@/features/donations/components/EditDonationForm/components/DonationAssetsItem/components';
import type { EditDonationFormAssetData } from '@/features/donations/components/EditDonationForm/types';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

import type { DonationAssetsItemProps } from './types';
import type { FormListFieldData } from 'antd';
import type { FormListProps } from 'antd/es/form';
import type { Store } from 'rc-field-form/es/interface';

const DonationAssetsItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name: baseName,
  required,
  onStateChange,
}: DonationAssetsItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const [adding, setAdding] = useStateMountSafe<boolean>();
  const [edited, setEdited] = useStateMountSafe<number | undefined>();
  const [selectedAssets, setSelectedAssets] = useStateMountSafe<string[]>([]);
  const baseNameAsArray = useMemo(() => (Array.isArray(baseName) ? baseName : [baseName]) as unknown[], [baseName]);
  const updateEdited = useCallback(
    (idx: number | undefined) => {
      setEdited(idx);
      if (idx === undefined) {
        setAdding(false);
      }
    },
    [setAdding, setEdited],
  );
  useEffect(() => {
    onStateChange(edited !== undefined);
  }, [edited, onStateChange]);

  return (
    <>
      <Divider type="horizontal">
        <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_ASSETS} />
      </Divider>
      <Form.List
        name={baseName as FormListProps['name']}
        rules={useMemo(
          () => [
            ...(required
              ? [
                  {
                    validator: async (_: unknown, fields: EditDonationFormAssetData[] | undefined) => {
                      if (!fields?.length) {
                        return Promise.reject(
                          new Error(
                            formatMessage({
                              id: I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ERROR_REQUIRED,
                            }),
                          ),
                        );
                      }
                    },
                  },
                ]
              : []),
            {
              validator: async (_: unknown, fields: EditDonationFormAssetData[] | undefined) => {
                if (fields?.length && !fields.filter(({ isActive }) => isActive).length) {
                  return Promise.reject(
                    new Error(
                      formatMessage({
                        id: I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ERROR_NO_ACTIVE,
                      }),
                    ),
                  );
                }
              },
            },
          ],
          [formatMessage, required],
        )}
      >
        {(fields, { add, remove }, { errors }) => {
          const doAdd = (value: EditDonationFormAssetData) => {
            add(value);
            setAdding(true);
            updateEdited(fields.length);
          };
          return (
            <>
              {fields.length ? (
                <List
                  data-test={dataTest}
                  loadMore={false}
                  style={{ width: '100%' }}
                  dataSource={fields}
                  renderItem={({ name, key, ...field }: FormListFieldData) => (
                    <Form.Item {...field} name={name} key={key} noStyle>
                      <DonationAssetListItem
                        data-test={dataTest && `${dataTest}-asset-${name}`}
                        name={name}
                        fullName={[...baseNameAsArray, name]}
                        selectedAssets={selectedAssets}
                        onEdit={() => updateEdited(name)}
                        onCancelEdit={() => {
                          if (adding) {
                            remove(name);
                          }
                          updateEdited(undefined);
                        }}
                        onFinishEdit={() => updateEdited(undefined)}
                        onRemove={() => remove(name)}
                        editable={isNil(edited)}
                        editing={edited === name}
                      />
                    </Form.Item>
                  )}
                />
              ) : null}
              <Form.Item>
                <OperationAddDonationAsset
                  data-test={dataTest && `${dataTest}-addAsset`}
                  selectedAssets={selectedAssets}
                  onAdd={doAdd}
                  disabled={edited !== undefined}
                />
                <Form.ErrorList helpStatus="error" errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Form.Item
        shouldUpdate={(prevValues, nextValues, opts) => {
          if (opts.source !== 'internal') {
            return false;
          }
          const prevAssets: EditDonationFormAssetData[] | undefined = get(
            prevValues,
            baseNameAsArray as Parameters<typeof get>[1],
          );
          const nextAssets: EditDonationFormAssetData[] | undefined = get(
            nextValues,
            baseNameAsArray as Parameters<typeof get>[1],
          );
          return (
            prevAssets?.length != nextAssets?.length
            || prevAssets?.filter(({ isActive }) => isActive).length
              != nextAssets?.filter(({ isActive }) => isActive).length
          );
        }}
      >
        {({ getFieldValue, validateFields, isFieldsTouched }) => {
          const assets: EditDonationFormAssetData[] | undefined = getFieldValue([baseNameAsArray]);
          const newAssets = assets?.map(({ asset }) => asset) ?? [];
          if (!isEqual(selectedAssets, newAssets)) {
            setTimeout(() => setSelectedAssets(newAssets), 0);
          }
          if (isFieldsTouched([baseNameAsArray])) {
            setTimeout(() => withSuppressPromise(validateFields)([baseNameAsArray]), 0);
          }
          return null;
        }}
      </Form.Item>
    </>
  );
};

const DonationAssetsItemMemo = React.memo(DonationAssetsItem) as typeof DonationAssetsItem;

export default DonationAssetsItemMemo;
