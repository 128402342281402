import { Alert, Tag } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, WarningTooltip } from '@/components';
import OperationRefreshQRDelegateContracts from '@/features/company-settings/components/OperationRefreshQRDelegateContracts';
import type { QRDelegateContractParticularIssueBannerProps } from '@/features/company-settings/components/QRDelegateContractIssueBanner/types';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import QRDelegateContractLink from '@/pages/audit/qr-contract-view/components/QRDelegateContractLink';

const QRDelegateContractNotExistIssueBanner: React.FC<QRDelegateContractParticularIssueBannerProps> = ({
  'data-test': dataTest,
  bt,
  mode,
  style,
  className,
  type,
}) => {
  const details = useMemo(
    () =>
      type === 'action' ? (
        <FormattedMessageWithMarkup
          id={I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_ISSUE_BANNER_ISSUES_NOT_EXIST_ACTION}
          values={{
            bt,
            check: (text: React.ReactNode) => (
              <OperationRefreshQRDelegateContracts
                data-test={dataTest && `${dataTest}-opCheck`}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      ) : (
        <FormattedMessageWithMarkup
          id={I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_ISSUE_BANNER_ISSUES_NOT_EXIST_LINK}
          values={{
            bt,
            link: (text: React.ReactNode) => (
              <QRDelegateContractLink
                data-test={dataTest && `${dataTest}-opDeploy`}
                value={bt}
                title={text}
                mode="text"
              />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshQRDelegateContracts
                data-test={dataTest && `${dataTest}-opCheck`}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      ),
    [bt, dataTest, type],
  );
  switch (mode) {
    case 'tag':
      return (
        <WarningTooltip type="error" title={details}>
          <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_ISSUE_BANNER_ISSUES_NOT_EXIST_TAG}
            />
          </Tag>
        </WarningTooltip>
      );
    case 'alert':
      return (
        <Alert
          data-test={dataTest}
          className={className}
          style={style}
          message={
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_QR_DELEGATE_ISSUE_BANNER_ISSUES_NOT_EXIST_TITLE}
            />
          }
          description={details}
          type="error"
          showIcon
        />
      );
    case 'message':
      return details;
  }
};

const QRDelegateContractNotExistIssueBannerMemo = React.memo(
  QRDelegateContractNotExistIssueBanner,
) as typeof QRDelegateContractNotExistIssueBanner;

export default QRDelegateContractNotExistIssueBannerMemo;
