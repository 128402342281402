import React, { useMemo } from 'react';

import { MoneyAmountItem } from '@/components';
import AssetLabel from '@/features/dictionary/blockchain/components/AssetLabel';
import { useAsset } from '@/features/dictionary/blockchain/hooks';


import type { AssetAmountItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetAmountItem = <Values extends Store = Store>({ assetId, ...props }: AssetAmountItemProps<Values>) => {
  const { data: asset } = useAsset(assetId);
  const moneyAsset = useMemo(
    () =>
      asset.data
        ? {
            code: asset.data.code,
            title: <AssetLabel value={asset.data} mode="short" withBlockchainMark withNetworkMark />,
            precision: asset.data.formatDecimals,
          }
        : undefined,
    [asset.data],
  );
  if (!moneyAsset) {
    return null;
  }
  return <MoneyAmountItem<Values> asset={moneyAsset} {...props} />;
};

const AssetAmountItemMemo = React.memo(AssetAmountItem) as typeof AssetAmountItem;

export default AssetAmountItemMemo;
