import { createAction } from '@reduxjs/toolkit';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type { BlockchainMetaAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import {
  BlockchainAPITypeAPIModel,
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { apiBasePath } from '@/infrastructure/api.provider';
import type { CommonLoadingState } from '@/infrastructure/model';
import { mapLoadingState } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createFullActions } from '@/infrastructure/model/full/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import createCache from '@/infrastructure/utils/cache';
import { someOrFail } from '@/infrastructure/utils/functions';
import { callWithThunkError } from '@/infrastructure/utils/redux';
import { addressToHex } from '@/infrastructure/utils/tron/address';
import { enumByKey, enumToObject } from '@/infrastructure/utils/ts';

import { queryAssets, queryBlockchains, queryBlockchainSystemInfo, queryTokenHistoryBalance } from './api';
import {
  makeSelectAllowance,
  makeSelectAssetFullData,
  makeSelectBlockchainFullData,
  makeSelectBlockchainSystemInfos,
  makeSelectContractExistence,
  makeSelectNativeBalance,
  makeSelectTokenBalance,
  makeSelectTokenHistoryBalance,
} from './selectors';
import { NAMESPACE } from './types';
import {
  createAllowanceKey,
  createAssetAddressHistoryKey,
  createAssetAddressKey,
  createBlockchainAddressKey,
  explorers,
} from './utils';
import { queryContractExistence, queryTokenBalance, queryNativeBalance, queryAllowance } from './web3-api';

import type {
  Asset,
  AssetAddress,
  BalanceData,
  BlockchainAddress,
  BlockchainSystemInfo,
  BTCBlockchainSystemInfo,
  FetchAssetAddressDataPayload,
  FetchBlockchainAddressDataPayload,
  TronBlockchainSystemInfo,
  Web3BlockchainSystemInfo,
  AssetFilterPredicate,
  AssetSortByColumn,
  BlockchainInfoFilterPredicate,
  BlockchainInfoSortByColumn,
  AssetAddressHistoryKey,
  FetchAssetAddressHistoryBalancePayload,
  AssetHistoryBalanceData,
  AllowanceId,
  FetchAllowancePayload,
  FetchBlockchainAPIPayload,
} from './types';

export const notifyNetworkUpdated = createAction<{
  previous: BlockchainNetworkTypeAPIModel;
  current: BlockchainNetworkTypeAPIModel;
}>(`${NAMESPACE}/notifyNetworkUpdated`);

export const storeSelectedNetwork = createAction<BlockchainNetworkTypeAPIModel>(`${NAMESPACE}/storeSelectedNetwork`);

export const networkCache = createCache<string>(import.meta.env.VITE_NETWORK_KEY, 'persistent');

export const initNetwork = createAppAsyncThunk(`${NAMESPACE}/initNetwork`, (_, { dispatch }) => {
  const network = enumByKey(BlockchainNetworkTypeAPIModel, networkCache.safeRead());
  if (network) {
    dispatch(storeSelectedNetwork(network));
  }
});

export const { storeAssetsFullData, markAssetsFullDirty } = createFullActions<
  Asset,
  'Assets',
  AssetFilterPredicate,
  AssetSortByColumn
>(NAMESPACE, 'Assets');
const assetsFetchLimit = pLimit(1);
export const fetchAssets = createAppAsyncThunk(
  `${NAMESPACE}/fetchAssets`,
  async ({ force }: { force?: boolean }, { getState, dispatch, signal }) =>
    assetsFetchLimit(async () => {
      const stored = makeSelectAssetFullData()(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const queried = await withAPICall(queryAssets)({ signal });
      dispatch(storeAssetsFullData(queried));
      return makeSelectAssetFullData()(getState());
    }),
);

export const { storeBlockchainsFullData, markBlockchainsFullDirty } = createFullActions<
  BlockchainMetaAPIModel,
  'Blockchains',
  BlockchainInfoFilterPredicate,
  BlockchainInfoSortByColumn
>(NAMESPACE, 'Blockchains');
const blockchainsFetchLimit = pLimit(1);
export const fetchBlockchains = createAppAsyncThunk(
  `${NAMESPACE}/fetchBlockchains`,
  async ({ force }: { force?: boolean }, { getState, dispatch, signal }) =>
    blockchainsFetchLimit(async () => {
      const stored = makeSelectBlockchainFullData()(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const queried = await withAPICall(queryBlockchains)({ signal });
      dispatch(storeBlockchainsFullData(queried));
      return makeSelectBlockchainFullData()(getState());
    }),
);

const addressToHexSafe = (address: string): string => {
  if (!address) {
    return address;
  }
  try {
    return addressToHex(address);
  } catch (e) {
    console.error(e, (e as Error).stack);
    return address;
  }
};

export const markBlockchainSystemInfoDirty = createAction(`${NAMESPACE}/markBlockchainSystemInfoDirty`);
export const storeBlockchainSystemInfo = createAction<
  CommonLoadingState<Partial<Record<BlockchainTypeAPIModel, BlockchainSystemInfo>>>
>(`${NAMESPACE}/storeBlockchainSystemInfo`);
const blockchainsSystemFetchLimit = pLimit(1);
export const fetchBlockchainsSystemInfo = createAppAsyncThunk(
  `${NAMESPACE}/fetchBlockchainsSystemInfo`,
  async ({ force }: { force?: boolean }, { getState, dispatch, signal }) =>
    blockchainsSystemFetchLimit(async () => {
      const stored = makeSelectBlockchainSystemInfos()(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const queried = await withAPICall(queryBlockchainSystemInfo)({ signal });
      const dataToStore = mapLoadingState(queried, (data) =>
        enumToObject(
          BlockchainTypeAPIModel,
          (bt): BlockchainSystemInfo | undefined => {
            const bcApi = data.api[bt];
            const api =
              (bcApi?.securedApi && `${apiBasePath}/${bcApi.securedApi}`)
              || (bcApi?.openApi && `${apiBasePath}/${bcApi.openApi}`)
              || bcApi?.publicApi;
            const apiAuth = !!bcApi?.securedApi;
            const app = explorers[bt];
            const web3BC = data.blockchains.networks[bt];
            const btcBC = data.blockchains.btcNetworks[bt];
            const tronBC = data.blockchains.tronNetworks[bt];
            const bc: Web3BlockchainSystemInfo | BTCBlockchainSystemInfo | TronBlockchainSystemInfo | undefined =
              (web3BC ? { ...web3BC, apiType: BlockchainAPITypeAPIModel.WEB3 } : undefined)
              ?? (btcBC ? { ...btcBC, apiType: BlockchainAPITypeAPIModel.BTC } : undefined)
              ?? (tronBC
                ? {
                    ...{
                      batchBalancesContractAddress: addressToHexSafe(tronBC.batchBalancesContractAddress),
                      forwarderFactoryAddress: addressToHexSafe(tronBC.forwarderFactoryAddress),
                      forwarderBatchCreateAddress: addressToHexSafe(tronBC.forwarderBatchCreateAddress),
                      merchantWalletProtoAddress: addressToHexSafe(tronBC.merchantWalletProtoAddress),
                      merchantWalletFactoryAddress: addressToHexSafe(tronBC.merchantWalletFactoryAddress),
                    },
                    apiType: BlockchainAPITypeAPIModel.Tron,
                  }
                : undefined);
            const isEnabled = Boolean(data.blockchains.enabled[bt]);
            const isForwarderSupported = Boolean(data.blockchains.supportsForwarder[bt]);
            const isForwarderRequired = !!tronBC;
            // eslint-disable-next-line no-nested-ternary
            const forwarder = isForwarderRequired ? 'required' : isForwarderSupported ? 'supported' : 'not-supported';
            return bc && api ? { ...bc, links: { api, apiAuth, app }, isEnabled, forwarder, bt } : undefined;
          },
          true,
        ),
      );
      dispatch(storeBlockchainSystemInfo(dataToStore));
      return makeSelectBlockchainSystemInfos()(getState());
    }),
);

export const fetchWeb3CompatBlockchainAPI = createAppAsyncThunk(
  `${NAMESPACE}/fetchWeb3CompatBlockchainAPI`,
  async ({ force, bt: btBase, asset }: FetchBlockchainAPIPayload, { dispatch }) => {
    let bt = btBase;
    if (!btBase) {
      const assets = await dispatch(fetchAssets({ force })).unwrap();
      bt = (assets.data ?? []).find(({ code }) => code === asset)?.blockchain;
    }
    if (!bt) {
      throw new Error(asset ? `Blockchain for asset "${asset}" not found` : `Blockchain "${btBase}" not found`);
    }
    const systemInfo = await dispatch(fetchBlockchainsSystemInfo({ force })).unwrap();
    const bc = systemInfo.data?.[bt];
    if (!bc) {
      throw new Error(`Blockchain "${bt}" api data not found`);
    }
    if (!bc.isEnabled) {
      throw new Error(`Blockchain "${bt}" api is disabled`);
    }
    if (!bc.links.api) {
      throw new Error(`Blockchain "${bt}" api link is unset`);
    }
    if (bc.apiType !== BlockchainAPITypeAPIModel.WEB3 && bc.apiType !== BlockchainAPITypeAPIModel.Tron) {
      throw new Error(`Blockchain "${bt}" has non WEB3 API`);
    }
    return { host: bc.links.api, auth: !!bc.links.apiAuth, ...bc };
  },
);

export const fetchWeb3Asset = createAppAsyncThunk(
  `${NAMESPACE}/fetchWeb3Asset`,
  async ({ force, asset: assetId }: { force?: boolean; asset: string }, { dispatch }) => {
    const assets = await dispatch(fetchAssets({ force })).unwrap();
    const asset = assets.data?.find(({ code }) => code === assetId);

    if (!asset) {
      throw new Error(`Asset "${assetId}" not found`);
    }
    if (!asset.address && !asset.blockchain) {
      throw new Error(`Asset "${assetId}" is not web3 asset`);
    }
    return { ...asset, address: someOrFail(asset.address), blockchain: someOrFail(asset.blockchain) };
  },
);

export const { storeNativeBalance, markNativeBalanceDirty } = createSingleActions<
  BalanceData,
  'NativeBalance',
  BlockchainAddress
>(NAMESPACE, 'NativeBalance');
const nativeFetchLimit = pLimit(1);
export const fetchNativeBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchNativeBalance`,
  async ({ force, ...key }: FetchBlockchainAddressDataPayload, { getState, dispatch }) =>
    nativeFetchLimit(async () => {
      const stored = makeSelectNativeBalance(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const balance = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ bt: key.bt, force })).unwrap();
        const queried = await withAPICall(queryNativeBalance)(bcMeta, key.address);
        return mapLoadingState(queried, (amount) => ({ amount, updatedAt: new Date() }));
      });
      dispatch(storeNativeBalance({ id: key, data: balance }));
      return makeSelectNativeBalance(key)(getState());
    }),
  { idGenerator: createBlockchainAddressKey },
);

export const { storeTokenBalance, markTokenBalanceDirty } = createSingleActions<
  BalanceData,
  'TokenBalance',
  AssetAddress
>(NAMESPACE, 'TokenBalance');
const tokenFetchLimit = pLimit(1);
export const fetchTokenBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchTokenBalance`,
  async ({ force, ...key }: FetchAssetAddressDataPayload, { getState, dispatch }) =>
    tokenFetchLimit(async () => {
      const stored = makeSelectTokenBalance(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const balance = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ asset: key.assetId, force })).unwrap();
        const asset = ((await dispatch(fetchAssets({ force })).unwrap()).data ?? []).find(
          ({ code }) => code === key.assetId,
        );
        if (!asset?.address) {
          throw new Error(`Asset ${key.assetId} token address not found`);
        }
        const queried = await withAPICall(queryTokenBalance)(bcMeta, asset.address, key.address);
        return mapLoadingState(queried, (amount) => ({ amount, updatedAt: new Date() }));
      });
      dispatch(storeTokenBalance({ id: key, data: balance }));
      return makeSelectTokenBalance(key)(getState());
    }),
  { idGenerator: createAssetAddressKey },
);

export const { storeTokenHistoryBalance, markTokenHistoryBalanceDirty } = createSingleActions<
  AssetHistoryBalanceData,
  'TokenHistoryBalance',
  AssetAddressHistoryKey
>(NAMESPACE, 'TokenHistoryBalance');
const tokenHistoryFetchLimit = pLimit(1);
export const fetchTokenHistoryBalance = createAppAsyncThunk(
  `${NAMESPACE}/fetchTokenHistoryBalance`,
  async ({ force, ...key }: FetchAssetAddressHistoryBalancePayload, { getState, dispatch, signal }) =>
    tokenHistoryFetchLimit(async () => {
      const stored = makeSelectTokenHistoryBalance(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const balance = await withAPICall(queryTokenHistoryBalance)(key.assetId, key.address, key.blockNum, { signal });
      dispatch(storeTokenHistoryBalance({ id: key, data: balance }));
      return makeSelectTokenHistoryBalance(key)(getState());
    }),
  { idGenerator: createAssetAddressHistoryKey },
);

export const { storeContractExistence, markContractExistenceDirty } = createSingleActions<
  boolean,
  'ContractExistence',
  BlockchainAddress
>(NAMESPACE, 'ContractExistence');
const contractFetchLimit = pLimit(1);
export const fetchContractExistence = createAppAsyncThunk(
  `${NAMESPACE}/fetchContractExistence`,
  async ({ force, ...key }: FetchBlockchainAddressDataPayload, { getState, dispatch }) =>
    contractFetchLimit(async () => {
      const stored = makeSelectContractExistence(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const data = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ bt: key.bt, force })).unwrap();
        return withAPICall(queryContractExistence)(bcMeta, key.address);
      });
      dispatch(storeContractExistence({ id: key, data }));
      return makeSelectContractExistence(key)(getState());
    }),
  { idGenerator: createBlockchainAddressKey },
);

export const { storeAllowance, markAllowanceDirty } = createSingleActions<bigint, 'Allowance', AllowanceId>(
  NAMESPACE,
  'Allowance',
);
const allowanceFetchLimit = pLimit(1);
export const fetchAllowance = createAppAsyncThunk(
  `${NAMESPACE}/fetchAllowance`,
  async ({ force, ...key }: FetchAllowancePayload, { getState, dispatch }) =>
    allowanceFetchLimit(async () => {
      const stored = makeSelectAllowance(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const data = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ asset: key.assetId, force })).unwrap();
        const asset = await dispatch(fetchWeb3Asset({ asset: key.assetId, force })).unwrap();
        return withAPICall(queryAllowance)(bcMeta, asset.address, key.owner, key.spender);
      });
      dispatch(storeAllowance({ id: key, data }));
      return makeSelectAllowance(key)(getState());
    }),
  { idGenerator: createAllowanceKey },
);
