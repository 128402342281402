import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { collectNow } from '@/features/collectable/actions';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import { useIsActiveMerchantWalletDeployed } from '@/features/merchant-wallets/hooks';
import { useActualBalances } from '@/features/statistics/hooks';
import type { HookAction } from '@/infrastructure/model';

type CollectUnavailabilityReason = 'no-data' | 'no-deployed-wallet' | 'empty-balance';

export interface UseCollectAssetActions {
  collectNow: HookAction<[], void, CollectUnavailabilityReason>;
}

export default function useCollectAssetActions(assetId: string): UseCollectAssetActions {
  const { data: asset } = useAsset(assetId);
  const { data: balances, loading: balancesLoading } = useActualBalances();
  const balance = useMemo(
    () => balances.data?.collectable.find(({ asset }) => asset === assetId),
    [assetId, balances.data?.collectable],
  );
  const { data: walletIsDeployed, loading: walletLoading } = useIsActiveMerchantWalletDeployed(asset.data?.blockchain);
  const { withExtractDataDispatch } = useAppDispatch();

  const collecting = useActionPending(collectNow, assetId);
  const collectUnavailabilityReason: CollectUnavailabilityReason | undefined = useMemo(() => {
    if (walletLoading || balances.isDirty || balancesLoading) {
      return 'no-data';
    }
    if (!balance?.value.isGreaterThan(0)) {
      return 'empty-balance';
    }
    if (!walletIsDeployed) {
      return 'no-deployed-wallet';
    }
    return undefined;
  }, [balance?.value, balances.isDirty, balancesLoading, walletIsDeployed, walletLoading]);
  const collectNowAction: UseCollectAssetActions['collectNow']['act'] = useCallback(
    () => withExtractDataDispatch(collectNow)({ asset: assetId }),
    [withExtractDataDispatch, assetId],
  );
  const collectNowHook: UseCollectAssetActions['collectNow'] = {
    act: collectNowAction,
    inAction: collecting,
    available: !collectUnavailabilityReason,
    unavailabilityReason: collectUnavailabilityReason,
  };

  return { collectNow: collectNowHook };
}
