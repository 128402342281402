/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PayoutBatchStatusAPIModel } from './PayoutBatchStatusAPIModel';
import {
    PayoutBatchStatusAPIModelFromJSON,
    PayoutBatchStatusAPIModelFromJSONTyped,
    PayoutBatchStatusAPIModelToJSON,
} from './PayoutBatchStatusAPIModel';

/**
 * 
 * @export
 * @interface PayoutBatchAPIModel
 */
export interface PayoutBatchAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    payoutId: string;
    /**
     * 
     * @type {number}
     * @memberof PayoutBatchAPIModel
     */
    num: number;
    /**
     * 
     * @type {PayoutBatchStatusAPIModel}
     * @memberof PayoutBatchAPIModel
     */
    status: PayoutBatchStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    wallet: string;
    /**
     * 
     * @type {number}
     * @memberof PayoutBatchAPIModel
     */
    attempts: number;
    /**
     * 
     * @type {Date}
     * @memberof PayoutBatchAPIModel
     */
    nextAttemptAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    failureReason?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    settlementId?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBatchAPIModel
     */
    amount: string;
    /**
     * 
     * @type {Date}
     * @memberof PayoutBatchAPIModel
     */
    broadcastAt?: Date;
}

/**
 * Check if a given object implements the PayoutBatchAPIModel interface.
 */
export function instanceOfPayoutBatchAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('payoutId' in value)) return false;
    if (!('num' in value)) return false;
    if (!('status' in value)) return false;
    if (!('wallet' in value)) return false;
    if (!('attempts' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function PayoutBatchAPIModelFromJSON(json: any): PayoutBatchAPIModel {
    return PayoutBatchAPIModelFromJSONTyped(json, false);
}

export function PayoutBatchAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutBatchAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'payoutId': json['payoutId'],
        'num': json['num'],
        'status': PayoutBatchStatusAPIModelFromJSON(json['status']),
        'wallet': json['wallet'],
        'attempts': json['attempts'],
        'nextAttemptAt': json['nextAttemptAt'] == null ? undefined : (new Date(json['nextAttemptAt'])),
        'failureReason': json['failureReason'] == null ? undefined : json['failureReason'],
        'settlementId': json['settlementId'] == null ? undefined : json['settlementId'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'amount': json['amount'],
        'broadcastAt': json['broadcastAt'] == null ? undefined : (new Date(json['broadcastAt'])),
    };
}

export function PayoutBatchAPIModelToJSON(value?: PayoutBatchAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'payoutId': value['payoutId'],
        'num': value['num'],
        'status': PayoutBatchStatusAPIModelToJSON(value['status']),
        'wallet': value['wallet'],
        'attempts': value['attempts'],
        'nextAttemptAt': value['nextAttemptAt'] == null ? undefined : ((value['nextAttemptAt']).toISOString()),
        'failureReason': value['failureReason'],
        'settlementId': value['settlementId'],
        'hash': value['hash'],
        'amount': value['amount'],
        'broadcastAt': value['broadcastAt'] == null ? undefined : ((value['broadcastAt']).toISOString()),
    };
}

