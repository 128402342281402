import React from 'react';

import { AssetAmountSelectItem } from '@/features/dictionary/blockchain/components';
import type { AssetForPaymentIssue } from '@/features/payments/hooks';
import { AssetForPaymentIssueRender, useAssetsForPayment } from '@/features/payments/hooks';

import type { PaymentAmountItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const PaymentAmountItem = <Values extends Store = Store>(props: PaymentAmountItemProps<Values>) => {
  const assets = useAssetsForPayment();
  return (
    <AssetAmountSelectItem<Values, AssetForPaymentIssue | undefined>
      {...props}
      assets={assets}
      Issues={AssetForPaymentIssueRender}
    />
  );
};

const PaymentAmountItemMemo = React.memo(PaymentAmountItem) as typeof PaymentAmountItem;

export default PaymentAmountItemMemo;
