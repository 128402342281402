/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CollectPeriodAPIModel } from './CollectPeriodAPIModel';
import {
    CollectPeriodAPIModelFromJSON,
    CollectPeriodAPIModelFromJSONTyped,
    CollectPeriodAPIModelToJSON,
} from './CollectPeriodAPIModel';

/**
 * 
 * @export
 * @interface CollectScheduleAPIModel
 */
export interface CollectScheduleAPIModel {
    /**
     * 
     * @type {CollectPeriodAPIModel}
     * @memberof CollectScheduleAPIModel
     */
    period: CollectPeriodAPIModel;
    /**
     * 
     * @type {number}
     * @memberof CollectScheduleAPIModel
     */
    unifiedMinAmount: number;
}

/**
 * Check if a given object implements the CollectScheduleAPIModel interface.
 */
export function instanceOfCollectScheduleAPIModel(value: object): boolean {
    if (!('period' in value)) return false;
    if (!('unifiedMinAmount' in value)) return false;
    return true;
}

export function CollectScheduleAPIModelFromJSON(json: any): CollectScheduleAPIModel {
    return CollectScheduleAPIModelFromJSONTyped(json, false);
}

export function CollectScheduleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectScheduleAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'period': CollectPeriodAPIModelFromJSON(json['period']),
        'unifiedMinAmount': json['unifiedMinAmount'],
    };
}

export function CollectScheduleAPIModelToJSON(value?: CollectScheduleAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': CollectPeriodAPIModelToJSON(value['period']),
        'unifiedMinAmount': value['unifiedMinAmount'],
    };
}

