import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markMerchantWalletDeployTransactionsDirty,
  markMerchantWalletOwnershipDirty,
  markMerchantWalletsDirty,
  storeMerchantWalletDeployTransactions,
  storeMerchantWalletOwnership,
  storeMerchantWallets,
} from './actions';
import { type MerchantWalletsState } from './types';

import type { Draft } from 'immer';

const initialState: MerchantWalletsState = {
  ownership: storedDirtyData,
  wallets: storedDirtyData,
  deployTransactions: {},
};

const { storeMerchantWalletDeployTransactionsReducer, markMerchantWalletDeployTransactionsDirtyReducer } =
  createSingleReducers(
    'MerchantWalletDeployTransactions',
    (state: Draft<MerchantWalletsState>) => state.deployTransactions,
    (global, deployTransactions) => ({ ...global, deployTransactions }),
    merchantWalletIdToStoreKey,
  );

const { storeMerchantWalletOwnershipReducer, markMerchantWalletOwnershipDirtyReducer } = createLoadingDataReducers(
  'MerchantWalletOwnership',
  (state: Draft<MerchantWalletsState>) => state.ownership,
  (global, ownership) => ({ ...global, ownership }),
);

const { storeMerchantWalletsReducer, markMerchantWalletsDirtyReducer } = createLoadingDataReducers(
  'MerchantWallets',
  (state: Draft<MerchantWalletsState>) => state.wallets,
  (state, wallets) => ({ ...state, wallets }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markMerchantWalletDeployTransactionsDirty, markMerchantWalletDeployTransactionsDirtyReducer)
    .addCase(storeMerchantWalletDeployTransactions, storeMerchantWalletDeployTransactionsReducer)

    .addCase(markMerchantWalletOwnershipDirty, markMerchantWalletOwnershipDirtyReducer)
    .addCase(storeMerchantWalletOwnership, storeMerchantWalletOwnershipReducer)

    .addCase(markMerchantWalletsDirty, markMerchantWalletsDirtyReducer)
    .addCase(storeMerchantWallets, storeMerchantWalletsReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
