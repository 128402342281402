import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessageWithMarkup, Operation } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import UpdateActiveAssetsLink from '@/pages/settings/company/assets-edit/components/UpdateActiveAssetsLink';

import type { OperationAddCryptoPeggedAssetsProps } from './types';

const OperationAddCryptoPeggedAssets: React.FC<OperationAddCryptoPeggedAssetsProps> = ({
  'data-test': dataTest,
  mainAsset,
  assets: allAssets,
  selectedAssets,
  onSelect,
}) => {
  const selectableAssets = useMemo(
    () =>
      allAssets
        ?.filter(({ peggedToCrypto }) => !!peggedToCrypto && peggedToCrypto === mainAsset.peggedToCrypto)
        .filter(({ code }) => !selectedAssets?.includes(code))
        .filter(({ issue }) => !issue)
        .map(({ code }) => code),
    [allAssets, mainAsset.peggedToCrypto, selectedAssets],
  );
  const doSelect = useCallback(() => {
    if (selectableAssets) {
      onSelect(selectableAssets);
    }
  }, [onSelect, selectableAssets]);
  const disabledMessage = useMemo(() => {
    if (!selectableAssets?.length)
      return (
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ADD_CRYPTO_PEGGED_DISABLED}
          values={{
            ln: (text: React.ReactNode) => (
              <UpdateActiveAssetsLink data-test={dataTest && `${dataTest}-settings`} mode="link" title={text} />
            ),
            pegged: mainAsset.peggedToCrypto,
          }}
        />
      );
    return undefined;
  }, [selectableAssets?.length, dataTest, mainAsset.peggedToCrypto]);

  return (
    <Operation
      mode="link"
      data-test={dataTest}
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
      onClick={doSelect}
      icon={<PlusCircleOutlined />}
      title={
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ADD_CRYPTO_PEGGED_TITLE}
          values={{ pegged: mainAsset.peggedToCrypto }}
        />
      }
      tooltip={
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_AUX_ASSETS_ADD_CRYPTO_PEGGED_TOOLTIP}
          values={{ pegged: mainAsset.peggedToCrypto }}
        />
      }
    />
  );
};

const OperationAddCryptoPeggedAssetsMemo = React.memo(
  OperationAddCryptoPeggedAssets,
) as typeof OperationAddCryptoPeggedAssets;

export default OperationAddCryptoPeggedAssetsMemo;
