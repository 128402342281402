import { LinkOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureStatistics } from '@/generated/i18n/i18n';

import BalanceStatisticsContainer from '../BalanceStatisticsContainer';

import type { ActualBalancesHeaderProps } from './types';

const style: React.CSSProperties = { width: '100%', justifyContent: 'space-between' };

const ActualBalancesHeader: React.FC<ActualBalancesHeaderProps> = ({ data, 'data-test': dataTest, links }) => (
  <Space style={style} wrap>
    <BalanceStatisticsContainer
      data={data.total}
      title={<FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_TOTAL_TITLE} />}
      data-test={dataTest && `${dataTest}-total`}
    />
    <BalanceStatisticsContainer
      data={data.totalAvailable}
      title={
        <Space>
          <FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_AVAILABLE_TITLE} />
          {links?.AvailableLink && (
            <links.AvailableLink
              data-test={dataTest && `${dataTest}-available`}
              mode="inline"
              icon={<LinkOutlined />}
            />
          )}
        </Space>
      }
      data-test={dataTest && `${dataTest}-available`}
    />
    <BalanceStatisticsContainer
      data={data.totalCollectable}
      title={
        <Space>
          <FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_COLLECTABLE_TITLE} />
          {links?.CollectableLink && (
            <links.CollectableLink
              data-test={dataTest && `${dataTest}-collectable`}
              mode="inline"
              icon={<LinkOutlined />}
            />
          )}
        </Space>
      }
      data-test={dataTest && `${dataTest}-collectable`}
    />
    <BalanceStatisticsContainer
      data={data.totalLocked}
      title={
        <Space>
          <FormattedMessage id={I18nFeatureStatistics.COMPONENTS_ACTUAL_BALANCES_VIEW_LOCKED_TITLE} />
          {links?.LockedLink && (
            <links.LockedLink data-test={dataTest && `${dataTest}-locked`} mode="inline" icon={<LinkOutlined />} />
          )}
        </Space>
      }
      data-test={dataTest && `${dataTest}-locked`}
    />
  </Space>
);

const ActualBalancesHeaderMemo = React.memo(ActualBalancesHeader);

export default ActualBalancesHeaderMemo;
