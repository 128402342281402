import type { CollectableEntityTypedId } from '@/features/collectable/types';
import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum BalancesPageRoutes {
  ACTUAL = 'actual',
  COLLECTABLE = 'collectable',
  COLLECTABLE_AVAILABLE = 'collectable/available',
  COLLECTABLE_LOCKED = 'collectable/locked',
  COLLECTABLE_ENTITY = 'collectable/entity/:kind/:id',
  COLLECTABLE_VIEW = 'collectable/view/:kind/:id',
}

export const balancesActualRoute = (route: BalancesPageRoutes) =>
  `${appRoute(AppPageRoutes.BALANCES)}${route.length ? `/${route}` : ''}`;

export const balancesActualLink = () => balancesActualRoute(BalancesPageRoutes.ACTUAL);

export const collectableAvailableLink = () => balancesActualRoute(BalancesPageRoutes.COLLECTABLE_AVAILABLE);
export const collectableLockedLink = () => balancesActualRoute(BalancesPageRoutes.COLLECTABLE_LOCKED);
export const collectableEntityLink = (id: CollectableEntityTypedId) =>
  `${balancesActualRoute(BalancesPageRoutes.COLLECTABLE)}/entity/${id.kind}/${id.id}`;
export const collectableViewLink = (id: CollectableEntityTypedId) =>
  `${balancesActualRoute(BalancesPageRoutes.COLLECTABLE)}/view/${id.kind}/${id.id}`;
