/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantGasHistoryPredicatesAPIModel } from './MerchantGasHistoryPredicatesAPIModel';
import {
    MerchantGasHistoryPredicatesAPIModelFromJSON,
    MerchantGasHistoryPredicatesAPIModelFromJSONTyped,
    MerchantGasHistoryPredicatesAPIModelToJSON,
} from './MerchantGasHistoryPredicatesAPIModel';
import type { PageGasHistorySortByAPIModel } from './PageGasHistorySortByAPIModel';
import {
    PageGasHistorySortByAPIModelFromJSON,
    PageGasHistorySortByAPIModelFromJSONTyped,
    PageGasHistorySortByAPIModelToJSON,
} from './PageGasHistorySortByAPIModel';

/**
 * 
 * @export
 * @interface MerchantGasHistoryFilterAPIModel
 */
export interface MerchantGasHistoryFilterAPIModel {
    /**
     * 
     * @type {MerchantGasHistoryPredicatesAPIModel}
     * @memberof MerchantGasHistoryFilterAPIModel
     */
    predicates: MerchantGasHistoryPredicatesAPIModel;
    /**
     * 
     * @type {PageGasHistorySortByAPIModel}
     * @memberof MerchantGasHistoryFilterAPIModel
     */
    page: PageGasHistorySortByAPIModel;
}

/**
 * Check if a given object implements the MerchantGasHistoryFilterAPIModel interface.
 */
export function instanceOfMerchantGasHistoryFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function MerchantGasHistoryFilterAPIModelFromJSON(json: any): MerchantGasHistoryFilterAPIModel {
    return MerchantGasHistoryFilterAPIModelFromJSONTyped(json, false);
}

export function MerchantGasHistoryFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantGasHistoryFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': MerchantGasHistoryPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageGasHistorySortByAPIModelFromJSON(json['page']),
    };
}

export function MerchantGasHistoryFilterAPIModelToJSON(value?: MerchantGasHistoryFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': MerchantGasHistoryPredicatesAPIModelToJSON(value['predicates']),
        'page': PageGasHistorySortByAPIModelToJSON(value['page']),
    };
}

