/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum MerchantWalletTransferDirectionAPIModel {
    Incoming = 'Incoming',
    Outgoing = 'Outgoing'
}


export function MerchantWalletTransferDirectionAPIModelFromJSON(json: any): MerchantWalletTransferDirectionAPIModel {
    return MerchantWalletTransferDirectionAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletTransferDirectionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletTransferDirectionAPIModel {
    return json as MerchantWalletTransferDirectionAPIModel;
}

export function MerchantWalletTransferDirectionAPIModelToJSON(value?: MerchantWalletTransferDirectionAPIModel | null): any {
    return value as any;
}

