/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum ForwarderDelegateStatusAPIModel {
    Defined = 'Defined',
    Pending = 'Pending',
    Deployed = 'Deployed',
    Failed = 'Failed'
}


export function ForwarderDelegateStatusAPIModelFromJSON(json: any): ForwarderDelegateStatusAPIModel {
    return ForwarderDelegateStatusAPIModelFromJSONTyped(json, false);
}

export function ForwarderDelegateStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwarderDelegateStatusAPIModel {
    return json as ForwarderDelegateStatusAPIModel;
}

export function ForwarderDelegateStatusAPIModelToJSON(value?: ForwarderDelegateStatusAPIModel | null): any {
    return value as any;
}

