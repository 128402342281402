/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SeedStoragePolicyTypeAPIModel } from './SeedStoragePolicyTypeAPIModel';
import {
    SeedStoragePolicyTypeAPIModelFromJSON,
    SeedStoragePolicyTypeAPIModelFromJSONTyped,
    SeedStoragePolicyTypeAPIModelToJSON,
} from './SeedStoragePolicyTypeAPIModel';

/**
 * 
 * @export
 * @interface CompanySeedPassEncryptedStorageAPIModel
 */
export interface CompanySeedPassEncryptedStorageAPIModel {
    /**
     * 
     * @type {Date}
     * @memberof CompanySeedPassEncryptedStorageAPIModel
     */
    updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySeedPassEncryptedStorageAPIModel
     */
    retrieveByNonce: boolean;
    /**
     * 
     * @type {SeedStoragePolicyTypeAPIModel}
     * @memberof CompanySeedPassEncryptedStorageAPIModel
     */
    policyType: SeedStoragePolicyTypeAPIModel;
}

/**
 * Check if a given object implements the CompanySeedPassEncryptedStorageAPIModel interface.
 */
export function instanceOfCompanySeedPassEncryptedStorageAPIModel(value: object): boolean {
    if (!('updatedAt' in value)) return false;
    if (!('retrieveByNonce' in value)) return false;
    if (!('policyType' in value)) return false;
    return true;
}

export function CompanySeedPassEncryptedStorageAPIModelFromJSON(json: any): CompanySeedPassEncryptedStorageAPIModel {
    return CompanySeedPassEncryptedStorageAPIModelFromJSONTyped(json, false);
}

export function CompanySeedPassEncryptedStorageAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySeedPassEncryptedStorageAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'updatedAt': (new Date(json['updatedAt'])),
        'retrieveByNonce': json['retrieveByNonce'],
        'policyType': SeedStoragePolicyTypeAPIModelFromJSON(json['policyType']),
    };
}

export function CompanySeedPassEncryptedStorageAPIModelToJSON(value?: CompanySeedPassEncryptedStorageAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'updatedAt': ((value['updatedAt']).toISOString()),
        'retrieveByNonce': value['retrieveByNonce'],
        'policyType': SeedStoragePolicyTypeAPIModelToJSON(value['policyType']),
    };
}

