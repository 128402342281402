import { Typography } from 'antd';
import React, { useMemo } from 'react';

import FormattedMessage from '@/components/FormattedMessage';

import type { FormattedMessageWithMarkupProps } from './types';

const { Paragraph, Title } = Typography;

const FormattedMessageWithMarkup: React.FC<FormattedMessageWithMarkupProps> = ({
  values: baseValues = {},
  ...props
}) => {
  const values = useMemo(
    () => ({
      p: (content: React.ReactNode) => <Paragraph>{content}</Paragraph>,
      b: (content: React.ReactNode) => <b>{content}</b>,
      h1: (content: React.ReactNode) => <Title level={4}>{content}</Title>,
      h2: (content: React.ReactNode) => <Title level={5}>{content}</Title>,
      ul: (content: React.ReactNode) => <ul style={{ listStyle: 'disc' }}>{content}</ul>,
      li: (content: React.ReactNode) => <li>{content}</li>,
      br: <br />,
      ...baseValues,
    }),
    [baseValues],
  );
  return <FormattedMessage {...props} values={values} />;
};

const FormattedMessageWithMarkupMemo = React.memo(FormattedMessageWithMarkup);

export default FormattedMessageWithMarkupMemo;
