import type {
  BlockchainTypeAPIModel,
  MerchantWalletAPIModel,
  MerchantWalletDeployTransactionAPIModel,
  MerchantWalletSignatureAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'merchant-wallets';

export interface MerchantWalletId {
  bt: BlockchainTypeAPIModel;
  address: string;
}

export type MerchantWalletSignature = MerchantWalletSignatureAPIModel;
export type MerchantWallet = MerchantWalletAPIModel & { issue: MerchantWalletIssue | undefined };
export type MerchantWalletDeployTransaction = MerchantWalletDeployTransactionAPIModel;

export enum MerchantWalletIssue {
  NOT_DEPLOYED = 'not-deployed',
  DEPLOYING = 'deploying',
  FAILED = 'failed',
  INVALID = 'invalid',
}

export interface MerchantWalletsState {
  ownership: LoadingStateWithDirty<MerchantWalletSignature>;
  wallets: LoadingFullDataState<MerchantWallet>;
  deployTransactions: SingleState<MerchantWalletDeployTransaction[]>; // MerchantWalletId
}
