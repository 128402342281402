import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { donationAssetStatusIcons } from '@/features/donations/components/DonationAssetStatusView';
import type { DonationAssetDeactivateUnavailabilityReason } from '@/features/donations/hooks';
import { useDonationAssetActions } from '@/features/donations/hooks';
import { I18nCommon, I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationUpdateDonationAssetStatusProps } from './types';

export const notificationOptions = {
  error: () => (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERROR_MESSAGE} />
  ),
  success: () => (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_SUCCESS_MESSAGE} />
  ),
};

const disabledMessages: Record<DonationAssetDeactivateUnavailabilityReason, React.ReactNode> = {
  'no-data': (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERRORS_NO_DATA} />
  ),
  loading: <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />,
  'invalid-status': (
    <FormattedMessage
      id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERRORS_INVALID_STATUS}
    />
  ),
  'last-asset': (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERRORS_LAST_ASSET} />
  ),
};

const OperationUpdateDonationAssetStatus: React.FC<OperationUpdateDonationAssetStatusProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  donationId,
  asset,
}) => {
  const { withNotification } = useNotification();
  const {
    data: { data: address },
    activateAsset: activateAssetAction,
    deactivateAsset: deactivateAssetAction,
  } = useDonationAssetActions(donationId, asset);
  const isActive = !!address?.isActive;
  const action = isActive ? deactivateAssetAction : activateAssetAction;

  const doUpdateStatus = useMemo(
    () => withSuppressError(withNotification(action.act, notificationOptions)),
    [withNotification, action.act],
  );

  const title = isActive ? (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_DEACTIVATE_TITLE} />
  ) : (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ACTIVATE_TITLE} />
  );
  const icon = donationAssetStatusIcons[`${!isActive}`];

  const disabledMessage = action.unavailabilityReason ? disabledMessages[action.unavailabilityReason] : null;

  return (
    <Operation
      data-test={dataTest}
      title={title}
      icon={icon}
      onClick={doUpdateStatus}
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
      inProgress={action.inAction}
      mode={mode}
    />
  );
};

const OperationUpdateDonationAssetStatusMemo = React.memo(OperationUpdateDonationAssetStatus);

export default OperationUpdateDonationAssetStatusMemo;
