import BigNumber from 'bignumber.js';

import type { BlockchainsResponseAPIModel } from '@/generated/api/ncps-api';
import { BlockchainsApi } from '@/generated/api/ncps-api';
import type {
  AddressAssetHistoryBalanceAPIModel,
  BlockchainInfoSummaryAPIModel,
  BlockchainTypeAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { DictionaryApi } from '@/generated/api/ncps-core/merchant-bo';
import { apiConfigurationFactory, coreConfigurationFactory } from '@/infrastructure/api.provider';

import type { AssetHistoryBalanceData } from './types';

const dictionaryApi = new DictionaryApi(coreConfigurationFactory('auth'));
const blockchainsApi = new BlockchainsApi(apiConfigurationFactory('public'));

const parseHistoryTokenBalance = ({
  balance,
  ...rest
}: AddressAssetHistoryBalanceAPIModel): AssetHistoryBalanceData => ({ balance: new BigNumber(balance, 10), ...rest });

export const queryBlockchains = async (initOverrides?: RequestInit) =>
  (await dictionaryApi.blockchains(initOverrides)).blockchains ?? [];

export interface BlockchainSystemInfoAPI extends Omit<BlockchainsResponseAPIModel, 'api' | 'blockchains'> {
  api: Partial<{ [k in BlockchainTypeAPIModel]: BlockchainsResponseAPIModel['api'][k] }>;
  blockchains: { [k in keyof BlockchainInfoSummaryAPIModel]: Partial<BlockchainInfoSummaryAPIModel[k]> };
}

export const queryBlockchainSystemInfo = async (initOverrides?: RequestInit): Promise<BlockchainSystemInfoAPI> => {
  const response = await blockchainsApi.blockchains(initOverrides);
  return { ...response, blockchains: response.blockchains as BlockchainSystemInfoAPI['blockchains'] };
};

export const queryAssets = async (initOverrides?: RequestInit) =>
  (await dictionaryApi.assets(initOverrides)).assets ?? [];

export const queryTokenHistoryBalance = async (
  assetId: string,
  address: string,
  blockNum: string,
  initOverrides?: RequestInit,
) =>
  parseHistoryTokenBalance(await dictionaryApi.getAddressAssetBlockBalance(address, assetId, blockNum, initOverrides));
