import React from 'react';

import type { CollectableBalanceViewProps } from '@/features/collectable/components';
import { CollectableBalanceView } from '@/features/collectable/components';

import { withCollectableBalanceDataLoading } from './hocs';

const CollectableBalanceViewCardHOC =
  withCollectableBalanceDataLoading<CollectableBalanceViewProps>(CollectableBalanceView);

const CollectableBalanceViewCardMemo: typeof CollectableBalanceViewCardHOC = React.memo(CollectableBalanceViewCardHOC);

export default CollectableBalanceViewCardMemo;
