import { PageContainer } from '@/components';
import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withPageParams } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { enumByKey } from '@/infrastructure/utils/ts';
import MerchantWalletLink from '@/pages/audit/wallet-view/components/MerchantWalletLink';

import { QRDelegateContractBreadcrumb, QRDelegateContractCard } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

interface QRDelegateContractPageParams {
  bt: BlockchainTypeAPIModel;
}

const paramParser = (
  params: Partial<Record<keyof QRDelegateContractPageParams, string | undefined>>,
): QRDelegateContractPageParams | undefined => {
  try {
    const bt = enumByKey(BlockchainTypeAPIModel, params.bt);
    return bt ? { bt } : undefined;
  } catch {
    return undefined;
  }
};

type QRDelegateContractPageProps = PropsWithChildren<TestableProps & { pageParams: QRDelegateContractPageParams }>;

const QRDelegateContractPage: React.FC<QRDelegateContractPageProps> = ({
  'data-test': dataTest = 'contract',
  pageParams: { bt },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<QRDelegateContractBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled bt={bt} />}
  >
    <QRDelegateContractCard
      data-test={dataTest && `${dataTest}-contract`}
      bt={bt}
      MerchantWalletLink={MerchantWalletLink}
    />
  </PageContainer>
);

const QRDelegateContractPageWithParam = withPageParams<
  keyof QRDelegateContractPageParams,
  QRDelegateContractPageParams
>(paramParser)(QRDelegateContractPage);

export default QRDelegateContractPageWithParam;
