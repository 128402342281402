import React from 'react';

import {
  MerchantWalletDeployTransactionView,
  MerchantWalletDeployTransactionsList,
} from '@/features/merchant-wallets/components';

import { withMerchantWalletTransactionsDataLoading, withMerchantWalletAddressGuard } from './hocs';

const MerchantWalletTransactionsCard = withMerchantWalletAddressGuard(
  withMerchantWalletTransactionsDataLoading((props) =>
    !props.data.length || props.data.length > 1 ? (
      <MerchantWalletDeployTransactionsList {...props} />
    ) : (
      <MerchantWalletDeployTransactionView {...props} data={props.data[0]} />
    ),
  ),
);

const MerchantWalletTransactionsCardMemo: typeof MerchantWalletTransactionsCard =
  React.memo(MerchantWalletTransactionsCard);

export default MerchantWalletTransactionsCardMemo;
