import { useCallback, useMemo } from 'react';

import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { useListBlockchains } from '@/features/dictionary/blockchain/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWallets } from '@/features/merchant-wallets/actions';
import { makeSelectMerchantWallets } from '@/features/merchant-wallets/selectors';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { useUser } from '@/features/user/hooks';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';

const dataSelector = makeSelectMerchantWallets();
const dataFetchingSelector = makeSelectPending(fetchMerchantWallets);
const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<MerchantWallet[]>, Parameters<typeof fetchMerchantWallets>[0]> =>
  fetchMerchantWallets({ force });

export default function useMerchantWallets(network?: BlockchainNetworkTypeAPIModel) {
  const {
    data: { data: user },
  } = useUser();
  const walletsState = useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector, !!user);

  const blockchainsState = useListBlockchains(network);
  const networkBlockchain = useMemo(
    () =>
      (
        (network ? blockchainsState.data.data?.filter(({ net }) => network === net) : blockchainsState.data.data) ?? []
      ).map(({ blockchain }) => blockchain),
    [blockchainsState.data.data, network],
  );
  const forceRefresh = useCallback(
    async () =>
      mapStoredState(await walletsState.forceRefresh(), (wallets) =>
        network ? wallets.filter(({ blockchain }) => networkBlockchain.includes(blockchain)) : wallets,
      ),
    [network, networkBlockchain, walletsState],
  );

  const data = useMemo(
    () =>
      mapStoredState(walletsState.data, (wallets) =>
        network ? wallets.filter(({ blockchain }) => networkBlockchain.includes(blockchain)) : wallets,
      ),
    [network, networkBlockchain, walletsState.data],
  );

  return { forceRefresh, data, loading: walletsState.loading };
}
