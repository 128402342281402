import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { MerchantWalletIssue } from '@/features/merchant-wallets/types';
import type {
  BlockchainTypeAPIModel,
  MerchantWalletAPIModel,
  MerchantWalletSignatureCreateAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletStatusAPIModel, UserApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';

const userAPI = new UserApi(coreConfigurationFactory('auth'));

const detectMerchantWalletIssue = (wallet: MerchantWalletAPIModel) => {
  switch (wallet.status) {
    case MerchantWalletStatusAPIModel.Defined:
      return MerchantWalletIssue.NOT_DEPLOYED;
    case MerchantWalletStatusAPIModel.Deploying:
      return MerchantWalletIssue.DEPLOYING;
    case MerchantWalletStatusAPIModel.DeployFailed:
      return MerchantWalletIssue.FAILED;
    case MerchantWalletStatusAPIModel.Invalid:
      return MerchantWalletIssue.INVALID;
    case MerchantWalletStatusAPIModel.Deployed:
      return undefined;
  }
};
const parseMerchantWallet = (wallet: MerchantWalletAPIModel): MerchantWallet => ({
  ...wallet,
  issue: detectMerchantWalletIssue(wallet),
});

export const queryMerchantWallets = async (initOverrides?: RequestInit): Promise<MerchantWallet[]> =>
  ((await userAPI.getMerchantWallets(initOverrides)).wallets ?? []).map(parseMerchantWallet);

export const queryMerchantWalletDeployTransactions = async (
  blockchain: BlockchainTypeAPIModel,
  address: string,
  initOverrides?: RequestInit,
) => (await userAPI.getWalletDeployTransactions(blockchain, address, initOverrides)).transactions ?? [];

export const queryOwnedCompanyWalletSignature = async (initOverrides?: RequestInit) =>
  await userAPI.getOwnedCompanyWalletSignature(initOverrides);

export const requestSaveOwnedCompanyWalletSignature = async (
  signature: MerchantWalletSignatureCreateAPIModel,
  initOverrides?: RequestInit,
) => await userAPI.confirmOwnedCompanyWalletOwnership(signature, initOverrides);

export const requestRefreshMerchantWallet = async (
  blockchain: BlockchainTypeAPIModel,
  address?: string,
  initOverrides?: RequestInit,
): Promise<MerchantWallet> =>
  parseMerchantWallet(await userAPI.refreshMerchantWallet(blockchain, {}, address, initOverrides));
