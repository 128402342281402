import { Alert, Tag } from 'antd';
import React, { useMemo } from 'react';

import { WarningTooltip } from '@/components';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import { withGasWallet } from '@/features/gas-wallets/hocs';

import type { GasWalletDerivedBalanceCheckBannerProps } from './types';

const type = 'derived-balance';

const GasWalletDerivedBalanceCheckBannerRaw: React.FC<GasWalletDerivedBalanceCheckBannerProps> = ({
  'data-test': dataTest,
  wallet,
  mode,
}) => {
  const checkPassed = useMemo(
    () => !wallet.lowWatermark || wallet.derivedBalance.gt(wallet.lowWatermark),
    [wallet.derivedBalance, wallet.lowWatermark],
  );
  if (checkPassed) {
    return null;
  }
  switch (mode) {
    case 'alert':
      return (
        <Alert
          message={<GasWalletInvalidStateMessage mode="short" wallet={wallet} type={type} />}
          description={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}
          data-test={dataTest}
          type="error"
          showIcon
        />
      );
    case 'icon':
      return (
        <WarningTooltip type="error" title={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}>
          <Tag color="error">
            <GasWalletInvalidStateMessage mode="tag" wallet={wallet} type={type} />
          </Tag>
        </WarningTooltip>
      );
    case 'message':
      return <GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />;
  }
};

const GasWalletDerivedBalanceCheckBanner = React.memo(
  withGasWallet<GasWalletDerivedBalanceCheckBannerProps>(GasWalletDerivedBalanceCheckBannerRaw),
);

export default GasWalletDerivedBalanceCheckBanner;
