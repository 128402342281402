import { List } from 'antd';
import React, { useCallback, useMemo } from 'react';

import type { DonationAddress } from '@/features/donations/types';
import { stringComparator } from '@/infrastructure/model/comparators';

import { DonationAssetItem } from './components';

import type { DonationAssetsListProps } from './types';

const addressComparator = stringComparator(({ asset }: DonationAddress) => asset);

const DonationAssetsList: React.FC<DonationAssetsListProps> = ({
  'data-test': dataTest,
  data: baseData,
  donationId,
  DonationAssetLink,
}) => {
  const data = useMemo(() => [...baseData].sort(addressComparator('ASC')), [baseData]);
  return (
    <List
      data-test={dataTest}
      itemLayout="horizontal"
      loadMore={false}
      style={{ width: '100%' }}
      dataSource={data}
      renderItem={useCallback(
        (address: DonationAddress) => (
          <DonationAssetItem
            data-test={dataTest && `${dataTest}-${address.asset}`}
            data={address}
            donationId={donationId}
            DonationAssetLink={DonationAssetLink}
          />
        ),
        [DonationAssetLink, dataTest, donationId],
      )}
    />
  );
};

const DonationAssetsListMemo = React.memo(DonationAssetsList);

export default DonationAssetsListMemo;
