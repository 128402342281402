import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import CollectableBalanceEntityLink from '@/pages/balances/collectable-entity/components/CollectableBalanceEntityLink';
import type { CollectableBalanceEntityParams } from '@/pages/balances/hocs';
import { withCollectableEntityPageParams } from '@/pages/balances/hocs';
import CollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';

import { CollectableBalanceViewBreadcrumb, CollectableBalanceViewCard } from './components';

import type React from 'react';

type CollectableBalanceEntityPageProps = { pageParams: CollectableBalanceEntityParams } & TestableProps;

const CollectableBalanceEntityPage: React.FC<CollectableBalanceEntityPageProps> = ({
  'data-test': dataTest = 'collectable',
  pageParams,
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <CollectableBalanceViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled id={pageParams} />
    }
  >
    <CollectableBalanceViewCard
      data-test={dataTest && `${dataTest}-entity`}
      id={pageParams}
      CollectTaskLink={CollectTaskLink}
      CollectableEntityLink={CollectableBalanceEntityLink}
    />
  </PageContainer>
);

const CollectableBalanceEntityPageWithParam = withCollectableEntityPageParams(CollectableBalanceEntityPage);

export default CollectableBalanceEntityPageWithParam;
