import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPagePayouts } from '@/generated/i18n/i18n';
import PayoutsBreadcrumb from '@/pages/payouts/components/PayoutsBreadcrumb';

import PayoutViewLink from '../PayoutViewLink';

import type { PayoutViewBreadcrumbProps } from './types';

const PayoutViewBreadcrumb: React.FC<PayoutViewBreadcrumbProps> = ({
  'data-test': dataTest,
  payoutId,
  disabled,
  items = [],
}) => (
  <PayoutsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPagePayouts.PAYOUT_VIEW_COMPONENTS_BREADCRUMB} values={{ id: payoutId }} />
        ) : (
          <PayoutViewLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={payoutId}
            title={
              <FormattedMessage id={I18nPagePayouts.PAYOUT_VIEW_COMPONENTS_BREADCRUMB} values={{ id: payoutId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const PayoutViewBreadcrumbMemo = React.memo(PayoutViewBreadcrumb);

export default PayoutViewBreadcrumbMemo;
