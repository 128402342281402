import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletsLink } from '@/pages/audit/routes';

import type { MerchantWalletsLinkProps } from './types';

const MerchantWalletsLink: React.FC<MerchantWalletsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  icon,
  title,
}) => (
  <Link
    data-test={dataTest}
    to={auditWalletsLink()}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageAudit.WALLETS_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const MerchantWalletsLinkMemo = React.memo(MerchantWalletsLink);

export default MerchantWalletsLinkMemo;
