import { Alert, Tag } from 'antd';
import React from 'react';

import { WarningTooltip } from '@/components';
import GasWalletInvalidStateMessage from '@/features/gas-wallets/components/GasWalletInvalidStateMessage';
import { withGasWallet } from '@/features/gas-wallets/hocs';

import type { GasWalletAnyOutOfServiceCheckBannerProps } from './types';

const type = 'any-service';

const GasWalletAnyOutOfServiceCheckBannerRaw: React.FC<GasWalletAnyOutOfServiceCheckBannerProps> = ({
  'data-test': dataTest,
  wallet,
  mode,
}) => {
  if (!wallet.isAnyOutOfService) {
    return null;
  }
  switch (mode) {
    case 'alert':
      return (
        <Alert
          message={<GasWalletInvalidStateMessage mode="short" wallet={wallet} type={type} />}
          description={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}
          data-test={dataTest}
          type="warning"
          showIcon
        />
      );
    case 'icon':
      return (
        <WarningTooltip title={<GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />}>
          <Tag color="warning">
            <GasWalletInvalidStateMessage mode="tag" wallet={wallet} type={type} />
          </Tag>
        </WarningTooltip>
      );
    case 'message':
      return <GasWalletInvalidStateMessage mode="full" wallet={wallet} type={type} />;
  }
};

const GasWalletAnyOutOfServiceCheckBanner = React.memo(
  withGasWallet<GasWalletAnyOutOfServiceCheckBannerProps>(GasWalletAnyOutOfServiceCheckBannerRaw),
);

export default GasWalletAnyOutOfServiceCheckBanner;
