import { useAppListDataParameters, useAppSelector } from '@/app/hooks';
import { storePaymentListParameters } from '@/features/payments/actions';
import { makeSelectPaymentListParameters } from '@/features/payments/selectors';
import type { PaymentFilterPredicate } from '@/features/payments/types';
import type { PaymentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { UsePaymentListParameters } from './types';

const selectPaymentListParameters = makeSelectPaymentListParameters();

export default function usePaymentListParameters(): UsePaymentListParameters {
  const state = useAppSelector(selectPaymentListParameters);
  return useAppListDataParameters<Omit<PaymentFilterPredicate, 'network'>, PaymentSortByAPIModel>(
    state,
    storePaymentListParameters,
  );
}
