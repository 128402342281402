import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { useWeb3AuthEnabled } from '@/features/auth/hooks';
import { useSingleRun, useStateMountSafe } from '@/hooks';
import { suppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import Web3AuthContext from './context';

import type { Web3AuthContextType } from './context';
import type { PropsWithChildren } from 'react';
import type React from 'react';

const withWeb3AuthContext = <P extends PropsWithChildren<EmptyObject>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithWeb3AuthContext',
  )((props) => {
    const [context, setContext] = useStateMountSafe<{ unique?: string } & Web3AuthContextType>({ initialized: false });
    const { withSingleRun, inProgress } = useSingleRun();
    const [tried, setTried] = useStateMountSafe(false);
    const enabled = useWeb3AuthEnabled();
    const initialize = useMemo(
      () =>
        withSingleRun(async () => {
          if ((context.initialized && tried) || inProgress) {
            return;
          }
          if (!enabled) {
            setContext({ initialized: true });
            return;
          }
          try {
            const onUpdate = () => setContext((prev) => ({ ...prev, unique: uuid() }));
            const { default: web3Auth } = await import('@/infrastructure/security/web3-auth/web3-auth');
            const created = await web3Auth.create({ onUpdate });
            setContext({ initialized: true, web3Auth: created });
          } catch (err) {
            console.warn(err);
            setContext({ initialized: true });
          } finally {
            setTried(true);
          }
        }),
      [withSingleRun, context.initialized, tried, inProgress, enabled, setContext, setTried],
    );
    if (!inProgress && (!context.initialized || (!tried && enabled))) {
      suppressPromise(initialize());
    }
    return (
      <Web3AuthContext.Provider value={context}>
        <Component {...props} />
      </Web3AuthContext.Provider>
    );
  });

export default withWeb3AuthContext;
