/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventTypeAPIModel {
    PaymentCreated = 'PaymentCreated',
    PaymentStatusChanged = 'PaymentStatusChanged',
    SubscriptionCreated = 'SubscriptionCreated',
    SubscriptionStatusChanged = 'SubscriptionStatusChanged',
    SubscriptionChargeCreated = 'SubscriptionChargeCreated',
    SubscriptionChargeStatusChanged = 'SubscriptionChargeStatusChanged',
    SubscriptionCharged = 'SubscriptionCharged',
    RechargeAddressRegistered = 'RechargeAddressRegistered',
    RechargeTransactionProvided = 'RechargeTransactionProvided',
    DonationCreated = 'DonationCreated',
    DonationUpdated = 'DonationUpdated',
    DonationTransactionProvided = 'DonationTransactionProvided'
}


export function EventTypeAPIModelFromJSON(json: any): EventTypeAPIModel {
    return EventTypeAPIModelFromJSONTyped(json, false);
}

export function EventTypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTypeAPIModel {
    return json as EventTypeAPIModel;
}

export function EventTypeAPIModelToJSON(value?: EventTypeAPIModel | null): any {
    return value as any;
}

