import React from 'react';

import { MerchantWalletsList } from '@/features/merchant-wallets/components';

import { withMerchantWalletsActualDataLoading } from './hocs';

const MerchantWalletsHistoryCard = withMerchantWalletsActualDataLoading(MerchantWalletsList);

const MerchantWalletsHistoryCardMemo: typeof MerchantWalletsHistoryCard = React.memo(
  MerchantWalletsHistoryCard,
) as typeof MerchantWalletsHistoryCard;

export default MerchantWalletsHistoryCardMemo;
