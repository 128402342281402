/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportTypeAPIModel {
    PDF = 'PDF',
    XLS = 'XLS',
    CSV = 'CSV',
    TXT = 'TXT',
    DOC = 'DOC',
    RTF = 'RTF',
    Unknown = 'Unknown'
}


export function ReportTypeAPIModelFromJSON(json: any): ReportTypeAPIModel {
    return ReportTypeAPIModelFromJSONTyped(json, false);
}

export function ReportTypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTypeAPIModel {
    return json as ReportTypeAPIModel;
}

export function ReportTypeAPIModelToJSON(value?: ReportTypeAPIModel | null): any {
    return value as any;
}

