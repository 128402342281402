import { css } from '@emotion/css';
import { Divider, Form } from 'antd';
import noop from 'lodash-es/noop';
import React, { useEffect, useMemo } from 'react';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { PayoutTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { assertNotNil, noopAsync, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { asType, nameof } from '@/infrastructure/utils/ts';

import { PayoutAssetItem, PayoutDestinationsItem, PayoutTitleItem } from './components';

import type { PayoutDestinationRow } from './components';
import type { EditPayoutFormData, EditPayoutFormProps } from './types';
import type { FormInstance } from 'antd/es/form';

const isSomeDestinationsChanged = (
  formDestinations?: PayoutDestinationRow[],
  destinations?: PayoutDestinationRow[],
) => {
  if (formDestinations?.length !== destinations?.length || !destinations || !formDestinations) {
    return true;
  }

  return !!destinations.find(
    ({ num, amount, address }) =>
      !formDestinations.find(
        (destination) => destination.num === num && destination.address === address && destination.amount.eq(amount),
      ),
  );
};

const formMessages = {
  submit: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_SUBMIT_TITLE} tagName="span" />,
};

const fields = [
  nameof<EditPayoutFormData>('asset'),
  nameof<EditPayoutFormData>('title'),
  nameof<EditPayoutFormData>('destinations'),
];

const EditPayoutForm: React.FC<EditPayoutFormProps> = ({
  'data-test': dataTest,
  isNew,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  initialValues,
  style,
  className,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<EditPayoutFormData>();
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useMemo(
    () =>
      withSubmitting(async ({ asset, title, destinations }: EditPayoutFormData) => {
        assertNotNil(asset, () => new Error('invalid form'));
        assertNotNil(title, () => new Error('invalid form'));
        assertNotNil(destinations, () => new Error('invalid form'));
        await onSubmit({
          asset,
          title,
          // FIXME: we intentionally use only MerkleTree type to avoid the necessity of the nonce management for multiple payouts
          mode: PayoutTypeAPIModel.MerkleTree,
          destinations: destinations.map(({ amount, ...destination }, idx) => ({
            ...destination,
            num: idx,
            amount: { asset, value: amount },
          })),
        });
        setTimeout(doReset, 0);
      }),
    [doReset, onSubmit, withSubmitting],
  );

  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const [isBeingEdited, setBeingEdited] = useStateMountSafe(false);
  const [isFormReady, setFormReady] = useStateMountSafe(false);

  const doSubmitForm = useMemo(() => (isFormReady ? doSubmit : noopAsync), [doSubmit, isFormReady]);

  const checkIsComplete = useMemo(() => {
    const values = initialValues;
    if (!values || isNew) {
      return () => true;
    }
    return ({ getFieldValue }: FormInstance<EditPayoutFormData>) => {
      const formAsset: string | undefined = getFieldValue(nameof<EditPayoutFormData>('asset'));
      const formTitle = asType<string | undefined>(getFieldValue(nameof<EditPayoutFormData>('title')))?.trim();
      const destinations: PayoutDestinationRow[] | undefined = getFieldValue(
        nameof<EditPayoutFormData>('destinations'),
      );
      return (
        values.title !== formTitle
        || values.asset !== formAsset
        || isSomeDestinationsChanged(destinations, initialValues.destinations)
      );
    };
  }, [initialValues, isNew]);

  useEffect(() => {
    const readiness = isFormComplete && !isBeingEdited;
    if (readiness !== isFormReady) {
      setFormReady(readiness);
      const submitFn = readiness
        ? withVoidOrThrow(
            wrap(
              async () => doSubmit(form.getFieldsValue()),
              () => onSubmitting?.(true),
              () => onSubmitting?.(false),
            ),
          )
        : undefined;
      submitCallback?.(submitFn);
    }
  }, [
    doSubmit,
    doSubmitForm,
    form,
    isBeingEdited,
    isFormComplete,
    isFormReady,
    onSubmitting,
    setFormReady,
    submitCallback,
  ]);
  return (
    <Form<EditPayoutFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      style={style}
      className={className}
      {...layout}
      initialValues={initialValues}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <PayoutAssetItem<EditPayoutFormData>
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<EditPayoutFormData>('asset')}
        readonly={!isNew}
      />
      <PayoutTitleItem<EditPayoutFormData>
        data-test={dataTest && `${dataTest}-title`}
        name={nameof<EditPayoutFormData>('title')}
        readonly={!isNew}
        required
      />
      <Divider
        className={css`
          margin: 0;
        `}
      />
      <PayoutDestinationsItem<EditPayoutFormData>
        data-test={dataTest && `${dataTest}-destinations`}
        name={nameof<EditPayoutFormData>('destinations')}
        assetItemName={nameof<EditPayoutFormData>('asset')}
        required
        onStateChange={setBeingEdited}
      />
      <FormCompletenessItem<EditPayoutFormData>
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={checkIsComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { display: 'none' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const EditPayoutFormMemo = React.memo(EditPayoutForm);

export default EditPayoutFormMemo;
