import { css } from '@emotion/css';
import { Spin } from 'antd';
import debounce from 'lodash-es/debounce';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { FormattedMessage } from '@/components';
import type { BrandingData } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { withFinally } from '@/infrastructure/utils/functions';

import { useBrandingDataPreviewMessaging } from './hooks';

import type { BrandingDataPreviewProps } from './types';

const debounceTimeout = 1000;

const BrandingDataPreview: React.FC<BrandingDataPreviewProps> = ({ initialValue, onUpdaterReady }) => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [widgetLoaded, updateWidgetLoadingState] = useStateMountSafe(false);
  const setWidgetLoaded = useCallback(() => updateWidgetLoadingState(true), [updateWidgetLoadingState]);
  const [updating, setUpdating] = useStateMountSafe(false);
  const { sendBrandingUpdate } = useBrandingDataPreviewMessaging(ref, setWidgetLoaded, initialValue);
  const showBranding = useMemo(
    () => withFinally(sendBrandingUpdate, () => setUpdating(false)),
    [sendBrandingUpdate, setUpdating],
  );
  const debouncedUpdate = useMemo(() => debounce(showBranding, debounceTimeout), [showBranding]);
  useEffect(() => {
    setTimeout(
      () =>
        onUpdaterReady(async (data: BrandingData) => {
          setUpdating(true);
          await debouncedUpdate(data);
        }),
      0,
    );
  }, [onUpdaterReady, debouncedUpdate, setUpdating]);

  const isWidgetReady = ref.current && widgetLoaded;

  return (
    <Spin
      wrapperClassName={css`
        height: 100%;
        max-height: unset;
        border: 1px solid lightgray;
        padding: 7px;
        overflow-y: auto;
        background: #eee;

        > div.ant-spin-container {
          height: 100%;
          max-height: unset;

          > iframe {
            border: 0;
            height: 100%;
            width: 100%;
          }
        }
      `}
      spinning={updating || !widgetLoaded}
      tip={updating && <FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_PREVIEW_UPDATING} />}
    >
      <iframe
        hidden={!isWidgetReady}
        ref={ref}
        src={window.env.NCPS_PAYMENTS_WIDGET_BRANDING_PREVIEW_URL}
        title="preview"
      />
    </Spin>
  );
};

const BrandingDataPreviewMemo = React.memo(BrandingDataPreview);

export default BrandingDataPreviewMemo;
