import isNil from 'lodash-es/isNil';
import { useCallback, useMemo } from 'react';

import type { PayoutBatch, PayoutDestination } from '@/features/payouts/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { flatCombine, storedDataLoaded, storedDataError } from '@/infrastructure/model';

import usePayoutBatches from '../usePayoutBatches';
import usePayoutDestinations from '../usePayoutDestinations';

import type { PayoutBatchDetailed, UsePayoutBatch } from './types';

const combineState = (
  batchesState: LoadingStateWithDirty<PayoutBatch[]>,
  destinationsState: LoadingStateWithDirty<PayoutDestination[]>,
  batchNum: number | undefined,
) =>
  !isNil(batchNum)
    ? flatCombine(batchesState, destinationsState, (batches, destinations) => {
        const batch = batches.find(({ num }) => num === batchNum);
        return batch
          ? storedDataLoaded({
              ...batch,
              destinations: destinations.filter(({ batchNum }) => batchNum === batch.num),
            })
          : storedDataError<PayoutBatchDetailed>('No batch');
      })
    : storedDataError<PayoutBatchDetailed>('No batch number');

export default function usePayoutBatch(payoutId: string | undefined, batchNum: number | undefined): UsePayoutBatch {
  const batches = usePayoutBatches(payoutId);
  const destinations = usePayoutDestinations(payoutId);

  const forceRefresh = useCallback(async () => {
    const [batchesState, destinationsState] = await Promise.all([batches.forceRefresh(), destinations.forceRefresh()]);
    return combineState(batchesState, destinationsState, batchNum);
  }, [batchNum, batches, destinations]);
  const data = useMemo(
    () => combineState(batches.data, destinations.data, batchNum),
    [batchNum, batches.data, destinations.data],
  );
  const loading = batches.loading || destinations.loading;
  return useMemo(() => ({ data, loading, forceRefresh }), [data, forceRefresh, loading]);
}
