import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import DonationsBreadcrumb from '@/pages/donations/donations/components/DonationsBreadcrumb';

import DonationAddLink from '../DonationAddLink';

import type { DonationAddBreadcrumbProps } from './types';

const DonationAddBreadcrumb: React.FC<DonationAddBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <DonationsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageDonations.DONATION_ADD_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <DonationAddLink
            title={<FormattedMessage id={I18nPageDonations.DONATION_ADD_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="link"
          />
        ),
      },
      ...items,
    ]}
  />
);

const DonationAddBreadcrumbMemo = React.memo(DonationAddBreadcrumb);

export default DonationAddBreadcrumbMemo;
