import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import type { AuditBreadcrumbProps } from '@/pages/audit/components/AuditBreadcrumb/types';

const AuditBreadcrumb: React.FC<AuditBreadcrumbProps> = ({ items = [], 'data-test': dataTest }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[{ title: <FormattedMessage id={I18nPageAudit.COMPONENTS_BREADCRUMB} /> }, ...items]}
  />
);

const AuditBreadcrumbMemo = React.memo(AuditBreadcrumb);

export default AuditBreadcrumbMemo;
