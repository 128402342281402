import { useMemo } from 'react';

import type { AssetForSelection, AssetSelectIssueRender } from '@/features/dictionary/blockchain/components';
import { useIsFeatureEnabled } from '@/features/feature-toggle/hooks';
import { MerchantWalletIssue } from '@/features/merchant-wallets/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingStateWithDirty, mapStoredState } from '@/infrastructure/model';

import useCompanyActiveAssets from '../useCompanyActiveAssets';

import { CompanyActiveAssetWalletIssueBanner } from './components';

import type { CompanyActiveAssetsForSelectIssue } from './types';

export const CompanyActiveAssetsForSelectIssueRender: Record<
  CompanyActiveAssetsForSelectIssue,
  AssetSelectIssueRender
> = {
  'wallet-issue': CompanyActiveAssetWalletIssueBanner,
  'wallet-invalid': CompanyActiveAssetWalletIssueBanner,
};

export default function useActiveAssetsForSelect({
  excludeAPI,
  disableInvalidWallet,
  disableWalletWithIssue,
}: {
  excludeAPI?: BlockchainAPITypeAPIModel | BlockchainAPITypeAPIModel[];
  disableInvalidWallet?: boolean;
  disableWalletWithIssue?: boolean;
}) {
  const { data: activeAssetsData, forceRefresh, loading } = useCompanyActiveAssets();

  const isTronDisabled = useIsFeatureEnabled('disableTron');

  const data = useMemo<LoadingStateWithDirty<AssetForSelection<CompanyActiveAssetsForSelectIssue | undefined>[]>>(
    () =>
      mapStoredState(activeAssetsData, (assets) => {
        const baseExcludeAPIs = excludeAPI && (Array.isArray(excludeAPI) ? excludeAPI : [excludeAPI]);
        const excludeAPIs = isTronDisabled
          ? [...(baseExcludeAPIs ?? []), BlockchainAPITypeAPIModel.Tron]
          : baseExcludeAPIs;
        const valuesExcludedAPI = excludeAPIs
          ? assets.filter((v) => !v.apiType || !excludeAPIs.includes(v.apiType))
          : assets;

        const limitedByAPIAssets = valuesExcludedAPI.filter((v) => v.apiType !== BlockchainAPITypeAPIModel.BTC);
        return limitedByAPIAssets.map((asset) => ({
          ...asset,
          issue:
            // eslint-disable-next-line no-nested-ternary
            disableInvalidWallet && asset.walletIssue === MerchantWalletIssue.INVALID
              ? 'wallet-invalid'
              : disableWalletWithIssue && asset.walletIssue
                ? 'wallet-issue'
                : undefined,
        }));
      }),
    [activeAssetsData, disableInvalidWallet, disableWalletWithIssue, excludeAPI, isTronDisabled],
  );

  return { data, forceRefresh, loading };
}
