import { useCallback, useMemo } from 'react';

import { DataFetchError, FormattedMessage, PageCard, PageContainer } from '@/components';
import { EditDonationForm } from '@/features/donations/components';
import type { EditDonationFormData } from '@/features/donations/components/EditDonationForm/types';
import { useDonation, useDonationActions } from '@/features/donations/hooks';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useFormPageAction,
  useNotification,
  useStateMountSafe,
} from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import withDonationPageParams from '@/pages/donations/hocs/withDonationPageParams';
import { donationsLink } from '@/pages/donations/routes';

import { DonationEditBreadcrumb } from './components';
import { useInitialValues } from './hooks';

import type React from 'react';

interface DonationEditPageProps extends TestableProps {
  pageParams: { donationId: string };
}

const DonationEditPage: React.FC<DonationEditPageProps> = ({
  'data-test': dataTest = 'donation-edit',
  pageParams: { donationId },
}) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { data: donation, loading, forceRefresh } = useDonation(donationId);
  const { update: updateAction, updateImage: updateImageAction } = useDonationActions(donationId);
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(donationsLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const [submitting, setSubmitting] = useStateMountSafe(false);
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  useFirstRenderEffect(() => {
    goalReached(YMGoals.DONATION_UPDATE_INIT);
  });

  const initialValues = useInitialValues(donation);

  const doEdit = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(
            async ({ assets, imageKey, title, description }: EditDonationFormData) => {
              await updateAction.act({
                id: donationId,
                title,
                description,
                activeAssets: assets.filter(({ isActive }) => isActive).map(({ asset }) => asset),
                defaultAmounts: assets.flatMap(({ defaultAmounts }) => defaultAmounts),
              });
              if (imageKey) {
                await updateImageAction.act(imageKey);
              }
            },
            () => <FormattedMessage id={I18nPageDonations.DONATION_EDIT_MESSAGES_SUCCESS} />,
          ),
          () => <FormattedMessage id={I18nPageDonations.DONATION_EDIT_MESSAGES_ERROR} />,
        ),
        () => {
          goalReached(YMGoals.DONATION_UPDATED);
          doRedirect();
        },
      ),
    [withDefaultError, withSuccess, updateAction, donationId, updateImageAction, doRedirect],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={
        <DonationEditBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled donationId={donationId} />
      }
      submit={submit}
      submitting={submitting}
      cancel={doRedirect}
    >
      <PageCard loading={(!initialValues.data && initialValues.isDirty) || loading} cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data ? (
            !initialValues.isDirty ? (
              <DataFetchError refresh={forceRefresh} back message={initialValues.error} />
            ) : null
          ) : (
            <EditDonationForm
              initialValues={initialValues.data}
              data-test={dataTest && `${dataTest}-form`}
              onSubmit={doEdit}
              onSubmitting={setSubmitting}
              onReset={doRedirect}
              submitCallback={updateOnSubmit}
            />
          )
        }
      </PageCard>
    </PageContainer>
  );
};

export default withDonationPageParams(DonationEditPage);
