import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import type { CollectableBalanceFilterPredicate } from '@/features/collectable/types';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import {
  CollectableBalanceAddressFilterItem,
  CollectableKindFilterItem,
} from './components';

import type { CollectableBalanceFilterFormProps } from './types';

const fields = [
  nameof<CollectableBalanceFilterPredicate>('calculatedAtRange'),
  nameof<CollectableBalanceFilterPredicate>('addressLike'),
  nameof<CollectableBalanceFilterPredicate>('kindIn'),
  nameof<CollectableBalanceFilterPredicate>('assetIn'),
  nameof<CollectableBalanceFilterPredicate>('btIn'),
];

const calculatedAtRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_FILTER_FORM_PROCESS_AT_TO_LABEL} />,
  },
  from: {
    label: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_FILTER_FORM_PROCESS_AT_FROM_LABEL} />,
  },
};

const CollectableBalanceFilterForm: React.FC<CollectableBalanceFilterFormProps> = ({ hidden, ...props }) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<CollectableBalanceFilterPredicate> fields={fields} {...props}>
      {!hidden?.calculatedAtRange ? (
        <DateTimeRangeItem<CollectableBalanceFilterPredicate>
          data-test={dataTest && `${dataTest}-calculatedAt`}
          name={nameof<CollectableBalanceFilterPredicate>('calculatedAtRange')}
          messages={calculatedAtRangeMessages}
          readonly={readonly}
          required={false}
          relativeUpdateKey="calculatedAtRangeRelative"
        />
      ) : null}
      {!hidden?.addressLike ? (
        <CollectableBalanceAddressFilterItem
          data-test={dataTest && `${dataTest}-addressLike`}
          name={nameof<CollectableBalanceFilterPredicate>('addressLike')}
          readonly={readonly}
          required={false}
        />
      ) : null}
      {!hidden?.btIn ? (
        <BlockchainItem
          data-test={dataTest && `${dataTest}-blockchain`}
          name={nameof<CollectableBalanceFilterPredicate>('btIn')}
          multiselect
          network={network}
          readonly={readonly}
          required={false}
        />
      ) : null}
      {!hidden?.assetIn ? (
        <AssetItem
          data-test={dataTest && `${dataTest}-asset`}
          name={nameof<CollectableBalanceFilterPredicate>('assetIn')}
          multiselect
          assetType="exchangeable"
          network={network}
          readonly={readonly}
          required={false}
        />
      ) : null}
      {!hidden?.kindIn ? (
        <CollectableKindFilterItem
          data-test={dataTest && `${dataTest}-kind`}
          name={nameof<CollectableBalanceFilterPredicate>('kindIn')}
          readonly={readonly}
          required={false}
        />
      ) : null}
    </FilterForm>
  );
};

const CollectableBalanceFilterFormMemo = React.memo(CollectableBalanceFilterForm);

export default CollectableBalanceFilterFormMemo;
