import React from 'react';

import type { AssetSelectIssueRender } from '@/features/dictionary/blockchain/components';
import { MerchantWalletIssueBanner } from '@/features/merchant-wallets/components';

const CompanyActiveAssetWalletIssueBanner: AssetSelectIssueRender = ({ 'data-test': dataTest, assetId }) => (
  <MerchantWalletIssueBanner
    data-test={dataTest && `${dataTest}-${assetId}-banner`}
    assetId={assetId}
    mode="message"
    type="link"
  />
);

const CompanyActiveAssetWalletIssueBannerMemo = React.memo(CompanyActiveAssetWalletIssueBanner);

export default CompanyActiveAssetWalletIssueBannerMemo;
