import React from 'react';

import { QRDelegateContractsList } from '@/features/company-settings/components';

import { withQRDelegateContractsDataLoading } from './hocs';

const QRDelegateContractListCard = withQRDelegateContractsDataLoading(QRDelegateContractsList);

const QRDelegateContractListCardMemo: typeof QRDelegateContractListCard = React.memo(
  QRDelegateContractListCard,
) as typeof QRDelegateContractListCard;

export default QRDelegateContractListCardMemo;
