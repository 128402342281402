import React, { useMemo } from 'react';

import {
  type CompanyActiveAssetsForSelectIssue,
  CompanyActiveAssetsForSelectIssueRender,
} from '@/features/company-settings/hooks';
import { AssetSelectItem } from '@/features/dictionary/blockchain/components';
import { useAssetsForDonation } from '@/features/donations/hooks';

import type { DonationAssetItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const ItemProps = { label: null, wrapperCol: { span: 24 }, labelCol: { span: 0 } };
const SelectInputProps = { allowClear: false };
const validation = { allowMultipleBlockchains: true };

const DonationAssetItem = <Values extends Store = Store>({
  initialValue,
  ...props
}: DonationAssetItemProps<Values>) => {
  const assets = useAssetsForDonation();
  return (
    <AssetSelectItem<Values, CompanyActiveAssetsForSelectIssue | undefined>
      {...props}
      SelectInputProps={SelectInputProps}
      assets={assets}
      ItemProps={useMemo(() => ({ ...ItemProps, initialValue }), [initialValue])}
      Issues={CompanyActiveAssetsForSelectIssueRender}
      validation={validation}
    />
  );
};

const DonationAssetItemMemo = React.memo(DonationAssetItem) as typeof DonationAssetItem;

export default DonationAssetItemMemo;
