import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import CollectTaskStatusView from '@/features/collectable/components/CollectTaskStatusView';
import { CollectTaskStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { CollectTaskStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CollectTaskStatusFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: CollectTaskStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_FILTER_FORM_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={
        <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_FILTER_FORM_STATUS_PLACEHOLDER} />
      }
      options={useMemo(
        () =>
          enumValues(CollectTaskStatusAPIModel).map((code) => ({
            value: code,
            label: <CollectTaskStatusView value={code} mode="full" data-test={dataTest && `${dataTest}-status`} />,
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const CollectTaskStatusFilterItemMemo = React.memo(CollectTaskStatusFilterItem) as typeof CollectTaskStatusFilterItem;

export default CollectTaskStatusFilterItemMemo;
