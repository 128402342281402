/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalIntentAPIModel } from './WithdrawalIntentAPIModel';
import {
    WithdrawalIntentAPIModelFromJSON,
    WithdrawalIntentAPIModelFromJSONTyped,
    WithdrawalIntentAPIModelToJSON,
} from './WithdrawalIntentAPIModel';

/**
 * 
 * @export
 * @interface GetWithdrawalIntentByIdResponseAPIModel
 */
export interface GetWithdrawalIntentByIdResponseAPIModel {
    /**
     * 
     * @type {WithdrawalIntentAPIModel}
     * @memberof GetWithdrawalIntentByIdResponseAPIModel
     */
    intent: WithdrawalIntentAPIModel;
}

/**
 * Check if a given object implements the GetWithdrawalIntentByIdResponseAPIModel interface.
 */
export function instanceOfGetWithdrawalIntentByIdResponseAPIModel(value: object): boolean {
    if (!('intent' in value)) return false;
    return true;
}

export function GetWithdrawalIntentByIdResponseAPIModelFromJSON(json: any): GetWithdrawalIntentByIdResponseAPIModel {
    return GetWithdrawalIntentByIdResponseAPIModelFromJSONTyped(json, false);
}

export function GetWithdrawalIntentByIdResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWithdrawalIntentByIdResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'intent': WithdrawalIntentAPIModelFromJSON(json['intent']),
    };
}

export function GetWithdrawalIntentByIdResponseAPIModelToJSON(value?: GetWithdrawalIntentByIdResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'intent': WithdrawalIntentAPIModelToJSON(value['intent']),
    };
}

