import { Space } from 'antd';
import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { OperationRefreshQRDelegateContracts } from '@/features/company-settings/components';
import { useQRDelegateContracts } from '@/features/company-settings/hooks';
import type { QRDelegateContract } from '@/features/company-settings/types';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

const selectNetworkType = makeSelectSelectedNetwork();

const withQRDelegateContractsActualDataLoading = <
  Original extends { data: QRDelegateContract[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'> = Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithQRDelegateContractsActualDataLoading',
  )((props) => {
    const dataTest = props['data-test'];

    const network = useAppSelector(selectNetworkType);
    const state = useQRDelegateContracts(network);
    return withCardDataLoading<QRDelegateContract[], Original>({
      ...state,
      'data-test': dataTest,
      title: (
        <Space>
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
          <FormattedMessage id={I18nPageAudit.QR_CONTRACTS_COMPONENTS_CARD_TITLE} />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshQRDelegateContracts data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withQRDelegateContractsActualDataLoading;
