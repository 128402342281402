/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  NewStartWithdrawalExternalAPIModel,
  NewWithdrawalBatchAPIModel,
  PayoutAPIModel,
  SliceWithdrawalWithdrawalSortByAPIModel,
  WithdrawalAPIModel,
  WithdrawalBatchAPIModel,
  WithdrawalBatchBTCOutputTransactionAPIModel,
  WithdrawalBatchesAPIModel,
  WithdrawalFeePaymentAPIModel,
  WithdrawalFilterAPIModel,
  WithdrawalPredicatesAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    NewStartWithdrawalExternalAPIModelFromJSON,
    NewStartWithdrawalExternalAPIModelToJSON,
    NewWithdrawalBatchAPIModelFromJSON,
    NewWithdrawalBatchAPIModelToJSON,
    PayoutAPIModelFromJSON,
    PayoutAPIModelToJSON,
    SliceWithdrawalWithdrawalSortByAPIModelFromJSON,
    SliceWithdrawalWithdrawalSortByAPIModelToJSON,
    WithdrawalAPIModelFromJSON,
    WithdrawalAPIModelToJSON,
    WithdrawalBatchAPIModelFromJSON,
    WithdrawalBatchAPIModelToJSON,
    WithdrawalBatchBTCOutputTransactionAPIModelFromJSON,
    WithdrawalBatchBTCOutputTransactionAPIModelToJSON,
    WithdrawalBatchesAPIModelFromJSON,
    WithdrawalBatchesAPIModelToJSON,
    WithdrawalFeePaymentAPIModelFromJSON,
    WithdrawalFeePaymentAPIModelToJSON,
    WithdrawalFilterAPIModelFromJSON,
    WithdrawalFilterAPIModelToJSON,
    WithdrawalPredicatesAPIModelFromJSON,
    WithdrawalPredicatesAPIModelToJSON,
} from '../models/index';

export interface CancelWithdrawalRequest {
    withdrawalId: string;
}

export interface ExportReconciliationTransactionsByWithdrawalPredicatesRequest {
    withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel;
}

export interface ExportReconciliationsByWithdrawalPredicatesRequest {
    withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel;
}

export interface GetWithdrawalRequest {
    withdrawalId: string;
}

export interface GetWithdrawalBatchRequest {
    withdrawalId: string;
    batchNum: number;
}

export interface GetWithdrawalBatchBTCRequest {
    withdrawalId: string;
    batchNum: number;
}

export interface GetWithdrawalBatchesRequest {
    withdrawalId: string;
}

export interface GetWithdrawalFeePaymentRequest {
    withdrawalId: string;
}

export interface GetWithdrawalPayoutsRequest {
    withdrawalId: string;
}

export interface GetWithdrawalsRequest {
    withdrawalFilterAPIModel: WithdrawalFilterAPIModel;
}

export interface PauseWithdrawalRequest {
    withdrawalId: string;
}

export interface PayWithdrawalFeeRequest {
    withdrawalId: string;
}

export interface ResetWithdrawalRequest {
    withdrawalId: string;
}

export interface StartWithdrawalRequest {
    withdrawalId: string;
}

export interface StartWithdrawalExternalRequest {
    withdrawalId: string;
    newStartWithdrawalExternalAPIModel: NewStartWithdrawalExternalAPIModel;
}

export interface UpdateWithdrawalBatchRequest {
    withdrawalId: string;
    batchNum: number;
    newWithdrawalBatchAPIModel: NewWithdrawalBatchAPIModel;
}

/**
 * WithdrawApi - interface
 * 
 * @export
 * @interface WithdrawApiInterface
 */
export interface WithdrawApiInterface {
    /**
     * Cancels withdrawal
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    cancelWithdrawalRaw(requestParameters: CancelWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Cancels withdrawal
     */
    cancelWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Exports reconciliation transactions by withdrawal predicates
     * @param {WithdrawalPredicatesAPIModel} withdrawalPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    exportReconciliationTransactionsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationTransactionsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports reconciliation transactions by withdrawal predicates
     */
    exportReconciliationTransactionsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Exports reconciliations by withdrawal predicates
     * @param {WithdrawalPredicatesAPIModel} withdrawalPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    exportReconciliationsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports reconciliations by withdrawal predicates
     */
    exportReconciliationsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns withdrawal by id
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalRaw(requestParameters: GetWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Returns withdrawal by id
     */
    getWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Returns withdrawal batch by id
     * @param {string} withdrawalId 
     * @param {number} batchNum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalBatchRaw(requestParameters: GetWithdrawalBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchAPIModel>>;

    /**
     * Returns withdrawal batch by id
     */
    getWithdrawalBatch(withdrawalId: string, batchNum: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchAPIModel>;

    /**
     * Returns withdrawal batch by id
     * @param {string} withdrawalId 
     * @param {number} batchNum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalBatchBTCRaw(requestParameters: GetWithdrawalBatchBTCRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchBTCOutputTransactionAPIModel>>;

    /**
     * Returns withdrawal batch by id
     */
    getWithdrawalBatchBTC(withdrawalId: string, batchNum: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchBTCOutputTransactionAPIModel>;

    /**
     * Returns withdrawal batches
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalBatchesRaw(requestParameters: GetWithdrawalBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchesAPIModel>>;

    /**
     * Returns withdrawal batches
     */
    getWithdrawalBatches(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchesAPIModel>;

    /**
     * Returns the withdrawal fee payment
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalFeePaymentRaw(requestParameters: GetWithdrawalFeePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalFeePaymentAPIModel>>;

    /**
     * Returns the withdrawal fee payment
     */
    getWithdrawalFeePayment(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalFeePaymentAPIModel>;

    /**
     * Withdrawal payouts
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalPayoutsRaw(requestParameters: GetWithdrawalPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutAPIModel>>>;

    /**
     * Withdrawal payouts
     */
    getWithdrawalPayouts(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutAPIModel>>;

    /**
     * Returns withdrawals according to filter
     * @param {WithdrawalFilterAPIModel} withdrawalFilterAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    getWithdrawalsRaw(requestParameters: GetWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalWithdrawalSortByAPIModel>>;

    /**
     * Returns withdrawals according to filter
     */
    getWithdrawals(withdrawalFilterAPIModel: WithdrawalFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalWithdrawalSortByAPIModel>;

    /**
     * Stops/pauses currently running withdrawal
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    pauseWithdrawalRaw(requestParameters: PauseWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Stops/pauses currently running withdrawal
     */
    pauseWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Initiates the withdrawal fee payment
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    payWithdrawalFeeRaw(requestParameters: PayWithdrawalFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Initiates the withdrawal fee payment
     */
    payWithdrawalFee(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Resets all VRS in batches that are not processed yet
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    resetWithdrawalRaw(requestParameters: ResetWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Resets all VRS in batches that are not processed yet
     */
    resetWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Starts withdrawal (in the background job)
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    startWithdrawalRaw(requestParameters: StartWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Starts withdrawal (in the background job)
     */
    startWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Starts withdrawal with transactions being sent externally (from MM)
     * @param {string} withdrawalId 
     * @param {NewStartWithdrawalExternalAPIModel} newStartWithdrawalExternalAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    startWithdrawalExternalRaw(requestParameters: StartWithdrawalExternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>>;

    /**
     * Starts withdrawal with transactions being sent externally (from MM)
     */
    startWithdrawalExternal(withdrawalId: string, newStartWithdrawalExternalAPIModel: NewStartWithdrawalExternalAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel>;

    /**
     * Updates withdrawal specific batch
     * @param {string} withdrawalId 
     * @param {number} batchNum 
     * @param {NewWithdrawalBatchAPIModel} newWithdrawalBatchAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawApiInterface
     */
    updateWithdrawalBatchRaw(requestParameters: UpdateWithdrawalBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchAPIModel>>;

    /**
     * Updates withdrawal specific batch
     */
    updateWithdrawalBatch(withdrawalId: string, batchNum: number, newWithdrawalBatchAPIModel: NewWithdrawalBatchAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchAPIModel>;

}

/**
 * 
 */
export class WithdrawApi extends runtime.BaseAPI implements WithdrawApiInterface {

    /**
     * Cancels withdrawal
     */
    async cancelWithdrawalRaw(requestParameters: CancelWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling cancelWithdrawal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/cancel`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Cancels withdrawal
     */
    async cancelWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.cancelWithdrawalRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Exports reconciliation transactions by withdrawal predicates
     */
    async exportReconciliationTransactionsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationTransactionsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['withdrawalPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalPredicatesAPIModel',
                'Required parameter "withdrawalPredicatesAPIModel" was null or undefined when calling exportReconciliationTransactionsByWithdrawalPredicates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/export/reconciliation-transactions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalPredicatesAPIModelToJSON(requestParameters['withdrawalPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports reconciliation transactions by withdrawal predicates
     */
    async exportReconciliationTransactionsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportReconciliationTransactionsByWithdrawalPredicatesRaw({ withdrawalPredicatesAPIModel: withdrawalPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Exports reconciliations by withdrawal predicates
     */
    async exportReconciliationsByWithdrawalPredicatesRaw(requestParameters: ExportReconciliationsByWithdrawalPredicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['withdrawalPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalPredicatesAPIModel',
                'Required parameter "withdrawalPredicatesAPIModel" was null or undefined when calling exportReconciliationsByWithdrawalPredicates().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/export/reconciliations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalPredicatesAPIModelToJSON(requestParameters['withdrawalPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports reconciliations by withdrawal predicates
     */
    async exportReconciliationsByWithdrawalPredicates(withdrawalPredicatesAPIModel: WithdrawalPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportReconciliationsByWithdrawalPredicatesRaw({ withdrawalPredicatesAPIModel: withdrawalPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal by id
     */
    async getWithdrawalRaw(requestParameters: GetWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal by id
     */
    async getWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.getWithdrawalRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal batch by id
     */
    async getWithdrawalBatchRaw(requestParameters: GetWithdrawalBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalBatch().'
            );
        }

        if (requestParameters['batchNum'] == null) {
            throw new runtime.RequiredError(
                'batchNum',
                'Required parameter "batchNum" was null or undefined when calling getWithdrawalBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/batches/{batchNum}`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))).replace(`{${"batchNum"}}`, encodeURIComponent(String(requestParameters['batchNum']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalBatchAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal batch by id
     */
    async getWithdrawalBatch(withdrawalId: string, batchNum: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchAPIModel> {
        const response = await this.getWithdrawalBatchRaw({ withdrawalId: withdrawalId, batchNum: batchNum }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal batch by id
     */
    async getWithdrawalBatchBTCRaw(requestParameters: GetWithdrawalBatchBTCRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchBTCOutputTransactionAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalBatchBTC().'
            );
        }

        if (requestParameters['batchNum'] == null) {
            throw new runtime.RequiredError(
                'batchNum',
                'Required parameter "batchNum" was null or undefined when calling getWithdrawalBatchBTC().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/batches/{batchNum}/btc`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))).replace(`{${"batchNum"}}`, encodeURIComponent(String(requestParameters['batchNum']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalBatchBTCOutputTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal batch by id
     */
    async getWithdrawalBatchBTC(withdrawalId: string, batchNum: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchBTCOutputTransactionAPIModel> {
        const response = await this.getWithdrawalBatchBTCRaw({ withdrawalId: withdrawalId, batchNum: batchNum }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal batches
     */
    async getWithdrawalBatchesRaw(requestParameters: GetWithdrawalBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchesAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalBatches().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/batches`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalBatchesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal batches
     */
    async getWithdrawalBatches(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchesAPIModel> {
        const response = await this.getWithdrawalBatchesRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the withdrawal fee payment
     */
    async getWithdrawalFeePaymentRaw(requestParameters: GetWithdrawalFeePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalFeePaymentAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalFeePayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/fee`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalFeePaymentAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns the withdrawal fee payment
     */
    async getWithdrawalFeePayment(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalFeePaymentAPIModel> {
        const response = await this.getWithdrawalFeePaymentRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Withdrawal payouts
     */
    async getWithdrawalPayoutsRaw(requestParameters: GetWithdrawalPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutAPIModel>>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling getWithdrawalPayouts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/payouts`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayoutAPIModelFromJSON));
    }

    /**
     * Withdrawal payouts
     */
    async getWithdrawalPayouts(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutAPIModel>> {
        const response = await this.getWithdrawalPayoutsRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawals according to filter
     */
    async getWithdrawalsRaw(requestParameters: GetWithdrawalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalWithdrawalSortByAPIModel>> {
        if (requestParameters['withdrawalFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawalFilterAPIModel',
                'Required parameter "withdrawalFilterAPIModel" was null or undefined when calling getWithdrawals().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalFilterAPIModelToJSON(requestParameters['withdrawalFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceWithdrawalWithdrawalSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawals according to filter
     */
    async getWithdrawals(withdrawalFilterAPIModel: WithdrawalFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalWithdrawalSortByAPIModel> {
        const response = await this.getWithdrawalsRaw({ withdrawalFilterAPIModel: withdrawalFilterAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Stops/pauses currently running withdrawal
     */
    async pauseWithdrawalRaw(requestParameters: PauseWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling pauseWithdrawal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/pause`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Stops/pauses currently running withdrawal
     */
    async pauseWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.pauseWithdrawalRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Initiates the withdrawal fee payment
     */
    async payWithdrawalFeeRaw(requestParameters: PayWithdrawalFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling payWithdrawalFee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/fee`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Initiates the withdrawal fee payment
     */
    async payWithdrawalFee(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.payWithdrawalFeeRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Resets all VRS in batches that are not processed yet
     */
    async resetWithdrawalRaw(requestParameters: ResetWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling resetWithdrawal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/reset`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Resets all VRS in batches that are not processed yet
     */
    async resetWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.resetWithdrawalRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Starts withdrawal (in the background job)
     */
    async startWithdrawalRaw(requestParameters: StartWithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling startWithdrawal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/start`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Starts withdrawal (in the background job)
     */
    async startWithdrawal(withdrawalId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.startWithdrawalRaw({ withdrawalId: withdrawalId }, initOverrides);
        return await response.value();
    }

    /**
     * Starts withdrawal with transactions being sent externally (from MM)
     */
    async startWithdrawalExternalRaw(requestParameters: StartWithdrawalExternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling startWithdrawalExternal().'
            );
        }

        if (requestParameters['newStartWithdrawalExternalAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newStartWithdrawalExternalAPIModel',
                'Required parameter "newStartWithdrawalExternalAPIModel" was null or undefined when calling startWithdrawalExternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/start-external`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewStartWithdrawalExternalAPIModelToJSON(requestParameters['newStartWithdrawalExternalAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalAPIModelFromJSON(jsonValue));
    }

    /**
     * Starts withdrawal with transactions being sent externally (from MM)
     */
    async startWithdrawalExternal(withdrawalId: string, newStartWithdrawalExternalAPIModel: NewStartWithdrawalExternalAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalAPIModel> {
        const response = await this.startWithdrawalExternalRaw({ withdrawalId: withdrawalId, newStartWithdrawalExternalAPIModel: newStartWithdrawalExternalAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates withdrawal specific batch
     */
    async updateWithdrawalBatchRaw(requestParameters: UpdateWithdrawalBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawalBatchAPIModel>> {
        if (requestParameters['withdrawalId'] == null) {
            throw new runtime.RequiredError(
                'withdrawalId',
                'Required parameter "withdrawalId" was null or undefined when calling updateWithdrawalBatch().'
            );
        }

        if (requestParameters['batchNum'] == null) {
            throw new runtime.RequiredError(
                'batchNum',
                'Required parameter "batchNum" was null or undefined when calling updateWithdrawalBatch().'
            );
        }

        if (requestParameters['newWithdrawalBatchAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newWithdrawalBatchAPIModel',
                'Required parameter "newWithdrawalBatchAPIModel" was null or undefined when calling updateWithdrawalBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/{withdrawalId}/batches/{batchNum}`.replace(`{${"withdrawalId"}}`, encodeURIComponent(String(requestParameters['withdrawalId']))).replace(`{${"batchNum"}}`, encodeURIComponent(String(requestParameters['batchNum']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewWithdrawalBatchAPIModelToJSON(requestParameters['newWithdrawalBatchAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawalBatchAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates withdrawal specific batch
     */
    async updateWithdrawalBatch(withdrawalId: string, batchNum: number, newWithdrawalBatchAPIModel: NewWithdrawalBatchAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawalBatchAPIModel> {
        const response = await this.updateWithdrawalBatchRaw({ withdrawalId: withdrawalId, batchNum: batchNum, newWithdrawalBatchAPIModel: newWithdrawalBatchAPIModel }, initOverrides);
        return await response.value();
    }

}
