import { Divider } from 'antd';
import React from 'react';

import { ActualBalancesHeader, ActualBalancesList } from './components';

import type { ActualBalancesViewProps } from './types';

const ActualBalancesView: React.FC<ActualBalancesViewProps> = ({ data, 'data-test': dataTest, links }) => (
  <>
    <ActualBalancesHeader data={data} data-test={dataTest && `${dataTest}-header`} links={links} />
    <Divider />
    <ActualBalancesList data={data.balances} data-test={dataTest && `${dataTest}-list`} />
  </>
);

const ActualBalancesViewMemo = React.memo(ActualBalancesView);

export default ActualBalancesViewMemo;
