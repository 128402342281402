import { css, cx } from '@emotion/css';
import { Typography } from 'antd';
import isNil from 'lodash-es/isNil';
import React, { useMemo } from 'react';

import { useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';

import type { ReadonlyComponentProps } from './types';

const { Text } = Typography;

const ReadonlyComponent: React.FC<ReadonlyComponentProps> = ({
  'data-test': dataTest,
  value,
  className,
  style,
  copyable = true,
  ellipsis,
  nonStretches,
}) => {
  const [mouseIn, setMouseIn] = useStateMountSafe<boolean>();
  const onMouseEnter = useMemo(() => (copyable ? () => setMouseIn(true) : noop), [copyable, setMouseIn]);
  const onMouseLeave = useMemo(() => (copyable ? () => setMouseIn(false) : noop), [copyable, setMouseIn]);
  const alwaysVisible = typeof copyable === 'object' && copyable.alwaysVisible;
  return !isNil(value) ? (
    <Text
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-test={dataTest}
      style={style}
      className={cx(
        css`
          color: inherit;
          white-space: nowrap;
          font-size: inherit;
          font-weight: inherit;
        `,
        nonStretches
          ?? css`
            width: 100%;
          `,
        className,
      )}
      ellipsis={ellipsis}
      copyable={(mouseIn || alwaysVisible) && copyable}
    >
      {value}
    </Text>
  ) : null;
};

const ReadonlyComponentMemo = React.memo(ReadonlyComponent) as typeof ReadonlyComponent;

export default ReadonlyComponentMemo;
