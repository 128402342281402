import React, { useMemo } from 'react';

import { SelectWithReloadingItem } from '@/components';

import { useDataOptions, useMessages, useValidationRules } from './hooks';

import type { AssetForSelection, AssetSelectItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetSelectItem = <Values extends Store = Store, Issue extends string | undefined = undefined>({
  'data-test': dataTest,
  name,
  assets,
  Issues,
  readonly,
  required,
  messages: baseMessages,
  selectedAssets,
  rules: extraRules,
  SelectInputProps,
  ItemProps: BaseItemProps,
  validation,
}: AssetSelectItemProps<Values, Issue>) => {
  const dataToOptions = useDataOptions<Values, Issue>({ 'data-test': dataTest, Issues, selectedAssets });
  const messages = useMessages(baseMessages ?? {});

  const rules = useValidationRules<Values>({ rules: extraRules, selectedAssets, ...validation });
  const ItemProps = useMemo(() => ({ ...BaseItemProps, rules }), [BaseItemProps, rules]);

  return (
    <SelectWithReloadingItem<Values, AssetForSelection<Issue>>
      data-test={dataTest}
      disabled={readonly}
      required={required}
      name={name}
      messages={messages}
      refresh={assets.forceRefresh}
      loading={assets.loading}
      options={assets.data}
      dataToOptions={dataToOptions}
      ItemProps={ItemProps}
      SelectInputProps={SelectInputProps}
    />
  );
};

const AssetSelectItemMemo = React.memo(AssetSelectItem) as typeof AssetSelectItem;

export default AssetSelectItemMemo;
