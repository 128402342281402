import React from 'react';

import { MerchantWalletView } from '@/features/merchant-wallets/components';

import { withMerchantWalletDataLoading } from './hocs';

const MerchantWalletCard = withMerchantWalletDataLoading(MerchantWalletView);

const MerchantWalletCardMemo: typeof MerchantWalletCard = React.memo(MerchantWalletCard);

export default MerchantWalletCardMemo;
