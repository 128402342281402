/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollectNowRequestAPIModel
 */
export interface CollectNowRequestAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectNowRequestAPIModel
     */
    asset: string;
}

/**
 * Check if a given object implements the CollectNowRequestAPIModel interface.
 */
export function instanceOfCollectNowRequestAPIModel(value: object): boolean {
    if (!('asset' in value)) return false;
    return true;
}

export function CollectNowRequestAPIModelFromJSON(json: any): CollectNowRequestAPIModel {
    return CollectNowRequestAPIModelFromJSONTyped(json, false);
}

export function CollectNowRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectNowRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'asset': json['asset'],
    };
}

export function CollectNowRequestAPIModelToJSON(value?: CollectNowRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'asset': value['asset'],
    };
}

