import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import type { Report } from '@/features/reports/types';
import { exportReconciliationsBySettlement } from '@/features/settlements/actions';
import type { HookAction } from '@/infrastructure/model';

import useSettlementList from '../useSettlementList';

export interface UseSettlementsActions {
  exportReconciliations: HookAction<[boolean], Report>;
}

export default function useSettlementsActions(): UseSettlementsActions {
  const { loading, filter } = useSettlementList();
  const { withExtractDataDispatch } = useAppDispatch();

  const isExporting = useActionPending(exportReconciliationsBySettlement);
  const exportReconciliationsAction: UseSettlementsActions['exportReconciliations'] = {
    act: useCallback(
      (includeTransactions: boolean) =>
        withExtractDataDispatch(exportReconciliationsBySettlement)({
          predicates: filter,
          includeTransactions,
        }),
      [filter, withExtractDataDispatch],
    ),
    inAction: isExporting,
    available: !loading,
  };

  return { exportReconciliations: exportReconciliationsAction };
}
