import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import ActualBalancesBreadcrumb from '@/pages/balances/actual/components/ActualBalancesBreadcrumb';

import LockedBalancesLink from '../LockedBalancesLink';

import type { LockedBalancesBreadcrumbProps } from './types';

const LockedBalancesBreadcrumb: React.FC<LockedBalancesBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <ActualBalancesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageBalances.LOCKED_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <LockedBalancesLink
            mode="text"
            title={<FormattedMessage id={I18nPageBalances.LOCKED_COMPONENTS_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const LockedBalancesBreadcrumbMemo = React.memo(LockedBalancesBreadcrumb);

export default LockedBalancesBreadcrumbMemo;
