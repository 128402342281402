import { parseReport } from '@/features/reports/api';
import type {
  GasHistorySortByAPIModel,
  MerchantGasHistoryPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { CompanyApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import type { SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';

const gasHistoryApi = new CompanyApi(coreConfigurationFactory('auth'));

export const queryGasHistoryList = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<MerchantGasHistoryPredicatesAPIModel, GasHistorySortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await gasHistoryApi.searchGasHistory(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
  );

export const queryGasHistory = async (id: string, initOverrides?: RequestInit) =>
  gasHistoryApi.getGasHistory(id, initOverrides);

export const requestExportGasHistory = async (
  predicates: MerchantGasHistoryPredicatesAPIModel,
  initOverrides?: RequestInit,
) => parseReport(await gasHistoryApi.exportGasHistory(predicates, initOverrides));
