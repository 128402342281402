import { useAppDispatch } from '@/app/hooks';
import { PageContainer } from '@/components';
import { markCollectAvailableBalanceListDirty } from '@/features/collectable/actions';
import { useFirstRenderEffect } from '@/hooks';

import { CollectableBalancesBreadcrumb, CollectableBalancesCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'balances';

const CollectableBalancesPage: React.FC = () => {
  const { withDispatch } = useAppDispatch();
  useFirstRenderEffect(withDispatch(markCollectAvailableBalanceListDirty));
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<CollectableBalancesBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <CollectableBalancesCard data-test={dataTest && `${dataTest}-card`} />
    </PageContainer>
  );
};

export default CollectableBalancesPage;
