import { AppstoreAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Form, Space } from 'antd';
import React, { useEffect, useMemo } from 'react';

import type { MoneyAmountInputValue } from '@/components';
import { FormattedMessage, FormCompletenessItem, Operation } from '@/components';
import {
  AssetAmountItem as BaseAssetAmountItem,
  withDefaultAmountLimits,
} from '@/features/dictionary/blockchain/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useLocaleSettings, useStateMountSafe } from '@/hooks';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { DonationAssetAmountsItemProps } from './types';
import type { FormListProps } from 'antd/es/form';
import type { Store } from 'rc-field-form/es/interface';

const maxAssetAmounts = 3;

const AssetAmountItem = withDefaultAmountLimits(BaseAssetAmountItem) as typeof BaseAssetAmountItem;

const DonationAssetAmountsItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name: baseName,
  fullName,
  asset,
  initialValues: baseInitialValues,
  onStateChange,
}: DonationAssetAmountsItemProps<Values>) => {
  const fullNameAsArray = useMemo(() => (Array.isArray(fullName) ? fullName : [fullName]), [fullName]);
  const { formatBigNumber } = useLocaleSettings();
  const initialValues = useMemo(
    () => baseInitialValues?.map(({ value }) => ({ value, valid: true, inputValue: formatBigNumber(value) })),
    [baseInitialValues, formatBigNumber],
  );

  const [hasSameValues, setHasSameValues] = useStateMountSafe(false);
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  useEffect(() => {
    onStateChange(isFormComplete && !hasSameValues);
  }, [hasSameValues, isFormComplete, onStateChange]);

  return (
    <Form.List name={baseName as FormListProps['name']} initialValue={initialValues}>
      {(amountFields, { add, remove }, { errors }) => (
        <Form.Item
          className={css`
            margin-bottom: 0;
          `}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 0 }}
        >
          <Space
            key="container"
            wrap
            className={css`
              align-items: start;
            `}
          >
            {amountFields.map(({ key, name, ...field }) => (
              <AssetAmountItem
                key={key}
                data-test={dataTest}
                name={`${name}`}
                nonNegative
                assetId={asset}
                noLabelOnHelp
                ItemProps={{
                  style: { whiteSpace: 'pre-wrap', width: 100 },
                  label: null,
                  wrapperCol: { span: 24 },
                  labelCol: { span: 0 },
                  // preserve: false,
                  ...field,
                }}
                InputProps={{
                  suffix: (
                    <Operation
                      mode="icon"
                      data-test={dataTest && `${dataTest}-remove`}
                      onClick={() => remove(name)}
                      icon={<DeleteOutlined />}
                      title={
                        <FormattedMessage
                          id={
                            I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_REMOVE_TITLE
                          }
                        />
                      }
                    />
                  ),
                }}
              />
            ))}
            <Operation
              mode={!amountFields.length ? 'button' : 'inline'}
              data-test={dataTest && `${dataTest}-add`}
              onClick={() => add()}
              disabled={amountFields.length >= maxAssetAmounts}
              disabledMessage={
                amountFields.length >= maxAssetAmounts && (
                  <FormattedMessage
                    id={
                      I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_LIMIT_EXCEEDED_MESSAGE
                    }
                  />
                )
              }
              icon={<AppstoreAddOutlined />}
              title={
                <FormattedMessage
                  id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_TITLE}
                />
              }
              tooltip={
                <FormattedMessage
                  id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_HELP}
                />
              }
            />
          </Space>
          <Form.ErrorList
            errors={[
              ...errors,
              ...(hasSameValues
                ? [
                    <FormattedMessage
                      key="message"
                      id={
                        I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_DUPLICATE_AMOUNTS_MESSAGE
                      }
                    />,
                  ]
                : []),
            ]}
          />
          <FormCompletenessItem
            onChange={setFormComplete}
            requiredFields={amountFields.map((_, idx) => [...fullNameAsArray, `${idx}`])}
            checkIsComplete={({ getFieldValue }) => {
              const values: (MoneyAmountInputValue | undefined)[] = getFieldValue(fullNameAsArray);
              const stringified = values.map((v) => v?.value?.toString()).filter(notEmpty);
              const someValuesAreEqual = new Set(stringified).size != stringified.length;
              setHasSameValues(someValuesAreEqual);
              return !someValuesAreEqual;
            }}
          />
        </Form.Item>
      )}
    </Form.List>
  );
};

const DonationAssetAmountsItemMemo = React.memo(DonationAssetAmountsItem) as typeof DonationAssetAmountsItem;

export default DonationAssetAmountsItemMemo;
