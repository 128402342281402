import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { globalInit } from '@/features/global/actions';
import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { InitStatus, storedDirtyData } from '@/infrastructure/model';
import { createFullReducers } from '@/infrastructure/model/full/reducers';
import { defaultFullState } from '@/infrastructure/model/full/types';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';
import { suppressPromise } from '@/infrastructure/utils/functions';

import {
  initNetwork,
  markAllowanceDirty,
  markAssetsFullDirty,
  markBlockchainsFullDirty,
  markBlockchainSystemInfoDirty,
  markContractExistenceDirty,
  markNativeBalanceDirty,
  markTokenBalanceDirty,
  markTokenHistoryBalanceDirty,
  networkCache,
  notifyNetworkUpdated,
  storeAllowance,
  storeAssetsFullData,
  storeBlockchainsFullData,
  storeBlockchainSystemInfo,
  storeContractExistence,
  storeNativeBalance,
  storeSelectedNetwork,
  storeTokenBalance,
  storeTokenHistoryBalance,
} from './actions';
import { assetsDefaultSortBy, blockchainDefaultSortBy } from './types';
import {
  createAllowanceKey,
  createAssetAddressHistoryKey,
  createAssetAddressKey,
  createBlockchainAddressKey,
  extractAssetId,
  extractBlockchainType,
} from './utils';

import type { BlockchainState } from './types';
import type { Draft } from 'immer';

const initialState: BlockchainState = {
  selected: { value: BlockchainNetworkTypeAPIModel.TestNet, status: InitStatus.NOT_INITIALIZED },

  bcSystemInfo: storedDirtyData,
  blockchains: defaultFullState({}, blockchainDefaultSortBy),
  tokenBalance: {},
  tokenHistoryBalance: {},
  nativeBalance: {},
  allowance: {},
  assets: defaultFullState({}, assetsDefaultSortBy),
  contractExistence: {},
};

const { storeAssetsFullDataReducer, markAssetsFullDirtyReducer } = createFullReducers(
  'Assets' as const,
  (state: Draft<BlockchainState>) => state.assets,
  (state, assets) => ({ ...state, assets }),
  initialState.assets,
  extractAssetId,
);

const { storeBlockchainsFullDataReducer, markBlockchainsFullDirtyReducer } = createFullReducers(
  'Blockchains' as const,
  (state: Draft<BlockchainState>) => state.blockchains,
  (state, blockchains) => ({ ...state, blockchains }),
  initialState.blockchains,
  extractBlockchainType,
);

const { storeTokenBalanceReducer, markTokenBalanceDirtyReducer } = createSingleReducers(
  'TokenBalance' as const,
  (state: Draft<BlockchainState>) => state.tokenBalance,
  (state, tokenBalance) => ({ ...state, tokenBalance }),
  createAssetAddressKey,
);

const { storeTokenHistoryBalanceReducer, markTokenHistoryBalanceDirtyReducer } = createSingleReducers(
  'TokenHistoryBalance' as const,
  (state: Draft<BlockchainState>) => state.tokenHistoryBalance,
  (state, tokenHistoryBalance) => ({ ...state, tokenHistoryBalance }),
  createAssetAddressHistoryKey,
);

const { storeNativeBalanceReducer, markNativeBalanceDirtyReducer } = createSingleReducers(
  'NativeBalance' as const,
  (state: Draft<BlockchainState>) => state.nativeBalance,
  (state, nativeBalance) => ({ ...state, nativeBalance }),
  createBlockchainAddressKey,
);

const { storeContractExistenceReducer, markContractExistenceDirtyReducer } = createSingleReducers(
  'ContractExistence' as const,
  (state: Draft<BlockchainState>) => state.contractExistence,
  (state, contractExistence) => ({ ...state, contractExistence }),
  createBlockchainAddressKey,
);

const { storeAllowanceReducer, markAllowanceDirtyReducer } = createSingleReducers(
  'Allowance' as const,
  (state: Draft<BlockchainState>) => state.allowance,
  (state, allowance) => ({ ...state, allowance }),
  createAllowanceKey,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markAssetsFullDirty, markAssetsFullDirtyReducer)
    .addCase(storeAssetsFullData, storeAssetsFullDataReducer)
    .addCase(markBlockchainsFullDirty, markBlockchainsFullDirtyReducer)
    .addCase(storeBlockchainsFullData, storeBlockchainsFullDataReducer)
    .addCase(markBlockchainSystemInfoDirty, (state) =>
      !state.bcSystemInfo.isDirty ? { ...state, bcSystemInfo: { ...state.bcSystemInfo, isDirty: false } } : state,
    )
    .addCase(storeBlockchainSystemInfo, (state, { payload }) => ({
      ...state,
      bcSystemInfo: { ...payload, isDirty: false },
    }))

    .addCase(markNativeBalanceDirty, markNativeBalanceDirtyReducer)
    .addCase(storeNativeBalance, storeNativeBalanceReducer)

    .addCase(markTokenBalanceDirty, markTokenBalanceDirtyReducer)
    .addCase(storeTokenBalance, storeTokenBalanceReducer)

    .addCase(markTokenHistoryBalanceDirty, markTokenHistoryBalanceDirtyReducer)
    .addCase(storeTokenHistoryBalance, storeTokenHistoryBalanceReducer)

    .addCase(markContractExistenceDirty, markContractExistenceDirtyReducer)
    .addCase(storeContractExistence, storeContractExistenceReducer)

    .addCase(markAllowanceDirty, markAllowanceDirtyReducer)
    .addCase(storeAllowance, storeAllowanceReducer)

    .addCase(storeSelectedNetwork, (state, { payload }) => ({
      ...state,
      selected: { status: InitStatus.FINISHED, value: payload },
    }))

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );

  listenerMiddleware.startListening({
    actionCreator: globalInit,
    effect: (_, listenerApi) => {
      suppressPromise(listenerApi.dispatch(initNetwork()).unwrap());
      listenerApi.unsubscribe();
    },
  });
  listenerMiddleware.startListening({
    actionCreator: storeSelectedNetwork,
    effect: (_, listenerApi) => {
      const previous = makeSelectSelectedNetwork()(listenerApi.getOriginalState());
      const current = makeSelectSelectedNetwork()(listenerApi.getState());
      networkCache.safeSave(current);
      if (current !== previous) {
        listenerApi.dispatch(notifyNetworkUpdated({ previous, current }));
      }
    },
  });
});

export default reducer;
