/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { AssetFullMetaAPIModel } from './AssetFullMetaAPIModel';
import {
    AssetFullMetaAPIModelFromJSON,
    AssetFullMetaAPIModelFromJSONTyped,
    AssetFullMetaAPIModelToJSON,
} from './AssetFullMetaAPIModel';

/**
 * 
 * @export
 * @interface GetAssetsResponseAPIModel
 */
export interface GetAssetsResponseAPIModel {
    /**
     * 
     * @type {Array<AssetFullMetaAPIModel>}
     * @memberof GetAssetsResponseAPIModel
     */
    assets?: Array<AssetFullMetaAPIModel>;
}

/**
 * Check if a given object implements the GetAssetsResponseAPIModel interface.
 */
export function instanceOfGetAssetsResponseAPIModel(value: object): boolean {
    return true;
}

export function GetAssetsResponseAPIModelFromJSON(json: any): GetAssetsResponseAPIModel {
    return GetAssetsResponseAPIModelFromJSONTyped(json, false);
}

export function GetAssetsResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAssetsResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'assets': json['assets'] == null ? undefined : ((json['assets'] as Array<any>).map(AssetFullMetaAPIModelFromJSON)),
    };
}

export function GetAssetsResponseAPIModelToJSON(value?: GetAssetsResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'assets': value['assets'] == null ? undefined : ((value['assets'] as Array<any>).map(AssetFullMetaAPIModelToJSON)),
    };
}

