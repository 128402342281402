import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { GasWalletInvalidStateBanner } from '@/features/gas-wallets/components';
import { useSubscriptionPlan, useSubscriptionPlanActions } from '@/features/subscription-plans/hooks';
import { SubscriptionPlanStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';

import type { OperationChangeSubscriptionPlanStatusProps } from './types';

const options = {
  error: () => <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_ERROR_MESSAGE} />,
  success: () => (
    <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_SUCCESS_MESSAGE} />
  ),
};

const OperationChangeSubscriptionPlanStatus: React.FC<OperationChangeSubscriptionPlanStatusProps> = ({
  'data-test': dataTest,
  planId,
  mode,
}) => {
  const { data: planState } = useSubscriptionPlan(planId);
  const { activate: activateAction, deactivate: deactivateAction } = useSubscriptionPlanActions(planId);
  const { withNotification } = useNotification();

  const activate = useMemo(() => withNotification(activateAction.act, options), [activateAction, withNotification]);

  const deactivate = useMemo(
    () => withNotification(deactivateAction.act, options),
    [withNotification, deactivateAction],
  );

  const activateUnavailabilityReason = useMemo(() => {
    if (activateAction.unavailabilityReason === 'invalid-status') {
      return (
        <FormattedMessage
          id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE_INVALID_STATUS}
        />
      );
    }
    if (activateAction.unavailabilityReason === 'gas-wallet-issue' && planState.data?.amount.asset) {
      return <GasWalletInvalidStateBanner assetId={planState.data.amount.asset} mode="message" />;
    }
    return undefined;
  }, [activateAction.unavailabilityReason, planState.data?.amount.asset]);
  const deactivateUnavailabilityReason = useMemo(() => {
    if (deactivateAction.unavailabilityReason === 'invalid-status') {
      return (
        <FormattedMessage
          id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_INVALID_STATUS}
        />
      );
    }
    return undefined;
  }, [deactivateAction.unavailabilityReason]);

  return (
    <>
      {(!planState.data?.status || planState.data.status === SubscriptionPlanStatusAPIModel.Draft) && (
        <Operation
          data-test={dataTest && `${dataTest}-activate`}
          title={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE} />}
          mode={mode}
          disabled={!activateAction.available}
          disabledMessage={activateUnavailabilityReason}
          icon={<PlayCircleOutlined style={{ color: 'green' }} />}
          confirmation={{
            title: (
              <FormattedMessage
                id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE_CONFIRMATION}
              />
            ),
          }}
          onClick={activate}
        />
      )}
      {planState.data?.status === SubscriptionPlanStatusAPIModel.Active && (
        <Operation
          data-test={dataTest && `${dataTest}-deactivate`}
          title={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE} />}
          mode={mode}
          icon={<PauseCircleOutlined style={{ color: 'orange' }} />}
          disabled={!!deactivateUnavailabilityReason}
          disabledMessage={deactivateUnavailabilityReason}
          onClick={deactivate}
        />
      )}
      {planState.data?.status === SubscriptionPlanStatusAPIModel.Archived && (
        <Operation
          data-test={dataTest && `${dataTest}-activate`}
          title={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE} />}
          mode={mode}
          disabled={!!activateUnavailabilityReason}
          disabledMessage={activateUnavailabilityReason}
          icon={<PlayCircleOutlined style={{ color: 'green' }} />}
          onClick={activate}
        />
      )}
    </>
  );
};

const OperationChangeSubscriptionPlanStatusMemo = React.memo(OperationChangeSubscriptionPlanStatus);

export default OperationChangeSubscriptionPlanStatusMemo;
