/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyNotificationSettingsAPIModel } from './CompanyNotificationSettingsAPIModel';
import {
    CompanyNotificationSettingsAPIModelFromJSON,
    CompanyNotificationSettingsAPIModelFromJSONTyped,
    CompanyNotificationSettingsAPIModelToJSON,
} from './CompanyNotificationSettingsAPIModel';
import type { ForwarderSettingsDetailsAPIModel } from './ForwarderSettingsDetailsAPIModel';
import {
    ForwarderSettingsDetailsAPIModelFromJSON,
    ForwarderSettingsDetailsAPIModelFromJSONTyped,
    ForwarderSettingsDetailsAPIModelToJSON,
} from './ForwarderSettingsDetailsAPIModel';
import type { GasWalletDetailsAPIModel } from './GasWalletDetailsAPIModel';
import {
    GasWalletDetailsAPIModelFromJSON,
    GasWalletDetailsAPIModelFromJSONTyped,
    GasWalletDetailsAPIModelToJSON,
} from './GasWalletDetailsAPIModel';
import type { PaymentSettingsAPIModel } from './PaymentSettingsAPIModel';
import {
    PaymentSettingsAPIModelFromJSON,
    PaymentSettingsAPIModelFromJSONTyped,
    PaymentSettingsAPIModelToJSON,
} from './PaymentSettingsAPIModel';

/**
 * 
 * @export
 * @interface CompanySettingsAPIModel
 */
export interface CompanySettingsAPIModel {
    /**
     * 
     * @type {number}
     * @memberof CompanySettingsAPIModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsAPIModel
     */
    defaultAsset: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySettingsAPIModel
     */
    activeAssets?: Array<string>;
    /**
     * 
     * @type {Array<CompanyNotificationSettingsAPIModel>}
     * @memberof CompanySettingsAPIModel
     */
    notificationSettings?: Array<CompanyNotificationSettingsAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsAPIModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsAPIModel
     */
    website?: string;
    /**
     * 
     * @type {PaymentSettingsAPIModel}
     * @memberof CompanySettingsAPIModel
     */
    payments: PaymentSettingsAPIModel;
    /**
     * 
     * @type {{ [key: string]: GasWalletDetailsAPIModel; }}
     * @memberof CompanySettingsAPIModel
     */
    gasWallets: { [key: string]: GasWalletDetailsAPIModel; };
    /**
     * 
     * @type {Array<ForwarderSettingsDetailsAPIModel>}
     * @memberof CompanySettingsAPIModel
     */
    forwarderSettings?: Array<ForwarderSettingsDetailsAPIModel>;
}

/**
 * Check if a given object implements the CompanySettingsAPIModel interface.
 */
export function instanceOfCompanySettingsAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('defaultAsset' in value)) return false;
    if (!('payments' in value)) return false;
    if (!('gasWallets' in value)) return false;
    return true;
}

export function CompanySettingsAPIModelFromJSON(json: any): CompanySettingsAPIModel {
    return CompanySettingsAPIModelFromJSONTyped(json, false);
}

export function CompanySettingsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySettingsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'defaultAsset': json['defaultAsset'],
        'activeAssets': json['activeAssets'] == null ? undefined : json['activeAssets'],
        'notificationSettings': json['notificationSettings'] == null ? undefined : ((json['notificationSettings'] as Array<any>).map(CompanyNotificationSettingsAPIModelFromJSON)),
        'email': json['email'] == null ? undefined : json['email'],
        'website': json['website'] == null ? undefined : json['website'],
        'payments': PaymentSettingsAPIModelFromJSON(json['payments']),
        'gasWallets': json['gasWallets'],
        'forwarderSettings': json['forwarderSettings'] == null ? undefined : ((json['forwarderSettings'] as Array<any>).map(ForwarderSettingsDetailsAPIModelFromJSON)),
    };
}

export function CompanySettingsAPIModelToJSON(value?: CompanySettingsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'defaultAsset': value['defaultAsset'],
        'activeAssets': value['activeAssets'],
        'notificationSettings': value['notificationSettings'] == null ? undefined : ((value['notificationSettings'] as Array<any>).map(CompanyNotificationSettingsAPIModelToJSON)),
        'email': value['email'],
        'website': value['website'],
        'payments': PaymentSettingsAPIModelToJSON(value['payments']),
        'gasWallets': value['gasWallets'],
        'forwarderSettings': value['forwarderSettings'] == null ? undefined : ((value['forwarderSettings'] as Array<any>).map(ForwarderSettingsDetailsAPIModelToJSON)),
    };
}

