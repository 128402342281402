import { useMemo } from 'react';

import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayment } from '@/features/payments/actions';
import { makeSelectPayment } from '@/features/payments/selectors';
import type { Payment } from '@/features/payments/types';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

import type { UsePayment } from './types';

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Payment>, Parameters<typeof fetchPayment>[0]> =>
  fetchPayment({ id, force });

export default function usePayment(id: string | undefined): UsePayment {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchPayment, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectPayment, noSingleDataIdFn(), id, dataFetchingSelector);
}
