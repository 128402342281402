import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import SubscriptionPlansBreadcrumb from '@/pages/subscriptions/plans/components/SubscriptionPlansBreadcrumb';

import SubscriptionPlanAddLink from '../SubscriptionPlanAddLink';

import type { SubscriptionPlanAddBreadcrumbProps } from './types';

const SubscriptionPlanAddBreadcrumb: React.FC<SubscriptionPlanAddBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <SubscriptionPlansBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSubscriptions.PLAN_ADD_COMPONENTS_BREADCRUMB} />
        ) : (
          <SubscriptionPlanAddLink
            title={<FormattedMessage id={I18nPageSubscriptions.PLAN_ADD_COMPONENTS_BREADCRUMB} />}
            mode="link"
          />
        ),
      },
      ...items,
    ]}
  />
);

const SubscriptionPlanAddBreadcrumbMemo = React.memo(SubscriptionPlanAddBreadcrumb);

export default SubscriptionPlanAddBreadcrumbMemo;
