import { Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import PayoutsDefaultRoute from './components/PayoutsDefaultRoute';
import PayoutAddPage from './payout-add';
import PayoutBatchViewPage from './payout-batch-view';
import PayoutEditPage from './payout-edit';
import PayoutViewPage from './payout-view';
import PayoutsPage from './payouts';
import { PayoutsPageRoutes } from './routes';

import type React from 'react';

const PayoutsPagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={PayoutsPageRoutes.ALL} Component={PayoutsPage} />
        <Route path={PayoutsPageRoutes.ADD} Component={PayoutAddPage} />
        <Route path={PayoutsPageRoutes.EDIT} Component={PayoutEditPage} />
        <Route path={PayoutsPageRoutes.VIEW} Component={PayoutViewPage} />
        <Route path={PayoutsPageRoutes.BATCH_VIEW} Component={PayoutBatchViewPage} />
        <Route path="*" Component={PayoutsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const PayoutsPages: React.FC = withBasicPageGuard(PayoutsPagesRaw);

export { PayoutsDefaultRoute };

export default <Route key={AppPageRoutes.PAYOUTS} path={`${AppPageRoutes.PAYOUTS}/*`} Component={PayoutsPages} />;
