import { type UseAppListDataView, useAppListDataView } from '@/app/hooks';
import { storeCollectLockedBalanceListParameters } from '@/features/collectable/actions';
import type { CollectableBalance, CollectableBalanceFilterPredicate } from '@/features/collectable/types';
import type { CollectableAddressBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useCollectLockedBalanceList from '../useCollectLockedBalanceList';

export type UseCollectLockedBalanceListView = UseAppListDataView<
  CollectableBalance,
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel
>;

export default function useCollectLockedBalanceListView(): UseCollectLockedBalanceListView {
  return useAppListDataView(useCollectLockedBalanceList(), storeCollectLockedBalanceListParameters);
}
