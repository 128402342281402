/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { MerchantWalletTransferDirectionAPIModel } from './MerchantWalletTransferDirectionAPIModel';
import {
    MerchantWalletTransferDirectionAPIModelFromJSON,
    MerchantWalletTransferDirectionAPIModelFromJSONTyped,
    MerchantWalletTransferDirectionAPIModelToJSON,
} from './MerchantWalletTransferDirectionAPIModel';
import type { MerchantWalletTransferKindAPIModel } from './MerchantWalletTransferKindAPIModel';
import {
    MerchantWalletTransferKindAPIModelFromJSON,
    MerchantWalletTransferKindAPIModelFromJSONTyped,
    MerchantWalletTransferKindAPIModelToJSON,
} from './MerchantWalletTransferKindAPIModel';
import type { MerchantWalletTransferReconciliationStatusAPIModel } from './MerchantWalletTransferReconciliationStatusAPIModel';
import {
    MerchantWalletTransferReconciliationStatusAPIModelFromJSON,
    MerchantWalletTransferReconciliationStatusAPIModelFromJSONTyped,
    MerchantWalletTransferReconciliationStatusAPIModelToJSON,
} from './MerchantWalletTransferReconciliationStatusAPIModel';
import type { Tuple2BlockchainTypeStringAPIModel } from './Tuple2BlockchainTypeStringAPIModel';
import {
    Tuple2BlockchainTypeStringAPIModelFromJSON,
    Tuple2BlockchainTypeStringAPIModelFromJSONTyped,
    Tuple2BlockchainTypeStringAPIModelToJSON,
} from './Tuple2BlockchainTypeStringAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletTransferPredicatesAPIModel
 */
export interface MerchantWalletTransferPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {MerchantWalletTransferDirectionAPIModel}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    directionEq?: MerchantWalletTransferDirectionAPIModel;
    /**
     * 
     * @type {Array<MerchantWalletTransferKindAPIModel>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    kindIn?: Array<MerchantWalletTransferKindAPIModel>;
    /**
     * 
     * @type {Array<MerchantWalletTransferReconciliationStatusAPIModel>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    reconciliationStatusIn?: Array<MerchantWalletTransferReconciliationStatusAPIModel>;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<Tuple2BlockchainTypeStringAPIModel>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    btHashIn?: Array<Tuple2BlockchainTypeStringAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    withdrawalIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    walletLike?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    blockNumIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    senderLike?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    destinationLike?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    txHashLike?: string;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    broadcastAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof MerchantWalletTransferPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the MerchantWalletTransferPredicatesAPIModel interface.
 */
export function instanceOfMerchantWalletTransferPredicatesAPIModel(value: object): boolean {
    return true;
}

export function MerchantWalletTransferPredicatesAPIModelFromJSON(json: any): MerchantWalletTransferPredicatesAPIModel {
    return MerchantWalletTransferPredicatesAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletTransferPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletTransferPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'directionEq': json['directionEq'] == null ? undefined : MerchantWalletTransferDirectionAPIModelFromJSON(json['directionEq']),
        'kindIn': json['kindIn'] == null ? undefined : ((json['kindIn'] as Array<any>).map(MerchantWalletTransferKindAPIModelFromJSON)),
        'reconciliationStatusIn': json['reconciliationStatusIn'] == null ? undefined : ((json['reconciliationStatusIn'] as Array<any>).map(MerchantWalletTransferReconciliationStatusAPIModelFromJSON)),
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'btHashIn': json['btHashIn'] == null ? undefined : ((json['btHashIn'] as Array<any>).map(Tuple2BlockchainTypeStringAPIModelFromJSON)),
        'withdrawalIn': json['withdrawalIn'] == null ? undefined : json['withdrawalIn'],
        'walletLike': json['walletLike'] == null ? undefined : json['walletLike'],
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'blockNumIn': json['blockNumIn'] == null ? undefined : json['blockNumIn'],
        'senderLike': json['senderLike'] == null ? undefined : json['senderLike'],
        'destinationLike': json['destinationLike'] == null ? undefined : json['destinationLike'],
        'txHashLike': json['txHashLike'] == null ? undefined : json['txHashLike'],
        'broadcastAtRange': json['broadcastAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['broadcastAtRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function MerchantWalletTransferPredicatesAPIModelToJSON(value?: MerchantWalletTransferPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'directionEq': MerchantWalletTransferDirectionAPIModelToJSON(value['directionEq']),
        'kindIn': value['kindIn'] == null ? undefined : ((value['kindIn'] as Array<any>).map(MerchantWalletTransferKindAPIModelToJSON)),
        'reconciliationStatusIn': value['reconciliationStatusIn'] == null ? undefined : ((value['reconciliationStatusIn'] as Array<any>).map(MerchantWalletTransferReconciliationStatusAPIModelToJSON)),
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'btHashIn': value['btHashIn'] == null ? undefined : ((value['btHashIn'] as Array<any>).map(Tuple2BlockchainTypeStringAPIModelToJSON)),
        'withdrawalIn': value['withdrawalIn'],
        'walletLike': value['walletLike'],
        'assetIn': value['assetIn'],
        'blockNumIn': value['blockNumIn'],
        'senderLike': value['senderLike'],
        'destinationLike': value['destinationLike'],
        'txHashLike': value['txHashLike'],
        'broadcastAtRange': DateTimeRangeAPIModelToJSON(value['broadcastAtRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

