import React from 'react';

import type { MerchantWalletsListProps } from '@/features/merchant-wallets/components';
import { OperationDeployMerchantWallet } from '@/features/merchant-wallets/components';

const MerchantWalletActualListOperations: MerchantWalletsListProps['Operations'] = ({
  'data-test': dataTest,
  value,
}) => <OperationDeployMerchantWallet data-test={dataTest} bt={value.blockchain} />;

const MerchantWalletActualListOperationsMemo = React.memo(MerchantWalletActualListOperations);

export default MerchantWalletActualListOperationsMemo;
