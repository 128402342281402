/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BigIntRangeAPIModel } from './BigIntRangeAPIModel';
import {
    BigIntRangeAPIModelFromJSON,
    BigIntRangeAPIModelFromJSONTyped,
    BigIntRangeAPIModelToJSON,
} from './BigIntRangeAPIModel';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { TransactionResultAPIModel } from './TransactionResultAPIModel';
import {
    TransactionResultAPIModelFromJSON,
    TransactionResultAPIModelFromJSONTyped,
    TransactionResultAPIModelToJSON,
} from './TransactionResultAPIModel';

/**
 * 
 * @export
 * @interface MerchantGasHistoryPredicatesAPIModel
 */
export interface MerchantGasHistoryPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    addressLike?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    sponsorAddressLike?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    functionNameEq?: string;
    /**
     * 
     * @type {BigIntRangeAPIModel}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    gasPriceRange?: BigIntRangeAPIModel;
    /**
     * 
     * @type {BigIntRangeAPIModel}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    gasSpentRange?: BigIntRangeAPIModel;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {TransactionResultAPIModel}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    resultEq?: TransactionResultAPIModel;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    createdAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof MerchantGasHistoryPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the MerchantGasHistoryPredicatesAPIModel interface.
 */
export function instanceOfMerchantGasHistoryPredicatesAPIModel(value: object): boolean {
    return true;
}

export function MerchantGasHistoryPredicatesAPIModelFromJSON(json: any): MerchantGasHistoryPredicatesAPIModel {
    return MerchantGasHistoryPredicatesAPIModelFromJSONTyped(json, false);
}

export function MerchantGasHistoryPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantGasHistoryPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'addressLike': json['addressLike'] == null ? undefined : json['addressLike'],
        'sponsorAddressLike': json['sponsorAddressLike'] == null ? undefined : json['sponsorAddressLike'],
        'functionNameEq': json['functionNameEq'] == null ? undefined : json['functionNameEq'],
        'gasPriceRange': json['gasPriceRange'] == null ? undefined : BigIntRangeAPIModelFromJSON(json['gasPriceRange']),
        'gasSpentRange': json['gasSpentRange'] == null ? undefined : BigIntRangeAPIModelFromJSON(json['gasSpentRange']),
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'resultEq': json['resultEq'] == null ? undefined : TransactionResultAPIModelFromJSON(json['resultEq']),
        'createdAtRange': json['createdAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdAtRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function MerchantGasHistoryPredicatesAPIModelToJSON(value?: MerchantGasHistoryPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'addressLike': value['addressLike'],
        'sponsorAddressLike': value['sponsorAddressLike'],
        'functionNameEq': value['functionNameEq'],
        'gasPriceRange': BigIntRangeAPIModelToJSON(value['gasPriceRange']),
        'gasSpentRange': BigIntRangeAPIModelToJSON(value['gasSpentRange']),
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'resultEq': TransactionResultAPIModelToJSON(value['resultEq']),
        'createdAtRange': DateTimeRangeAPIModelToJSON(value['createdAtRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

