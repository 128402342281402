import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditQRDelegateContractsLink } from '@/pages/audit/routes';

import type { QRDelegateContractsLinkProps } from './types';

const QRDelegateContractsLink: React.FC<QRDelegateContractsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  icon,
  title,
}) => (
  <Link
    data-test={dataTest}
    to={auditQRDelegateContractsLink()}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageAudit.QR_CONTRACTS_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const QRDelegateContractsLinkMemo = React.memo(QRDelegateContractsLink);

export default QRDelegateContractsLinkMemo;
