import { CheckCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import type { MerchantWalletActiveStatusViewProps } from './types';

const i18n: Record<'true' | 'false', I18nMessages> = {
  true: I18nFeatureMerchantWallets.COMPONENTS_ACTIVE_STATUS_VIEW_ACTIVE,
  false: I18nFeatureMerchantWallets.COMPONENTS_ACTIVE_STATUS_VIEW_HISTORY,
};

const icons: Record<'true' | 'false', React.ReactNode> = {
  true: <CheckCircleOutlined style={{ color: 'green' }} />,
  false: <HistoryOutlined style={{ color: 'blue' }} />,
};

const MerchantWalletActiveStatusView: React.FC<MerchantWalletActiveStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) =>
  value ? (
    <StatusViewElement data-test={dataTest} i18n={i18n[`${value}`]} icon={icons[`${value}`]} mode={mode} />
  ) : null;

const MerchantWalletActiveStatusViewMemo = React.memo(MerchantWalletActiveStatusView);

export default MerchantWalletActiveStatusViewMemo;
