import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectLockedBalances } from '@/features/collectable/actions';
import {
  makeSelectCollectLockedBalanceListData,
  makeSelectCollectLockedBalanceListParameters,
} from '@/features/collectable/selectors';
import type { CollectableBalance, CollectableBalanceFilterPredicate } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { CollectableAddressBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseCollectLockedBalanceList = UseAppListData<
  CollectableBalance,
  CollectableBalanceFilterPredicate,
  CollectableAddressBalanceSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<CollectableBalance>, Parameters<typeof fetchCollectLockedBalances>[0]> =>
  fetchCollectLockedBalances({ force });

const dataFetchingSelector = makeSelectPending(fetchCollectLockedBalances);
const dataSelector = makeSelectCollectLockedBalanceListData();
const parametersSelector = makeSelectCollectLockedBalanceListParameters();

export default function useCollectLockedBalanceList(): UseCollectLockedBalanceList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
