import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import AuditBreadcrumb from '@/pages/audit/components/AuditBreadcrumb';

import QRDelegateContractsLink from '../QRDelegateContractsLink';

import type { QRDelegateContractsBreadcrumbProps } from './types';

const QRDelegateContractsBreadcrumb: React.FC<QRDelegateContractsBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  items = [],
}) => (
  <AuditBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.QR_CONTRACTS_COMPONENTS_BREADCRUMB} />
        ) : (
          <QRDelegateContractsLink
            mode="text"
            title={<FormattedMessage id={I18nPageAudit.QR_CONTRACTS_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const QRDelegateContractsBreadcrumbMemo = React.memo(QRDelegateContractsBreadcrumb);

export default QRDelegateContractsBreadcrumbMemo;
