import { Alert } from 'antd';
import React, { useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useCollectThreshold } from '@/features/collectable/hooks';
import { QRDelegateContractIssueBanner } from '@/features/company-settings/components';
import { useCompanySettings, useQRDelegateContracts } from '@/features/company-settings/hooks';
import type { QRDelegateContract } from '@/features/company-settings/types';
import { AssetAmount, AssetLabel } from '@/features/dictionary/blockchain/components';
import { useBlockchainSystemInfos, useListAssets } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainSystemInfo } from '@/features/dictionary/blockchain/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { stringComparator } from '@/infrastructure/model/comparators';
import { onlyUnique } from '@/infrastructure/utils/functions';
import type { ArrayElement } from '@/infrastructure/utils/ts';
import { notEmpty } from '@/infrastructure/utils/ts';
import CompanySettingsLink from '@/pages/settings/company/summary/components/CompanySettingsLink';

import type { QRSupportItemInputProps } from './types';
import type { QRSupportErrorData } from '../../types';
import type BigNumber from 'bignumber.js';

interface QRAsset {
  code: string;
  bt: BlockchainTypeAPIModel;
  forwarder?: BlockchainSystemInfo['forwarder'];
  contract?: QRDelegateContract;
  qrEnabled?: boolean;
  threshold?: BigNumber;
}

const parseError = (
  { code: asset, bt, qrEnabled, threshold, contract }: QRAsset,
  amount: BigNumber,
  dataTest?: string,
): ArrayElement<NonNullable<QRSupportErrorData['errors']>> | undefined => {
  if (!qrEnabled)
    return {
      asset,
      type: 'not-selected',
      message: (
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_REASONS_NOT_SELECTED}
          values={{
            asset: (
              <AssetLabel data-test={dataTest && `${dataTest}-${asset}-notSelected-asset`} value={asset} mode="short" />
            ),
            ln: (title: React.ReactNode) => (
              <CompanySettingsLink
                data-test={dataTest && `${dataTest}-${asset}-notSelected-link`}
                title={title}
                mode="text"
              />
            ),
          }}
        />
      ),
    };
  if (!contract || contract.issue)
    return {
      asset,
      type: 'wallet-issue',
      message: (
        <QRDelegateContractIssueBanner
          data-test={dataTest && `${dataTest}-${asset}-walletIssue`}
          bt={bt}
          mode="message"
          type="link"
        />
      ),
    };
  if (threshold?.gt(amount))
    return {
      asset,
      type: 'low-amount',
      message: (
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_REASONS_LOW_AMOUNT}
          values={{
            threshold: (
              <AssetAmount
                data-test={dataTest && `${dataTest}-${asset}-lowAmount-threshold`}
                assetId={asset}
                value={threshold}
                withCurrency
              />
            ),
          }}
        />
      ),
    };
  return undefined;
};

const parseWarning = (
  { code: asset, bt, contract, threshold }: QRAsset,
  amount: BigNumber,
  dataTest?: string,
): ArrayElement<NonNullable<QRSupportErrorData['warnings']>> | undefined => {
  if (!contract || contract.issue)
    return {
      asset,
      type: 'wallet-issue',
      message: (
        <QRDelegateContractIssueBanner
          data-test={dataTest && `${dataTest}-${asset}-walletIssue`}
          bt={bt}
          mode="message"
          type="link"
        />
      ),
    };
  if (threshold?.gt(amount))
    return {
      asset,
      type: 'low-amount',
      message: (
        <FormattedMessageWithMarkup
          id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_WARNING_REASONS_LOW_AMOUNT}
          values={{
            threshold: (
              <AssetAmount
                data-test={dataTest && `${dataTest}-${asset}-lowAmount-threshold`}
                assetId={asset}
                value={threshold}
                withCurrency
              />
            ),
          }}
        />
      ),
    };
  return undefined;
};

const assetComparator = stringComparator(({ code }: QRAsset) => code)('ASC');

const QRSupportItemInput: React.FC<QRSupportItemInputProps> = ({
  'data-test': dataTest,
  value,
  assets,
  amount,
  onChange,
}) => {
  const { data: settingsState } = useCompanySettings();
  const { data: allAssetState } = useListAssets();
  const { data: blockchainsState } = useBlockchainSystemInfos();
  const { data: contractsState } = useQRDelegateContracts();
  const { data: thresholdState } = useCollectThreshold();

  const assetsState = useMemo(
    () =>
      allAssetState.data
        ?.filter(({ code }) => assets.includes(code))
        .map(({ code, blockchain }) =>
          blockchain
            ? {
                code,
                bt: blockchain,
                forwarder: blockchainsState.data?.find(({ bt }) => bt === blockchain)?.forwarder,
                contract: contractsState.data?.find((contract) => contract.blockchain === blockchain),
                qrEnabled: settingsState.data?.qrSupport.find((btFw) => btFw.blockchain === blockchain)?.isEnabled,
                threshold: thresholdState.data?.find(({ asset }) => asset === code)?.value,
              }
            : undefined,
        )
        .filter(notEmpty)
        .sort(assetComparator) ?? [],
    [
      allAssetState.data,
      assets,
      blockchainsState.data,
      contractsState.data,
      settingsState.data?.qrSupport,
      thresholdState.data,
    ],
  );

  useEffect(() => {
    const errors = assetsState
      .filter(({ forwarder }) => forwarder === 'required')
      .map((asset) => parseError(asset, amount, dataTest))
      .filter(notEmpty);
    if (errors.length) {
      if (!isEqual(value?.errors, errors)) {
        onChange?.({ ...value, errors, warnings: undefined });
      }
      return;
    }

    const warnings = assetsState
      .filter(({ forwarder, qrEnabled }) => forwarder === 'supported' && !!qrEnabled)
      .map((asset) => parseWarning(asset, amount, dataTest))
      .filter(notEmpty);
    if (warnings.length) {
      if (!isEqual(value?.warnings, warnings)) {
        onChange?.({ ...value, errors: undefined, warnings });
      }
      return;
    }

    if (value?.errors || value?.warnings) {
      onChange?.({ ...value, errors: undefined, warnings: undefined });
    }
  }, [onChange, value, assetsState, amount, dataTest]);

  if (value?.errors) {
    const errorAssets = value.errors.map(({ asset }) => asset).filter(onlyUnique);
    return (
      <Alert
        data-test={dataTest}
        showIcon
        type="error"
        message={
          <FormattedMessage
            id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_MESSAGE}
          />
        }
        description={
          value.errors.length > 1 ? (
            <FormattedMessageWithMarkup
              id={
                I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_DESCRIPTION_MULTIPLE
              }
              values={{
                assets: errorAssets.map((asset, idx) => (
                  <React.Fragment key={asset}>
                    <AssetLabel
                      data-test={dataTest && `${dataTest}-${asset}-asset`}
                      value={asset}
                      mode="short"
                      style={{ display: 'inline' }}
                      withBlockchainMark
                    />
                    {idx < errorAssets.length - 1 && ', '}
                  </React.Fragment>
                )),
                reasons: (
                  <>
                    {value.errors.map(({ asset, type, message }) => (
                      <li key={`${type}-${asset}`}>{message}</li>
                    ))}
                  </>
                ),
              }}
            />
          ) : (
            <FormattedMessageWithMarkup
              id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERROR_DESCRIPTION_SINGLE}
              values={{
                asset: (
                  <AssetLabel
                    data-test={dataTest && `${dataTest}-${value.errors[0].asset}-asset`}
                    value={value.errors[0].asset}
                    mode="short"
                    style={{ display: 'inline' }}
                    withBlockchainMark
                  />
                ),
                reason: value.errors[0].message,
              }}
            />
          )
        }
      />
    );
  }

  if (value?.warnings) {
    const warningAssets = value.warnings.map(({ asset }) => asset).filter(onlyUnique);
    return (
      <Alert
        data-test={dataTest}
        showIcon
        type="warning"
        message={
          value.warnings.length > 1 ? (
            <FormattedMessageWithMarkup
              id={
                I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_WARNING_DESCRIPTION_MULTIPLE
              }
              values={{
                assets: warningAssets.map((asset, idx) => (
                  <React.Fragment key={asset}>
                    <AssetLabel
                      data-test={dataTest && `${dataTest}-${asset}-asset`}
                      value={asset}
                      mode="short"
                      style={{ display: 'inline' }}
                      withBlockchainMark
                    />
                    {idx < warningAssets.length - 1 && ', '}
                  </React.Fragment>
                )),
                reasons: (
                  <>
                    {value.warnings.map(({ asset, type, message }) => (
                      <li key={`${type}-${asset}`}>{message}</li>
                    ))}
                  </>
                ),
              }}
            />
          ) : (
            <FormattedMessageWithMarkup
              id={
                I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_WARNING_DESCRIPTION_SINGLE
              }
              values={{
                asset: (
                  <AssetLabel
                    data-test={dataTest && `${dataTest}-${value.warnings[0].asset}-asset`}
                    value={value.warnings[0].asset}
                    mode="short"
                    style={{ display: 'inline' }}
                    withBlockchainMark
                  />
                ),
                reason: value.warnings[0].message,
              }}
            />
          )
        }
      />
    );
  }

  return null;
};

const QRSupportItemInputMemo = React.memo(QRSupportItemInput);

export default QRSupportItemInputMemo;
