import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import RechargeAddressesBreadcrumb from '@/pages/recharges/addresses/components/RechargeAddressesBreadcrumb';

import RechargeAddressAddLink from '../RechargeAddressAddLink';

import type { RechargeAddressAddBreadcrumbProps } from './types';

const RechargeAddressAddBreadcrumb: React.FC<RechargeAddressAddBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <RechargeAddressesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageRecharges.ADDRESS_ADD_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <RechargeAddressAddLink
            title={<FormattedMessage id={I18nPageRecharges.ADDRESS_ADD_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="link"
          />
        ),
      },
      ...items,
    ]}
  />
);

const RechargeAddressAddBreadcrumbMemo = React.memo(RechargeAddressAddBreadcrumb);

export default RechargeAddressAddBreadcrumbMemo;
