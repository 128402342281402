import { PageContainer } from '@/components';
import QRDelegateContractLink from '@/pages/audit/qr-contract-view/components/QRDelegateContractLink';

import {
  QRDelegateContractListOperations,
  QRDelegateContractListCard,
  QRDelegateContractsBreadcrumb,
} from './components';

import type React from 'react';

const dataTest: string | undefined = 'contracts';

const QRDelegateContractsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<QRDelegateContractsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <QRDelegateContractListCard
      data-test={dataTest && `${dataTest}-list`}
      DelegateLink={QRDelegateContractLink}
      Operations={QRDelegateContractListOperations}
    />
  </PageContainer>
);

export default QRDelegateContractsPage;
