import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { useMerchantWalletWeb3Actions } from '@/features/merchant-wallets/hooks';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationDeployMerchantWalletWithConnectorProps } from './types';

const OperationDeployMerchantWalletWithConnector: React.FC<OperationDeployMerchantWalletWithConnectorProps> = ({
  'data-test': dataTest,
  bt,
  onSuccess,
  withInProgress = emptyWith,
}) => {
  const { deploy: deployAction } = useMerchantWalletWeb3Actions(bt, undefined);
  const blockchainState = useBlockchainSystemInfo(bt);
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) =>
        state.apiType === BlockchainAPITypeAPIModel.WEB3 ? state : null,
      ),
    [blockchainState.data],
  );
  const { connector, account } = useWeb3Connector();
  const { withNotification } = useNotification();
  const options = useMemo(
    () => ({
      error: () => ({
        message: (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_FAILED_MESSAGE}
          />
        ),
        description: (
          <FormattedMessageWithMarkup
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_FAILED_DESCRIPTION}
            values={{ account }}
          />
        ),
      }),
      success: () => (
        <FormattedMessage
          id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_SUCCESS_MESSAGE}
        />
      ),
    }),
    [account],
  );
  const doDeploy = useMemo(
    () => withNotification(withOnSuccess(withInProgress(deployAction.act), onSuccess), options),
    [withNotification, withInProgress, deployAction.act, onSuccess, options],
  );
  const disabledMessage = useMemo(() => {
    switch (deployAction.unavailabilityReason) {
      case 'already-deployed':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_ALREADY_DEPLOYED}
          />
        );
      case 'no-blockchain-data':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA}
          />
        );
      case 'no-wallet-signature':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_WALLET_SIGNATURE}
          />
        );
      case 'loading':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'no-user':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_USER}
          />
        );
      case 'invalid-chain-id':
        return (
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_INVALID_CHAIN_ID}
          />
        );
      case 'unsupported-chain':
        return (
          <FormattedMessageWithMarkup
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_UNSUPPORTED}
            values={{ bt }}
          />
        );
      case undefined:
        return undefined;
    }
  }, [bt, deployAction.unavailabilityReason]);

  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connector.id}
      requiredChain={blockchain.data?.chainId ? { id: blockchain.data.chainId, network: bt } : undefined}
      disabled={!deployAction.available}
      disabledMessage={disabledMessage}
      inProgress={deployAction.inAction}
      mainAction={useMemo(
        () => ({
          onAction: doDeploy,
          title: (
            <FormattedMessage
              id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [connector.name, doDeploy],
      )}
    />
  );
};

const OperationDeployMerchantWalletWithConnectorContexted = withWeb3Connector(
  OperationDeployMerchantWalletWithConnector,
);

const OperationDeployMerchantWalletWithConnectorMemo = React.memo(
  OperationDeployMerchantWalletWithConnectorContexted,
) as typeof OperationDeployMerchantWalletWithConnectorContexted;

export default OperationDeployMerchantWalletWithConnectorMemo;
