import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { AddressLink, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import QRDelegateContractIssueBanner from '../QRDelegateContractIssueBanner';
import QRDelegateContractStatusView from '../QRDelegateContractStatusView';

import type { QRDelegateContractViewProps } from './types';

const QRDelegateContractView: React.FC<QRDelegateContractViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
  MerchantWalletLink,
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<ReadonlyComponent data-test={dataTest && `${dataTest}-id`} value={data.id} />}
    column={columns}
  >
    {data.issue && (
      <Descriptions.Item span={3}>
        <QRDelegateContractIssueBanner
          bt={data.blockchain}
          data-test={dataTest && `${dataTest}-banner`}
          type="action"
          mode="alert"
        />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCompanySettings.LABELS_ROW_TITLE_QR_DELEGATE_STATE} />}>
      <QRDelegateContractStatusView data-test={dataTest && `${dataTest}-status`} value={data.status} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureCompanySettings.LABELS_ROW_TITLE_QR_DELEGATE_BLOCKCHAIN} />}
    >
      <BlockchainLabel data-test={dataTest && `${dataTest}-blockchain`} bt={data.blockchain} />
    </Descriptions.Item>
    {data.address ? (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureCompanySettings.LABELS_ROW_TITLE_QR_DELEGATE_ADDRESS} />}
      >
        <AddressLink data-test={dataTest && `${dataTest}-address`} bt={data.blockchain} address={data.address} />
      </Descriptions.Item>
    ) : null}
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureCompanySettings.LABELS_ROW_TITLE_QR_DELEGATE_MERCHANT_WALLET} />}
    >
      {MerchantWalletLink ? (
        <MerchantWalletLink
          data-test={dataTest && `${dataTest}-wallet`}
          value={{ bt: data.blockchain, address: data.merchantWallet }}
          mode="text"
        />
      ) : (
        <AddressLink data-test={dataTest && `${dataTest}-wallet`} bt={data.blockchain} address={data.merchantWallet} />
      )}
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureCompanySettings.LABELS_ROW_TITLE_QR_DELEGATE_FACTORY} />}
    >
      <AddressLink
        data-test={dataTest && `${dataTest}-factoryAddress`}
        bt={data.blockchain}
        address={data.forwarderFactory}
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureCompanySettings.LABELS_ROW_TITLE_QR_DELEGATE_CREATED_AT} />}
    >
      <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
    </Descriptions.Item>
  </Descriptions>
);
const QRDelegateContractViewMemo = React.memo(QRDelegateContractView);

export default QRDelegateContractViewMemo;
