import { InteractionOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { collectableEntityLink } from '@/pages/balances/routes';

import type { CollectableBalanceEntityLinkProps } from './types';

const CollectableBalanceEntityLink: React.FC<CollectableBalanceEntityLinkProps> = ({
  value,
  title,
  mode = 'inline',
  icon,
  'data-test': dataTest,
}) => {
  return (
    <Link
      data-test={dataTest}
      to={collectableEntityLink(value)}
      icon={icon ?? <InteractionOutlined />}
      disabledMessage={<FormattedMessage id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_LINK_TITLE} />}
      mode={mode}
      title={title || <FormattedMessage id={I18nPageBalances.COLLECTABLE_ENTITY_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const CollectableBalanceEntityLinkMemo = React.memo(CollectableBalanceEntityLink);

export default CollectableBalanceEntityLinkMemo;
