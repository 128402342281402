/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { PaymentTransactionStatusAPIModel } from './PaymentTransactionStatusAPIModel';
import {
    PaymentTransactionStatusAPIModelFromJSON,
    PaymentTransactionStatusAPIModelFromJSONTyped,
    PaymentTransactionStatusAPIModelToJSON,
} from './PaymentTransactionStatusAPIModel';

/**
 * 
 * @export
 * @interface PaymentTransactionAPIModel
 */
export interface PaymentTransactionAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionAPIModel
     */
    paymentId: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof PaymentTransactionAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionAPIModel
     */
    txId: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentTransactionAPIModel
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionAPIModel
     */
    amount: string;
    /**
     * 
     * @type {PaymentTransactionStatusAPIModel}
     * @memberof PaymentTransactionAPIModel
     */
    status: PaymentTransactionStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof PaymentTransactionAPIModel
     */
    paidAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentTransactionAPIModel
     */
    isForwarder: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionAPIModel
     */
    blockNum?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionAPIModel
     */
    withdrawnWith?: string;
}

/**
 * Check if a given object implements the PaymentTransactionAPIModel interface.
 */
export function instanceOfPaymentTransactionAPIModel(value: object): boolean {
    if (!('paymentId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('txId' in value)) return false;
    if (!('index' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('status' in value)) return false;
    if (!('paidAt' in value)) return false;
    if (!('isForwarder' in value)) return false;
    return true;
}

export function PaymentTransactionAPIModelFromJSON(json: any): PaymentTransactionAPIModel {
    return PaymentTransactionAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentId': json['paymentId'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'txId': json['txId'],
        'index': json['index'],
        'amount': json['amount'],
        'status': PaymentTransactionStatusAPIModelFromJSON(json['status']),
        'paidAt': (new Date(json['paidAt'])),
        'isForwarder': json['isForwarder'],
        'blockNum': json['blockNum'] == null ? undefined : json['blockNum'],
        'withdrawnWith': json['withdrawnWith'] == null ? undefined : json['withdrawnWith'],
    };
}

export function PaymentTransactionAPIModelToJSON(value?: PaymentTransactionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentId': value['paymentId'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'txId': value['txId'],
        'index': value['index'],
        'amount': value['amount'],
        'status': PaymentTransactionStatusAPIModelToJSON(value['status']),
        'paidAt': ((value['paidAt']).toISOString()),
        'isForwarder': value['isForwarder'],
        'blockNum': value['blockNum'],
        'withdrawnWith': value['withdrawnWith'],
    };
}

