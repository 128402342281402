import { useCallback, useMemo } from 'react';

import type { UseAppLoadableData } from '@/app/hooks';
import type { CompanyAssetWithWalletState, CompanySettings } from '@/features/company-settings/types';
import { useListAssets, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import type { AssetWithNetwork } from '@/features/dictionary/blockchain/types';
import { useActiveFactoryMerchantWallets } from '@/features/merchant-wallets/hooks';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { MerchantWalletIssue } from '@/features/merchant-wallets/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { combine } from '@/infrastructure/model';

import useCompanySettings from '../useCompanySettings';

const combineData = (
  settingsData: LoadingStateWithDirty<CompanySettings>,
  assetsData: LoadingStateWithDirty<AssetWithNetwork[]>,
  walletsData: LoadingStateWithDirty<{ wallet?: MerchantWallet }[]>,
): LoadingStateWithDirty<CompanyAssetWithWalletState[]> =>
  combine(
    combine(settingsData, assetsData, ({ activeAssets }, assets) =>
      assets.filter(({ code }) => activeAssets.includes(code)),
    ),
    walletsData,
    (assets, wallets) =>
      assets.map((asset) => {
        const wallet = wallets.find(({ wallet }) => asset.blockchain === wallet?.blockchain)?.wallet;
        return { ...asset, walletIssue: wallet ? wallet.issue : MerchantWalletIssue.NOT_DEPLOYED };
      }),
  );

export default function useCompanyActiveAssets(): UseAppLoadableData<
  CompanyAssetWithWalletState[],
  LoadingStateWithDirty<CompanyAssetWithWalletState[]>
> {
  const settingsState = useCompanySettings();
  const { network } = useSelectedNetwork();
  const assetsState = useListAssets(network);
  const walletsState = useActiveFactoryMerchantWallets(network);

  const forceRefresh = useCallback(
    async () => combineData(await settingsState.forceRefresh(), assetsState.data, walletsState.data),
    [assetsState.data, settingsState, walletsState.data],
  );

  const data = useMemo(
    () => combineData(settingsState.data, assetsState.data, walletsState.data),
    [assetsState.data, settingsState.data, walletsState.data],
  );

  const loading = settingsState.loading || assetsState.loading || walletsState.loading;
  return { data, forceRefresh, loading };
}
