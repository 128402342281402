import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import ReportSchedulesBreadcrumb from '@/pages/reports/report-schedules/components/ReportSchedulesBreadcrumb';

import ReportScheduleAddLink from '../ReportScheduleAddLink';

import type { ReportScheduleAddBreadcrumbProps } from './types';

const ReportScheduleAddBreadcrumbRaw: React.FC<ReportScheduleAddBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <ReportSchedulesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_ADD_COMPONENTS_BREADCRUMB} />
        ) : (
          <ReportScheduleAddLink
            mode="link"
            title={<FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_ADD_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const ReportScheduleAddBreadcrumb = React.memo(ReportScheduleAddBreadcrumbRaw);

export default ReportScheduleAddBreadcrumb;
