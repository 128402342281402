import { Form } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import {
  FormattedMessage,
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  PageLoading,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import type { AssetAmountSelectItemValue } from '@/features/dictionary/blockchain/components';
import { useIsFeatureEnabled } from '@/features/feature-toggle/hooks';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { amountToAPI } from '@/infrastructure/model/api';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';

import {
  DescriptionItem,
  ExpiresAtItem,
  QRSupportErrorItem,
  MetadataItem,
  AuxAssetsItem,
  PaymentAmountItem,
} from './components';
import { useInitialValues } from './hooks';

import type { CreatePaymentFormData, CreatePaymentFormProps } from './types';
import type { NamePath } from 'rc-field-form/lib/interface';

const requiredFields: NamePath[] = [
  [nameof<CreatePaymentFormData>('amount'), nameof<AssetAmountSelectItemValue>('asset')],
  [nameof<CreatePaymentFormData>('amount'), nameof<AssetAmountSelectItemValue>('amount')],
  nameof<CreatePaymentFormData>('fwError'),
  nameof<CreatePaymentFormData>('expiresAt'),
];

const fieldsToValidate: NamePath[] = [
  nameof<CreatePaymentFormData>('metadata'),
  nameof<CreatePaymentFormData>('description'),
  nameof<CreatePaymentFormData>('auxAssets'),
];

const formMessages = {
  submit: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_SUBMIT_BUTTON} tagName="span" />,
};

const CreatePaymentForm: React.FC<CreatePaymentFormProps> = ({
  'data-test': dataTest,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const auxEnabled = useIsFeatureEnabled('enableAuxAmount');
  const initialValues = useInitialValues();
  const previousValues = useRef(initialValues);
  const { form, withResetForm } = useForm<CreatePaymentFormData>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useMemo(
    () =>
      withSubmitting(async ({ amount, auxAssets, ...values }: CreatePaymentFormData) => {
        await onSubmit({
          ...values,
          amount: amountToAPI({ value: amount.amount.value ?? BigNumber(0), asset: amount.asset }),
          auxAmounts:
            auxAssets
              ?.filter((asset) => asset !== amount.asset)
              .map((asset) => amountToAPI({ value: amount.amount.value ?? BigNumber(0), asset })) ?? [],
          expiresAt: values.expiresAt.toDate(),
        });
        setTimeout(doReset, 0);
      }),
    [doReset, onSubmit, withSubmitting],
  );
  const [doSubmitForm, setDoSubmitForm] = useStateMountSafe<Func | undefined>(undefined);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const isValueNonInitial = !initialValues || !isEqual(initialValues, value);
      const isValueUpdated = !previousValues.current || !isEqual(previousValues.current, value);
      const isCompleteAndUpdated = complete && isValueNonInitial;

      if (isFormComplete !== isCompleteAndUpdated) {
        setFormComplete(isCompleteAndUpdated);
      }
      if (isValueUpdated) {
        previousValues.current = value;
      }
      if (isFormComplete !== complete || isValueUpdated) {
        const submitFn = complete
          ? withSubmitting(
              withVoidOrThrow(
                wrap(
                  () => doSubmit(value),
                  () => onSubmitting?.(true),
                  () => onSubmitting?.(false),
                ),
              ),
            )
          : undefined;
        submitCallback?.(submitFn);
        setDoSubmitForm(() => submitFn);
      }
    },
    [
      form,
      initialValues,
      isFormComplete,
      setFormComplete,
      withSubmitting,
      submitCallback,
      setDoSubmitForm,
      doSubmit,
      onSubmitting,
    ],
  );

  const mainAmount = Form.useWatch<Partial<CreatePaymentFormData['amount']> | undefined>(
    nameof<CreatePaymentFormData>('amount'),
    form,
  );

  if (!initialValues) {
    return <PageLoading type="CreatePaymentForm" />;
  }

  return (
    <Form<CreatePaymentFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      {...layout}
      initialValues={initialValues}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <PaymentAmountItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-mainAmount`}
        name={nameof<CreatePaymentFormData>('amount')}
      />
      <QRSupportErrorItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-forwarder`}
        name={nameof<CreatePaymentFormData>('fwError')}
        assets={mainAmount?.asset}
        amount={mainAmount?.amount?.value}
      />
      <ExpiresAtItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-expiresAt`}
        name={nameof<CreatePaymentFormData>('expiresAt')}
      />
      <DescriptionItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-description`}
        name={nameof<CreatePaymentFormData>('description')}
      />
      <MetadataItem<CreatePaymentFormData>
        data-test={dataTest && `${dataTest}-metadata`}
        name={nameof<CreatePaymentFormData>('metadata')}
      />
      {auxEnabled ? (
        <AuxAssetsItem
          data-test={dataTest && `${dataTest}-auxAssets`}
          name={nameof<CreatePaymentFormData>('auxAssets')}
          mainAsset={mainAmount?.asset}
          amount={mainAmount?.amount?.value}
        />
      ) : null}
      <FormCompletenessItem<CreatePaymentFormData>
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { display: 'none' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const CreatePaymentFormMemo = React.memo(CreatePaymentForm);

export default CreatePaymentFormMemo;
