/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3796
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CollectTaskSummaryAPIModel } from './CollectTaskSummaryAPIModel';
import {
    CollectTaskSummaryAPIModelFromJSON,
    CollectTaskSummaryAPIModelFromJSONTyped,
    CollectTaskSummaryAPIModelToJSON,
} from './CollectTaskSummaryAPIModel';
import type { PageCollectTaskSortByAPIModel } from './PageCollectTaskSortByAPIModel';
import {
    PageCollectTaskSortByAPIModelFromJSON,
    PageCollectTaskSortByAPIModelFromJSONTyped,
    PageCollectTaskSortByAPIModelToJSON,
} from './PageCollectTaskSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceCollectTaskSummaryCollectTaskSortByAPIModel
 */
export interface SliceCollectTaskSummaryCollectTaskSortByAPIModel {
    /**
     * 
     * @type {Array<CollectTaskSummaryAPIModel>}
     * @memberof SliceCollectTaskSummaryCollectTaskSortByAPIModel
     */
    list?: Array<CollectTaskSummaryAPIModel>;
    /**
     * 
     * @type {PageCollectTaskSortByAPIModel}
     * @memberof SliceCollectTaskSummaryCollectTaskSortByAPIModel
     */
    page: PageCollectTaskSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceCollectTaskSummaryCollectTaskSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceCollectTaskSummaryCollectTaskSortByAPIModel interface.
 */
export function instanceOfSliceCollectTaskSummaryCollectTaskSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceCollectTaskSummaryCollectTaskSortByAPIModelFromJSON(json: any): SliceCollectTaskSummaryCollectTaskSortByAPIModel {
    return SliceCollectTaskSummaryCollectTaskSortByAPIModelFromJSONTyped(json, false);
}

export function SliceCollectTaskSummaryCollectTaskSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceCollectTaskSummaryCollectTaskSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(CollectTaskSummaryAPIModelFromJSON)),
        'page': PageCollectTaskSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceCollectTaskSummaryCollectTaskSortByAPIModelToJSON(value?: SliceCollectTaskSummaryCollectTaskSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(CollectTaskSummaryAPIModelToJSON)),
        'page': PageCollectTaskSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

