import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantWalletDeployTransactions } from '@/features/merchant-wallets/hooks';

import type { OperationRefreshMerchantWalletDeployTransactionsProps } from './types';

const OperationRefreshMerchantWalletDeployTransactions: React.FC<
  OperationRefreshMerchantWalletDeployTransactionsProps
> = ({ 'data-test': dataTest, bt, address, title, mode = 'inline' }) => {
  const { forceRefresh } = useMerchantWalletDeployTransactions(bt, address);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={{ title }} />;
};

const OperationRefreshMerchantWalletDeployTransactionsMemo = React.memo(
  OperationRefreshMerchantWalletDeployTransactions,
);

export default OperationRefreshMerchantWalletDeployTransactionsMemo;
