import React, { useMemo } from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { useCollectThreshold } from '@/features/collectable/hooks';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRMinimumAmountMessageProps } from './types';

const QRMinimumAmountMessage: React.FC<QRMinimumAmountMessageProps> = ({ 'data-test': dataTest, asset }) => {
  const { data: thresholdState } = useCollectThreshold();
  const assetThreshold = useMemo(
    () => thresholdState.data?.find((threshold) => threshold.asset === asset),
    [asset, thresholdState.data],
  );

  return assetThreshold ? (
    <FormattedMessageWithMarkup
      id={I18nFeatureCompanySettings.COMPONENTS_QR_MINIMUM_AMOUNT_MESSAGE}
      values={{
        amount: (
          <AssetAmount
            data-test={dataTest && `${dataTest}-threshold`}
            assetId={assetThreshold.asset}
            value={assetThreshold.value}
          />
        ),
      }}
    />
  ) : null;
};

const QRMinimumAmountMessageMemo = React.memo(QRMinimumAmountMessage);

export default QRMinimumAmountMessageMemo;
